<div class="page-wraper">

    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">Gallery</h1>
                </div>
            </div>
        </div>

        <div class="section-full content-inner">
            <div class="container">
                <div class="shop-form">
                    <div class="row col-xl-12">
                        <div class="col-xl-3" *ngFor="let data of screen"  style="margin-bottom: 10px;">
                            <img src={{data.img}} alt={{data.alt}} style="width: 100%; height: 100%; object-fit: cover; border-radius: 15px;"
                            (click)="imgClick(data.img)">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>