import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offer2',
  templateUrl: './offer2.component.html',
  styleUrls: ['./offer2.component.css']
})
export class Offer2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  submit()
  {
    window.open("http://fbcakes.com/", '_blank');
  }


}
