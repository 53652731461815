import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-headera',
  templateUrl: './headera.component.html',
  styleUrls: ['./headera.component.css']
})
export class HeaderaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  setheadertype(type) {
    localStorage.setItem("header_type", type)
  }
}
