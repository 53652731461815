import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  store = [
    {
      "Id": 1,
      "Title": "Manyata",
      "Address": "Saroj Paradise Apartments Rachenahalli Main Rd,P\/T Layout,Sinthan Nagar",
      "City": "Bengaluru,Karnataka",
      "Pincode": 560045,
      "Mobile": 7299940444,
      "Cid": 1
    },
    {
      "Id": 3,
      "Title": "Peelamedu",
      "Address": "PKD Nagar, Avinashi Road, Peelamedu",
      "City": "Coimbatore",
      "Pincode": 641004,
      "Mobile": 9626455585,
      "Cid": 2
    },
    {
      "Id": 4,
      "Title": "Kpkm",
      "Address": "60, RGM Complex OMR, Karapakkam",
      "City": "Chennai",
      "Pincode": 600097,
      "Mobile": 7299922119,
      "Cid": 3
    },
    {
      "Id": 24,
      "Title": "Kovilambakkam",
      "Address": "Medavakkam Main Road,Kovilambakkam Bus Stop",
      "City": "Chennai",
      "Pincode": 600129,
      "Mobile": 7299959559,
      "Cid": 3
    },
    {
      "Id": 25,
      "Title": "Ponniamman Koil",
      "Address": "No.5, Pillaiyar Kovil St., Ponniaman Bustop",
      "City": "Chennai",
      "Pincode": 600091,
      "Mobile": 7299929229,
      "Cid": 3
    },
    {
      "Id": 26,
      "Title": "Keelkattalai Bus stop",
      "Address": "Kumaran St,(opp) Keelakattalai Bus Stop",
      "City": "Chennai",
      "Pincode": 600117,
      "Mobile": 7299975888,
      "Cid": 3
    },
    {
      "Id": 27,
      "Title": "Madipakkam (Ram Nagar)",
      "Address": "No,921 Ground Floor,Srinivasa Nagar,Madipakkam",
      "City": "Chennai",
      "Pincode": 600091,
      "Mobile": 7299989889,
      "Cid": 3
    },
    {
      "Id": 28,
      "Title": "Adambakkam",
      "Address": "O,N:23 N,N:15, East Karikalan 1st St,Adambakkam",
      "City": "Chennai",
      "Pincode": 600088,
      "Mobile": 7299939888,
      "Cid": 3
    },
    {
      "Id": 29,
      "Title": "Medavakkam",
      "Address": "3\/121 Perumbakkam main Road,Medavakkam",
      "City": "Chennai",
      "Pincode": 600100,
      "Mobile": 7299924224,
      "Cid": 3
    },
    {
      "Id": 30,
      "Title": "Pallavaram",
      "Address": "No:25 Gst Road, Pallavaram",
      "City": "Chennai",
      "Pincode": 600043,
      "Mobile": 7299972424,
      "Cid": 3
    },
    {
      "Id": 31,
      "Title": "Velachery",
      "Address": "No,100 FT ROAD VELACHERY, NEAR GRT",
      "City": "Chennai",
      "Pincode": 600042,
      "Mobile": 7299919119,
      "Cid": 3
    },
    {
      "Id": 32,
      "Title": "Siddhapudur",
      "Address": "1150,chinnaswamy naidu road,GP signal new siddhapudur Kovai-641044",
      "City": "Coimbatore",
      "Pincode": 641044,
      "Mobile": 9626455532,
      "Cid": 2
    },
    {
      "Id": 34,
      "Title": "Ashok Pillar",
      "Address": "Ashok Pillar Junction ",
      "City": "Chennai",
      "Pincode": 600033,
      "Mobile": 7299919888,
      "Cid": 3
    },
    {
      "Id": 37,
      "Title": "Perungudi",
      "Address": "No:16 Ground Floor ,Mgr Main Road,Kandanchavadi",
      "City": "Chennai",
      "Pincode": 600096,
      "Mobile": 7299939339,
      "Cid": 3
    },
    {
      "Id": 54,
      "Title": "Selaiyur (OLD)",
      "Address": "No 542, Velacherry Main Road, Camp Road Junction, Selaiyur",
      "City": "Chennai",
      "Pincode": 600073,
      "Mobile": 7338886673,
      "Cid": 3
    },
    {
      "Id": 55,
      "Title": "Egmore",
      "Address": "Shivalaya Building,97 and 98,Ethiraj Salai,Egmore",
      "City": "Chennai",
      "Pincode": 600008,
      "Mobile": 7305556222,
      "Cid": 3
    },
    {
      "Id": 58,
      "Title": "Valasaravakkam",
      "Address": "NO 35, Ground Floor, Arcot Road, Valasaravakkam",
      "City": "Chennai",
      "Pincode": 600087,
      "Mobile": 7299959888,
      "Cid": 3
    },
    {
      "Id": 63,
      "Title": "Anna Nagar",
      "Address": "116\/132 and 114\/130, A.G Block 4th Ave, AB Block, Shanthi Colony, Anna Nagar",
      "City": "Chennai",
      "Pincode": 600040,
      "Mobile": 7305566888,
      "Cid": 3
    },
    {
      "Id": 64,
      "Title": "Kolathur",
      "Address": "No 146 Paper mills road, Sakthivel nagar, Kolathur",
      "City": "Chennai",
      "Pincode": 600082,
      "Mobile": 7305590555,
      "Cid": 3
    },
    {
      "Id": 65,
      "Title": "Ekkatuthangal",
      "Address": "30, Jawaharlal Nehru Rd, Achugam Nagar, Ekkatuthangal, Chennai, Tamil Nadu 600032",
      "City": "Chennai",
      "Pincode": 600032,
      "Mobile": 7305530555,
      "Cid": 3
    },
    {
      "Id": 66,
      "Title": "Triplicane",
      "Address": "257, Bharathi Salai, Ellis Puram, Padupakkam, Triplicane, Chennai, Tamil Nadu 600005",
      "City": "Chennai",
      "Pincode": 600005,
      "Mobile": 8939993388,
      "Cid": 3
    },
    {
      "Id": 70,
      "Title": "Perambur",
      "Address": "159\/71, Madhavaram High Rd, Perambur South, Madhavaram High Rd, Perambur South, Perambur",
      "City": "Chennai",
      "Pincode": 600011,
      "Mobile": 8939992232,
      "Cid": 3
    },
    {
      "Id": 72,
      "Title": "Ambattur O.T.",
      "Address": "No.455, Lakshmi Complex, MTH Road, Ambattur",
      "City": "Thiruvallur",
      "Pincode": 600053,
      "Mobile": 8575357525,
      "Cid": 29
    },
    {
      "Id": 76,
      "Title": "Sukrawarpet",
      "Address": "543 Sukrawarpet St, Gandhi Park",
      "City": "Coimbatore",
      "Pincode": 641001,
      "Mobile": 9626455522,
      "Cid": 2
    },
    {
      "Id": 78,
      "Title": "Ashok Nagar",
      "Address": "Old no:22\/6,New no:76\/6 1st Avenue Ashok Nagar",
      "City": "Chennai",
      "Pincode": 600083,
      "Mobile": 8939600082,
      "Cid": 3
    },
    {
      "Id": 81,
      "Title": "Moolakadai",
      "Address": "MOOLAKADAI BUS STOP JUNCTION",
      "City": "Chennai",
      "Pincode": 600060,
      "Mobile": 8939600086,
      "Cid": 3
    },
    {
      "Id": 93,
      "Title": "Thiruvallur",
      "Address": "oil mill, 176, JN Rd, Thiruvallur",
      "City": "Chennai",
      "Pincode": 602003,
      "Mobile": 8939600080,
      "Cid": 29
    },
    {
      "Id": 95,
      "Title": "Thiruvotriyur",
      "Address": "Theradi , Sannathi Street Chennai - 600019",
      "City": "Chennai",
      "Pincode": "(null)",
      "Mobile": 7550096219,
      "Cid": 29
    },
    {
      "Id": 98,
      "Title": "Avadi",
      "Address": "Poonthamalli road, Near Ponnu super market, Avadi",
      "City": "Chennai",
      "Pincode": "(null)",
      "Mobile": 7550096212,
      "Cid": 29
    },
    {
      "Id": 101,
      "Title": "Choolaimedu",
      "Address": "No:11 Nelson Manickam Road",
      "City": "Chennai",
      "Pincode": 600094,
      "Mobile": 8939600095,
      "Cid": 3
    },
    {
      "Id": 102,
      "Title": "RedHills",
      "Address": "FB cakes  No.168 GNT road SKLS Galaxy mall (or) pvr cinema opposite Redhills",
      "City": "Chennai",
      "Pincode": 600052,
      "Mobile": 9150014112,
      "Cid": 29
    },
    {
      "Id": 103,
      "Title": "Padi",
      "Address": "Fb cakes, 182\/A C.T.H Road, Padi",
      "City": "Chennai",
      "Pincode": 600050,
      "Mobile": 9150014113,
      "Cid": 29
    },
    {
      "Id": 105,
      "Title": "JP Nagar - Sarakki Signal",
      "Address": "No.4\/5,Sarakki gate, Kanakapura Road, JP Nagar",
      "City": "Bangalore",
      "Pincode": 560078,
      "Mobile": 9880413008,
      "Cid": 1
    },
    {
      "Id": 106,
      "Title": "JP Nagar - Royal School",
      "Address": "24th main road, JP Nagar",
      "City": "Banglore",
      "Pincode": 560078,
      "Mobile": 9880153004,
      "Cid": 1
    },
    {
      "Id": 107,
      "Title": "Pallikaranai",
      "Address": "Pallikaranai Main Road  NEAR:-THAMARAI KULAM",
      "City": "pallikaranai",
      "Pincode": 600100,
      "Mobile": 7824882888,
      "Cid": 3
    },
    {
      "Id": 109,
      "Title": "Sundarapuram",
      "Address": "Opposite to Gandhi Nagar Bus stand",
      "City": "Coimbatore",
      "Pincode": 641024,
      "Mobile": 9626455222,
      "Cid": 2
    },
    {
      "Id": 110,
      "Title": "Fresh Bakes Store",
      "Address": "Balaguru Gardens",
      "City": "Coimbatore",
      "Pincode": 641004,
      "Mobile": 9360445956,
      "Cid": 2
    },
    {
      "Id": 111,
      "Title": "T.Nagar",
      "Address": "Shop 1 and 2 North Usman Road Panagal Park Market, T. Nagar",
      "City": "Chennai",
      "Pincode": 600017,
      "Mobile": 8939600041,
      "Cid": 3
    },
    {
      "Id": 113,
      "Title": "Villivakkam",
      "Address": "Villivakkam Bus Depot",
      "City": "Chennai",
      "Pincode": 600049,
      "Mobile": 7825881888,
      "Cid": 3
    },
    {
      "Id": 115,
      "Title": "avadi",
      "Address": "AVadi bus stop near by poorvika",
      "City": "avadi",
      "Pincode": 600054,
      "Mobile": 9150977764,
      "Cid": 29
    },
    {
      "Id": 116,
      "Title": "Thiruninravur",
      "Address": "Opp to SBI",
      "City": "Chennai",
      "Pincode": "(null)",
      "Mobile": 8925527019,
      "Cid": 29
    },
    {
      "Id": 117,
      "Title": "Mannadi",
      "Address": "No 68\/137, Prakasam Salai, Mannadi",
      "City": "Chennai",
      "Pincode": 600108,
      "Mobile": 1,
      "Cid": 29
    },
    {
      "Id": 122,
      "Title": "Ramanathapuram",
      "Address": "784, Trichy Road, T Nagar, Ramanathapuram",
      "City": "Coimbatore",
      "Pincode": 641045,
      "Mobile": 9626455226,
      "Cid": 2
    },
    {
      "Id": 125,
      "Title": "BTM Layout",
      "Address": "No.11\/1 ,100Feet Ring Road BTM BTM Layout",
      "City": "Bangalore -76",
      "Pincode": 560076,
      "Mobile": 9880615125,
      "Cid": 1
    },
    {
      "Id": 127,
      "Title": "Sriperumbudur",
      "Address": "No 141,Gandhi Road, Sriperumbudur",
      "City": "Chennai",
      "Pincode": 602105,
      "Mobile": 9150743338,
      "Cid": 29
    },
    {
      "Id": 128,
      "Title": "Kanchipuram",
      "Address": "No.227, Gandhi Road, Near Theradi, Kanchipuram",
      "City": "Kanchipuram",
      "Pincode": 631501,
      "Mobile": 9150744486,
      "Cid": 29
    },
    {
      "Id": 132,
      "Title": "Ganapathy",
      "Address": "GPK Building, Ganapathy",
      "City": "Coimbatore",
      "Pincode": 641006,
      "Mobile": 9626455333,
      "Cid": 2
    },
    {
      "Id": 133,
      "Title": "Ponneri",
      "Address": "NO:277,GROUND FLOOR,HARIHARAN BAZAR STREET,PONNERI, THIRUVALLUR-601204",
      "City": "Chennai",
      "Pincode": 601204,
      "Mobile": 9791055542,
      "Cid": 29
    },
    {
      "Id": 134,
      "Title": "Gummidipondi",
      "Address": "no237\/aA Gnt road klk complex gummidipondi Near by bus stand oppsite indian bank",
      "City": "Thiruvallur",
      "Pincode": 601201,
      "Mobile": 8925702223,
      "Cid": 29
    },
    {
      "Id": 137,
      "Title": "Maduravoyal",
      "Address": "No 02 Sannathi Street, Near Market Bus stop, Maduravoyal, Chennai, Tamil Nadu 600095",
      "City": "Chennai",
      "Pincode": 600095,
      "Mobile": 9789000000,
      "Cid": 29
    },
    {
      "Id": 138,
      "Title": "Nolambur",
      "Address": "NO-8\/PC-1,Mogappair West,near 7M bus stand chennai-",
      "City": "Chennai",
      "Pincode": 600037,
      "Mobile": 7358588834,
      "Cid": 29
    },
    {
      "Id": 139,
      "Title": "Thirumullaivoyal",
      "Address": "Thirumullaivoyal",
      "City": "Chennai",
      "Pincode": 600062,
      "Mobile": 8925702225,
      "Cid": 29
    },
    {
      "Id": 140,
      "Title": "TIRUTTANI",
      "Address": "85 & 87, CUDDAPAH TRUNK  ROAD,TIRUTTANI,TIRUVALLUR   NEAR BY RAILWAY STATION",
      "City": "Thiruvallur",
      "Pincode": 631209,
      "Mobile": 7397377723,
      "Cid": 29
    },
    {
      "Id": 148,
      "Title": "Thiruvalllur",
      "Address": "No.3\/4 J.N road Ground Floor",
      "City": "Thiruvallur",
      "Pincode": 602001,
      "Mobile": 9150255546,
      "Cid": 29
    },
    {
      "Id": 152,
      "Title": "Uthukottai",
      "Address": "near by anna salai",
      "City": "Thiruvallur",
      "Pincode": 673541,
      "Mobile": 9150255539,
      "Cid": 29
    }
  ]

  window()
  {
    document.location.href = window.location.origin + ''
  }

}
