import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
// var finalprice=0

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.css']
})


export class ItemDetailsComponent implements OnInit {
  title: String = ""
  product;
  pid: boolean = false;
  msg: string = "";
  kgprice = 0;
  myProperty = 'this write in script';
  variantgroups = [];
  addongroups = [];
  options = [];
  predefinedquantity = []
  predefinedquantityblast = []
  qty: string="";
  qty2: string=""
  message = [];
  baseurl = window.location.origin;
  submitbtn:boolean=false;
  selectedCountry: any;
  qtytext:string=""
  cakemsgalert:boolean=false;
  Sel_Kg_Id: number = null;
  optionGroup;
  spgId=0;
  

  private opts = [
    {
      key: 1,
      value: '0.5Kg'
    },
    {
      key: 2,
      value: '1Kg'
    },
    {
      key: 3,
      value: '2Kg'
    },
    {
      key: 4,
      value: '4.5Kg'
    }
  ];

  myoption=[
    {
      Id:1,
      name:'Eggless',
      price:100,
      selected:false
    }

    
  ]

  event2 = [
    {
      name: 'cakes and prices',
      link: 'http://fbcakes.com/offer/cakes-and-prices'
    },

    {
      name: 'cakes near me',
      link: 'http://fbcakes.com/offer/cakes-near-me'
    },
    {
      name: 'cakes for birthday',
      link: 'http://fbcakes.com/offer/cakes-for-birthday'
    },
    {
      name: 'cakes for girls',
      link: 'http://fbcakes.com/offer/cakes-for-girls'
    },
    {
      name: 'cakes for boys',
      link: 'http://fbcakes.com/offer/cakes-for-boys'
    },
    {
      name: 'cakes online',
      link: 'http://fbcakes.com/offer/cakes-online'
    },




    {
      name: 'cakes for kids',
      link: 'http://fbcakes.com/offer/cakes-for-kids'
    },
    {
      name: 'cake design',
      link: 'http://fbcakes.com/offer/cake-design'
    },
    {
      name: 'cakes for anniversary',
      link: 'http://fbcakes.com/offer/cakes-for-anniversary'
    },
    {
      name: 'cakes for children birthday',
      link: 'http://fbcakes.com/offer/cakes-for-children-birthday'
    },
    {
      name: 'cakes for girls birthday',
      link: 'http://fbcakes.com/offer/cakes-for-girls-birthday'
    },
    {
      name: 'cakes at low price',
      link: 'http://fbcakes.com/offer/cakes-at-low-price'
    },
    
    {
      name: 'cakes available near me',
      link: 'http://fbcakes.com'
    },
    {
      name: 'cakes below 300',
      link: 'http://fbcakes.com/offer/cakes-below-300'
    },
    {
      name: 'cakes below 500',
      link: 'http://fbcakes.com/offer/cakes-below-500'
    },



    {
      name: 'birthday cakes for girls',
      link: 'http://fbcakes.com/offer/birthday-cakes-for-girls'
    },
    {
      name: 'birthday cakes for boys',
      link: 'http://fbcakes.com/offer/birthday-cakes-for-boys'
    },
    {
      name: 'baby shower cakes for girls',
      link: 'http://fbcakes.com'
    },
    {
      name: 'birthday cakes for men',
      link: 'http://fbcakes.com'
    },
    {
      name: 'baby shower cakes for boys',
      link: 'http://fbcakes.com'
    },
    {
      name: 'birthday cakes for women',
      link: 'http://fbcakes.com/offer/birthday-cakes-for-women'
    },



    // Hydrabed
    {
      name: 'cakes in Hyderabad',
      link: 'http://fbcakes.com/'
    },

    {
      name: 'customized cakes Hyderabad',
      link: 'http://fbcakes.com/'
    },

    {
      name: 'cake shop online delivery Banjara hills',
      link: 'http://fbcakes.com/'
    },

    {
      name: 'cake shop online delivery Uppal',
      link: 'http://fbcakes.com/'
    },

    {
      name: 'cake shop online delivery Khairatabad',
      link: 'http://fbcakes.com/'
    },

    {
      name: 'cake shop online delivery ECIL',
      link: 'http://fbcakes.com/'
    },
  ]

  firstSelectValue = null;


  get firstSelectOptions() {
    return this.opts.map(({ value }) => value);
  }

  countries = [
    {
      id: 1,
      name: '0.5Kg'
    },
    {
      id: 2,
      name: '1Kg'
    },
    {
      id: 3,
      name: '2Kg'
    },
    {
      id: 4,
      name: '4.5Kg'
    }
  ];

 
  variantlist:any=[
   // {type:1,OptionId:2, Name:'venilla', price:640},
    {type:1,OptionId:3, Name:'Black Forest', price:780},
    {type:1,OptionId:4, Name:'Chocolate', price:780},
   // {type:1,OptionId:5, Name:'Mango', price:740},
   // {type:1,OptionId:6, Name:'PineApple', price:790},
    {type:1,OptionId:7, Name:'Black Current', price:880},
    {type:1,OptionId:8, Name:'Strawerry', price:880},
    {type:1,OptionId:9, Name:'Butter Scotch', price:930},
    {type:1,OptionId:10, Name:'White Forest', price:930},
    {type:1,OptionId:11, Name:'Choco Strawerry', price:980},
    {type:1,OptionId:12, Name:'Choco Vanilla', price:980},
    {type:1,OptionId:13, Name:'Choco Truffle', price:1010},
    {type:1,OptionId:14, Name:'Ferro Truffle', price:1010},
    {type:1,OptionId:15, Name:'Blueberry', price:1010},
    {type:1,OptionId:16, Name:'Milky Truffle', price:1010},

    // {type:2,OptionId:17, Name:'Black Forest Blast', price:990},
    // {type:2,OptionId:18, Name:'White Forest Blast', price:990},
    // {type:2,OptionId:19, Name:'Black Current Blast', price:1111},
    // {type:2,OptionId:20, Name:'Strawerry Blast', price:1111},
    // {type:2,OptionId:21, Name:'Butterscotch Blast', price:1111},
    // {type:2,OptionId:22, Name:'Mango Blast', price:1111},
    // {type:2,OptionId:23, Name:'Triple Blast', price:1190},
    // {type:2,OptionId:24, Name:'Milky Blast', price:1190},
    // {type:2,OptionId:25, Name:'Choco Truffle Blast', price:1190},
    // {type:2,OptionId:26, Name:'PineApple Blast', price:1190},
    // {type:2,OptionId:27, Name:'Blueberry Blast', price:1190},
    // {type:2,OptionId:28, Name:'Rasamalai Blast', price:1190},
    // {type:2,OptionId:29, Name:'Ferro Blast', price:1190},
  ]

   var_type=[{type:1, name:"Fresh Cream",selected:true},{type:2, name:"Blast",selected:false}]

  Rpid:any=0;

  constructor(private auth: AuthService, 
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title, 
    private metaS: Meta) {
      this.Last_optname=""
    var ptag = this.route.snapshot.paramMap.get('id').split('cake-');
    console.log('IIIII-full: ',this.route.snapshot.paramMap.get('id'));
   console.log('IIIII: ',ptag[1]);
   console.log('ptag00: ',ptag[0]);
   this.blasttypeid=1;
   this.Rpid=ptag[1];

 if(ptag[0]=="1-2-and-1-kg")
 {
  console.log("COrrect Do!")
  //this.document.location.href = 'https://fbcakes.com';
  document.location.href = this.baseurl;
  //window.open("https://fbcakes.com", '_blank');
 }
    


 

   if (!localStorage.getItem("selected_item")) 
   {

    this.auth.getSPG_Product2(ptag[1]).subscribe((data: any) => {
      console.log("................................................ptag[1] data", data.SPG_list[0]);
      this.product=data.SPG_list[0];
      
    })

   

    // this.predefinedquantities.filter((x, i) => x.ProductId == productId).filter((x, i) => i>0 && i<3);
  

    // this.auth.getproducts(1000).subscribe(data => {
    //   this.predefinedquantity = data["predefinedquantities"].filter(x=>x.ProductId==ptag[1])
    //   console.log("this.predefinedquantity",this.predefinedquantity)
    //   this.predefinedquantity.forEach((pq, i) => {
    //     console.log("pq0",pq)
    //     pq.selected = false
    //     if (i == 0) {
    //       pq.selected = true
    //       this.setqty(pq)
    //       console.log("pq1",pq)
    //     }
    //     console.log("pq2",pq)
    //   })
    //      })

         this.auth.getproducts(1000).subscribe(data => {
         console.log("wrks fine: 1", ptag[1]);
            this.predefinedquantity = data["predefinedquantities"].filter(x=>x.ProductId==ptag[1])
          
          //  this.predefinedquantity = data["predefinedquantities"].filter(x=>x.CakeQuantityId==1)

            this.predefinedquantityblast = data["predefinedquantities"].filter(x=>x.ProductId==ptag[1] && x.CakeQuantityId==2)
         
          
          console.log("this.predefinedquantity",this.predefinedquantity)
    //      this.predefinedquantity.filter((x, i) => x.ProductId == ptag[1]).filter((x, i) => i>0 && i<3);
          this.predefinedquantity.forEach((pq, i) => {
            console.log("pq0",pq)
            pq.selected = false
            if (i == 0) {
              pq.selected = true
              this.setqty(pq)
              console.log("pq1",pq)
            }
            console.log("pq2",pq)
          })

          
             })
         
  
    //      this.predefinedquantity.forEach((pq, i) => {
    //   console.log("pq0",pq)
    //   pq.selected = false
    //   if (i == 0) {
    //     pq.selected = true
    //     this.setqty(pq)
    //     console.log("pq1",pq)
    //   }
    //   console.log("pq2",pq)
    // })
  }
  else
  {
    this.product = JSON.parse(localStorage.getItem("selected_item"));

    if(this.product.Id==ptag[1])
    {
      //this.product = JSON.parse(localStorage.getItem("selected_item"));
      this.predefinedquantity = JSON.parse(localStorage.getItem("selected_item_qtys")); 
      console.log("this.predefinedquantity",this.predefinedquantity)
    //  this.predefinedquantity.filter((x, i) => x.ProductId == ptag[1]).filter((x, i) => i>0 && i<3);
       
      this.predefinedquantity.forEach((pq, i) => {
        pq.selected = false
        if (i == 0) {
          pq.selected = true
          this.setqty(pq)
          console.log("pq1",pq)
        }
        console.log("pq2",pq)
      })

// ggg
      this.variantlist.forEach((pq, i) => {
        pq.selected = false
        if (i == 0) {
          pq.selected = true
          this.setvari(pq)
          console.log("pq1",pq)
        }
        console.log("pq2",pq)
      })
      console.log("this.product",this.predefinedquantity)
    }
    else
    {
      this.auth.getSPG_Product2(ptag[1]).subscribe((data: any) => {
        console.log("ptag[1] data", data.SPG_list[0]);
        this.product=data.SPG_list[0];
        
      })
  
  
  
      this.auth.getproducts(3).subscribe(data => {
        this.predefinedquantity = data["predefinedquantities"].filter(x=>x.ProductId==ptag[1])

      //  this.predefinedquantity = data["predefinedquantities"].filter(x=>x.CakeQuantityId==1)

        console.log("this.predefinedquantity ok",this.predefinedquantity)
        console.log("wrks fine: 1", ptag[1]);
       // this.predefinedquantity.filter((x, i) => x.ProductId == ptag[1]).filter((x, i) => i>0 && i<3);
       
        this.predefinedquantity.forEach((pq, i) => {
          console.log("pq0",pq)
          pq.selected = false
          if (i == 0) {
            pq.selected = true
            this.setqty(pq)
            console.log("pq1",pq)
          }
          console.log("pq2",pq)
        })
           })
    }

//console.log("this.titleService",this.product.Name)
    this.titleService.setTitle(this.product.Name+ "Cake - Free Delivery | FBcakes")

   
  }

  
 

    
    //  this.predefinedquantity.forEach((pq, i) => {
    //    pq.selected = false
    //    if (i == 0) {
    //      pq.selected = true
    //      this.setqty(pq)
    //    }
    //  })



    // this.predefinedquantity[0].selected == true
    // console.log(this.predefinedquantity[0].selected)
    // this.setqty(this.predefinedquantity[0].Id)
  }

  ngOnInit(): void {
     this.getoptions();
     console.log("this.product",this.product)
     if(this.product==null)
     {

     }
    if (this.product.ImgUrl != '' && this.product.ImgUrl != null) {
      document.getElementById("imagesearch").setAttribute("data-exthumbimage", this.product.ImgUrl)
      document.getElementById("imagesearch").setAttribute("data-src", this.product.ImgUrl)
      document.getElementById("imagesearch").setAttribute("title", this.product.Name)
    }
    this.getmsg();
    console.log("my options",this.options)
  }

  getoptions() {
    var ptag = this.route.snapshot.paramMap.get('id').split('cake-');
    console.log("getoptions ptag: ",ptag);
    // this.auth.getoptions(this.product.Id).subscribe(data => {
      this.auth.getoptions(ptag[1]).subscribe(data => {
      console.log("Options: ",data)
      var response: any = data;
      this.optionGroup= response.optiongroups;
      this.variantgroups = response.optiongroups.filter(x => x.OptionGroup.OptionGroupType == 1);
      this.addongroups = response.optiongroups.filter(x => x.OptionGroup.OptionGroupType == 2);
      this.options = response.options;
      // this.variantgroups.forEach(group => {
      //   this.options.filter(x => x.OptionGroupId == group.OptionGroupId)[0].selected = true;
      // })
    })
  }

  // getoptionsbyid(OptionGroupId) {
  //   if (this.options.length > 0) {
  //     return this.options.filter(x => x.OptionGroupId == OptionGroupId);
  //   } else {
  //     return [];
  //   }
  // }

  getoptionsbyid(c)
  {
    return this.options[0]
  }

  Sel_Var_Id;
  setvariant(OptionGroupId, optionid) {
    console.log("OptionId",optionid)
    this.Sel_Var_Id=optionid;
    this.options.filter(x => x.OptionGroupId == OptionGroupId).forEach(option => {
      if (option.Id == optionid) {
        option.selected = true;
        console.log("ONN");
        
      } else {
        option.selected = false
        console.log("OFF");
      }
    })
  }

  getprice2() {
    var price = this.product.Price;
    var myprice=0;
    this.options.forEach(option => {
      if (option.selected) {
        myprice =myprice+option.Price
        //*Number(this.qty2)

        console.log("option price 1",myprice)
        
       // myprice=(option.Price*Number(this.qty2))
        console.log("option price 2",myprice*Number(this.qty2))

        price += option.Price
        price += option.Price*Number(this.qty2);
        // console.log("OGID",option.Id)
        // console.log("OGID Price",option.Price)
       
      }
      console.log("option total price",myprice)
      
     
      console.log("Product Price",this.kgprice)
      // console.log("Option Price",this.kgprice)
      console.log("Total myPrice",myprice+this.kgprice)
    
    });
    
    return price + (this.kgprice || this.product.quantity);
  }

  Soptionprice1=0;
  Soptionprice2=0;
  getprice3() {
    var price = this.product.Price;
    // console.log("Product_Price Rs."+price)
    this.options.forEach(option => {
      if (option.selected) {
        // console.log("Selected Price Rs."+option.Price*Number(this.qty2))
        var crosprice=option.Price*Number(this.qty2);
        price+=crosprice;
       // this.Soptionprice1=price;
        // console.log("Cross_Price"+price)
        if(option.OptionGroup.OptionGroupType==1)
        {
          var vprice=option.Price*Number(this.qty2);
          this.Soptionprice1=vprice;
          // console.log(".......................................vprice",vprice)
        }

        if(option.OptionGroup.OptionGroupType==2)
        {
          var aprice=option.Price*Number(this.qty2);
          this.Soptionprice2=aprice;
          // console.log(".......................................aprice",aprice)
        }
      }


      // if (option.OptionGroup.OptionGroupType==1 && option.selected==true)
      // {
      //   var mycrosprice=option.Price*Number(this.qty2);
      //   this.Soptionprice1+=mycrosprice;
      //   console.log("Soptionprice1"+ this.Soptionprice1)
      // }

      // if (option.OptionGroup.OptionGroupType==2 && option.selected==true)
      // {
      //   var mycrosprice2=option.Price*Number(this.qty2);
      //   this.Soptionprice2+=mycrosprice2;
      //   console.log("Soptionprice2"+ this.Soptionprice2)
      // }

    })
   var finalprice=(price+Number(this.kgprice))
   // console.log("Final_Price"+(price+Number(this.kgprice)))
    return finalprice;
  }

  getprice()
  {
    var price = this.product.Price;
    var ep=0
    var varientp=0
    console.log("true",this.qty2);
    
    
    //  var finalprice=(price+Number(this.kgprice))
    var finalprice=this.Last_Total;
     if (this.myoption[0].selected==true) {
      console.log("true");
      // ep=100*Number(this.qty2); e1
      ep=150*Number(this.qty2);

      

    }
    else{
      console.log("false");
      ep=0
      
    }

    if (this.variantlist[0].selected==true)
    {
      varientp=this.variantlist[0].price
     // this.fin_varient=this.variantlist[0].price
      console.log("varient list: ",varientp)
    }
     return finalprice+ep;
  }

  isChecked:boolean=false;
  checkValue2(event: any){
    console.log("Work")
    console.log(event);
 }
 checkValue(event: any)

 { console.log("Work")
  console.log(event.currentTarget.checked); }

  test(x)
  {
    
    
  }

  getmsg() {
    if (this.product.Category.ParentCategory.Id == 24) {
      console.log("My getmsg 1:"+this.product.Category.ParentCategory.Id);
      this.pid = true;

    }
    else {
      this.pid = false;
      console.log("My getmsg 2:"+this.product.Category.ParentCategory.Id);
    }


  }
  // setqty(pqty) {
  //   // console.log("Hai :", i)
  //   this.kgprice = pqty.Quantity;
  //   console.log("jjj",pqty, this.kgprice)

   
  //   this.qty=pqty.TotalQuantity
  //   console.log("qt",this.qty)
  //   // this.product.quantity = qty;
  //   // this.product.freequantity = freeQty;
  // }

  setqty2(pqty) {
    // console.log("Hai :", i)
    this.kgprice = pqty.Price;
    // console.log("kg price",this.kgprice)

    this.qty2=pqty.Quantity
   
    this.qty=pqty.TotalQuantity
    this.qtytext=pqty.QuantityText
    // console.log("quantity",this.qty)
    // console.log("QTY Text:",pqty.QuantityText)
    // console.log("Product_Price:",pqty.Price)
    // console.log("QuantityText:",pqty.QuantityText)
    // console.log("Quantity:",this.qty2)
  }

  lab_qty=[];
  freeQty=0;

  Last_Total=0
  Last_optname=""

  setqty(pqty) {
    console.log("pqty",pqty)
    this.kgprice = pqty.Price;
    this.qty2=pqty.Quantity
    this.qty=pqty.TotalQuantity
    this.qtytext=pqty.QuantityText
    this.Sel_Kg_Id=pqty.Id

   // console.log("Quantity: "+this.qty2+" - "+"Kg Total: Rs."+this.kgprice+" - Id:"+this.Sel_Kg_Id)
   // console.log(pqty);
    this.lab_qty=pqty;
    this.freeQty=pqty.FreeQuantity;

    // if()
  //  var qtyfv=pqty.Quantity*this.fin_varient

  console.log("My SALES PRODUCT GROUPS: ",this.product.SaleProductId)

  if(this.product.SaleProductId==5762 || this.product.SaleProductId==5767)
  {
    var qtyfv=pqty.Quantity*this.basevarient
  }
  else
  {
    var qtyfv=0;
  }
    
    
   // this.kgprice=this.kgprice+qtyfv

    this.Last_Total=this.kgprice+qtyfv
   

  
    
    // console.log("fin_varient total: ",this.fin_varient)
    // console.log("*************** run Final total: ",this.kgprice)
    // console.log("Qty: ",this.qty2," ",this.qty)

    console.log("old vari:",this.fin_varient)
    console.log("AAA_QTY:",this.qty2)
    console.log("AAA_KG-PRICE:",this.kgprice)
    console.log("AAA_VAR-PRICE:",qtyfv)
    console.log("AAA_FINAL-Total:",this.Last_Total)

  }

  fin_varient=0;
  basevarient=0
  setvari(data)
  {
    console.log("data",data.price)
    this.basevarient=data.price
   // console.log("fin_varient Total",data.price*parseInt(this.qty2))

   if(this.product.SaleProductId==5762 || this.product.SaleProductId==5767)
   {
    this.fin_varient=data.price*parseFloat(this.qty2)
   }
   else
   {
    this.fin_varient=data.price*parseInt(this.qty2)
   }

   // this.fin_varient=data.price*parseFloat(this.qty2)
    this.Last_Total=this.fin_varient+this.kgprice
    this.Last_optname=data.Name
    //return fin_varient;

    console.log("BBB_QTY:",this.qty2)
    console.log("BBB-KG-PRICE:",this.kgprice)
    console.log("BBB-VAR-PRICE:",this.fin_varient)
    console.log("BBB-FINAL-Total:",this.Last_Total)
  }

  setVarient(varient){
    console.log("varint",varient)
  }

  submitcart()
  {
    if(this.msg!='')
    {
      this.addtocart();
      this.cakemsgalert=false
    }
    else
    {
      this.cakemsgalert=true
//alert("Cake Message requird!")
    }
  }

  addtocart2()
  {
if(this.product.SaleProductId==5762)
{
  console.log("true: x",this.product.SaleProductId);
}
else
{
  console.log("false: xx",this.product.SaleProductId);
}
  }

  addtocart() {
    var cartitems = JSON.parse(localStorage.getItem("cart_items"));
    console.log("old cart items", cartitems)
    if (!cartitems) {
      cartitems = [];
    }
    var optionid=[];
    var variants = [];
    var addons = [];
    this.options.forEach(option => {
      if (option.selected) {
        if (this.variantgroups.some(x => x.OptionGroupId == option.OptionGroupId)) variants.push(option)
        if (this.addongroups.some(x => x.OptionGroupId == option.OptionGroupId)) addons.push(option)
        {
          optionid=option.Id
        }
      }
    });

    console.log("OptionId",optionid)
    this.product.variants = variants;
    this.product.addons = addons;
    this.product.Message = this.msg;
    this.product.Nvarient=this.Last_optname
    this.product.PrepTime=2

    if(this.product.SaleProductId==5767)
{
  this.product.Name=this.product.Name+" ("+this.product.Nvarient+") ";
  this.product.oj=this.product.Nvarient;
  this.product.PrepTime=12
  // if(this.product.ProductId==7765 || this.product.ProductId==6930 || this.product.ProductId==6665)
  // {
  //   this.product.PrepTime=3
  // }
  
}

if(this.product.SaleProductId==5762)
  {
    this.product.Name=this.product.Name+" ("+this.product.Nvarient+") ";
  this.product.oj=this.product.Nvarient;
    this.product.PrepTime=3
  }
    if (this.myoption[0].selected==true) {
      console.log("true");
      // this.product.eggless=100*Number(this.qty2); e2
      this.product.eggless=150*Number(this.qty2);
      this.product.note="Eggless Added ₹"+this.product.eggless
    }
    
    
    else
    {
      this.product.eggless = 0;
      this.product.note=0;
    }
    
    
    if(this.qty!="")
    {
      this.product.Quantity=parseFloat(this.qty);
      this.product.quantity=this.qtytext;
    }
    else{
      this.product.Quantity=1
      this.product.quantity="";
    }


    this.product.pqty=Number(this.qty2);
    this.product.prate=this.getprice();

    this.product.quantityrate=this.kgprice;
    this.product.optionrate1=this.Soptionprice1;
    this.product.optionrate2=this.Soptionprice2;

    this.product.TotalAmount=this.getprice();
    this.product.SelectKG_Id=this.Sel_Kg_Id;
    this.product.SelectVarient_Id=this.Sel_Var_Id;
    this.product.SelectAddon_Id=optionid;

    this.product.AllQty=this.predefinedquantity;
    this.product.freeQty=this.freeQty;
    // this.product.AllOptions=this.options
    // this.product.AllOptionsgroups=this.optionGroup
    
    // this.product.quantity = document.getElementById("demo_vertical2").nodeValue;
    cartitems.push(this.product)
    localStorage.setItem("cart_items", JSON.stringify(cartitems));
    localStorage.setItem("selected_item", JSON.stringify(this.product));
    // this.router.navigate(['/itemdetails']);
    document.location.href = this.baseurl + '/cart'
    console.log("old cart items2", cartitems)
    this.qty="";
  }

  ADSclick()
  {
    console.log("Works");
    var Phoneno=JSON.parse(localStorage.getItem("customer_emailid"))
    console.log("Phone :",Phoneno);
    if(Phoneno!=null)
    {
      var cData="Cust.Mobile: "+Phoneno
      console.log("Yes");
      this.auth.sarinsAds(cData).subscribe(data => {
        console.log("data", data)
      })
    }
    else{
      console.log("No");
    }
  }





// new work
NewfinalAmount=0;

NEW_QTY=0
QtyAmount=0;
FlavAmount=0

finaltotal()
{
  this.NewfinalAmount=this.QtyAmount+this.FlavAmount;
}

newSETQTY(x)
{
  this.NEW_QTY=x.Quantity
  this.QtyAmount=x.Price
  this.finaltotal();
}
newSETVAR(x)
{
  this.FlavAmount=x.price*this.NEW_QTY;
  this.finaltotal();
}

blasttypeid=1;
blasttype(x)
{
  console.log("blast: ",x);
  this.blasttypeid=x.type;

  
}


setqty_new(x)
{
console.log(x)
console.log("QTY: ",x.Quantity);
console.log("PRICE: ",x.Price);

this.Sel_Kg_Id=x.Id

}






}

