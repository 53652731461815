<footer class="site-footer food-footer">
    <!-- Footer Top Part -->
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="widget widget_getintuch">
                        <h5 class="footer-title text-white">Contact Info</h5>
                        <ul>
                            <li>
                                <i (click)="map()" class="fa fa-map-marker"></i> 
                               
                                <p>HEAD OFFICE <br>60, RGM Complex, OMR, Karapakkam, Chennai-600097</p>
                            </li>
                            <li>
                                <!-- <i class="fa fa-phone"></i>  -->
                                <i (click)="whatsup()" class="fa fa-whatsapp"></i> 
                                <p (click)="phone()">(+91) 90034 32888</p>

                            </li>
                            <!-- <li>
                                <i class="fa fa-mobile"></i> 
                                <p>(+91) 7845584690</p>
                            </li> -->
                            <li>
                                <i class="fa fa-envelope"></i> 
                                <p>admin@fbcakes.com</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div class="widget recent-posts-entry">
                        <h4 class="footer-title">Recent Post</h4>
                        <div class="widget-post-bx">
                            <div class="widget-post clearfix">
                                <div class="dlab-post-media radius-sm"> 
                                    <img src="../../assets/images/blog/recent-blog/pic1.jpg" alt=""> 
                                </div>
                                <div class="dlab-post-info">
                                    <div class="dlab-post-header">
                                        <h6 class="post-title"><a href="blogsingle">The Background Of Bakery Now</a></h6>
                                    </div>
                                    <div class="dlab-post-meta">
                                        <ul>
                                            <li class="post-date"> <i class="la la-clock-o"></i><strong>March 25, 2020</strong> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="widget-post clearfix">
                                <div class="dlab-post-media radius-sm"> 
                                    <img src="../../assets/images/blog/recent-blog/pic2.jpg" alt=""> 
                                </div>
                                <div class="dlab-post-info">
                                    <div class="dlab-post-header">
                                        <h6 class="post-title"><a href="blogsingle">How Bakery Can Increase Your Profit</a></h6>
                                    </div>
                                    <div class="dlab-post-meta">
                                        <ul>
                                            <li class="post-date"> <i class="la la-clock-o"></i><strong>March 25, 2020</strong> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="widget widget_services border-0">
                       <h4 class="footer-title">Quick Links</h4>
                        <ul class="list-2">
                            <li><a href="" (click)="this.scrollToTop()">Home</a></li>
                            <li><a href="/aboutus" (click)="this.scrollToTop()">About</a></li>
                            <!-- <li><a href="#/" (click)="this.scrollToTop()">Our Menu</a></li> -->
                            <li><a href="/faq" (click)="this.scrollToTop()">FAQ</a></li>
                            <!-- <li><a href="/blog" (click)="this.scrollToTop()">Blog</a></li> -->
                            <li><a href="/carrer" (click)="this.scrollToTop()">Careers</a></li>
                            <li><a href="/store" (click)="this.scrollToTop()">Store List</a></li>
                            <li><a href="/contuctus" (click)="this.scrollToTop()">Contact Us</a></li>
                            <li><a href="/gallery" (click)="this.scrollToTop()">Gallery</a></li>

                            <li><a href="/privacypolicy" (click)="this.scrollToTop()">Privacy Policy</a></li> 
                            <li><a href="/termsandconditions" (click)="this.scrollToTop()">Terms and Conditions</a></li>

                            <li><a href="/returnpolicy" (click)="this.scrollToTop()">Return policy</a></li>
                            <li><a href="/deliverypolicy" (click)="this.scrollToTop()">Delivery policy</a></li>
                            
                            <li><a href="/Cancellationandrefundpolicy" (click)="this.scrollToTop()">Cancellation and Refund Policy</a></li> 
                            <!-- <li><a href="/mapview" (click)="this.scrollToTop()">Google Map</a></li> -->
                            <!-- <li><a href="#/map" (click)="this.scrollToTop()">map</a></li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="widget border-0">
                       <h4 class="footer-title">Work Timings</h4>
                       <p class="m-b20">Our support available to help you 24 hours a day, seven days a week.</p>
                        <ul class="work-hour-list">
                            <li>
                                <span class="day"><span>Shop walk in time :
                                </span></span> 
                                <span class="timing">10AM - 10PM</span>
                            </li>
                            <!-- <li>
                                <span class="day"><span>Saturday</span></span>
                                <span class="timing">10AM - 5PM</span>
                            </li> -->
                            <!-- <li>
                                <span class="day"><span>Sunday Close</span></span>
                                <span class="timing">During Lockdown</span>
                            </li> -->
                            
                            <li>
                                <span class="day"><span>Phone Order :
                                </span></span> 
                                <span class="timing">24/7</span>
                            </li>
                            <li>
                                <span class="day"><span>Open on all holidays & week ends</span></span>
                            </li>
                        </ul>

                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Bottom Part -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 text-left"> 
                    <!-- <span>Copyright © 2022 FBcakes. All Rights Reserved.</span>  -->
                </div>
                <div class="col-lg-6 col-md-6 text-right"> 
                    <div class="widget-link"> 
                        <ul>
                            <li><a href="/aboutus" (click)="this.scrollToTop()">About Us</a></li>
                            <li><a href="/faq" (click)="this.scrollToTop()">Faq</a></li> 
                            <li><a a href="https://www.instagram.com/fbcakesofficial/" target="blank"><i class="fa fa-instagram"></i></a></li>
                            <li><a a href="https://www.facebook.com/FBCakes/" target="blank"><i class="fa fa-facebook"></i></a></li>
                            <li><a a href="https://wa.me/9003432888" target="blank"><i class="fa fa-whatsapp"></i></a></li>

                            <!-- <li><a a href="https://wa.me/9003432888" target="blank">
                                <i class="fa fa-edit"></i>
                            </a>
                        </li> -->

                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>