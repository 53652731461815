import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footera',
  templateUrl: './footera.component.html',
  styleUrls: ['./footera.component.css']
})
export class FooteraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
