<footer class="site-footer pizza-footer">
    <div class="p-tb60 text-center bg-black-1">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="logo-footer">
                        <a href=""><img src="../../assets/images/pizza-logo.png" alt=""></a>
                    </div>
                    <div class="widget-link"> 
                        <ul>
                            <li><a href="">Home</a></li> 
                            <li><a href="aboutb">About Us</a></li> 
                            <li><a href="faq">Faq</a></li> 
                            <li><a href="contacta">Contact Us</a></li> 
                        </ul>
                    </div>
                    <p class="copyright">Copyright © 2020 DexignZone. all rights reserved.</p>
                    <ul class="dlab-social-icon">
                        <li><a class="btn white outline circle"><i class="fa fa-facebook"></i></a></li>
                        <li><a class="btn white outline circle"><i class="fa fa-google-plus"></i></a></li>
                        <li><a class="btn white outline circle"><i class="fa fa-linkedin"></i></a></li>
                        <li><a class="btn white outline circle"><i class="fa fa-twitter"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
