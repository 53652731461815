
  <div [@openClose]="isOpen ? 'open' : 'closed'"
    (@openClose.start)="onAnimationEvent($event)"
    (@openClose.done)="onAnimationEvent($event)"
    class="open-close-container">  
    <div>
    
        <button class="btn-header"  
    (click)="toggle()">🟢 Customer Support</button>
    </div>
    <!-- <p>The box is now {{ isOpen ? 'Open' : 'Closed' }}!</p> -->
    <div style="padding: 10px; margin-top: 10px;">

        <span style="font-size: 16px;">
            Welcome to FBcakes! 
            <br>My name is Basky, 
            <br>Ping me here or Call me @ 
            
            <a href="tel:+91-900-343-2888">90034 32888</a>
        </span>
       

        <div style="margin-top: 20px;" *ngIf="isloggedin">
            <textarea 
            rows="5" 
            name="text" 
            class="form-control"
            style="width: 100%; 
            font-size: 16px; padding: 10px; margin-top: 10px;" 
            [(ngModel)]="message"
            placeholder="Message..."></textarea>
        </div>


        <div style="margin-top: 20px;" *ngIf="!isloggedin"> 
            <textarea 
            rows="1" 
            name="text" 
            class="form-control"
            style="width: 100%; 
            font-size: 16px; padding: 10px;" 
            [(ngModel)]="Mobno"
            placeholder="10 digits Mobile No..."></textarea>

            <div style="margin-top: 10px;">
                <textarea 
                rows="3" 
                name="text" 
                class="form-control"
                style="width: 100%; 
                font-size: 16px; padding: 10px; margin-top: 10px;" 
                [(ngModel)]="message"
                placeholder="Message..."></textarea>
            </div>
        </div>
        

    </div>

    <div style="float: right; margin-right: 10px;">
        <label  style="margin-right: 10px;"
        class="btn btn-danger" 
        
        (click)="submit(message,Mobno)"><i class="fa fa-paper-plane"></i>
        </label>

        <label  style="margin-right: 10px;"
        class="btn btn-danger" 
        
        (click)="submit5(message,Mobno)"><i class="fa fa-whatsapp"></i>
        </label>

        <label  style="margin-right: 10px;"
        class="btn btn-danger" 
        (click)="call()"><i class="fa fa-phone"></i>
        </label>






        <!-- <a style="margin-right: 15px;" href="" (click)="submit(message,Mobno)"><i class="fa fa-paper-plane"></i></a>
        <a style="margin-right: 15px;" href="" (click)="submit5(message,Mobno)" target="blank"><i class="fa fa-whatsapp"></i></a>
        <a style="margin-right: 15px;" href="tel:+91-900-343-2888" target="blank"><i class="fa fa-phone"></i></a> -->
    </div>

    <div  style="margin-top: 45px; padding: 10px;">
        <span *ngIf="Rmsg==1" style="color: red; font-size: 16px;">Input Fields are Empty!</span>
        <span *ngIf="Rmsg==2"style="color: green; font-size: 16px;">Your Msg Submit Successful! <br>You will get a reply within a time.</span>
        <span *ngIf="Rmsg==3" style="color: red; font-size: 16px;">Plz Login with your Mobile No.</span>
    </div>

    <!-- <div *ngIf="!isloggedin()" style="margin-top: 45px; padding: 10px;">
        <span *ngIf="Rmsg==3" style="color: red; font-size: 16px;">Plz Login your Phone No.</span>
    </div> -->
    
  </div>