import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../auth.service';
import { ItemDetailsComponent } from '../item-details/item-details.component';
import Swal from 'sweetalert2';

// var AccQty=1;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  cartitems = [];
  taxes: any = [];
  Quantity: any = ["1"]
  tax = 0
  verifytoggle: boolean = true;
  customerid = 0;
  otp: string = '';
  email: string = '';
  PhoneNo: string = '';
  cartalert: boolean = false;
  editmsg: '';
  variantgroups = [];
  addongroups = [];
  options = [];
  Accessories;
  Acctotal: number;
  AccQty: number = 1;




  constructor(private auth: AuthService) {
    this.accessories = JSON.parse(localStorage.getItem("accessories"))
    this.accessories = this.accessories ? this.accessories : []
    console.log("this.accessories", this.accessories)
    this.acctotal();
  }

  acctotal() {
    this.Acctotal = 0;
    this.accessories.forEach(x => {
      this.Acctotal += (x.Price * x.Quantity)
    })
    console.log("Acctotal", this.Acctotal)
  }

  saveoptionslocal() {
    this.auth.getproducts(3).subscribe(data => {
      console.log(data);
      localStorage.setItem("SavedAllQTY", JSON.stringify(data["predefinedquantities"]));
    })
  }

  ngOnInit(): void {
    this.cartitems = JSON.parse(localStorage.getItem("cart_items") || '[]');
    this.setprice();
    this.getTaxes();
    this.scrollToTop();
    this.saveoptionslocal();
    this.setacctotal();
    console.log("cartitems", this.cartitems);
  }
  getTaxes() {
    this.auth.getTaxes(3).subscribe(data => {
      this.taxes = data;
      this.settaxes();
    })
  }
  // settaxes() {
  //   this.tax = 0
  //   this.cartitems.forEach(item => {
  //     var taxgroup = this.taxes.filter(x => x.Id == item.TaxGroupId)[0]
  //     item.TotalPrice = item.Price;
  //     item.variants.forEach(variant => {
  //       item.TotalPrice += variant.Price
  //     });
  //     item.addons.forEach(addon => {
  //       item.TotalPrice += addon.Price
  //     });
  //     item.TotalPrice = item.TotalPrice * item.Quantity
  //     item.TotalAmount = item.TotalPrice * item.Quantity
  //     this.tax = this.tax + item.TotalAmount * ((taxgroup.Tax1 + taxgroup.Tax2 + taxgroup.Tax3) / 100)
  //   });
  //   this.tax = +this.tax.toFixed(0)
  // }

  predefinedquantity = [];
  preindex
  kgprice;
  qty2;
  qty;
  editqtyID = null;
  alqtyname;
  Addon_price = 0

  // OPTIONS WORK

  editOptions(pId, index: number, Olqty) {
    var alqty = JSON.parse(localStorage.getItem("cart_items"))
    console.log("alqty", alqty[index].Name)
    this.alqtyname = alqty[index].Name
    this.predefinedquantity = alqty[index].AllQty
    this.qty2 = Olqty
    console.log("Product_Id", pId)
    console.log("Index No.", index)
    this.preindex = index;
    console.log("QTY", this.predefinedquantity)
    var SelectKG_Id = alqty[index].SelectKG_Id
    console.log("Selected_Old_QTY_Id", SelectKG_Id)
    this.predefinedquantity.forEach((pq, i) => {
      pq.selected = false
      console.log("pq", pq.Product.Id)
      if (pq.Id == SelectKG_Id) {
        pq.selected = true
        this.setqty(pq)
      }
    })


  }

  editOptions_varient100(pId, index: number) {
    console.log("Product_Id:", pId)
    var aloptions = JSON.parse(localStorage.getItem("cart_items"))
    console.log("aloptions:", aloptions[0].AllOptions)
    var response: any = aloptions[0];

    console.log("response.OptionGroup", response.aloptionsgroups)
    this.variantgroups = response.OptionGroup.filter(x => x.OptionGroup.OptionGroupType == 1);
    this.addongroups = response.OptionGroup.filter(x => x.OptionGroup.OptionGroupType == 2);

    this.variantgroups.forEach(group => {
      this.options.filter(x => x.OptionGroupId == group.OptionGroupId)[0].selected = true;
    })

    console.log("My_Options", this.options)

    var response: any = aloptions;
    this.variantgroups = response.optiongroups.filter(x => x.OptionGroup.OptionGroupType == 1);
    this.addongroups = response.optiongroups.filter(x => x.OptionGroup.OptionGroupType == 2);
    this.options = response.AllOptions;
    this.variantgroups.forEach(group => {
      this.options.filter(x => x.OptionGroupId == group.OptionGroupId)[0].selected = true;
    })
    console.log("Options", this.options)
  }



  setqty(pqty) {
    this.kgprice = pqty.Price;
    this.qty2 = pqty.Quantity
    this.qty = pqty.TotalQuantity
    this.editqtyID = pqty.Id
    console.log("Edit Qty ID:", this.editqtyID)
  }

  EditOptions() {

    var alqty = JSON.parse(localStorage.getItem("cart_items"))
    console.log("alqty:",alqty)
    this.cartitems[this.preindex].pqty = parseFloat(this.qty2)
    this.cartitems[this.preindex].quantityrate = this.kgprice
    this.cartitems[this.preindex].Quantity = this.qty
    this.cartitems[this.preindex].SelectKG_Id = this.editqtyID

    console.log("New Edited Cart:", this.cartitems)

    console.log("variants[0]", alqty[this.preindex].variants[0])
    var var_price=0
   // var var_price = (alqty[this.preindex].variants[0].Price) * parseFloat(this.qty2)
    console.log("var_price", var_price)


    // if (alqty[this.preindex].variants.length > 0) {
    //   var var_price = (alqty[this.preindex].variants[0].Price) * parseFloat(this.qty2)
    // }

    this.cartitems[this.preindex].optionrate1 = var_price
    if (alqty[this.preindex].addons.length > 0) {
      var add_price = (alqty[this.preindex].addons[0].Price) * parseFloat(this.qty2)
      this.cartitems[this.preindex].optionrate2 = add_price
    }
    console.log("varients", var_price)
    console.log("addans", add_price)
    add_price=0
    // localStorage.setItem("cart_items", JSON.stringify(this.cartitems))


    this.cartitems[this.preindex].prate = var_price + add_price + this.kgprice
    localStorage.setItem("cart_items", JSON.stringify(this.cartitems))
    // console.log("New Edited Cart:", this.cartitems)
  }


  var_price = 0;
  varientIndex;
  editOptions_varient(pId, index: number, vid) {
    var alqty = JSON.parse(localStorage.getItem("cart_items"))
    this.varientIndex = index;
    this.qty2 = alqty[index].pqty
    this.alqtyname = alqty[index].Name
    this.auth.getoptions(pId).subscribe(data => {
      console.log("My Options Data", data)
      var response: any = data;
      this.variantgroups = response.optiongroups.filter(x => x.OptionGroup.OptionGroupType == 1);
      this.addongroups = response.optiongroups.filter(x => x.OptionGroup.OptionGroupType == 2);
      this.options = response.options;
      this.variantgroups.forEach(group => {
        this.options.filter(x => x.Id == vid)[0].selected = true;
      }
      )
      this.options.forEach(item => {
        if (item.selected == true) {
          this.var_price = item.Price * this.qty2
          console.log("price", item.Price * this.qty2)
        }
      })

    })
  }

  getoptionsbyid(OptionGroupId) {
    if (this.options.length > 0) {
      return this.options.filter(x => x.OptionGroupId == OptionGroupId);
    } else {
      return [];
    }
  }

  preoptionid;

  setvariant(OptionGroupId, optionid, Price) {
    this.preoptionid = optionid;

    console.log("OptionId", optionid)
    console.log("OptionGroupId", OptionGroupId)
    console.log("Qty:", this.qty2)
    console.log("Price:", Price)
    console.log("tot:", Price * this.qty2)
    this.var_price = Price * this.qty2
    this.options.filter(x => x.OptionGroupId == OptionGroupId).forEach(option => {
      if (option.Id == optionid) {
        option.selected = true;
      } else {
        option.selected = false
      }
    })
  }

  EditVarient() {
    var variants = [];
    this.options.forEach(option => {
      if (option.selected) {
        if (this.variantgroups.some(x => x.OptionGroupId == option.OptionGroupId)) variants.push(option)
      }
    });
    console.log("Varients", variants)
    this.cartitems[this.varientIndex].optionrate1 = this.var_price
    this.cartitems[this.varientIndex].variants = variants
    this.cartitems[this.varientIndex].SelectVarient_Id = this.preoptionid
    this.cartitems[this.varientIndex].prate = this.var_price + (this.cartitems[this.varientIndex].addons[0].Price * this.cartitems[this.varientIndex].pqty) + this.cartitems[this.varientIndex].quantityrate;
    localStorage.setItem("cart_items", JSON.stringify(this.cartitems))

    console.log("v_edit_total_amount", this.var_price + (this.cartitems[this.varientIndex].addons[0].Price * this.cartitems[this.varientIndex].pqty) + this.cartitems[this.varientIndex].quantityrate
    )
    console.log("New Edited Cart:", this.cartitems)
  }

  EditOption() {
    // var addons = [];
    // this.options.forEach(option => {
    //   if (option.selected) {
    //     if (this.addongroups.some(x => x.OptionGroupId == option.OptionGroupId)) addons.push(option)
    //   }
    // });
    // console.log("Selected Addons", addons)
    // this.cartitems[this.varientIndex].addons = addons
    // this.cartitems[this.varientIndex].SelectVarient_Id = this.preoptionid
    this.cartitems[this.varientIndex].optionrate2 = this.Addon_price
    localStorage.setItem("cart_items", JSON.stringify(this.cartitems))
    console.log("New Edited Cart:", this.cartitems)

    this.cartitems[this.varientIndex].prate = this.Addon_price + (this.cartitems[this.varientIndex].varients.Price * this.cartitems[this.varientIndex].pqty) + this.cartitems[this.varientIndex].quantityrate;

    localStorage.setItem("cart_items", JSON.stringify(this.cartitems))

  }
  temp_product;
  editOptions_addons1(product, index: number) {
    console.log(product)
    this.temp_product = product

    var alqty = JSON.parse(localStorage.getItem("cart_items"))
    this.qty2 = alqty[index].pqty
    this.varientIndex = index;
    console.log("Quantity:", this.qty2)

    this.auth.getoptions(this.temp_product.Id).subscribe(data => {
      console.log(data)
      var response: any = data;
      // this.optionGroup= response.optiongroups;
      this.variantgroups = response.optiongroups.filter(x => x.OptionGroup.OptionGroupType == 1);
      this.addongroups = response.optiongroups.filter(x => x.OptionGroup.OptionGroupType == 2);
      this.options = response.options;
      this.options.forEach(opt => {
        opt.selected = this.temp_product.addons.some(x => x.Id == opt.Id) || this.temp_product.variants.some(x => x.Id == opt.Id)
        if (opt.selected == true) {
          console.log("Addons Price:", opt.Price * this.qty2)
          this.Addon_price = opt.Price * this.qty2
        }

      })
      // this.variantgroups.forEach(group => {
      //   //  this.options.filter(x => x.OptionGroupId == group.OptionGroupId)[0].selected = true;
      //   this.options.filter(x => x.Id == this.temp_product.Id)[0].selected = true;
      // })
    })
  }
  editOptions_addons(id, index: number) {
    this.varientIndex = index;
    var alqty = JSON.parse(localStorage.getItem("cart_items"))
    console.log("Cart_index", index)
    console.log("Product_id", id)
    this.alqtyname = alqty[index].Name

    this.auth.getoptions(id).subscribe(data => {
      console.log(data)
      var response: any = data;
      // this.optionGroup= response.optiongroups;
      this.variantgroups = response.optiongroups.filter(x => x.OptionGroup.OptionGroupType == 1);
      this.addongroups = response.optiongroups.filter(x => x.OptionGroup.OptionGroupType == 2);
      this.options = response.options;
      this.variantgroups.forEach(group => {
        //  this.options.filter(x => x.OptionGroupId == group.OptionGroupId)[0].selected = true;
        this.options.filter(x => x.Id == id)[0].selected = true;
      })
    })
  }


  edit_addons(id) {
    console.log("Addons_Id:", id)
    this.options.filter(x => x.Id == id)[0].selected = true;
  }

  getaccess() {
    this.auth.getAccessories().subscribe(data => {
      this.Accessories = data;
      this.Accessories.forEach(add => {
        add.select_value = this.accessories.some(x => x.Id == add.Id);
        add.Quantity = 1,
          add.TotalPrice = add.Price,
          add.addons = [],
          add.variants = []

      })

      console.log("Accessories1", this.Accessories)
    }
    )
  }



  addAccQty() {
    this.AccQty++
  }

  minusAccQty() {
    if (this.AccQty > 1) {
      this.AccQty--
    }
  }


// 18tax
  // settaxes_BGST() {
  //   var totalprice = 0;
  //   var tax = 0;
  //   this.cartitems.forEach(element => {
  //     totalprice += Number(element.quantityrate + element.optionrate1 + element.optionrate2);
  //   });
  //   tax = (totalprice + this.Acctotal) * 5 / 100
  //   // return Number(tax.toFixed(0))
  //   return Number(tax.toFixed(0))
  // }

  settaxes() {
    var totalprice = 0;
    var tax = 0;
    this.cartitems.forEach(element => {
      totalprice += Number(element.prate);
    });
    tax = (totalprice) * 18 / 100
    // return Number(tax.toFixed(0))
    return Number(tax.toFixed(0))
  }

  setprice() {
    this.cartitems.forEach(item => {
      item.TotalPrice = 0
      item.TotalPrice = item.Price;
      item.variants.forEach(variant => {
        item.TotalPrice += variant.Price
      });
      item.addons.forEach(addon => {
        item.TotalPrice += addon.Price
      });
    });
  }



  // getTotal() {
  //   var totalprice = 0;
  //   this.cartitems.forEach(element => {
  //     totalprice += element.TotalPrice;

  //   });
  //   return totalprice
  // }

  //old
  // getTotal() {
  //   var totalprice = 0;
  //   this.cartitems.forEach(element => {
  //     totalprice += Number(element.prate);
  //   });
  //   return totalprice
  // }

 
  getTotal_bgst() {
    var totalprice = 0;
    this.cartitems.forEach(element => {
      totalprice += Number(element.quantityrate + element.optionrate1 + element.optionrate2);
    });
    return totalprice + this.Acctotal;
  }

  getTotal() {
    var totalprice = 0;
    console.log("CART ITEMS: ",this.cartitems)
    this.cartitems.forEach(element => {
      totalprice += Number(element.prate);
    });
    //return totalprice + this.Acctotal;
    return totalprice
  }

  removeitem(id) {
    alert("Work 2")
    this.cartitems = this.cartitems.filter(x => x.Id != id);
    localStorage.setItem("cart_items", JSON.stringify(this.cartitems))
    this.settaxes();
  }

  removeTask(index: number) {
    //   alert("Work 1")
    //   this.cartalert=true;
    //  this.cartitems.splice(index, 1)
    //  localStorage.setItem("cart_items",JSON.stringify(this.cartitems))
    //  this.settaxes();

    Swal.fire({
      title: 'Cart Items',
      text: 'Are you sure you want to remove this Item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      confirmButtonColor: 'red',
    }).then((result) => {

      if (result.isConfirmed) {

        console.log('Clicked Yes, File deleted!');
        this.cartitems.splice(index, 1)
        localStorage.setItem("cart_items", JSON.stringify(this.cartitems))
        this.settaxes();

      } else if (result.isDismissed) {

        console.log('Clicked No, File is safe!');

      }
    })

  }


  // CAKE MESSAGE EDIT!


  msgedit(msg, index: number) {
    this.editmsg = msg;

    Swal.fire({
      title: 'Edit Cake Message',
      showCancelButton: true,


      input: 'text',
      inputValue: this.editmsg,

      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      confirmButtonColor: 'red',

    }).then((result) => {

      if (result.isConfirmed) {

        if (result.value != '') {
          console.log('My New MSG', result);
          console.log('INDEX', index);
          this.cartitems[index].Message = result.value
          localStorage.setItem("cart_items", JSON.stringify(this.cartitems))
        }
        else {
          this.cartitems[index].Message = "No Cake Message"
          localStorage.setItem("cart_items", JSON.stringify(this.cartitems))
        }

        // console.log('My New MSG', result);
        // console.log('INDEX', index);
        // this.cartitems[index].Message = result.value
        // localStorage.setItem("cart_items", JSON.stringify(this.cartitems))


        // console.log('My New MSG', result);
        // console.log('INDEX', index);
        // this.cartitems[index].Message = result.value
        // localStorage.setItem("cart_items", JSON.stringify(this.cartitems))

      } else if (result.isDismissed) {

        console.log('Clicked No, File is safe!');

      }
    })

  }


  isloggedin() {
    if (localStorage.getItem("customer_id") == undefined) {
      return false;
    } else {
      return true;
    }
  }

  ordercheckout() {

    document.location.href = window.location.origin + '/checkout'
  }
  // customerlogin(email) {
  //   console.log(email)
  //   this.email = email;
  //   this.auth.customerlogin(email, 3).subscribe(data => {
  //     console.log(data);
  //     this.verifytoggle = !this.verifytoggle
  //   })
  // }

  customerlogin(PhoneNo, email) {

    console.log("PhoneNo: ", PhoneNo)
    console.log("Email: ", email)

    // this.logintoast = ""
    this.PhoneNo = PhoneNo;
    // this.auth.customerlogin("91" + PhoneNo, 3, email).subscribe(data => {
    //   console.log(data);
    //   this.verifytoggle = !this.verifytoggle
    // })

  }
  otpverification(otp) {
    console.log(otp)
    this.auth.otpverification(this.email, otp).subscribe(data => {
      console.log(data);
      localStorage.setItem("customer_id", data["Id"].toString())
      this.verifytoggle = !this.verifytoggle;
      this.auth.getcustomerdetails(data["Id"]).subscribe(details => {
        console.log(details)
        var address: any = details["addresses"];
        var currentaddressid = 0;
        localStorage.setItem("addresses", JSON.stringify(address));
        address.forEach(element => {
          if (element.iscurrentaddress) {
            currentaddressid = element.Id
          }
          if (currentaddressid == 0 && data["status"] == 200) {
            this.auth.setcurrentaddress(address.Id, this.customerid).subscribe(data => {
              var addresses: any = data;
              addresses.forEach(element => {
                if (element.iscurrentaddress) {
                  currentaddressid = element.Id;
                }
              });
            })
          }
          else {
            alert("OTP Error")
          }
          localStorage.setItem("currentaddressid", currentaddressid.toString())
        });
        details["orders"].forEach(element => {
          element.items = details["orderitems"].filter(x => x.OrderId == element.Id)

        });
        localStorage.setItem("orders", JSON.stringify(details["orders"]));
      })


    })
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(2, currentScroll - (currentScroll / 8));
      }
    })();
  }

  removeAccess(i) {
    console.log(i)
    Swal.fire({
      title: 'Accessories Items',
      text: 'Are you sure you want to remove this Item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      confirmButtonColor: 'red',
    }).then((result) => {

      if (result.isConfirmed) {

        console.log('Clicked Yes, File deleted!');
        this.accessories.splice(i, 1)
        localStorage.setItem("accessories", JSON.stringify(this.accessories))
        console.log(this.accessories)
        this.acctotal();

      } else if (result.isDismissed) {

        console.log('Clicked No, File is safe!');

      }
    })
  }


  saveAccesories() {
    console.log("Hey its work")

    var price = 0;
    this.Accessories.forEach(item => {
      if (item.selected == true) {
        console.log("Selected Accessories", item.Name)
        price += item.Price;
        console.log("Accessories Price", price)
      }

      else {
        console.log("No Items Selected")
      }
    })

  }

  setacctotal() {
    // console.log("this.acc"+this.accessories)
    // this.accessories.forEach(x=>{
    //   this.Acctotal+=(x.Total*x.Quantity)
    // })
    // console.log("Acc total:",this.Acctotal)
  }


  accessories = []
  addAccessories(option) {
    console.log(option)
    if (!option.select_value) {
      this.accessories.push(option)
      option.select_value = true
    } else {
      this.accessories = this.accessories.filter(x => x.Id != option.Id)
      option.select_value = false
    }
    console.log("Last", this.accessories)
    localStorage.setItem("accessories", JSON.stringify(this.accessories))
    this.acctotal();
  }

  // setAlltotal()
  // {
  //   console.log("Acc Totol: "+this.accessories)
  //   this.accessories.forEach(x=>{
  //     this.acctotal=this.acctotal+(x.total*x.Quantity)
  //   })
  //   console.log("Acc total: ",this.acctotal)
  // }

  saveAcc(option, i) {
    this.accessories[i] = option
    localStorage.setItem("accessories", JSON.stringify(this.accessories))
    this.acctotal();
  }
}





