import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'
import { DeviceDetectorService } from "ngx-device-detector";
import { HttpClient } from '@angular/common/http';
import { NULL_EXPR, THIS_EXPR } from '@angular/compiler/src/output/output_ast';


import { ConnectionService } from 'ng-connection-service';

declare function closeModal(): any

@Component({
  selector: 'app-headerd',
  templateUrl: './headerd.component.html',
  styleUrls: ['./headerd.component.css'],
  host: {
    "(window:resize)": "onWindowResize($event)"
  }
})


export class HeaderdComponent implements OnInit {
  verifytoggle:
    boolean = true;
  customerid = 0;
  otp: string = '';
  email: string = ''
  PhoneNo: string = ''
  inputtext: string = ''
  inputtext2: string = ''
  button = 'Submit';
  isLoading = false;
  isMobile: boolean = false;
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  mobileWidth: number = 560;
  logintoast: string = '';
  logintoast2: string = '';
  public lat;
  public lng;
  deviceInfo = null;
  ipAddress = null;
  ipLoc = null;
  // this.ipAddress
  clicked = false;
  Admin: boolean;

  status = 'ONLINE'; //initializing as online by default
  isConnected = true;

  click() {
    this.isLoading = true;
    this.button = '';

    setTimeout(() => {
      this.isLoading = false;
      this.button = 'Submit';
    }, 3000)
  }
  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    this.isMobile = this.width < this.mobileWidth;
  }
  islogged: boolean = false
  constructor(private auth: AuthService, private deviceService: DeviceDetectorService, private http: HttpClient, private connectionService: ConnectionService) {
    console.log("constructor work")
    this.customerid = +localStorage.getItem("customer_id");
    // this.getLocation();
    this.logintoast2 = '';
    this.getIPaddress()
    this.auth.isloggedin.subscribe(d => {
      this.islogged = d
    })
    // this.connectionService.monitor().subscribe(isConnected => {
    //   this.isConnected = isConnected;
    //   if(this.isConnected){
    //     this.status = "ONLINE";
    //     console.log("Internet",this.status)
    //   } else {
    //     this.status = "OFFLINE"
    //     console.log("Internet",this.status)
    //   }

    //   console.log("final Internet",this.status)
    //   alert(this.status);
    // });
  }





  ngOnInit(): void {
    console.log("ngOnInit work")
    this.isMobile = this.width < this.mobileWidth;
  }
  setheadertype(type) {
    localStorage.setItem("header_type", type)
  }
  getcartitemquantity() {
    var count = 0;
    if (JSON.parse(localStorage.getItem('cart_items'))) {
      count = JSON.parse(localStorage.getItem('cart_items')).length
    }
    return count;
  }
  // customerlogin(email) {
  //   if (email.length == 10) {
  //     console.log(email)
  //     this.logintoast = ""
  //     this.email = email;
  //     this.auth.customerlogin("91" + email, 3).subscribe(data => {
  //       console.log(data);
  //       this.verifytoggle = !this.verifytoggle
  //     })
  //   }
  //   else {
  //     this.logintoast = "Enter 10 digit No."
  //   }
  // }
  isvalidemail() {
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(this.inputtext);
  }
  Email: string
  // PhoneNo: string

  misc_otp:any=''

  customerlogin(PhoneNo, email) {
    console.log("Length: ", PhoneNo.toString().length, PhoneNo, email)

    if (PhoneNo.toString().length == 10 && this.PhoneNo != null) {
      this.clicked = true;
      console.log("PhoneNo: ", PhoneNo)
      console.log("Email: ", email)
      this.logintoast = ""
      this.PhoneNo = PhoneNo;
      this.email = email;

      // var L_ip=this.ipAddress;
      // var L_address=this.ipLoc.city+', '+this.ipLoc.regionName+', '+this.ipLoc.country
      // var L_city=this.ipLoc.city
      // var L_pin=this.ipLoc.zip

      // console.log("1 L_ip",this.ipAddress);
      // console.log("1 L_city",this.ipLoc.city);
      // localStorage.setItem("addresses", JSON.stringify(address));

      this.auth.customerlogin("91" + PhoneNo, 3, email).subscribe(data => {
        console.log("my data", data);
        this.verifytoggle = !this.verifytoggle
        this.misc_otp=data['serv'];
    //    alert("Temp SMS not send !!! ("+data['serv']+") is your OTP (One Time Password) for logging in the FBcakes webSite.");
      })
      // this.sdata();
    }
    else {
      this.clicked = false;
      this.logintoast = "Enter 10 digit Mobile Number!"
    }



  }
  resendotp() {
    // var L_ip=this.ipAddress;
    // var L_address=this.ipLoc.city+', '+this.ipLoc.regionName+', '+this.ipLoc.country
    // var L_city=this.ipLoc.city
    // var L_pin=this.ipLoc.zip
    this.auth.customerlogin("91" + this.PhoneNo, 3, this.email).subscribe(data => {
      console.log(data);
      this.logintoast = ""
      this.misc_otp=data['serv'];
      this.logintoast2 = "Re-send OTP will send Shortly!"
     // alert("Temp SMS not send !!! ("+data['serv']+") is your OTP (One Time Password) for logging in the FBcakes webSite.");

      // this.otpverification("")

      // codeBox1.value
      // this.verifytoggle = !this.verifytoggle
    })
  }

  customerlogin2(PhoneNo, email) {

    console.log("PhoneNo: ", PhoneNo)
    console.log("Email: ", email)

    this.logintoast = ""
    this.PhoneNo = PhoneNo;
    //     var L_ip=this.ipAddress;
    // var L_address=this.ipLoc.city+', '+this.ipLoc.regionName+', '+this.ipLoc.country
    // var L_city=this.ipLoc.city
    // var L_pin=this.ipLoc.zip

    // console.log("2 L_ip",this.ipAddress);
    // console.log("2 L_city",this.ipLoc.city);

    this.auth.customerlogin("91" + PhoneNo, 3, email).subscribe(data => {
      console.log(data);
      this.verifytoggle = !this.verifytoggle
      // data-dismiss="modal"
    })

  }

  otpverification(otp) {
    console.log(otp)
    this.logintoast2 = '';
    if (otp.length != 4) {
      this.logintoast = "OTP Field Empty!"
      return otp;
    }
    this.auth.otpverification("91" + this.PhoneNo, otp).subscribe(data => {
      console.log(data);
      console.log(data["Id"]);
      if (data['status'] == 200) {
        localStorage.setItem("customer_id", data["customerid"].toString())
        localStorage.setItem("tok", data["tok"].toString())
        localStorage.setItem("customer_emailid", this.PhoneNo)
        localStorage.setItem("customer_PhoneNo", this.email)
        // this.verifytoggle = !this.verifytoggle;
        this.auth.getcustomerdetails(data["customerid"]).subscribe(details => {
          console.log(details)
          var address: any = details["addresses"];
          var currentaddressid = 0;
          localStorage.setItem("addresses", JSON.stringify(address));
          closeModal()

          address.forEach(element => {
            if (data["status"] == 200) {
              this.verifytoggle = !this.verifytoggle;
              this.logintoast = ""
              if (element.iscurrentaddress) {
                currentaddressid = element.CustomerAddressId
              }
              if (currentaddressid == 0) {
                this.auth.setcurrentaddress(address.Id, this.customerid).subscribe(data => {
                  var addresses: any = data;
                  addresses.forEach(element => {
                    if (element.iscurrentaddress) {
                      currentaddressid = element.CustomerAddressId;
                    }
                  });
                })
              }
            }
            else {
              alert("Error");
              this.logintoast = "Your OTP is Incorrect!"
            }
            localStorage.setItem("currentaddressid", currentaddressid.toString())
            
            // localStorage.setItem("currentemailid", currentaddressid.toString())
            // localStorage.setItem("currentmobileno", currentaddressid.toString())
          });
          details["orders"].forEach(element => {
            element.items = details["orderitems"].filter(x => x.OrderId == element.Id)

          });
          localStorage.setItem("orders", JSON.stringify(details["orders"]));
          localStorage.setItem("toogle", "1")
          this.auth.isloggedin.next(true)
        })
      } else {
        // alert("Error");
        this.logintoast = "Incorrect OTP"
        otp = ''
      }
    })
  }

  mobnochange() {
    this.clicked = false;
    console.log("PhoneNo", this.PhoneNo);
    console.log("Email", this.email);
    this.PhoneNo = null
    this.logintoast = ""
    this.verifytoggle = !this.verifytoggle
  }


  isloggedin() {
    if (localStorage.getItem("customer_id") == undefined) {
      return false;
    } else {
      return true;
    }
  }


  // getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position: Position) => {
  //       if (position) {
  //         this.lat = position.coords.latitude;
  //         this.lng = position.coords.longitude;
  //         this.deviceInfo = this.deviceService.getDeviceInfo();

  //         console.log("Lat Id: " +this.lat);
  //         console.log("Long Id: "+this.lng);
  //         console.log("Device info: "+this.deviceInfo.deviceType); 

  //       }
  //     },
  //       (error: PositionError) => console.log(error));
  //   } else {
  //     console.log("Geolocation is not supported by this browser.")
  //   }
  // }

  // sdata()
  // {
  //   var id =localStorage.getItem("customer_id")
  //   var dtype=this.deviceInfo.deviceType
  //   // console.log("s Cid:",localStorage.getItem("customer_id"))
  //   // console.log("s Phoneno:",this.PhoneNo)
  //   // console.log("s Email:",this.email)
  //   // console.log("s Lat Id: " +this.lat);
  //   // console.log("s Long Id: "+this.lng);
  //   // console.log("s Device info: "+this.deviceInfo.deviceType); 

  //   this.auth.sendlocmail(this.email,"91"+this.PhoneNo,id,this.lat,this.lng,dtype).subscribe(data => {
  //     console.log("Mes info",data);
  // }
  //   )}

  SearchClick(x) {
    console.log("Search Click: ", x)
    this.auth.showsearch.next(true)
  }


  getIPaddress() {
    this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
      console.log("IP_Address", res)

      this.http.get("http://ip-api.com/json/" + res.ip).subscribe((loc: any) => {
        this.ipLoc = loc;
        console.log("IP Location", loc)
      });

    });
  }


}
