<div class="page-wraper" #dialog>
    <div hidden id="loading-area">
        <div class="ball-pulse-rise">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <!-- Content -->
    <!-- <div class="page-content bg-white">
        <div class="dlab-bnr-inr overlay-black-middle"
            style="background-image:url(https://restroking.dexignzone.com/xhtml/images/banner/bnr1.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <div class="breadcrumb-row">
                        <ul class="list-inline">
                            <li style="font-size: 25px;">Buy 1 kg Get 1/2 kg Free!</li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div> -->


        <div class="page-content bg-white">
            <div class="dlab-bnr-inr overlay-black-middle" id="main-img"
                >

                <!-- style="background-image:url(https://i.ibb.co/F66JFH4/New-wall-001.jpg);" -->

                <div class="container">
                    <div class="dlab-bnr-inr-entry">
                        <!-- <div class="breadcrumb-row">
                            <ul class="list-inline">
                                <li style="font-size: 25px;">Buy 1 kg Get 1/2 kg Free!</li>
                            </ul>
                        </div> -->
                    </div>
                    
                </div>
            </div>


        <!-- inner page banner END -->
        <!-- contact area -->
        <div class="content-block">
            <!-- Product details -->
            <div class="section-full content-inner-1 bg-gray-light">
                <div class="container woo-entry">
                    <div class="row">
                        <div class="col-lg-6 m-b30">
                            <div class="product-gallery on-show-slider lightgallery" id="lightgallery">
                                <div class="dlab-box">
                                    <div class="dlab-thum-bx">
                                        <img *ngIf="product.ImgUrl" src="{{product.ImgUrl}}" alt="{{product.Name}} Cake | {{product.Description}}">
                                        <img *ngIf="!product.ImgUrl" src="../../assets/images/product/item2.jpg" alt="">
                                        <span id="imagesearch" data-exthumbimage="../../assets/images/product/item2.jpg"
                                            data-src="../../assets/images/product/item2.jpg" class="check-km"
                                            title="Image 1 Title will come here">
                                            <i class="fa fa-search"></i>
                                        </span>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div>
                                <span>
                                    <b>Note:</b> Design and icing of cake may vary from the image shown here since each chef has his/her own way of baking and designing a cake.
                                </span>
                            </div>
                        </div>

                        
                        <div class="col-lg-6 m-b30">
                            <form method="post" class="cart sticky-top">
                                <div class="dlab-post-title">
                                    <h4 class="post-title" >{{product.Name.toUpperCase()}} CAKE</h4>
                                    <span>{{product.Description}}</span>

                                    <div class="dlab-divider bg-gray tb15">
                                        <i class="icon-dot c-square"></i>
                                    </div>
                                </div>
                                <div class="relative">
                                    <h2 class="m-tb10 text-red">₹ {{getprice().toFixed(0)}} 
                                        <!-- | {{Last_Total}} | {{Last_optname}} -->
                                    </h2>
                                    <div class="shop-item-rating">
                                        <span class="rating-bx">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star-o"></i>
                                        </span>

                                    </div>
                                </div>



                                <div class="dlab-divider bg-gray tb15">
                                    <i class="icon-dot c-square"></i>
                                </div>


                                <!-- <h2>Select Cake Type *</h2>
                                        <div class="btn-group product-item-size " data-toggle="buttons"
                                            style="flex-wrap: wrap;">
                                            <label *ngFor="let country of var_type" class="btn" 
                                            name="city" style="margin: 5px; font-size: 18px;"
                                                [class.active]="country.selected == true" (click)="blasttype(country)">
                                                <input type="radio" value="{{country.type}}" [checked]="country.selected">
                                                {{country.name}}
                                                
                                            </label>
                                            
                                        </div>
                               <br><br> -->
                               
                                <!-- <h6>Select Weight</h6> -->

                                <div class="row" *ngIf="product.IsQtyPredefined">
                                   
                                    <div class="m-b30 col-md-12 col-sm-8">
                                        <h2>Select Weight *</h2>
                                        <div class="btn-group product-item-size " data-toggle="buttons"
                                            style="flex-wrap: wrap;">
                                            <label *ngFor="let country of predefinedquantity" class="btn" 
                                            name="city" style="margin: 5px; font-size: 18px;"
                                                [class.active]="country.selected == true" (click)="setqty(country)">
                                                <input type="radio" value="{{country.Id}}" [checked]="country.selected">
                                                {{country.QuantityText}}
                                                 <!-- | Rs.{{country.Price}} -->
                                            </label>
                                        </div>

                                        <!-- <div class="btn-group product-item-size " data-toggle="buttons"
                                        style="flex-wrap: wrap;">
                                        <label *ngFor="let country of predefinedquantity" class="btn" 
                                        name="city" style="margin: 5px; font-size: 18px;"
                                            [class.active]="country.selected == true" (click)="setqty(country)">
                                            <input type="radio" value="{{country.Id}}" [checked]="country.selected">
                                            {{country.QuantityText}}
                                             
                                        </label>
                                    </div> -->

                                       <div>
                                       
                                        <!-- *ngIf="product.SaleProductId!=5762 && product.SaleProductId!=5767" -->
                                        <div >
                                            <div style="margin-top: 20px;" 
                                        *ngIf="lab_qty.FreeQuantity!=0">
                                            <span class="text-danger" 
                                            style="font-weight: bold; background-color: rgb(245, 241, 236); padding: 10px; border-radius: 10px; font-size: 18px;">
                                                {{lab_qty.Quantity}} kg Cake - ₹{{getprice().toFixed(0)}} + {{lab_qty.FreeQuantity}} Kg Cake Free !</span>
                                          </div>

                                        </div > 

                                        <div *ngIf="product.SaleProductId==5762">

                                        </div>

                                       </div>
                                        


                                    </div>
                                </div>
                                
                                <hr>

                                <!-- varient work -->

                                <div class="row" >
                                    <div class="m-b30 col-md-12
                                     col-sm-8" *ngIf="variantgroups.length > 0">
                                        <div *ngFor="let group of variantgroups">
                                            <h2>{{group.OptionGroup.Name}} *</h2>
                                            <div class="btn-group product-item-size" data-toggle="buttons"
                                                style="flex-wrap: wrap;">
                                                <label *ngFor="let option of getoptionsbyid(group.OptionGroupId)" 
                                                style="font-size: 18px; margin: 5px;"
                                                    (click)="setvariant(group.OptionGroupId, option.Id)" class="btn"
                                                    [class.active]="option.selected == true">
                                                    <input name="options" id="option7" type="radio"
                                                        [(ngModel)]="option.selected"> {{option.Name}}
                                                    <!-- <a *ngIf="option.Price!=0"> + ₹ {{option.Price}}</a> -->

                                                    <a *ngIf="option.Price!=0"> + ₹ {{(this.qty2*option.Price).toFixed(0)}}</a>
                                                </label>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="m-b30 col-md-12 col-sm-8" *ngIf="product.SaleProductId==5762 || product.SaleProductId==5767">
                                        <h2>Flavour *</h2>
                                        <div class="btn-group product-item-size " data-toggle="buttons"
                                            style="flex-wrap: wrap;">
                                          
                                            <label *ngFor="let country of variantlist" 
                                            class="btn" 
                                            name="city" 
                                            style="margin: 5px; font-size: 18px;" (click)="setvari(country)"
                                                [class.active]="country.selected == true" 
                                                >
                                                <input type="radio" value="{{country.OptionId}}" [checked]="country.selected" 
                                                >
                                                {{country.Name}}
                                                <!-- _Rs  {{(this.qty2*country.price).toFixed(0)}} -->
                                            </label>
                                        </div>
                                        </div>
                                   
                                    <!-- <div>
                                        <label *ngFor="let option of variantlist" class="btn" type="radio" id="option7">
                                            {{option.name}}
                                        </label>

                                    </div> -->

                                    <!-- <div style="text-align: center; margin: auto;" >
                                        <a href="https://bit.ly/36Ze8WZ" target="blank">
                                            <img src="https://i.ibb.co/zR0D8vX/JJJ.jpg" 
                                            alt="sarina boutique" style="width: 350px; height: 200px;">
                                        </a>
                                        
                                    </div> -->




                                    <div *ngFor="let group of addongroups" class="m-b30 col-md-12 col-sm-12">
                                        <h2>{{group.OptionGroup.Name}} (Optional) </h2>
                                       
                                        <div class="btn-group  btn-group-toggle product-item-size add-onns"
                                            data-toggle="buttons">
                                           <div *ngFor="let option of getoptionsbyid(group.OptionGroupId);let i = index">
                                               <label *ngIf="i == 0"
                                                   class="btn " (click)="option.selected = !option.selected" style="font-size: 18px; margin: 5px;">
                                                   <input type="checkbox">Make Eggless
                                                   <a *ngIf="option.Price!=0" style="font-size: 18px;">+ ₹ {{(this.qty2*option.Price).toFixed(0)}}</a>
                                               </label>
                                           </div>                                            
                                        </div>
                                    </div>

                                   
                                    <div class="m-b30 col-md-12 col-sm-12" *ngIf="Rpid!=6660 && Rpid!=6678">
                                        <h2> Eggless (Optional) </h2>
                                        <div class="btn-group  btn-group-toggle product-item-size add-onns"
                                            data-toggle="buttons">
                                           <div *ngFor="let option of myoption;let i = index">
                                               <label *ngIf="i == 0"
                                                   class="btn" 
                                                   (click)="option.selected = !option.selected"
                                                   style="font-size: 18px; margin: 5px;">
                                                   <input type="checkbox">Make {{option.name}}
                                                   <a *ngIf="option.Price!=0" style="font-size: 18px;">+ ₹ {{(this.qty2*150).toFixed(0)}}</a>
                                               </label>
                                           </div>                                            
                                        </div>

                                    </div>


                                    <!-- gst  egless-->
                                <!-- <div  class="m-b30 col-md-12 col-sm-12">
                                    <h2>Eggless (Optional) </h2>
                                   
                                    <div class="btn-group  btn-group-toggle product-item-size add-onns"
                                        data-toggle="buttons">
                                       <div >
                                           <label 
                                               class="btn " style="font-size: 18px; margin: 5px;">
                                               <input type="checkbox">Make Eggless
                                               <a  style="font-size: 18px;">+ ₹ 100</a>
                                           </label>
                                       </div>                                            
                                    </div>
                                </div> -->

                                </div>


                                
                            



                                <!-- <div *ngIf="product.IsQtyPredefined">
                                    <h6>*Short Msg on Cake!</h6>
                                    <input class="form-control" name="dzNumber" placeholder="eg: Happy Birthday Krishna"
                                        type="text" [(ngModel)]="msg">

                                    <div *ngIf="cakemsgalert==true" style="margin-top: 10px;">
                                        <span class="text-danger">Cake Message Required!</span>
                                    </div>
                                </div> -->

                                <div>
                                    <h2>Short Msg on Cake *</h2>
                                    <input class="form-control" name="dzNumber" placeholder="eg: Happy Birthday My Sweart Heart ❤️"
                                        type="text" [(ngModel)]="msg" style="font-size: 18px; padding: 20px;">

                                    <div *ngIf="cakemsgalert==true" style="margin-top: 15px; font-size: 18px;margin-left: 10px;">
                                        <span class="text-danger">Cake Message Required!</span>
                                    </div>
                                </div>

                               


                                <!-- <input 
                               *ngIf="this.pid==true"
                               class="form-control" 
                               name="dzNumber" 
                               placeholder="Message on Cake"
                               type="email" 
                               [(ngModel)]="msg"> -->
                                <br />
                                <!-- <span>{{msg}}</span> -->
                                <!-- 2,3,S: 2.5+2.5
                                3S:5-FIN
                                4S:5 PEN
                                5S:5 PEN
                                6S:5 PEN -->
                                <!-- Start -->
                                <!-- <select 
                                *ngIf="product.IsQtyPredefined" 
                                name="city"
                                    class="rounded-inputs20 select-select col-md-3"
                                    (change)="setqty($event.target.value)">
                                    <option 
                                    *ngFor="let country of predefinedquantity" 
                                    (click)="setqty(country.Id)"
                                        value="{{country.Id}}">
                                        {{country.QuantityText}}
                                    </option>
                                </select> -->

                                <!-- End -->

                                <!-- Start -->

                                <!-- <select
                                formControlName="city" 
                                class="form-control input-sm">
                                    <option 
                                    *ngFor="let country of predefinedquantity" 
                                    value="{{country.Id}}">
                                    {{country.QuantityText}}
                                </option>
                                </select> -->

                                <!-- End -->

                                <!-- cart button -->

                                <!-- <button class="btn btnhover" 
                                (click)="addtocart()"
                                
                                >

                                    <i class="ti-shopping-cart"></i> Add To Cart
                                </button> -->
                                <button class="btn btnhover btn-danger" (click)="submitcart()" style="font-size: 20px;">

                                    <i class="ti-shopping-cart" ></i> Add To Cart
                                </button>
                               
                                <br /><br /><br /><br />


<!-- new work -->
                                <!-- <span>Total: </span> {{NewfinalAmount}}
                                <br>

                                <br>
                                <div class="m-b30 col-md-12 col-sm-8">
                                    <h2>Select Weight *</h2>
                                    <div class="btn-group product-item-size " data-toggle="buttons"
                                        style="flex-wrap: wrap;">
                                        <label *ngFor="let country of predefinedquantity" class="btn" 
                                        name="city" style="margin: 5px; font-size: 18px;"
                                            [class.active]="country.selected == true" 
                                            (click)="newSETQTY(country)">
                                            <input type="radio" value="{{country.Id}}" [checked]="country.selected">
                                            {{country.QuantityText}}
                                        </label>
                                    </div></div>

                                    <div class="m-b30 col-md-12 col-sm-8">
                                        <h2>Flavour *</h2>
                                        <div class="btn-group product-item-size " data-toggle="buttons"
                                            style="flex-wrap: wrap;">
                                          
                                            <label *ngFor="let country of variantlist" 
                                            class="btn" 
                                            name="city" 
                                            style="margin: 5px; font-size: 18px;" 
                                            (click)="newSETVAR(country)"
                                                [class.active]="country.selected == true" 
                                                >
                                                <input type="radio" value="{{country.OptionId}}" 
                                                [checked]="country.selected" 
                                                >
                                                {{country.Name}}_Rs {{country.price}}
                                            </label>
                                        </div>
                                        </div>
                                        
                                        <br><br><br>

                                <span>Eggless: </span><br> -->


                                <!-- <div style="text-align: center; margin-top: 20px;" >
                                    <a  (click)="ADSclick()" href="https://bit.ly/36Ze8WZ" target="blank">
                                        <img src="https://i.ibb.co/zR0D8vX/JJJ.jpg" 
                                        alt="sarina the boutique" style="width: 350px; height: 200px;">
                                    </a>
                                    
                                </div> -->

                                <!-- <span
                                class="text-danger">Please Select one Varient !!!</span> -->
                            </form>



                            <!-- <div>
                                <input [(ngModel)]="msg" placeholder="Message on Cake" type="text" style="padding: 10;">
                            </div> -->
                            <br>

                            <!-- target -->

                                    <!-- ADS -->
                       
                                    


                        </div>

                        <div style="margin-left: 10px; margin-right:10px">
                            <hr><h2>{{product.Name}} Cake Description</h2>
                            <div *ngFor="let group of variantgroups">
                                <p style="font-size: 18px; text-align: justify;">We have Several Fondant Flavors in {{product.Name}} like 
                                    <span *ngFor="let option of getoptionsbyid(group.OptionGroupId)">
                                        {{option.Name}} Flavor Cake,
                                    </span> 
                                </p>

                            </div>

                            <p style="font-size: 18px; text-align: justify">
                                This is a classic -  {{product.Name}} cake which is a very delightful and coloured cake with a subtle flavour. 
                                It is very fluffy, light, and moist. Thanks to the help of a few special ingredients. 
                                This coloured cake can never go wrong with anyone. It has a simple and delicate flavour. 
                                A perfect gift for someone who does like mild flavours. 
                                It is suitable for all occasions and ages, you can without a doubt send this cake to surprise your loved one.
                                We make Eggless Cake Also..
                            </p>


                        </div>
                       
                        <!-- <div *ngFor="let group of variantgroups">
                            <hr>
                            <h2>{{product.Name}} Cake Description</h2>
                            <p style="font-size: 18px; text-align: justify;">We have Several Fondant Flavors in {{product.Name}} like 
                                <span *ngFor="let option of getoptionsbyid(group.OptionGroupId)">
                                    {{option.Name}} Flavor Cake,
                                </span> We make Eggless Cake Also..
                            </p>
                            <p style="font-size: 18px; text-align: justify;">
                                This is a classic -  {{product.Name}} cake which is a very delightful and coloured cake with a subtle flavour. 
                                It is very fluffy, light, and moist. Thanks to the help of a few special ingredients. 
                                This coloured cake can never go wrong with anyone. It has a simple and delicate flavour. 
                                A perfect gift for someone who does like mild flavours. 
                                It is suitable for all occasions and ages, you can without a doubt send this cake to surprise your loved one.
                                We make Eggless Cake Also..
                            </p>
                        </div> -->

                        <div style="margin-top: 10px; margin-left: 10px; margin-right:10px;">
                           <h2>{{product.Name}} Cake Delivery Details</h2>
                           <p style="font-size: 18px; text-align: justify;">
                            {{product.Name}} cake is hand-delivered by our FBCakes delivery boys in a good quality cardboard box.
                            Candle and knife will be delivered as per the availability.
                           </p>
                        </div>

                        <div style="margin-top: 10px; margin-left: 10px; margin-right:10px">
                            <h2>{{product.Name}} Cake Care Instructions</h2>
                            <p style="font-size: 18px; text-align: justify;">
                                Store {{product.Name.toLowerCase()}} cake in a refrigerator.
                                Consume {{product.Name.toLowerCase()}} cake within 1 Day.
                            </p>
                         </div>

                         <diV>
                            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6972226194484846"
     crossorigin="anonymous"></script>
<!-- test 002 vert -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-6972226194484846"
     data-ad-slot="7002893946"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>
                         </diV>

                        

                        

                         <!-- <div style="margin-left: 10px; margin-right:10px">
                             <br>
                            <h2>Top Searches</h2>
                        
                            <span *ngFor="let e of event2">
                                <a style="font-size: 22px; line-height: 30pt; text-align: justify;" href={{e.link}}>{{e.name}}</a> <span style="font-size: 32px; color: #c0c0c0;"> | </span>
                        
                            </span>
                        </div> -->

                        
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>

    <br><br>
    
    <button class="scroltop icon-up" type="button"><i class="fa fa-arrow-up"></i></button>


    <!-- <h3>Data Binding Test</h3><br/>
    <select 
    *ngIf="product.IsQtyPredefined" 
    name="city" 
    class="rounded-inputs20 select-select col-md-3"
        (change)="setqty($event.target.value)">
        <option *ngFor="let country of predefinedquantity" 
        (click)="setqty(country.Id)" value="{{country.Id}}">
            {{country.QuantityText}}
        </option>
    </select>

    <h5>0.5Kg</h5>

    <select 
    class="rounded-inputs20 select-select col-md-3"
    [(ngModel)]="firstSelectValue">
        <option 
        *ngFor="let opt of firstSelectOptions" 
        [value]="opt">
          {{ opt }}
        </option>
      </select>
  
      <div>
        <h5> select value: {{ firstSelectValue }}</h5>
      </div> -->
</div>




