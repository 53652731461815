<div class="page-wraper">

    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">Proposal Cake</h1>
                </div>
            </div>
        </div>

        <div class="section-full content-inner">
            <div class="container">
                <div class="shop-form">

                    <div class="content-block">
                        <!-- Product details -->
                        <div class="section-full content-inner-1 bg-gray-light">
                            <div class="container woo-entry">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <div class="product-gallery on-show-slider lightgallery" id="lightgallery">
                                            <div class="dlab-box">
                                                <div class="dlab-thum-bx">
                                                    <img  src="https://tinyurl.com/2ncuj4y7" alt="Valentine Cake | Proposal Cake for your Sweetheart 💖 | Buy 1 kg Get 1/2 kg Free!">
                                                   
                                                    <span id="imagesearch" data-exthumbimage="../../assets/images/product/item2.jpg"
                                                        data-src="../../assets/images/product/item2.jpg" class="check-km"
                                                        title="Image 1 Title will come here">
                                                        <i class="fa fa-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 m-b30">
                                        <form method="post" class="cart sticky-top">
                                            <div class="dlab-post-title">
                                                <h1 class="post-title">Proposal Cake for your Sweetheart 💖 | Buy 1 kg Get 1/2 kg Free!</h1>
            
                                                <div class="dlab-divider bg-gray tb15">
                                                    <i class="icon-dot c-square"></i>
                                                </div>
                                            </div>
                                            <div class="relative">
                                                <h3 class="m-tb10 text-red">₹ 699.00 </h3>
                                                <div class="shop-item-rating">
                                                    <span class="rating-bx">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star-o"></i>
                                                    </span>
            
                                                </div>
                                            </div>

                                            <div class="relative">
                                                <h2 class="m-tb10">Description </h2>
                                                <span>
                                                    It is always the little gestures that count when you are in love. Remind your lover every now and then that they are loved and cherished by you. This Proposal Cake is perfect to take the next step forward in your girl friend relationship or  your Sweetheart. Proposal Cake of goodness that will melt their Sweet heart. 
                                                    Order Proposal Cakes online and get them delivered Free.
                                                </span>
<hr>
                                                
                                               
                                                <h2 class="m-tb10" style="margin-top: 10px;">Weight </h2>
                                                <span>* 1.5kg + 1/2Kg Free! = Rs 2548</span><br>
                                                <span>* 2kg + 1Kg Free! = Rs 3000</span><br>
                                                <span>* 3kg + 1.5Kg Free! = Rs 5000</span>
                                                <hr>
                                                <h2 class="m-tb10" style="margin-top: 10px;">Flavour </h2>
                                                <span>We have Several Fondant Flavors Proposal Cakes like
                                                    Black Forest, Mango, Strawberry, Blueberry,
                                                    White Forest, ButterScotch, ChocoTruffle, Pineapple,
                                                    Blast(Milky, Blueberry, Ferro, ButterScotch, Red)</span>
                                                </div>
                                                <hr>
                                                <h2 class="m-tb10" style="margin-top: 10px;">Tags </h2>
                                                <span>#engagementcake #birthdaycake #weddingcake #cakedecorating #anniversarycake</span>
            <hr>
                                                <button class="btn btnhover btn-danger" (click)="submit()">

                                                    <i class="ti-shopping-cart"></i> Shop Page
                                                </button>
                                        </form>
                                        <br>
            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    


                </div>


            </div>




        </div>