            <!-- header -->
            <header  class="site-header header center mo-left header-style-2">
                <!-- main header -->
                <div class="sticky-header main-bar-wraper navbar-expand-lg">
                    <div class="main-bar clearfix ">
                        <div class="container clearfix">
                            <!-- website logo -->
                            <div class="logo-header mostion">
                                <a href="" class="dez-page"><img src="../../assets/images/logo.png" alt=""></a>
                            </div>
                            <!-- nav toggle button -->
                            <button class="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                            <!-- main nav -->
                            <div class="header-nav navbar-collapse collapse justify-content-between" id="navbarNavDropdown">
                                <div class="logo-header mostion">
                                    <a href="" class="dez-page"><img src="../../assets/images/logo.png" alt=""></a>
                                </div>
                                <ul class="nav navbar-nav nav1">	
                                    <li class="active"><a href="javascript:void(0);">Home<i class="fa fa-chevron-down"></i></a>
                                        <ul class="sub-menu">
                                            <li><a href="">Home - Cake Bakery</a></li>
                                            <li><a href="indexb">Home - Pizza & Burger</a></li>
                                            <li><a href="indexc">Home - South Indian</a></li>
                                            <li><a href="indexd">Home - Pizza 2</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="javascript:void(0);">Pages<i class="fa fa-chevron-down"></i></a>
                                        <ul class="sub-menu">
                                            <li><a href="abouta">About Us</a></li>
                                            <li><a href="ourservices">Our Services</a></li>
                                            <li><a href="faq">FAQs</a></li>
                                            <li><a href="booking">Booking</a></li>
                                            <li><a href="error">404 Error</a></li>
                                            <li><a href="calendar">Calendar</a></li>
                                            <li><a href="comingsoon">Coming Soon</a></li>
                                            <li><a href="team">Team</a></li>
                                            <li>
                                                <a href="javascript:void(0);">Contact Us <i class="fa fa-angle-right"></i></a>
                                                <ul class="sub-menu">
                                                    <li><a href="contacta">Contact Us 1</a></li>
                                                    <li><a href="contactb">Contact Us 2</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="javascript:;">Features<i class="fa fa-chevron-down"></i></a>
                                        <ul class="sub-menu tab-content">
                                            <li>
                                                <a href="javascript:;">Header Style <i class="fa fa-angle-right"></i></a>
                                                <ul class="sub-menu">
                                                    <li><a href="javascript:;" (click)="setheadertype('a')">Header 1</a></li>
                                                    <li><a href="javascript:;" (click)="setheadertype('b')">Header 2</a></li>
                                                    <li><a href="javascript:;" (click)="setheadertype('c')">Header 3</a></li>
                                                    <li><a href="javascript:;" (click)="setheadertype('d')">Header 4</a></li>
                                                </ul>
                                            </li>
                                            <li> 
                                                <a href="javascript:;">Footer Style<i class="fa fa-angle-right"></i></a>
                                                <ul class="sub-menu">
                                                    <li><a href="footerstylea">Footer 1 </a></li>
                                                    <li><a href="footerstyleb">Footer 2</a></li>
                                                    <li><a href="footerstylec">Footer 3</a></li>
                                                    <li><a href="footerstyled">Footer 4</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <ul class="nav navbar-nav nav2">
                                    <li><a href="javascript:void(0);">Our Menu<i class="fa fa-chevron-down"></i></a>
                                        <ul class="sub-menu">
                                            <li><a href="ourmenu">Menu Style 1</a></li>
                                            <li><a href="ourmenub">Menu Style 2</a></li>
                                            <li><a href="ourmenuc">Menu Style 3</a></li>
                                            <li><a href="ourmenud">Menu Style 4</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">Shop <i class="fa fa-chevron-down"></i></a>
                                        <ul class="sub-menu">
                                            <li><a href="shop">Shop</a></li>
                                            <li><a href="shopsidebar">Shop Sidebar</a></li>
                                            <li><a href="itemdetails">Product Details</a></li>
                                            <li><a href="shopcart">Cart</a></li>
                                            <li><a href="shopwishlist">Wishlist</a></li>
                                            <li><a href="shopcheckout">Checkout</a></li>
                                            <li><a href="shoplogin">Login</a></li>
                                            <li><a href="shopregister">Register</a></li>
                                        </ul>
                                    </li>
                                    <li class="has-mega-menu"><a href="javascript:void(0);">Blog<i class="fa fa-chevron-down"></i></a>
                                        <ul class="mega-menu">
                                            <li> <a href="javascript:;">Blog Grid</a>
                                                <ul>
                                                    <li><a href="bloggridb">Grid 2</a></li>
                                                    <li><a href="bloggridbsidebar">Grid 2 sidebar</a></li>
                                                    <li><a href="bloggridbsidebarleft">Grid 2 sidebar left</a></li>
                                                    <li><a href="bloggridc">Grid 3</a></li>
                                                </ul>
                                            </li>
                                            <li> <a href="javascript:;">Blog Half Image</a>
                                                <ul>
                                                    <li><a href="bloghalfimg">Half image</a></li>
                                                    <li><a href="bloghalfimgsidebar">Half image sidebar</a></li>
                                                    <li><a href="bloghalfimgleftsidebar">Half image sidebar left</a></li>
                                                </ul>
                                            </li>
                                            <li> <a href="javascript:;">Blog Large Image</a>
                                                <ul>
                                                    <li><a href="bloglargeimg">Large image</a></li>
                                                    <li><a href="bloglargeimgsidebar">Large image sidebar</a></li>
                                                    <li><a href="bloglargeimgleftsidebar">Large image sidebar left</a></li>
                                                </ul>
                                            </li>
                                            <li> <a href="javascript:;">Blog Details</a>
                                                <ul>
                                                    <li><a href="blogsingle">Single</a></li>
                                                    <li><a href="blogsinglesidebar">Single sidebar</a></li>
                                                    <li><a href="blogsingleleftsidebar">Single sidebar right</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>		
                            </div>
                        </div>
                    </div>
                </div>
                <!-- main header END -->
            </header>
            <!-- header END -->


            <!-- Core Commands
=============

    Command                   Description
    -------                   -----------
    ?                         Help menu
    background                Backgrounds the current session
    bg                        Alias for background
    bgkill                    Kills a background meterpreter script
    bglist                    Lists running background scripts
    bgrun                     Executes a meterpreter script as a background thread
    channel                   Displays information or control active channels
    close                     Closes a channel
    detach                    Detach the meterpreter session (for http/https)
    disable_unicode_encoding  Disables encoding of unicode strings
    enable_unicode_encoding   Enables encoding of unicode strings
    exit                      Terminate the meterpreter session
    get_timeouts              Get the current session timeout values
    guid                      Get the session GUID
    help                      Help menu
    info                      Displays information about a Post module
    irb                       Open an interactive Ruby shell on the current session
    load                      Load one or more meterpreter extensions
    machine_id                Get the MSF ID of the machine attached to the session
    pry                       Open the Pry debugger on the current session
    quit                      Terminate the meterpreter session
    read                      Reads data from a channel
    resource                  Run the commands stored in a file
    run                       Executes a meterpreter script or Post module
    secure                    (Re)Negotiate TLV packet encryption on the session
    sessions                  Quickly switch to another session
    set_timeouts              Set the current session timeout values
    sleep                     Force Meterpreter to go quiet, then re-establish session
    transport                 Manage the transport mechanisms
    use                       Deprecated alias for "load"
    uuid                      Get the UUID for the current session
    write                     Writes data to a channel


Stdapi: File system Commands
============================

    Command       Description
    -------       -----------
    cat           Read the contents of a file to the screen
    cd            Change directory
    checksum      Retrieve the checksum of a file
    cp            Copy source to destination
    del           Delete the specified file
    dir           List files (alias for ls)
    download      Download a file or directory
    edit          Edit a file
    getlwd        Print local working directory
    getwd         Print working directory
    lcd           Change local working directory
    lls           List local files
    lpwd          Print local working directory
    ls            List files
    mkdir         Make directory
    mv            Move source to destination
    pwd           Print working directory
    rm            Delete the specified file
    rmdir         Remove directory
    search        Search for files
    upload        Upload a file or directory


Stdapi: Networking Commands
===========================

    Command       Description
    -------       -----------
    ifconfig      Display interfaces
    ipconfig      Display interfaces
    portfwd       Forward a local port to a remote service
    route         View and modify the routing table


Stdapi: System Commands
=======================

    Command       Description
    -------       -----------
    execute       Execute a command
    getenv        Get one or more environment variable values
    getuid        Get the user that the server is running as
    localtime     Displays the target system local date and time
    pgrep         Filter processes by name
    ps            List running processes
    shell         Drop into a system command shell
    sysinfo       Gets information about the remote system, such as OS


Stdapi: User interface Commands
===============================

    Command       Description
    -------       -----------
    screenshare   Watch the remote user desktop in real time
    screenshot    Grab a screenshot of the interactive desktop


Stdapi: Webcam Commands
=======================

    Command        Description
    -------        -----------
    record_mic     Record audio from the default microphone for X seconds
    webcam_chat    Start a video chat
    webcam_list    List webcams
    webcam_snap    Take a snapshot from the specified webcam
    webcam_stream  Play a video stream from the specified webcam


Stdapi: Audio Output Commands
=============================

    Command       Description
    -------       -----------
    play          play a waveform audio file (.wav) on the target system


Android Commands
================

    Command           Description
    -------           -----------
    activity_start    Start an Android activity from a Uri string
    check_root        Check if device is rooted
    dump_calllog      Get call log
    dump_contacts     Get contacts list
    dump_sms          Get sms messages
    geolocate         Get current lat-long using geolocation
    hide_app_icon     Hide the app icon from the launcher
    interval_collect  Manage interval collection capabilities
    send_sms          Sends SMS from target session
    set_audio_mode    Set Ringer Mode
    sqlite_query      Query a SQLite database from storage
    wakelock          Enable/Disable Wakelock
    wlan_geolocate    Get current lat-long using WLAN information


Application Controller Commands
===============================

    Command        Description
    -------        -----------
    app_install    Request to install apk file
    app_list       List installed apps in the device
    app_run        Start Main Activty for package name
    app_uninstall  Request to uninstall application
 -->
