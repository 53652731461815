<div class="page-wraper">
   
    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <!-- <div class="container">
                <div class="dlab-bnr-inr-entry">
                   
                    <h1 class="text-white">Terms and Conditions</h1>
                </div>
            </div> -->
        </div>

        <div class="section-full content-inner">
            <div class="container">
               
              
            </div>
           



        </div>