<div class="page-wraper">

    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">Contact Us</h1>
                </div>
            </div>
        </div>

        <div class="section-full content-inner">
            <div class="container">
                <div class="shop-form">


                    <h1>Contact Us</h1>

                    <div class="row col-xl-12">
                        <div class="col-xl-6">

                            <input style="font-size: 19px;" class="form-control" name="Con_Name" placeholder="Name"
                                type="name" [(ngModel)]="name">
                            <br>
                            <!-- <span>{{name}}</span> -->
                            <!-- <input style="font-size: 19px;" class="form-control" name="Con_Name" placeholder="Email Address" type="email"
                                [(ngModel)]="email">
                            <br> -->

                            <input style="font-size: 19px;" class="form-control" name="Con_Name" placeholder="Phone no."
                                [(ngModel)]="mobile">
                            <br>

                            <!-- <input  class="form-control" 
                        name="Con_Name" 
                        placeholder="Enquiry"
                        type="Con_Enq"
                        style="height: 100px;"
                        > -->

                            <textarea rows="6" name="text" class="form-control"
                                style="width: 100%; margin-top: 5px; font-size: 19px;" [(ngModel)]="message"
                                placeholder="Enquiry / Complaint"></textarea>

                           

                                <!-- <label 
                            class="btn btn-success" 
                            style="margin-top: 20px; " 
                            (click)="whatsup()">WhatsApp
                                
                            </label>

                            <span style="padding: 10px; margin-top: 30px;">or</span> -->

                            <label 
                            class="btn btn-danger" 
                            style="margin-top: 20px;" 
                            (click)="submit()">Submit
                                Form</label>

                            <div style="margin-top:10px;">
                                <span [ngStyle]="{'color': rcolor==0 ? 'red':'green' }">{{this.report}}</span>

                            </div>
<!-- <div style="margin-top: 20px">
    <span >Note: If you contact us through WhatsApp. you will get replied soon 😊. </span>

</div> -->
                            
                        </div>

                        <!-- <div class="col-xl-6">
                            <div >
                                <h5 class="footer-title">Contact Info</h5>
                                <ul>
                                    <li>
                                        <i class="fa fa-map-marker"></i> 
                                       
                                        <p>HEAD OFFICE <br>60, RGM Complex, OMR, Karapakkam, Chennai-600097</p>
                                    </li>
                                    <li>
                                       
                                        <i class="fa fa-mobile"></i> 
                                        <p>(+91) 90034 32888</p>
                                    </li>
                                   
                                    <li>
                                        <i class="fa fa-envelope"></i> 
                                        <p>admin@fbcakes.com</p>
                                    </li>
                                </ul>
                            </div>
                        </div> -->



                    </div>


                </div>




            </div>