import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css']
})
export class LabelComponent implements OnInit {

  label_name = ""
  myproduct = []
  bannername: string = ""
  constructor(private route: ActivatedRoute, private router: Router) {
    this.label_name = this.route.snapshot.paramMap.get('label')
    console.log('label_name: ', this.label_name);
    this.myproduct = this.product.filter(x => x.label == this.label_name)
    console.log('myproduct: ', this.myproduct);

    if (this.myproduct.length == 0) {
      console.log("works")
      // window.open('http://fbcakes.com/');
      this.router.navigate(['']);
    }
    this.bannername = this.label_name.split("-").join(" ")
  }

  ngOnInit(): void {
  }

  product = [
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(77)[superuser]%20%20%20%204%204799%205%205899%206%206999.jpg',
      alt: 'Royal Affair Cake online free delivery chennai',
      name: 'Royal Affair Cake',
      price: '6796',
      link: 'https://fbcakes.com/cakes/royal-affair-2-cake-5955',
      label: 'cakes-for-birthday'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/31_grande%20%207.5-5999%209-6999.jpg',
      alt: 'Pink Stunner Cake online free delivery chennai',
      name: 'Pink Stunner Cake',
      price: '4288',
      link: 'https://fbcakes.com/cakes/pink-stunner-cake-5971',
      label: 'cakes-for-birthday'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(25)[superuser]%20_fond19%20%204%204799%205%205899%206%206999.jpg',
      alt: 'Lollipop Cake online free delivery chennai',
      name: 'Lollipop Cake',
      price: '3827',
      link: 'https://fbcakes.com/cakes/lollipop-cake-5977',
      label: 'cakes-for-birthday'
    },

    //cakes-for-girls

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/strawberry_rose_cake%20499%20999.jpg',
      alt: 'Pastel Pink Heaven Cake online free delivery chennai',
      name: 'Pastel Pink Heaven Cake',
      price: '480',
      link: 'https://fbcakes.com/cakes/pastel-pink-heaven-cake-5835',
      label: 'cakes-for-girls'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/double-heart-chocolate-cake%20%202%201799%203%202699.jpg',
      alt: 'Double Heart Cake online free delivery chennai',
      name: 'Double Heart Cake',
      price: '1680',
      link: 'https://fbcakes.com/cakes/double-heart-cake-5843',
      label: 'cakes-for-girls'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/forever-n-a-day-more-cake%20%20499%20999.jpg',
      alt: 'Forever A Day More Cake online free delivery chennai',
      name: 'Forever A Day More Cake',
      price: '840',
      link: 'https://fbcakes.com/cakes/forever-%22n%22-a-day-more-cake-5857',
      label: 'cakes-for-girls'
    },

    // cakes-near-me
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/IMG-20170209%20%20%201.5-%201499%202-%201999.jpg',
      alt: 'Disney Princess Cake online free delivery chennai',
      name: 'Disney Princess Cake',
      price: '1560',
      link: 'https://fbcakes.com/cakes/disney-princess-cake-5973',
      label: 'cakes-near-me'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/choclate-vanilla.jpg',
      alt: 'Choco Vanilla Cake online free delivery chennai',
      name: 'Choco Vanilla Cake',
      price: '485',
      link: 'https://fbcakes.com/cakes/choco-vanilla-cake-12566',
      label: 'cakes-near-me'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A4444%20(1).jpg',
      alt: 'Red Velvet Cake online free delivery chennai',
      name: 'Red Velvet Cake',
      price: '545',
      link: 'https://fbcakes.com/cakes/red-velvet-cake-12584',
      label: 'cakes-near-me'
    },

    //cakes-and-prices
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(85)%204%204799%205%205899%206%206999.jpg',
      alt: 'Black and Red Barbie Doll Cake online free delivery chennai',
      name: 'Black and Red Barbie Doll Cake',
      price: '4947',
      link: 'https://fbcakes.com/cakes/black-and-red-barbie-doll-cake-5954',
      label: 'cakes-and-prices'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/1468798_%201.5%201399%20%203%202499.jpg',
      alt: 'Princess Ballerina Cake online free delivery chennai',
      name: 'Princess Ballerina Cake',
      price: '1560',
      link: 'https://fbcakes.com/cakes/princess-ballerina-cake-5968',
      label: 'cakes-and-prices'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/chocolate%20499%20999.jpg',
      alt: 'Heartilicious Pleasure Cake online free delivery chennai',
      name: 'Heartilicious Pleasure Cake',
      price: '840',
      link: 'https://fbcakes.com/cakes/heartilicious-pleasure-cake-5836',
      label: 'cakes-and-prices'
    },

    // cakes-for-boys
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/strawberry_rose_cake%20499%20999.jpg',
      alt: 'Pastel Pink Heaven Cake online free delivery chennai',
      name: 'Pastel Pink Heaven Cake',
      price: '480',
      link: 'https://fbcakes.com/cakes/pastel-pink-heaven-cake-5835',
      label: 'cakes-for-boys'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/double-heart-chocolate-cake%20%202%201799%203%202699.jpg',
      alt: 'Double Heart Cake online free delivery chennai',
      name: 'Double Heart Cake',
      price: '1680',
      link: 'https://fbcakes.com/cakes/double-heart-cake-5843',
      label: 'cakes-for-boys'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/forever-n-a-day-more-cake%20%20499%20999.jpg',
      alt: 'Forever A Day More Cake online free delivery chennai',
      name: 'Forever A Day More Cake',
      price: '840',
      link: 'https://fbcakes.com/cakes/forever-%22n%22-a-day-more-cake-5857',
      label: 'cakes-for-boys'
    },

    //cakes-online
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/choclate-vanilla.jpg',
      alt: 'Choco Vanilla Cake online free delivery chennai',
      name: 'Choco Vanilla Cake',
      price: '485',
      link: 'https://fbcakes.com/cakes/choco-vanilla-cake-12566',
      label: 'cakes-online'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A4444%20(1).jpg',
      alt: 'Red Velvet Cake online free delivery chennai',
      name: 'Red Velvet Cake',
      price: '545',
      link: 'https://fbcakes.com/cakes/red-velvet-cake-12584',
      label: 'cakes-online'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/forever-n-a-day-more-cake%20%20499%20999.jpg',
      alt: 'Forever A Day More Cake online free delivery chennai',
      name: 'Forever A Day More Cake',
      price: '840',
      link: 'https://fbcakes.com/cakes/forever-%22n%22-a-day-more-cake-5857',
      label: 'cakes-online'
    },

    //cakes-for-kids
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(85)%204%204799%205%205899%206%206999.jpg',
      alt: 'Black and Red Barbie Doll Cake online free delivery chennai',
      name: 'Black and Red Barbie Doll Cake',
      price: '4947',
      link: 'https://fbcakes.com/cakes/black-and-red-barbie-doll-cake-5954',
      label: 'cakes-for-kids'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/31_grande%20%207.5-5999%209-6999.jpg',
      alt: 'Pink Stunner Cake online free delivery chennai',
      name: 'Pink Stunner Cake',
      price: '4288',
      link: 'https://fbcakes.com/cakes/pink-stunner-cake-5971',
      label: 'cakes-for-kids'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(25)[superuser]%20_fond19%20%204%204799%205%205899%206%206999.jpg',
      alt: 'Lollipop Cake online free delivery chennai',
      name: 'Lollipop Cake',
      price: '3827',
      link: 'https://fbcakes.com/cakes/lollipop-cake-5977',
      label: 'cakes-for-kids'
    },

    //cake-design
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(85)%204%204799%205%205899%206%206999.jpg',
      alt: 'Black and Red Barbie Doll Cake online free delivery chennai',
      name: 'Black and Red Barbie Doll Cake',
      price: '4947',
      link: 'https://fbcakes.com/cakes/black-and-red-barbie-doll-cake-5954',
      label: 'cake-design'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/double-heart-chocolate-cake%20%202%201799%203%202699.jpg',
      alt: 'Double Heart Cake online free delivery chennai',
      name: 'Double Heart Cake',
      price: '1680',
      link: 'https://fbcakes.com/cakes/double-heart-cake-5843',
      label: 'cake-design'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/forever-n-a-day-more-cake%20%20499%20999.jpg',
      alt: 'Forever A Day More Cake online free delivery chennai',
      name: 'Forever A Day More Cake',
      price: '840',
      link: 'https://fbcakes.com/cakes/forever-%22n%22-a-day-more-cake-5857',
      label: 'cake-design'
    },

    //cakes for anniversary

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(85)%204%204799%205%205899%206%206999.jpg',
      alt: 'Black and Red Barbie Doll Cake online free delivery chennai',
      name: 'Black and Red Barbie Doll Cake',
      price: '4947',
      link: 'https://fbcakes.com/cakes/black-and-red-barbie-doll-cake-5954',
      label: 'cakes-for-anniversary'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/1468798_%201.5%201399%20%203%202499.jpg',
      alt: 'Princess Ballerina Cake online free delivery chennai',
      name: 'Princess Ballerina Cake',
      price: '1560',
      link: 'https://fbcakes.com/cakes/princess-ballerina-cake-5968',
      label: 'cakes-for-anniversary'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/strawberry_rose_cake%20499%20999.jpg',
      alt: 'Pastel Pink Heaven Cake online free delivery chennai',
      name: 'Pastel Pink Heaven Cake',
      price: '480',
      link: 'https://fbcakes.com/cakes/pastel-pink-heaven-cake-5835',
      label: 'cakes-for-anniversary'
    },


    //cakes for children birthday
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(85)%204%204799%205%205899%206%206999.jpg',
      alt: 'Black and Red Barbie Doll Cake online free delivery chennai',
      name: 'Black and Red Barbie Doll Cake',
      price: '4947',
      link: 'https://fbcakes.com/cakes/black-and-red-barbie-doll-cake-5954',
      label: 'cakes-for-children-birthday'
    },
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/31_grande%20%207.5-5999%209-6999.jpg',
      alt: 'Pink Stunner Cake online free delivery chennai',
      name: 'Pink Stunner Cake',
      price: '4288',
      link: 'https://fbcakes.com/cakes/pink-stunner-cake-5971',
      label: 'cakes-for-children-birthday'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(25)[superuser]%20_fond19%20%204%204799%205%205899%206%206999.jpg',
      alt: 'Lollipop Cake online free delivery chennai',
      name: 'Lollipop Cake',
      price: '3827',
      link: 'https://fbcakes.com/cakes/lollipop-cake-5977',
      label: 'cakes-for-children-birthday'
    },

    //cakes for girls birthday

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(68)[superuser]%20%20_fond19%204%204799%205%205899%206%206999.jpg',
      alt: 'Lavender Blue Ombre Cake online free delivery chennai',
      name: 'Lavender Blue Ombre Cake',
      price: '3893',
      link: 'https://fbcakes.com/cakes/lavender-blue-ombre-cake-5966',
      label: 'cakes-for-girls-birthday'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/1468798_%201.5%201399%20%203%202499.jpg',
      alt: 'Princess Ballerina Cake online free delivery chennai',
      name: 'Princess Ballerina Cake',
      price: '1560',
      link: 'https://fbcakes.com/cakes/princess-ballerina-cake-5968',
      label: 'cakes-for-girls-birthday'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2022.jpg',
      alt: 'Blueberry Cake online free delivery chennai',
      name: 'Blueberry Cake',
      price: '485',
      link: 'https://fbcakes.com/cakes/blueberry-cake-12570',
      label: 'cakes-for-girls-birthday'
    },

    //cakes at low price

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A0261%20low%20res.jpg',
      alt: 'Black Forest Cake online free delivery chennai',
      name: 'Black Forest Cake',
      price: '390',
      link: 'https://fbcakes.com/cakes/black-forest-cake-12561',
      label: 'cakes-at-low-price'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2008.jpg',
      alt: 'Mango Cake online free delivery chennai',
      name: 'Mango Cake',
      price: '390',
      link: 'https://fbcakes.com/cakes/mango-cake-12562',
      label: 'cakes-at-low-price'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2034.jpg',
      alt: 'Pineapple Cake online free delivery chennai',
      name: 'Pineapple Cake',
      price: '410',
      link: 'https://fbcakes.com/cakes/pineapple-cake-12563',
      label: 'cakes-at-low-price'
    },


    // cakes below 300

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A0261%20low%20res.jpg',
      alt: 'Black Forest Cake online free delivery chennai',
      name: 'Black Forest Cake',
      price: '390',
      link: 'https://fbcakes.com/cakes/black-forest-cake-12561',
      label: 'cakes-below-300'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2008.jpg',
      alt: 'Mango Cake online free delivery chennai',
      name: 'Mango Cake',
      price: '390',
      link: 'https://fbcakes.com/cakes/mango-cake-12562',
      label: 'cakes-below-300'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2034.jpg',
      alt: 'Pineapple Cake online free delivery chennai',
      name: 'Pineapple Cake',
      price: '410',
      link: 'https://fbcakes.com/cakes/pineapple-cake-12563',
      label: 'cakes-below-300'
    },


    // cakes below 500

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A0261%20low%20res.jpg',
      alt: 'Black Forest Cake online free delivery chennai',
      name: 'Black Forest Cake',
      price: '390',
      link: 'https://fbcakes.com/cakes/black-forest-cake-12561',
      label: 'cakes-below-500'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2008.jpg',
      alt: 'Mango Cake online free delivery chennai',
      name: 'Mango Cake',
      price: '390',
      link: 'https://fbcakes.com/cakes/mango-cake-12562',
      label: 'cakes-below-500'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2034.jpg',
      alt: 'Pineapple Cake online free delivery chennai',
      name: 'Pineapple Cake',
      price: '410',
      link: 'https://fbcakes.com/cakes/pineapple-cake-12563',
      label: 'cakes-below-500'
    },

    //birthday-cakes-for-girls

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(68)[superuser]%20%20_fond19%204%204799%205%205899%206%206999.jpg',
      alt: 'Lavender Blue Ombre Cake online free delivery chennai',
      name: 'Lavender Blue Ombre Cake',
      price: '3893',
      link: 'https://fbcakes.com/cakes/lavender-blue-ombre-cake-5966',
      label: 'birthday-cakes-for-girls'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/1468798_%201.5%201399%20%203%202499.jpg',
      alt: 'Princess Ballerina Cake online free delivery chennai',
      name: 'Princess Ballerina Cake',
      price: '1560',
      link: 'https://fbcakes.com/cakes/princess-ballerina-cake-5968',
      label: 'birthday-cakes-for-girls'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2022.jpg',
      alt: 'Blueberry Cake online free delivery chennai',
      name: 'Blueberry Cake',
      price: '485',
      link: 'https://fbcakes.com/cakes/blueberry-cake-12570',
      label: 'birthday-cakes-for-girls'
    },

    //birthday-cakes-for-boys


    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(68)[superuser]%20%20_fond19%204%204799%205%205899%206%206999.jpg',
      alt: 'Lavender Blue Ombre Cake online free delivery chennai',
      name: 'Lavender Blue Ombre Cake',
      price: '3893',
      link: 'https://fbcakes.com/cakes/lavender-blue-ombre-cake-5966',
      label: 'birthday-cakes-for-boys'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/1468798_%201.5%201399%20%203%202499.jpg',
      alt: 'Princess Ballerina Cake online free delivery chennai',
      name: 'Princess Ballerina Cake',
      price: '1560',
      link: 'https://fbcakes.com/cakes/princess-ballerina-cake-5968',
      label: 'birthday-cakes-for-boys'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2022.jpg',
      alt: 'Blueberry Cake online free delivery chennai',
      name: 'Blueberry Cake',
      price: '485',
      link: 'https://fbcakes.com/cakes/blueberry-cake-12570',
      label: 'birthday-cakes-for-boys'
    },

    //birthday-cakes-for-women


    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(68)[superuser]%20%20_fond19%204%204799%205%205899%206%206999.jpg',
      alt: 'Lavender Blue Ombre Cake online free delivery chennai',
      name: 'Lavender Blue Ombre Cake',
      price: '3893',
      link: 'https://fbcakes.com/cakes/lavender-blue-ombre-cake-5966',
      label: 'birthday-cakes-for-women'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/1468798_%201.5%201399%20%203%202499.jpg',
      alt: 'Princess Ballerina Cake online free delivery chennai',
      name: 'Princess Ballerina Cake',
      price: '1560',
      link: 'https://fbcakes.com/cakes/princess-ballerina-cake-5968',
      label: 'birthday-cakes-for-women'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2022.jpg',
      alt: 'Blueberry Cake online free delivery chennai',
      name: 'Blueberry Cake',
      price: '485',
      link: 'https://fbcakes.com/cakes/blueberry-cake-12570',
      label: 'birthday-cakes-for-women'
    },

    //Best-Cream-Cakes
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A0261%20low%20res.jpg',
      alt: 'Black Forest Cake online free delivery chennai',
      name: 'Black Forest Cake',
      price: '390',
      link: 'https://fbcakes.com/cakes/black-forest-cake-12561',
      label: 'best-cream-cakes'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2008.jpg',
      alt: 'Mango Cake online free delivery chennai',
      name: 'Mango Cake',
      price: '390',
      link: 'https://fbcakes.com/cakes/mango-cake-12562',
      label: 'best-cream-cakes'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2034.jpg',
      alt: 'Pineapple Cake online free delivery chennai',
      name: 'Pineapple Cake',
      price: '410',
      link: 'https://fbcakes.com/cakes/pineapple-cake-12563',
      label: 'best-cream-cakes'
    },

    //best cakes in chennai
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A0261%20low%20res.jpg',
      alt: 'Black Forest Cake online free delivery chennai',
      name: 'Black Forest Cake',
      price: '390',
      link: 'https://fbcakes.com/cakes/black-forest-cake-12561',
      label: 'best-cakes-in-chennai'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2008.jpg',
      alt: 'Mango Cake online free delivery chennai',
      name: 'Mango Cake',
      price: '390',
      link: 'https://fbcakes.com/cakes/mango-cake-12562',
      label: 'best-cakes-in-chennai'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2034.jpg',
      alt: 'Pineapple Cake online free delivery chennai',
      name: 'Pineapple Cake',
      price: '410',
      link: 'https://fbcakes.com/cakes/pineapple-cake-12563',
      label: 'best-cakes-in-chennai'
    },


  ];



  event = [
    {
      name: 'Bento Cakes',
      link: 'https://fbcakes.com/'
    },

    {
      name: 'Bomb Cakes',
      link: 'https://fbcakes.com/'
    },

    {
      name: 'Surprise Cakes',
      link: 'https://fbcakes.com/'
    },

    {
      name: 'BTS Cakes',
      link: 'https://fbcakes.com/'
    },

    {
      name: 'Cocomelon Cakes',
      link: 'https://fbcakes.com/'
    },


    {
      name: 'Black and Red Barbie Doll Cake',
      link: 'https://fbcakes.com/cakes/black-and-red-barbie-doll-cake-5954'
    },

    {
      name: 'Toys Cakes',
      link: 'https://fbcakes.com/cakes/black-and-red-barbie-doll-cake-5954'
    },

    {
      name: 'First Birthday Grand Cake',
      link: 'https://fbcakes.com/cakes/miss-cupcake-cake-5956'
    },

    {
      name: 'Second Birthday Grand Cake',
      link: 'https://fbcakes.com/cakes/lavender-blue-ombre-cake-5966'
    },

    {
      name: 'Princess Cake',
      link: 'https://fbcakes.com/cakes/disney-princess-cake-5973'
    },

    {
      name: 'Football Cake',
      link: 'https://fbcakes.com/cakes/the-soccer-sensation-cake-6021'
    },

    {
      name: 'Valentine Day Cake',
      link: 'https://fbcakes.com/cakes/valentine-day-cake-cake-5834'
    },

  ]

  event2 = [
    {
      name: 'cakes and prices',
      link: 'https://fbcakes.com'
    },

    {
      name: 'best cakes in chennai',
      link: 'http://fbcakes.com/best-cakes-in-chennai'
    },

    {
      name: 'cakes near me',
      link: 'http://fbcakes.com'
    },
    {
      name: 'cakes for birthday',
      link: 'http://fbcakes.com'
    },

    {
      name: 'best cream cakes',
      link: 'http://fbcakes.com/best-cream-cakes'
    },
    {
      name: 'cakes for girls',
      link: 'http://fbcakes.com'
    },
    {
      name: 'cakes for boys',
      link: 'http://fbcakes.com'
    },
    {
      name: 'cakes online',
      link: 'http://fbcakes.com'
    },




    {
      name: 'cakes for kids',
      link: 'http://fbcakes.com'
    },
    {
      name: 'cake design',
      link: 'http://fbcakes.com'
    },
    {
      name: 'cakes for anniversary',
      link: 'http://fbcakes.com'
    },
    {
      name: 'cakes for children birthday',
      link: 'http://fbcakes.com'
    },
    {
      name: 'cakes for girls birthday',
      link: 'http://fbcakes.com'
    },



    {
      name: 'cakes at low price',
      link: 'http://fbcakes.com'
    },
    {
      name: 'cakes and prices',
      link: 'http://fbcakes.com'
    },
    {
      name: 'cakes available near me',
      link: 'http://fbcakes.com'
    },
    {
      name: 'cakes below 300',
      link: 'http://fbcakes.com'
    },
    {
      name: 'cakes below 500',
      link: 'http://fbcakes.com'
    },

  

    {
      name: 'birthday cakes for girls',
      link: 'http://fbcakes.com'
    },
    {
      name: 'birthday cakes for boys',
      link: 'https://fbcakes.com'
    },
    {
      name: 'baby shower cakes for girls',
      link: 'https://fbcakes.com'
    },
    {
      name: 'birthday cakes for men',
      link: 'https://fbcakes.com'
    },
    {
      name: 'baby shower cakes for boys',
      link: 'https://fbcakes.com'
    },
    {
      name: 'birthday cakes for women',
      link: 'https://fbcakes.com'
    },
  ]

  selectproduct(p) {
    window.open(p.link, '_blank');
  }

  window() {
    window.open('https://fbcakes.com/');
  }

}
