<header class="site-header header mo-left header-basic">
    <!-- main header -->
    <div class="sticky-header main-bar-wraper navbar-expand-lg">
        <div class="main-bar clearfix ">
            <div class="container clearfix">
                <!-- website logo -->
                <div class="logo-header mostion logo-dark">
                    <a href=""><img src="../../assets/images/logo-dark.png" alt=""></a>
                </div>
                <!-- nav toggle button -->
                <button class="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <!-- extra nav -->
                <div class="extra-nav">
                    <div class="extra-cell">
                        <ul class="extra-info">
                            <li>
                                <a href="contacta" class="btn">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Quik search -->
                <div class="dlab-quik-search ">
                    <form action="#">
                        <input name="search" value="" type="text" class="form-control" placeholder="Type to search">
                        <span id="quik-search-remove"><i class="ti-close"></i></span>
                    </form>
                </div>
                <!-- main nav -->
                <div class="header-nav navbar-collapse collapse justify-content-end primary" id="navbarNavDropdown">
                    <div class="logo-header">
                        <a href=""><img src="../../assets/images/logo-dark.png" alt=""></a>
                    </div>
                    <ul class="nav navbar-nav">	
                        <li class="active"><a href="javascript:void(0);">Home<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu">
                                <li><a href="">Home - Cake Bakery</a></li>
                                <li><a href="indexb">Home - Pizza & Burger</a></li>
                                <li><a href="indexc">Home - South Indian</a></li>
                                <li><a href="indexd">Home - Pizza 2</a></li>
                            </ul>
                        </li>
                        <li><a href="javascript:void(0);">Pages<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu">
                                <li><a href="abouta">About Us</a></li>
                                <li><a href="ourservices">Our Services</a></li>
                                <li><a href="faq">FAQs</a></li>
                                <li><a href="booking">Booking</a></li>
                                <li><a href="error">404 Error</a></li>
                                <li><a href="calendar">Calendar</a></li>
                                <li><a href="comingsoon">Coming Soon</a></li>
                                <li><a href="team">Team</a></li>
                                <li>
                                    <a href="javascript:void(0);">Contact Us <i class="fa fa-angle-right"></i></a>
                                    <ul class="sub-menu">
                                        <li><a href="contacta">Contact Us 1</a></li>
                                        <li><a href="contactb">Contact Us 2</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;">Features<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu tab-content">
                                <li>
                                    <a href="javascript:;">Header Style <i class="fa fa-angle-right"></i></a>
                                    <ul class="sub-menu">
                                        <li><a href="javascript:;" (click)="setheadertype('a')">Header 1</a></li>
                                        <li><a href="javascript:;" (click)="setheadertype('b')">Header 2</a></li>
                                        <li><a href="javascript:;" (click)="setheadertype('c')">Header 3</a></li>
                                        <li><a href="javascript:;" (click)="setheadertype('d')">Header 4</a></li>
                                    </ul>
                                </li>
                                <li> 
                                    <a href="javascript:;">Footer Style<i class="fa fa-angle-right"></i></a>
                                    <ul class="sub-menu">
                                        <li><a href="footerstylea">Footer 1 </a></li>
                                        <li><a href="footerstyleb">Footer 2</a></li>
                                        <li><a href="footerstylec">Footer 3</a></li>
                                        <li><a href="footerstyled">Footer 4</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li><a href="javascript:void(0);">Our Menu<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu">
                                <li><a href="ourmenu">Menu Style 1</a></li>
                                <li><a href="ourmenub">Menu Style 2</a></li>
                                <li><a href="ourmenuc">Menu Style 3</a></li>
                                <li><a href="ourmenud">Menu Style 4</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:void(0);">Shop <i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu">
                                <li><a href="shop">Shop</a></li>
                                <li><a href="shopsidebar">Shop Sidebar</a></li>
                                <li><a href="shopproductdetails">Product Details</a></li>
                                <li><a href="shopcart">Cart</a></li>
                                <li><a href="shopwishlist">Wishlist</a></li>
                                <li><a href="shopcheckout">Checkout</a></li>
                                <li><a href="shoplogin">Login</a></li>
                                <li><a href="shopregister">Register</a></li>
                            </ul>
                        </li>
                        <li class="has-mega-menu"><a href="javascript:void(0);">Blog<i class="fa fa-chevron-down"></i></a>
                            <ul class="mega-menu">
                                <li> <a href="javascript:;">Blog Grid</a>
                                    <ul>
                                        <li><a href="bloggridb">Grid 2</a></li>
                                        <li><a href="bloggridbsidebar">Grid 2 sidebar</a></li>
                                        <li><a href="bloggridbsidebarleft">Grid 2 sidebar left</a></li>
                                        <li><a href="bloggridc">Grid 3</a></li>
                                    </ul>
                                </li>
                                <li> <a href="javascript:;">Blog Half Image</a>
                                    <ul>
                                        <li><a href="bloghalfimg">Half image</a></li>
                                        <li><a href="bloghalfimgsidebar">Half image sidebar</a></li>
                                        <li><a href="bloghalfimgleftsidebar">Half image sidebar left</a></li>
                                    </ul>
                                </li>
                                <li> <a href="javascript:;">Blog Large Image</a>
                                    <ul>
                                        <li><a href="bloglargeimg">Large image</a></li>
                                        <li><a href="bloglargeimgsidebar">Large image sidebar</a></li>
                                        <li><a href="bloglargeimgleftsidebar">Large image sidebar left</a></li>
                                    </ul>
                                </li>
                                <li> <a href="javascript:;">Blog Details</a>
                                    <ul>
                                        <li><a href="blogsingle">Single</a></li>
                                        <li><a href="blogsinglesidebar">Single sidebar</a></li>
                                        <li><a href="blogsingleleftsidebar">Single sidebar right</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>	
                </div>
            </div>
        </div>
    </div>
    <!-- main header END -->
</header>

