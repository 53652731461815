import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'product'
})
export class ProductPipe implements PipeTransform {

  transform(products: any, saleProductId: number, productId: number): unknown {
    if (!products) return []
    return products.filter(x => (x.SaleProductId == saleProductId || saleProductId == 0) && (x.Id == productId || productId == 0));
  }
}
