<div class="page-wraper">
   
    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                   
                    <h1 class="text-white">Delivery Policy</h1>
                </div>
            </div>
        </div>

        <div class="section-full content-inner">
            <div class="container">
                <div class="shop-form">
                   

                    <h2><strong>Delivery Policy</strong></h2>

                    

                    

                    <p>Fbcakes offers the finest quality cakes and deliver on time as preferred by our Happy customers. The reliability of delivery and the quality of our service remains top at all times. Sometimes, if and when any consumer asks for cancellations or adjustments to any order, we help them.
                    </p>
                    <p>We have a wide range of cakes in different sizes, flavours and designs. According to the design, the delivery time may vary. For the convenience of the customers, we want our customers to order at least 2 to 8 hours before any sort of cake delivery. 
                    </p>
                    <p>All cake deliveries can only be delivered during the shop working hours
                    </p>
                    <p>We are truly not responsible for any sort of delay or failure in delivery due to invalid address, customer unavailability, peak time delays, unexpected weather conditions, natural disasters, etc.
                    </p>
                    <p>we have facilities for you to collect your cakes at your doorstep or collect in-store. If the distance is large, additional charges may apply. For payment, we have different methods which are supported by a Third-party payment gateway and you can also use payment options including Credit cards, Debit cards, Net Banking, COD (Cash on Delivery), etc. Our most trusted payment gateway partners make use of secure encryption technology for the protection of your card information at all times.
                    </p>
                    <p>Please contact us at fbcakes.info@gmail.com or +91 90034 32888</p>
                    <p>if you have any queries.</p>
                    
                   

                </div>

              
            </div>
           



        </div>