import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-low-price-cakes',
  templateUrl: './low-price-cakes.component.html',
  styleUrls: ['./low-price-cakes.component.css']
})
export class LowPriceCakesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  myproduct = [
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A0261%20low%20res.jpg',
      alt: 'Black Forest Cake online free delivery chennai',
      name:'Black Forest Cake',
      qty:'1/2 Kg',
      price: '390',
      link:'http://fbcakes.com/cakes/black-forest-cake-12561'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2008.jpg',
      alt: 'Mango Cream Cake online free delivery chennai',
      name:'Mango Cream Cake',
      qty:'1/2 Kg',
      price: '390',
      link:'http://fbcakes.com/cakes/mango-cake-12562'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/014A2034.jpg',
      alt: 'Pineapple Cake online free delivery chennai',
      name:'Pineapple Cake',
      qty:'1/2 Kg',
      price: '410',
      link:'http://fbcakes.com/cakes/pineapple-cake-12563'
    }

    
  ];

  event=[
    {
      name:'Bento Cakes',
      link:'http://fbcakes.com/'
    },

    {
      name:'Bomb Cakes',
      link:'http://fbcakes.com/'
    },

    {
      name:'Surprise Cakes',
      link:'http://fbcakes.com/'
    },

    {
      name:'BTS Cakes',
      link:'http://fbcakes.com/'
    },

    {
      name:'Cocomelon Cakes',
      link:'http://fbcakes.com/'
    },


    {
      name:'Black and Red Barbie Doll Cake',
      link:'http://fbcakes.com/cakes/black-and-red-barbie-doll-cake-5954'
    },

    {
      name:'Toys Cakes',
      link:'http://fbcakes.com/cakes/black-and-red-barbie-doll-cake-5954'
    },

    {
      name:'First Birthday Grand Cake',
      link:'http://fbcakes.com/cakes/miss-cupcake-cake-5956'
    },

    {
      name:'Second Birthday Grand Cake',
      link:'http://fbcakes.com/cakes/lavender-blue-ombre-cake-5966'
    },

    {
      name:'Princess Cake',
      link:'http://fbcakes.com/cakes/disney-princess-cake-5973'
    },

    {
      name:'Football Cake',
      link:'http://fbcakes.com/cakes/the-soccer-sensation-cake-6021'
    },

    {
      name:'Valentine Day Cake',
      link:'http://fbcakes.com/cakes/valentine-day-cake-cake-5834'
    },

  ]


  selectproduct(p)
  {
    window.open(p.link, '_blank');
  }

  window()
  {
    window.open('http://fbcakes.com/');
  }

}
