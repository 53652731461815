<div class="page-wraper">
    <div hidden id="loading-area">
        <div class="ball-pulse-rise">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt" id="main-img">
            <!-- style="background-image:url(../../assets/images/cartbanner.jpg);" -->
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <!-- <h1 class="text-white">My Account</h1> -->


                    <!-- Breadcrumb row -->
                    <!-- <div class="breadcrumb-row">
                        <ul class="list-inline">
                            <li><a href="">Home</a></li>
                            <li>My Account</li>
                        </ul>
                    </div> -->
                    <!-- Breadcrumb row END -->
                </div>
            </div>
        </div>



        <!-- inner page banner END -->
        <!-- contact area -->
        <div class="section-full bg-white content-inner">
            <div class="container">
                <div class="row">
                    <!-- Side bar start -->
                    <div class="col-lg-4" [class.sticky-top]="!isMobile">
                        <div class="side-bar">

                            <div class="widget recent-posts-entry">
                                <h5 class="widget-title style-1">My Account  <span style="font-style: italic;  font-size: 15px;">({{this.myAcco}})</span></h5>
                                <div class="widget-post-bx">


                                    <div class="widget-post clearfix">

                                        <i class="fa fa-home" aria-hidden="true"></i>
                                        <!-- </div> -->
                                        <div class="dlab-post-info">
                                            <div class="dlab-post-header">
                                                <h6 class="post-title"><a
                                                    href="">Home</a></h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="widget-post clearfix">

                                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                        <!-- </div> -->
                                        <div class="dlab-post-info">
                                            <div class="dlab-post-header">
                                                <h6 class="post-title" (click)="toggle=1"><a
                                                        href="javascript:void(0)">My Orders</a></h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="widget-post clearfix">

                                        <i class="fa fa-address-card-o" aria-hidden="true"></i>
                                        <div class="dlab-post-info">

                                            <div class="dlab-post-header">
                                                <h6 class="post-title" (click)="toggle=2;hide();"><a
                                                        href="javascript:void(0)">Address</a></h6>
                                            </div>
                                        </div>
                                    </div>


                                    <!-- <div class="widget-post clearfix">

                                        <i class="fa-solid fa-coins" aria-hidden="true"></i>
                                        <div class="dlab-post-info">

                                            <div class="dlab-post-header">
                                                <h6 class="post-title" (click)="toggle=2;hide();"><a
                                                        href="javascript:void(0)">FB Points</a></h6>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="widget-post clearfix">

                                        <i class="fa fa-power-off" aria-hidden="true"></i>
                                        <div class="dlab-post-info">

                                            <div class="dlab-post-header">
                                                <h6 class="post-title" (click)="logout()"><a
                                                        href="javascript:void(0)">LogOut</a></h6>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    <!-- </aside> -->
                    </div>
                    <!-- Side bar END -->
                    <!-- Left part start -->




                    <div class="col-lg-8 m-b30">
                        <!-- ADS -->
                        <!-- <div style="text-align: center;" >
                            <a  (click)="ADSclick()" href="https://bit.ly/36Ze8WZ" target="blank">
                                <img src="https://i.ibb.co/zR0D8vX/JJJ.jpg" 
                                alt="sarina boutique" style="width: 350px; height: 200px;">
                            </a>
                            
                        </div> -->

                        <!-- blog start -->
                        <div *ngIf="toggle == 2" class="blog-post blog-single sidebar">
                            <div>
                                <a href="javascript:void(0);" class="btn btnhover pull-right btn-danger"
                                    data-toggle="modal" data-target="#AddressModel" (click)="addaddress()">Add Address</a>
                            </div>

                            <!-- <div>
                                <a  class="btn btnhover pull-right btn-danger" (click)="this.mydistance3()"
                                    >loc</a>
                            </div> -->

                            

                            <div class="clear" id="comment-list">

                                <h3>{{selected}} </h3>



                                <div class="comments-area" id="comments">
                                    <h2 class="comments-title">Saved Address</h2>


                                    <div class="clearfix">
                                        <!-- comment list END -->
                                        <ol class="comment-list">

                                            <li class="comment" *ngFor="let address of addresses" >
                                                <!-- <div *ngIf="address.hidden==false"> -->
                                                <div class="comment-body" *ngIf="address.Contact.split('_')[2]==1"
                                                    [ngClass]="{'border-danger':address.iscurrentaddress }">
                                                    <div class="comment-author vcard">
                                                        <cite class="fn">{{address.Name}} </cite>
                                                    </div>
                                                    <p>{{address.Address}} </p>

                                                    <div class="reply" *ngIf="!address.iscurrentaddress"> <a
                                                            href="javascript:void(0);" class="comment-reply-link"
                                                            (click)=applyaddress(address)>
                                                            {{this.myccount=='0'?"Set Default Address":"Select Delivery
                                                            Address"}}
                                                        </a>

                                                        <a style="margin-left: 5px; background-color:#FFC300;"
                                                            href="javascript:void(0);" class="comment-reply-link"
                                                            (click)=removeaddress2(address)>
                                                            Remove
                                                        </a>

                                                    </div>
                                                </div>
                                            <!-- </div> -->
                                            </li>
                                        </ol>
                                    </div>

                                </div>

                            </div>



                        </div>



                        <div *ngIf="toggle == 1" class="clear" id="comment-list">
                            <div class="comments-area" id="comments">
                                <h2 class="comments-title">Orders</h2>


                                <div class="btn-group product-item-size" data-toggle="buttons"
                                    style="flex-wrap: wrap; margin-bottom: 10px;">
                                    <label class="btn active" (click)="porder()">
                                        <input name="options" id="option7" type="radio">
                                        Pending Orders
                                    </label>
                                    <label class="btn" (click)="corder()">
                                        <input name="options" id="option7" type="radio">
                                        Completed Orders
                                    </label>
                                    <label class="btn" (click)="ccorder()">
                                        <input name="options" id="option7" type="radio">
                                        Cancelled Order
                                    </label>
                                </div>









                                <!-- New Pending Order 2 -->

                                <div class="clearfix" *ngIf="ordtab==0" style="margin-left: 10px; ">


                                    <div *ngIf="(!orders||orders.length>0)">
                                        <table class="table table-striped table-bordered">

                                            <thead>
                                                <tr>
                                                    <th class="center">Ordered Date/Time</th>
                                                    <th class="center">OrderId </th>
                                                    <th class="center">Amount</th>
                                                    <!-- <th class="center">Payment</th> -->
                                                    <!-- <th  class="center">Payment Type</th> -->

                                                    <th class="center">Status</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr *ngFor="let order of orders">

                                                    <!-- <td>{{order.BillDateTime
                                                        | date:
                                                        'MMM d,y, h:mm
                                                        a'}}</td>
                                                    <td>#{{order.Id}}</td>
                                                    <td>{{order.BillAmount.toFixed(2)}}</td> -->

                                                    <td>{{order.oddt | 
                                                        date: 'h:mm a, d,MMM,y'}}
                                                        <!-- 'MMM d,y, h:mm
                                                        a'}} -->
                                                    </td>

                                                    <td>#{{order.OdrsId}}</td>
                                                    <td>₹{{order.ba.toFixed(2)}}</td>
                                                  

                                                    <!-- <td>
                                                        <div>
                                                            {{order.Transactions[0].TransTypeId==1 ? "Cash on Delivery":
                                                            order.Transactions[0].TransTypeId==4? "Online Payment " :
                                                            "Payment Error!!!"}}
                                                        </div>

                                                        <div *ngIf="order.Transactions[0].TransTypeId==4">
                            <b *ngIf="order.isPaid==0" class="red"> Transaction Failed
                                <a href="javascript:void(0);" class="comment-reply-link"
                                    (click)="tryagainpayment(order.Id,order.BillAmount)">(try
                                    again)</a>
                            </b>
                            <b *ngIf="order.isPaid==1" class="green">Transaction
                                Successful</b>
                        </div>
                                                    </td> -->




                                                    <td>

                                                        <label class="btn btn-danger"
                                                            (click)="customerOrders(order)">Track </label>

                                                        <!-- <label class="btn btn-warning" *ngIf="order.OrderStatusId==0" style="margin-left: 5px;"
                                                            (click)="customerSideCancelOrders(order)">Cancel</label> -->

                                                        <label class="btn btn-warning" *ngIf="order.OrderStatusId==0"
                                                            style="margin-left: 5px;"
                                                            (click)="cancelOrder(order)">Cancel</label>

                                                        <!-- <label class="btn btn-warning" *ngIf="order.OrderStatusId==5" style="margin-left: 5px;"
                                                            (click)="getRevieworder(order)">Review</label> -->

                                                    </td>


                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    <div *ngIf="!orders || orders?.length==0">
                                        <!-- No Record Found! -->
                                      
                                        <div>
                                           
                                            <br><br>
                                            <div class="d-flex justify-content-center">
                                                
                                                <div class="spinner-border" role="status">
                                                  <span class="sr-only">Loading...</span>
                                                </div>
                                              </div>
                                        </div>

                                        <div *ngIf="orders==null">
                                            <br><br>No Record Found!
                                        </div>

                                        
                                    </div>
                                </div>

                                <!-- New Pending Order -->

                                <!-- <div class="clearfix" *ngIf="ordtab==0" style="margin-left: 10px; ">


                                    <div *ngIf="orders[0].OrderStatusId!=6 && orders[0].OrderStatusId!=-1">
                                        <table class="table table-striped table-bordered">

                                            <thead>
                                                <tr>
                                                   
                                                    <th class="center">OrderId</th>
                                                    <th class="center">Amount</th>
                                                    <th class="center">Payment</th>
                                                  

                                                    <th class="center">Status</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr *ngFor="let order of orders">

                                                
                                                    <td>#{{order.Id}}</td>
                                                    <td>{{order.BillAmount.toFixed(2)}}</td>
                                                   
                                                    <td>
                                                        <div>
                                                            {{order.Transactions[0].TransTypeId==1 ? "Cash on Delivery":
                                                            order.Transactions[0].TransTypeId==4? "Online Payment " :
                                                            "Payment Error!!!"}}
                                                        </div>
                                                        <div *ngIf="order.Transactions[0].TransTypeId==4">
                                                            <b *ngIf="order.isPaid==0" class="red"> Transaction Failed
                                                                <a href="javascript:void(0);" class="comment-reply-link"
                                                                    (click)="tryagainpayment(order.Id,order.BillAmount)">(try
                                                                    again)</a>
                                                            </b>
                                                            <b *ngIf="order.isPaid==1" class="green">Transaction
                                                                Successful</b>
                                                        </div>
                                                    </td>




                                                    <td> <label class="btn btn-danger"
                                                            (click)="customerOrders(order)">Track </label>

                                                    </td>


                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div> -->





                                <!-- New Compleded Order -->

                                <!-- <div>
                                    <div class="clearfix" *ngIf="ordtab==1" style="margin-left: 10px; ">
                                        <div *ngIf="orders[0].OrderStatusId==6">
                                            <table class="table table-striped table-bordered">

                                                <thead>
                                                    <tr>
                                                        <th class="center">Ordered Time</th>
                                                        <th class="center">OrderId</th>
                                                        <th class="center">Amount</th>
                                                        <th class="center">Status</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr *ngFor="let order of orders">

                                                        <td>{{order.BillDateTime
                                                            | date:
                                                            'MMM d,y, h:mm
                                                            a'}}</td>
                                                        <td>#{{order.Id}}</td>
                                                        <td>{{order.BillAmount.toFixed(2)}}</td>
                                                        <td> <label class="btn btn-danger"
                                                                (click)="customerOrders(order)">View </label>

                                                        </td>


                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                        </div>
                                        </div> -->

                                <div class="clearfix" *ngIf="ordtab==1" style="margin-left: 10px; ">
                                    <!-- <p>{{corders.length}}</p> -->

                                    
                                    <div *ngIf="(!corders||corders.length!=0)">
                                        <table class="table table-striped table-bordered">

                                            <thead>
                                                <tr>
                                                    <!-- <th class="center">Ordered Time</th> -->
                                                    <th class="center">Date/Time</th>
                                                    <th class="center">OrderId</th>
                                                    <th class="center">Amount</th>
                                                    <!-- <th class="center">Payment</th> -->
                                                    <!-- <th  class="center">Payment Type</th> -->

                                                    <th class="center">Status</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr *ngFor="let order of corders">

                                                    <td>{{order.oddt
                                                        | date: 'h:mm a, d,MMM,y'}}</td>

                                                        

                                                        
                                                    <td>#{{order.OdrsId}}</td>
                                                    <td>{{order.ba.toFixed(2)}}</td>

                                                    <!-- <td>
                                                        <div>
                                                            {{order.Transactions[0].TransTypeId==1 ? "Cash on Delivery":
                                                            order.Transactions[0].TransTypeId==4? "Online Payment " :
                                                            "Payment Error!!!"}}
                                                        </div>
                                                        <div *ngIf="order.Transactions[0].TransTypeId==4">
                                                            <b *ngIf="order.isPaid==0" class="red"> Transaction Failed
                                                                <a href="javascript:void(0);" class="comment-reply-link"
                                                                    (click)="tryagainpayment(order.Id,order.BillAmount)">(try
                                                                    again)</a>
                                                            </b>
                                                            <b *ngIf="order.isPaid==1" class="green">Transaction
                                                                Successful</b>
                                                        </div>
                                                    </td> -->




                                                    <td> <label class="btn btn-danger"
                                                            (click)="customerOrders(order)">View Orders </label>

                                                        <!-- <label class="btn btn-warning" style="margin-left: 5px;"
                                                            (click)="getRevieworder(order)">Review</label> -->

                                                        <label class="btn btn-warning" style="margin-left: 5px;"
                                                            (click)="getRevieworder2(order)">Review</label>
                                                    </td>


                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    <div *ngIf="(corders.length==0)">
                                        <!-- No Record Found! -->
                                        <div>
                                           
                                            <br><br>
                                            <div class="d-flex justify-content-center">
                                                
                                                <div class="spinner-border" role="status">
                                                  <span class="sr-only">Loading...</span>
                                                </div>
                                              </div>
                                        </div>

                                        <div *ngIf="orders==null">
                                            <br><br>No Record Found!
                                        </div>
                                    </div>




                                </div>

                                <!-- New Cancelled Order -->

                                <div class="clearfix" *ngIf="ordtab==3" style="margin-left: 10px; ">
                                    <!-- <p>{{ccorders.length}}</p> -->

                                    <div *ngIf="ccorders.length!=0">
                                        <table class="table table-striped table-bordered">

                                            <thead>
                                                <tr>
                                                    <!-- <th class="center">Ordered Time</th> -->
                                                    <th class="center">OrderId</th>
                                                    <th class="center">Amount</th>
                                                    <th class="center">Payment</th>
                                                    <!-- <th  class="center">Payment Type</th> -->

                                                    <!-- <th class="center">Status</th> -->
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr *ngFor="let order of ccorders">

                                                    <!-- <td>{{order.BillDateTime
                                                                            | date:
                                                                            'MMM d,y, h:mm
                                                                            a'}}</td> -->
                                                    <td>#{{order.OdrsId}}</td>
                                                    <td>{{order.ba.toFixed(2)}}</td>
                                                    <!-- <td>{{order.BillAmount.toFixed(2)}}</td> -->
                                                    <td>
                                                        <div>
                                                            {{order.Transactions[0]?.PaymentTypeId==1 ? "Cash on
                                                            Delivery":
                                                            order.Transactions[0].PaymentTypeId==4? "Online Payment " :
                                                            "Payment Error!!!"}}
                                                        </div>
                                                        <!-- <div *ngIf="order.Transactions[0].TransTypeId==4">
                                                            <b *ngIf="order.isPaid==0" class="red"> Transaction Failed
                                                                <a href="javascript:void(0);" class="comment-reply-link"
                                                                    (click)="tryagainpayment(order.Id,order.BillAmount)">(try
                                                                    again)</a>
                                                            </b>
                                                            <b *ngIf="order.isPaid==1" class="green">Transaction
                                                                Successful</b>
                                                        </div> -->
                                                    </td>




                                                    <!-- <td> <label class="btn btn-danger"
                                                                                (click)="customerOrders(order)">Track </label>
                                                    
                                                                        </td> -->


                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    <div *ngIf="(ccorders.length==0)">
                                       <!-- No Record Found! -->
                                        <div>
                                           
                                            <br><br>
                                            <div class="d-flex justify-content-center">
                                                
                                                <div class="spinner-border" role="status">
                                                  <span class="sr-only">Loading...</span>
                                                </div>
                                              </div>
                                        </div>

                                        <div *ngIf="orders==null">
                                            <br><br>No Record Found!
                                        </div>
                                    </div>




                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                <button class="scroltop fa fa-chevron-up"></button>
            </div>

            <!-- ALERT2 -->

            <div class="modal fade subscribe-box" id="alertModal" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div *ngIf="_ccorder" class="modal-dialog" role="document">

                    <div class="dzSubscribe modal-content w-100 mb-10" id="pdfTable2" #pdfTable2>

                        <a (click)="downloadAsPDF2()" href="javascript:void(0);" class="comment-reply-link">
                            <span>OrderId: #{{_ccorder.Id}}</span>
                        </a>

                        <br />

                        <div class="widget-toolbar no-border invoice-info">

                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th class="center">
                                            Product Name
                                        </th>
                                        <th>Quantity</th>
                                        <!-- <th class="hidden-xs">
                        Kg.</th> -->
                                        <th class="hidden-480">
                                            Rate
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of _ccorder.OrderItems">
                                        <td>
                                            {{item.Name}}


                                            <div style="margin-left: 10px;">
                                                <!-- {{option.Name}} -->
                                                <span *ngFor="let option of item.Options"
                                                    style="font-size: 12px;">{{option.Name}}</span>
                                            </div>


                                            <!-- <div *ngFor="let option of item.Options" >
                                    <span>one</span>
                                    <span>two</span>
                                    <span>three</span>
                                    <span style="font-size: 12px;">{{option.Name}}</span>
                                </div> -->



                                        </td>
                                        <td>
                                            {{item.Quantity}}Kg
                                        </td>
                                        <td>
                                            ₹{{item.TotalAmount.toFixed(2)}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <span><b>Delivery Date:</b> {{_ccorder.OrderedDateTime | date:
                            'MMM d, y, h:mm a'}}</span>
                        <span><b>Payment Type: </b>{{_ccorder.Transactions[0].TransTypeId==1 ? "Cash on Delivery":
                            order.Transactions[0].TransTypeId==4? "Online Payment" :
                            "Payment Error!!!"
                            }}</span>

                        <!-- <li>
               
                Payment Type:
                <b class="red">{{order.Transactions[0].TransTypeId==1 ? "Cash on Delivery":
                    order.Transactions[0].TransTypeId==4? "Online Payment" :
                    "Payment Error!!!"
                    }}</b>
            </li> -->

                        <div style="align-self: flex-end; text-align: end;">
                            <Span><b>Tax Amount: </b>₹{{((_ccorder.PaidAmount*5)/100).toFixed(2)}}</Span>
                            <br>
                            <Span><b>Paid Amount: </b>₹{{_ccorder.PaidAmount.toFixed(2)}}</Span>
                        </div>

                    </div>

                </div>
            </div>

            <div class="modal fade subscribe-box" id="AddressModel_dup" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">

                <div class="modal-dialog" role="document">
                    <div class="dzSubscribe modal-content w-100 mb-10" novalidate>
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Address</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <br>

                        <div class="modal-body">
                            <div class="m-b30">

                                <div class="form-group ">
                                    <input type="text" [(ngModel)]="formaddress.name" class="form-control"
                                        placeholder="Name">
                                </div>
                                <div class="form-group">
                                    <input type="text" [(ngModel)]="formaddress.address" class="form-control"
                                        style="height: 100px;" placeholder="Address with Landmark">
                                </div>

                                <!-- <div class="row">
                        <div class="form-group col-md-6">
                            <input type="text" [(ngModel)]="formaddress.city" class="form-control"
                                        placeholder="City">
                            <select name="city" class="rounded-inputs20 select-select"
                                (change)="onChange($event.target.value)"
                                style="width: 100%; height: 100%; padding: 10px;">
                                <option *ngFor="let country of countries" [value]="country.id">{{country.name}}</option>
                            </select>
                        </div>

                        <div class="form-group col-md-6">
                            <input type="text" [(ngModel)]="formaddress.state" class="form-control"
                                        placeholder="State">
                            <select id="sel1" name="Country" class="rounded-inputs20 select-select"
                                style="width: 100%; height: 100%;">
                                <option *ngFor="let city of cities" [value]="city">{{city}}</option>
                            </select>
                        </div>

                    </div> -->


                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <!-- <input type="text" [(ngModel)]="formaddress.phone" class="form-control"
                                placeholder="Landmark"> -->

                                        <!-- <select 
                            [(ngModel)]="selected" 
                            name="valueCheck" 
                            (change)="valueChange($event)">
                                <option 
                                *ngFor="let obj of stud" 
                                [ngValue]="obj.name" 
                                [selected]="obj.name == selected">
                                    {{obj.name}} </option>
                            </select>

                            <h3>{{selected}}</h3> -->

                                        <select [(ngModel)]="selected" name="valueCheck" (change)="valueChange($event)">
                                            <option [ngValue]="undefined" disabled>Select Any </option>
                                            <option *ngFor="let obj of stud" [ngValue]="obj.rnNo"
                                                [selected]="obj.rnNo == selected">
                                                {{obj.name}} </option>

                                        </select>


                                        <h3>{{selected}} </h3>


                                    </div>
                                    <div class="form-group col-md-6">
                                        <input type="text" [(ngModel)]="formaddress.pincode" class="form-control"
                                            placeholder="Postcode / Zip">
                                    </div>
                                </div>

                                <h3>{{selected}}</h3>

                                <!-- <div>
                        
                        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                            <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                                (dragEnd)="markerDragEnd($event)">
                            </agm-marker>
                        </agm-map>
                    </div> -->
                            </div>



                        </div>


                        <div class="modal-footer">
                            <button type="submit" class="btn btnhover shadow" data-dismiss="modal"
                                (click)="saveaddress()">Save
                                Address</button>
                        </div>

                        <!-- <p>Lat: {{this.latitude}}</p>
            <p>Lat: {{this.longitude}}</p> -->

                        <div *ngIf='this.addressmessage!=false' style="margin-top: 20px;">
                            <span class="text-danger">Address fields should not be
                                Empty!</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade subscribe-box" id="Mapmodel" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="dzSubscribe modal-content w-100 mb-10" novalidate>
                        <!-- <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Address</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div> -->

                        <div class="modal-body">
                            <div class="m-b30">

                                <!-- <span>hello</span> -->
                            </div>

                        </div>

                    </div>



                </div>
            </div>


            <!-- Alert Change -->

            <div class="modal fade subscribe-box" id="AddressModelold" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">

                <div class="modal-dialog" role="document">
                    <div class="dzSubscribe modal-content w-100 mb-10">

                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Address</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <br>

                        <div class="modal-body">
                            <div class="m-b30">

                                <!-- NAME -->
                                <div class="form-group ">
                                    <input type="text" [(ngModel)]="formaddress.name" class="form-control"
                                        placeholder="Name">
                                </div>





                                <div class="col-md-12">
                                    <span>Choose your delivery cities!</span>
                                    <div class="btn-group product-item-size" data-toggle="buttons"
                                        style="flex-wrap: wrap;">
                                        <label *ngFor="let city of stud" class="btn" name="city"
                                            [class.active]="city.selected == true || city.name=='Chennai'"
                                            (click)="setcity(city.name)">
                                            <input type="radio" value="{{city.Id}}" [checked]="city.selected">
                                            {{city.name}}
                                        </label>
                                    </div>
                                </div>

                                <br>

                                <!-- ADRESS -->
                                <div class="form-group">
                                    <input type="textarea" rows="5" [(ngModel)]="formaddress.address"
                                        class="form-control" placeholder="Address">
                                </div>

                                <div class="row">
                                    <div class="form-group">
                                        <input type="text" [(ngModel)]="formaddress.pincode" class="form-control"
                                            placeholder="Landmark">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" [(ngModel)]="formaddress.pincode" class="form-control"
                                            placeholder="Landmark">
                                    </div>

                                </div>

                                <!-- <div class="form-group">
                        <input type="text" [(ngModel)]="formaddress.pincode" class="form-control"
                            placeholder="Landmark">
                    </div> -->




                                <!-- <div class="form-group col-md-6">
                          
                          
                          
                          
                            <input type="text" [(ngModel)]="formaddress.pincode" class="form-control"
                                placeholder="Postcode / Zip">
                        </div> -->
                            </div>
                        </div>

                        <!-- FOOTER -->
                        <div class="modal-footer">
                            <button type="submit" class="btn btnhover shadow btn-danger" data-dismiss="modal"
                                (click)="saveaddress()">Save
                                Address</button>
                        </div>
                        <div *ngIf='this.addressmessage!=false' style="margin-top: 20px;">
                            <span class="text-danger">Address fields should not be
                                Empty!</span>
                        </div>
                    </div>
                </div>
            </div>



            <!-- New Address test 1-->

            <div class="modal fade subscribe-box" id="AddressModel" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">

                <div class="modal-dialog" role="document">
                    <div class="dzSubscribe modal-content w-100 mb-10">

                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Address</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <br>























                        <!-- CURRENT -->

                        <div class="modal-body">
                            <div class="mb-10">

                                <div class="container">
                                    <!-- search -->
                                    <div class="form-group">


                                        <!-- search field and get current loc
                                        <div class="row">
                                            <div class="col">
                                                <input type="text" class="form-control"
                                                (input)="applyZIndex()"
                                                (keydown.enter)="$event.preventDefault()"
                                                placeholder="Search Address" autocorrect="off" autocapitalize="off"
                                                spellcheck="off" type="text" #search>
                                            </div>

                                            <div class="col">
                                                <button type="submit" class="btn btn btn-dark" (click)="setCurrentLocation()"
                                                style="margin-top: 4px;"
                                                >Get Current location</button>
                                            </div></div> -->



                                        <!-- <label>Search Address</label> -->
                                        <!-- <input type="text" class="form-control"
                                            (input)="applyZIndex()"
                                            (keydown.enter)="$event.preventDefault()"
                                            placeholder="Search Address" autocorrect="off" autocapitalize="off"
                                            spellcheck="off" type="text" #search> -->
                                    </div>


                                    

                                    <!-- map -->
                                    <!-- <div class="map">
                                        <agm-map #agmMap [latitude]="latitude" [longitude]="longitude"
                                            [zoom]="initialZoom" [usePanning]="true"
                                            (centerChange)="centerChanged($event)"
                                            (mapReady)="mapReady($event)" ></agm-map>
                                        <div class="map-center-overlay">
                                            <img src="https://res.cloudinary.com/swiggy/image/upload/v1493751154/Other_Pin_urgkbb.png"
                                                width="40px" height="auto">
                                        </div>
                                    </div>
                                    <br> -->

                                     <!-- NAME -->
                                <div class="form-group ">
                                    <input type="text" [(ngModel)]="formaddress.name" class="form-control"
                                        placeholder="Your Name*">
                                </div>

                                <!-- LandMArk -->
                                <div class="form-group">
                                    <input type="textarea" rows="3" [(ngModel)]="formaddress.phone" class="form-control"
                                        placeholder="Alternate Mob No.*">
                                </div>

                                     <!-- ADRESS -->
                                <div class="form-group">
                                    <textarea rows="5" [(ngModel)]="address_text" style="height: 15%;"
                                        class="form-control" placeholder="Address with Landmark*"></textarea>
                                </div>

                                 <!-- Pincode -->
                                <div class="form-group ">
                                    <input type="text" [(ngModel)]="formaddress.pincode" class="form-control"
                                        placeholder="Pincode*">
                                </div>

                                

                                    <!-- result -->

                                    <!-- <h3>Address: {{address_text}}</h3> -->
                                    <!-- <span>Latitude: {{latitude}}</span> |
                                    <span>Longitude: {{longitude}}</span> -->

                                    <!-- <br>

                                    <span>Nearest Store</span><br>

                                    <span>Id: {{final_store.StoreId}}</span><br>
                                    <span>Name: {{final_store.Name}}</span><br>
                                    <span>Km: {{final_km}}</span><br>
                                    <span>Time: {{final_duration}}</span>

                                    <span>lat: {{latitude}}</span><br>
                                    <span>lon: {{longitude}}</span> -->


                                </div>



                                <!-- CITY ROW-->
                                <!-- <div class="row">
                                    <div class="form-group col-md-6">
                                       
                                        <select class="form-control" [(ngModel)]="formaddress.state">
                                            chengalpattu
                                            <option value="Chennai, Tamilnadu">Chennai, Tamilnadu</option>
                                            <option value="Thiruvallur, Tamilnadu">Thiruvallur, Tamilnadu</option>
                                            <option value="Chengalpattu, Tamilnadu">Chengalpattu, Tamilnadu</option>
                                            <option value="Coimbatore, Tamilnadu">Coimbatore, Tamilnadu</option>
                                            <option value="Madurai, Tamilnadu">Madurai, Tamilnadu</option>
                                           
                                            <option value="Hyderabad, Telangana">Hyderabad, Telangana</option>
                                            <option value="Bangalore, karnataka">Bangalore, karnataka</option>

                                        </select>
                                        
                                    </div>

                                    <div class="form-group col-md-6">
                                        <div class="form-group">
                                            <input type="text" [(ngModel)]="formaddress.pincode" class="form-control"
                                                placeholder="Pincode">
                                        </div>
                                    </div> -->


                            </div>

                            <div class="modal-footer">
                                <button type="submit" class="btn btnhover shadow btn-danger" data-dismiss="modal"
                                    (click)="saveaddress()">Save Address</button>
                            </div>
                            <div *ngIf='this.addressmessage!=false' style="margin-top: 10px; align-self: center;">
                                <span class="text-danger">Address fields should not be
                                    Empty!</span>
                            </div>
                        </div>


<br><br>

                    </div>
                </div>
            </div>