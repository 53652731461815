import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-headerb',
  templateUrl: './headerb.component.html',
  styleUrls: ['./headerb.component.css']
})
export class HeaderbComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  setheadertype(type) {
    localStorage.setItem("header_type", type)
  }

}
