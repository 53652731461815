<footer class="site-footer " style="background-image: url(images/background/bg2.jpg); background-size: cover;">
    <!-- Footer Top Part -->
    <div class="footer-top bg-line-top">
        <div class="container">
            <div class="row">
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div class="widget widget_getintuch">
                        <h5 class="footer-title text-white">Contact Us</h5>
                        <ul>
                            <li>
                                <i class="fa fa-map-marker"></i> 
                                <p>Marmora City, Vietnam</p>
                            </li>
                            <li>
                                <i class="fa fa-phone"></i> 
                                <p>0800-123456</p>
                            </li>
                            <li>
                                <i class="fa fa-mobile"></i> 
                                <p>(+91) 0800-123456</p>
                            </li>
                            <li>
                                <i class="fa fa-envelope"></i> 
                                <p>info@example.com</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div class="widget recent-posts-entry">
                        <h4 class="footer-title">Recent Post</h4>
                        <div class="widget-post-bx">
                            <div class="widget-post clearfix">
                                <div class="dlab-post-media radius-sm"> 
                                    <img src="../../assets/images/blog/recent-blog/pic1.jpg" alt=""> 
                                </div>
                                <div class="dlab-post-info">
                                    <div class="dlab-post-header">
                                        <h6 class="post-title"><a href="blogsingle">The Background Of Bakery Now</a></h6>
                                    </div>
                                    <div class="dlab-post-meta">
                                        <ul>
                                            <li class="post-date"> <i class="la la-clock-o"></i><strong>March 25, 2020</strong> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="widget-post clearfix">
                                <div class="dlab-post-media radius-sm"> 
                                    <img src="../../assets/images/blog/recent-blog/pic2.jpg" alt=""> 
                                </div>
                                <div class="dlab-post-info">
                                    <div class="dlab-post-header">
                                        <h6 class="post-title"><a href="blogsingle">How Bakery Can Increase Your Profit</a></h6>
                                    </div>
                                    <div class="dlab-post-meta">
                                        <ul>
                                            <li class="post-date"> <i class="la la-clock-o"></i><strong>March 25, 2020</strong> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div class="widget widget_services border-0">
                       <h4 class="footer-title">Quick Links</h4>
                        <ul class="list-2">
                            <li><a href="">Home</a></li>
                            <li><a href="abouta">About</a></li>
                            <li><a href="ourmenu">Our Menu</a></li>
                            <li><a href="faq">FAQ</a></li>
                            <li><a href="bloggridb">Blog</a></li>
                            <li><a href="shop">Shop</a></li>
                            <li><a href="contacta">Contact</a></li>
                            <li><a href="team">Team</a></li>
                            <li><a href="calendar">Calendar</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div class="widget border-0">
                       <h4 class="footer-title">Opening Hours</h4>
                       <p class="m-b20">Our support available to help you 24 hours a day, seven days a week.</p>
                        <ul class="work-hour-list">
                            <li>
                                <span class="day"><span>Monday to Friday</span></span> 
                                <span class="timing">7AM - 5PM</span>
                            </li>
                            <li>
                                <span class="day"><span>Saturday</span></span>
                                <span class="timing">10AM - 5PM</span>
                            </li>
                            <li>
                                <span class="day"><span>Sunday</span></span>
                                <span class="timing">Closed</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Bottom Part -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 text-left"> <span>Copyright © 2020 DexignZone. all rights reserved.</span> </div>
                <div class="col-lg-6 col-md-6 text-right"> 
                    <div class="widget-link"> 
                        <ul>
                            <li><a href="abouta">About Us</a></li>
                            <li><a href="faq">Faq</a></li> 
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>