
<div class="page-wraper">
   
    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">FAQ</h1>
                </div>
            </div>
        </div>

        <div class="section-full content-inner">
            <div class="container">
                <div class="shop-form">
                   

               <div>
                   

                <div style="background-color: gainsboro; padding: 20px; margin-bottom: 10px; border-top-left-radius: 20px;">
                    <p style="font-size: 25px;  color: black;">Which all places do you serve?</p>
                    <p style=" color: black;">Currently, we serve across 5 more cities in India. such as Bangalore, Chennai, 
                        Madurai, Thiruvallur, Chengalpattu, Coimbatore and Hyderabad. 
                        However, we will soon be expanding our reach across the country and 
                        internationally. 
                        Stay connected with us, we are very social!</p>
                </div>


                <div style="background-color: gainsboro; padding: 20px; margin-bottom: 10px;">
                    <p style="font-size: 25px;  color: black;">What are your restaurant timings?</p>
                    <p style=" color: black;">All the restuarants are live from 9 am till 11 pm! </p>
                </div>

                <div style="background-color: gainsboro; padding: 20px; margin-bottom: 10px;">
                    <p style="font-size: 25px;  color: black;">Can I place an advance order?</p>
                    <p style=" color: black;">Yes! You can choose a later time slot for the delivery.</p>
                </div>

                <div style="background-color: gainsboro; padding: 20px; margin-bottom: 10px; border-bottom-right-radius: 20px;">
                    <p style="font-size: 25px;  color: black;">What is mode of payment?</p>
                    <p style=" color: black;">We accept credit cards, debit cards, net banking and cash on delivery also.</p>
                </div>

                <div style="background-color: gainsboro; padding: 20px; margin-bottom: 10px; border-bottom-right-radius: 20px;">
                    <p style="font-size: 25px;  color: black;">Are There Any Additional Charges?</p>
                    <p style=" color: black;">The site shows the actual price you'll pay. There are no additional charges for delivery.</p>
                </div>

               </div>


                </div>

              
            </div>
           



        </div>