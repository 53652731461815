import { Component } from '@angular/core';
import { DeviceDetectorService } from "ngx-device-detector";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Biz1Book';
  header_type = 'd';
  // footer_type = 'd'
  public lat;
  public lng;

  //name = "Angular " + VERSION.major;
  deviceInfo = null;
  isDesktopDevice: boolean;
  isTablet: boolean;
  isMobile: boolean;

  name: string = "";
  uuid: string = "";

  

  public ngOnInit(): void {
    
    // this.getLocation();
    // alert("Lat:"+this.lat);
   
  }

  // getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position: Position) => {
  //       if (position) {
  //         console.log("Latitude: " + position.coords.latitude +
  //           "Longitude: " + position.coords.longitude);
  //         this.lat = position.coords.latitude;
  //         this.lng = position.coords.longitude;
  //         // console.log(this.lat);
  //         // console.log(this.lat);
  //         this.http.post<any>('https://biz1posapi-np4.conveyor.cloud/api/GBtest/add', 
  //         { 
  //         Name:this.deviceInfo.deviceType,
  //         Email:this.lat,
  //         PhoneNo:this.lng


  //         }
  //         ).subscribe(data => {
  //           //  this.postId = data.id;
  //         })
  //       }
  //     },
  //       (error: PositionError) => console.log(error));
  //   } else {
  //     console.log("Geolocation is not supported by this browser.")
  //    // alert("Geolocation is not supported by this browser.");
  //   }
  // }

  

  // epicFunction() {
  //   this.deviceInfo = this.deviceService.getDeviceInfo();
  //   this.isMobile = this.deviceService.isMobile();
  //   this.isTablet = this.deviceService.isTablet();
  //   this.isDesktopDevice = this.deviceService.isDesktop();
  // }



  // constructor() {
  //   // if (!localStorage.getItem("header_type")) {
  //   //   localStorage.setItem("header_type", 'd')
  //   // }
  //   // this.header_type = localStorage.getItem("header_type")
  // }

 
  constructor(private deviceService: DeviceDetectorService, private http: HttpClient, private ry: Router,private auth: AuthService,) {
   // this.epicFunction();
   console.log("asdasdadsasd");
   console.log(ry.url, ry)
  }

  //DEVINFO
  sysAutoRefresh()
  {
    this.deviceInfo=this.deviceService.getDeviceInfo();
    this.isMobile=this.deviceService.isMobile();
    this.isTablet=this.deviceService.isTablet();
    this.isDesktopDevice=this.deviceService.isDesktop();
  }

  isloggedin() {
    if (localStorage.getItem("customer_id") == undefined) {
      return false;
    } else {
      return true;
    }
  }


  ADSclick()
  {
    
    var Phoneno=JSON.parse(localStorage.getItem("customer_emailid"))
    console.log("Phone :",Phoneno);
    if(Phoneno!=null)
    {
      var cData="Cust.Mobile: "+Phoneno
      console.log("Yes");
      this.auth.sarinsAds(cData).subscribe(data => {
        console.log("data", data)
      })
    }
    else{
      console.log("No");
    }
  }
}

