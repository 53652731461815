<div class="page-wraper">
   
    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                   
                    <h1 class="text-white">Return Policy</h1>
                </div>
            </div>
        </div>

        <div class="section-full content-inner">
            <div class="container">
                <div class="shop-form">
                   

                    <h2><strong>Return Policy</strong></h2>

                    <p>Welcome to FBCakes!</p>

                   

<p>In case of complaints regarding Cake Damage that come with store-side making, please refer the issue to the concerned. Let us know & we’ll help you with the same.</p>


    <p>You have received the cake in a bad condition or if the packaging is tampered with or damaged before delivery, </p>



        <p>please refuse to accept the package and return the package to the delivery person. </p>



            <p>Also, please call our customer care at +91 90034 32888 </p>

                <p>or </p>

<p>email us at fbcakes.info@gmail.com and mentioning your Order ID. </p>



    <p>We will personally ensure that a brand-new replacement is issued to you at no additional cost. </p>
                    
                   

                </div>

              
            </div>
           



        </div>