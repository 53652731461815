import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  local_url2 = "https://localhost:44383/api/"
  live_url2 = "https://biz1pos.azurewebsites.net/api/"

  local_url = "https://localhost:44315/api/"
  // live_url = "https://localhost:44315/api/"
  //work live_url = "https://biz1ecom.azurewebsites.net/api/"

  //  test
  live_url = "https://fbecom.azurewebsites.net/api/"
  // live_url="https://localhost:44315/api/"

  public showsearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isloggedin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }

  toFormData(formValue) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      formData.append(key, value);
    }
    return formData;
  }

  saveFeedback2(data, image) {
    var body = new FormData();
    body = this.toFormData(data)
    body.append("image", image);
    return this.http.post(this.live_url + "Ecommerce/_saveOrderReview", body);
  }
  saveCareer2(data, image) {
    // var formdata = this.toFormData(data)
    var body = new FormData();
    body = this.toFormData(data)
    body.append("file", image);

    return this.http.post(this.live_url + "Ecommerce/SaveCarrers_N", body);
  }

  getproducts(companyid) {
    // return this.http.get(this.local_url + "Ecommerce/getproducts?companyId=" + companyid);
    // bgst
    // return this.http.get(this.live_url + "Ecommerce/getproducts?companyId=" + companyid);
    // agst
    return this.http.get(this.live_url + "Ecommerce/getproducts?companyId=" + 1000);
  }


  getOrdersFBbyId(oid) {
    return this.http.get(this.live_url + 'OrderDashboard/getOrderbyId?oid=' + oid)
  }




  getoptions(productid) {
    // return this.http.get(this.local_url + "Ecommerce/getoptions?productId=" + productid);
    //  return this.http.get(this.live_url + "Ecommerce/getoptions?productId=" + productid);
    return this.http.get(this.live_url + "Ecommerce/getoptionstemp?productId=" + productid);
  }

  getStoreDetails(id) {
    return this.http.get(this.live_url + "Ecommerce/getstoresname?storeid=" + id);
  }

  getTaxes(companyid) {
    // return this.http.get(this.local_url + "Ecommerce/gettaxes?companyId=" + companyid);
    return this.http.get(this.live_url + "Ecommerce/gettaxes?companyId=" + companyid);
  }

  makepayment(payload) {
    return this.http.post(this.live_url + "Ecommerce/ecommercepaymentgateway", payload)
    //return this.http.post(this.live_url + "Ecommerce/ecommercepaymentgateway",payload)
  }

  // TOKEN JWT
  getorder(customerId) {
    return this.http.get(this.live_url + "Ecommerce/getOrderitemdetails2?customerid=" + customerId);
  }

  getordertok(customerId, tok) {
    const headers = new HttpHeaders({
      Authorization: tok,
    })
    return this.http.get(this.local_url + "OrderDashboard/getOrderitemdetails2?customerid=" + customerId,
      {
        headers: headers,
      })
  }



  getCliststateslist(customerId, token) {
    const headers = new HttpHeaders({

      Authorization:
        // 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJKV1RTZXJ2aWNlQWNjZXNzVG9rZW4iLCJqdGkiOiI3ODIwNjVmZC1kZmRjLTQ3NzItYTYwZi01YWM4NWY1ZDU3MjIiLCJpYXQiOiIyMy0xMS0yMDIzIDA1OjM1OjU1IiwiVXNlcklkIjoiMjQiLCJleHAiOjE3MDMzMDk3NTUsImlzcyI6IkpXVEF1dGhlbnRpY2F0aW9uU2VydmVyIiwiYXVkIjoiSldUU2VydmljZVBvc3RtYW5DbGllbnQifQ.EGKc9HYDJmLptmoVPSMcmD5vzIzdJC9XaGxrqLyl96c',
        token,
    })

    return this.http.get(this.local_url + "OrderDashboard/getOrderitemdetails2?customerid=" + customerId,
      {
        headers: headers,
      })
  }



  getorder_temp(oid) {
    return this.http.get(this.live_url + "Ecommerce/getorderdetail_temp?oid=" + oid);
  }

  changeCOD(oid) {
    return this.http.get(this.live_url + "Ecommerce/changetoCOD?oid=" + oid);
  }

  msgorderitem(orderId) {
    return this.http.get(this.live_url + "Ecommerce/getmsgOrderitem?OrderId=" + orderId);
  }

  saveCustomerEnquiry(name, email, mobile, msg) {
    return this.http.get(this.live_url + "Ecommerce/saveCustomerEnquiry?Name=" + name + "&Email=" + email + "&Mobile=" + mobile + "&msg=" + msg);
  }

  paymentDetails(x) {
    return this.http.get(this.live_url + "Ecommerce/payment_request_status?req_id=" + x);
  }

  saveCareer(name, age, mobile, email, address, edet, wdet, adet, jtype) {
    return this.http.get(this.live_url + "Ecommerce/saveCareerDetails?Name=" + name + "&Age=" + age + "&Mobile=" + mobile + "&Email=" + email +
      "&Address=" + address + "&edet=" + edet + "&wdet=" + wdet + "&adet=" + adet + "&note=" + jtype);
  }

  saveCustomerReview(cid, oid, msg) {
    return this.http.get(this.live_url + "Ecommerce/saveCustomerReview?cid=" + cid + "&oid=" + oid + "&msg=" + msg);
  }

  getReviewUrl(sid) {
    return this.http.get(this.live_url + "Ecommerce/getReviewUrl?sid=" + sid);
  }

  sarinsAds(msg) {
    return this.http.get(this.live_url + "Ecommerce/sarinaClicks?msg=" + encodeURIComponent(msg));
  }

  loc(lat, lon) {
    return this.http.get(this.live_url + "Ecommerce/Get_Loc?lat=" + lat + "&lng=" + lon);
  }

  simpleCart(msg) {
    return this.http.get(this.live_url + "Ecommerce/simpleCart?msg=" + encodeURIComponent(msg));
  }


  placeorder(payload) {
    var formdata = this.toFormData(payload)

   // placeorder_enc


    // instomojo
    //return this.http.post(this.live_url + "Ecommerce/placeorder", formdata);

    // phonepay b gst
    //return this.http.post(this.local_url + "Ecommerce/placeorder3", formdata);

    // phonepay a gst current(01/14/2024)
    //return this.http.post(this.live_url + "Ecommerce/placeorder3_gt", formdata);

    //placeorder3_gt_phonepay
    return this.http.post(this.live_url + "Ecommerce/placeorder3_gt_phonepay", formdata);


    //return this.http.post(this.local_url + "Ecommerce/placeorder", formdata);

    //ence insto
    //return this.http.post(this.live_url + "Ecommerce/placeorder_enc", formdata);
  }

  placeorder2(payload) {
    var formdata = this.toFormData(payload)
    // return this.http.post(this.live_url + "Ecommerce/placeorder2", formdata);
    //  return this.http.post(this.live_url + "Ecommerce/placeorder2", formdata);

    // enc bgst
    //  return this.http.post(this.local_url + "Ecommerce/placeorder2_enc", formdata);

    // enc agst
    return this.http.post(this.live_url + "Ecommerce/placeorder2_enc_gst", formdata);
  }
  // customerlogin(PhoneNo, companyid, email,L_ip,L_address,L_city,L_pin) {
  //   return this.http.get(this.live_url + `Ecommerce/customer_login3?PhoneNo=${PhoneNo}&&companyId=${companyid}&&email=${email}&&L_ip=${L_ip}&&L_address=${L_address}&&L_city=${L_city}&&L_pin=${L_pin}`);
  // }

  customerlogin(PhoneNo, companyid, email) {
    return this.http.get(this.live_url + `Ecommerce/ent_login4?PhoneNo=${PhoneNo}&&companyId=${companyid}&&email=${email}`);
  }
  otpverification(email, otp) {
    // return this.http.get(this.local_url + `Ecommerce/verify_otp?email=${email}&&otp=${otp}`);
    //return this.http.get(this.live_url + `Ecommerce/verify_otp?email=${email}&&otp=${otp}`);
    // return this.http.get(this.live_url + `Ecommerce/verify_otp2?PhoneNo=${email}&&otp=${otp}`);
    return this.http.get(this.live_url + `OrderDashboard/verify_otp2?PhoneNo=${email}&&otp=${otp}`);
  }

  getSPG_Products(Pid) {
    // bgst
    //return this.http.get(this.live_url + `Ecommerce/getspgproductlist?SPG_Id=` + Pid);

    //agst
    return this.http.get(this.live_url + `Ecommerce/getspgproductlist?SPG_Id=` + Pid);
  }

  getSPG_Product2(Pid) {
    return this.http.get(this.live_url + `Ecommerce/getSPGproduct?Pid=` + Pid);
  }

  ordercancelCust(oid) {
    return this.http.get(this.live_url + `Ecommerce/ordercancelCust?oid=` + oid);
  }

  orderPcode(data) {
    return this.http.get(this.live_url + `OrderDashboard/discountRule?data=` + data);
  }

  // token

  saveaddress(address) {
    var formdata = this.toFormData(address)
    // return this.http.post(this.local_url + "Ecommerce/saveaddress", formdata)
    // return this.http.post(this.local_url2 + "Ecommerce/saveaddress", formdata)
    return this.http.post(this.live_url + "Ecommerce/saveaddress", formdata)
  }

  saveaddresstok(address, token, cid) {
    var formdata = this.toFormData(address)
    const header = new HttpHeaders({
      Authorization: token,
    })

    return this.http.post(this.local_url + "OrderDashboard/saveaddress?cid=" + cid, formdata, { headers: header, })
  }




  //   deleteaddress(addressid) {
  //      return this.http.post(this.local_url + "Ecommerce/deleteaddress", addressid)
  //   }

  //   editaddress(addressid) {
  //     return this.http.post(this.local_url + "Ecommerce/editaddress", addressid)
  //  }

  setcurrentaddress(addressid, customerid) {
    // return this.http.get(this.local_url + "Ecommerce/setcurrentaddress?customeraddressid=" + addressid + '&&customerid=' + customerid)
    // return this.http.get(this.local_url2 + "Ecommerce/setcurrentaddress?customeraddressid=" + addressid + '&&customerid=' + customerid)
    return this.http.get(this.live_url + "Ecommerce/setcurrentaddress?customeraddressid=" + addressid + '&&customerid=' + customerid)
  }
  getcustomerdetails(customerid) {
    return this.http.get(this.live_url + "Ecommerce/getcustomerdetails?customerId=" + customerid)
  }



  sendordermsg(emailid, phoneno, amount, oid, oname, ptype) {
    return this.http.get(this.live_url + "Ecommerce/sendordermsg?emailid=" + emailid + "&phoneno=" + phoneno + "&amount=" + amount + "&oid=" + oid + "&oname=" + oname + "&ptype=" + ptype)
  }

  sendlocmail(email, PhoneNo, cid, lat, lon, dinfo) {
    return this.http.get(this.live_url + "Ecommerce/getloctarg?email=" + email + "&PhoneNo=" + PhoneNo + "&cid=" + cid + "&lat=" + lat + "&lon" + lon + "&dinfo" + dinfo);
  }

  getAccessories() {
    return this.http.get(this.live_url + "Ecommerce/getAccessories");
  }

  getorderpaymentconfirm(oid) {
    return this.http.get(this.live_url + "Ecommerce/orderpaymentconfirm?oid=" + oid);
  }

  getorderdetail(oid) {
    return this.http.get(this.live_url + "Ecommerce/getOrderdetail?oid=" + oid);
  }

  saveFeedback(name, phone, msg, oid, cid) {
    return this.http.get(this.live_url + "Ecommerce/saveOrderReview?Name=" + name + "&Mobile=" + phone + "&msg=" + msg + "&Oid=" + oid + "&Cid=" + cid);
  }



  viewFeedback(oid) {
    return this.http.get(this.live_url + "Ecommerce/getOrderFeedback?oid=" + oid);
  }

  // tryagainpayment(oid,total)
  // {
  //   return this.http.get(this.local_url +"Ecommerce/tryagainpayment?Oid="+oid+"&Total="+total);
  // }

  tryagainpayment(total) {
    var mytotal: number = total;
    // return this.http.get(this.local_url +"Ecommerce/tryagainpayment?total="+mytotal);
    return this.http.get(this.live_url + "Ecommerce/tryagainpayment?total=" + 20);
  }

  transupdate(oid, note) {
    var id: number = oid;
    var mojo: string = note
    return this.http.get(this.live_url + "Ecommerce/transupdate?oid=" + id + "&note=" + mojo);
  }

  cust_det(cid) {
    return this.http.get(this.live_url + "Ecommerce/customer_det?cid=" + cid);
  }

  getFBstores() {
    return this.http.get(this.live_url + "Ecommerce/gettracestores");
  }

  getrepayonline(key) {
    return this.http.get(this.live_url + "Ecommerce/rePayment?key=" + key);
  }

  getrepayonlineI(key) {
    return this.http.get(this.live_url + "Ecommerce/rePaymentI?key=" + key);
  }


  editCustAddress(customerid) {
    return this.http.get(this.live_url + "Ecommerce/editAddress?id=" + customerid)
  }

  tempOrderaccep(oid) {
    return this.http.get(this.live_url + "Ecommerce/t_order_status?orderId=" + oid + "&status=" + -5)
  }


  //  https://localhost:44315/api/Ecommerce/tryagainpayment?total=100

  // https://localhost:44315/api/Ecommerce/transupdate?oid=1579489&note=working

}


//https://fbecom.azurewebsites.net/api/Ecommerce/t_order_status?orderId=_____&status=__
