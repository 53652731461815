import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-firstbirthdaycake',
  templateUrl: './firstbirthdaycake.component.html',
  styleUrls: ['./firstbirthdaycake.component.css']
})
export class FirstbirthdaycakeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  myproduct = [
    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(77)[superuser]%20%20%20%204%204799%205%205899%206%206999.jpg',
      alt: 'Royal Affair Cake online free delivery chennai',
      name:'Royal Affair Cake',
      qty:'1/2 Kg',
      price: '6796',
      link:'https://fbcakes.com/cakes/royal-affair-2-cake-5955'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/31_grande%20%207.5-5999%209-6999.jpg',
      alt: 'Pink Stunner Cake online free delivery chennai',
      name:'Pink Stunner Cake',
      qty:'1/2 Kg',
      price: '4288',
      link:'https://fbcakes.com/cakes/pink-stunner-cake-5971'
    },

    {
      img: 'https://biz1pos.azurewebsites.net/images/3/5kgbcg%20(25)[superuser]%20_fond19%20%204%204799%205%205899%206%206999.jpg',
      alt: 'Lollipop Cake online free delivery chennai',
      name:'Lollipop Cake',
      qty:'1/2 Kg',
      price: '3827',
      link:'https://fbcakes.com/cakes/lollipop-cake-5977'
    }

    
  ];

  event=[
    {
      name:'Bento Cakes',
      link:'https://fbcakes.com/'
    },

    {
      name:'Bomb Cakes',
      link:'https://fbcakes.com/'
    },

    {
      name:'Surprise Cakes',
      link:'https://fbcakes.com/'
    },

    {
      name:'BTS Cakes',
      link:'https://fbcakes.com/'
    },

    {
      name:'Cocomelon Cakes',
      link:'https://fbcakes.com/'
    },


    {
      name:'Black and Red Barbie Doll Cake',
      link:'https://fbcakes.com/cakes/black-and-red-barbie-doll-cake-5954'
    },

    {
      name:'Toys Cakes',
      link:'https://fbcakes.com/cakes/black-and-red-barbie-doll-cake-5954'
    },

    {
      name:'First Birthday Grand Cake',
      link:'https://fbcakes.com/cakes/miss-cupcake-cake-5956'
    },

    {
      name:'Second Birthday Grand Cake',
      link:'https://fbcakes.com/cakes/lavender-blue-ombre-cake-5966'
    },

    {
      name:'Princess Cake',
      link:'https://fbcakes.com/cakes/disney-princess-cake-5973'
    },

    {
      name:'Football Cake',
      link:'https://fbcakes.com/cakes/the-soccer-sensation-cake-6021'
    },

    {
      name:'Valentine Day Cake',
      link:'https://fbcakes.com/cakes/valentine-day-cake-5834'
    }

  ]


  selectproduct(p)
  {
    window.open(p.link, '_blank');
  }

  window()
  {
    window.open('https://fbcakes.com/');
  }

}
