import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import * as moment from 'moment'
import Swal from 'sweetalert2';


@Component({
  selector: 'app-orderlist',
  templateUrl: './orderlist.component.html',
  styleUrls: ['./orderlist.component.css']
})
export class OrderlistComponent implements OnInit {

  orderid: number = 0
  // auth: any;
  customerid = 0;
  orders = [];

  show = false;
  fullScreen = true;
  template = ``
  store:any=[];
  paydetail=[];

  constructor(private route: ActivatedRoute, private auth: AuthService)
   { 
    this.per = 0;
   }


  // public counts = ["Recieved","In Progress","Ready for Billing",
  // "Billed","Order Closed"];
  // public orderStatus = "In Progress"

  name = 'Order Status';
  public counts = ["Preparing",
    "Dispatched", "Delivered"];

  public orderStatus = "Pending"

  per = 0;



  ngOnInit(): void {

    this.onClickInsideContainer(true)
    
    this.customerid = +localStorage.getItem("customer_id");
    console.log("Cus_Id", this.customerid);
    this.orderid = +this.route.snapshot.paramMap.get('id');
    console.log("OrderID", this.orderid)

    this.getOrder();


  }

  getOrder() {
    var cststus: 0;
    this.auth.getorder(this.customerid).subscribe(data => {
      console.log("Orders", data);
      this.orders = data["orders"].filter(x => x.OdrsId == this.orderid);
      console.log("desc order", this.orders)

      console.log("this.orders[0].OrderStatusId", this.orders[0].osi)

      cststus = this.orders[0].OrderStatusId
      console.log("cststus: ", cststus)

      if (this.orders[0].osi == 0) {
        this.per = 15
      }
      else if (this.orders[0].osi == -5) {
        this.per = 30
      }
      else if (this.orders[0].osi == 1) {
        this.per = 50
      }
      else if (this.orders[0].osi == 2) {
        this.per = 60
      }
      else if (this.orders[0].osi == 3) {
        this.per = 75
      }
      else if (this.orders[0].osi == 4) {
        this.per = 80
      }
      else if (this.orders[0].osi == 5) {
        this.per = 100
      }
      else {
        this.per = 0
      }


      

      console.log("This.per:", this.per, this.orders.length)
      setTimeout(() => {
        document.getElementsByClassName("progress-bar")[0]["style"].width = this.per + "%"
      }, 1000);
      this.getStoreDetais(this.orders[0].si)

      this.onClickInsideContainer(false)
    })


    //     if(this.orders[0].OrderStatusId==0)
    //     {
    //       //Pending
    // this.per=25
    //     }

    //     else if(this.orders[0].OrderStatusId==1)
    //     {
    //       //Accepted
    //       this.per=50
    //       console.log("This.per2:",this.per)

    //     }

    //     else if(this.orders[0].OrderStatusId==2)
    //     {
    //       //Preparing
    //       this.per=75
    //       console.log("This.per2:",this.per)

    //     }

    //     else if(this.orders[0].OrderStatusId==5)
    //     {
    //       //Completed
    //       this.per=100
    //       console.log("This.per2:",this.per)

    //     }
    // console.log("This.per2:",this.per)
  }


  getTotal() {
    var totalprice = 0;
    this.orders[0].OrderItems.forEach(element => {
      totalprice += Number(element.ta);
    });
   
    return (totalprice);
  }

  changeCOD(orders) {
    Swal.fire({
      title: 'Change Payment Method',
      text: 'Do you want to change this Order to Cash on Delivery?',
      // icon:'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: 'red',
    }).then((result) => {

      if (result.isConfirmed) {

        console.log('Clicked Yes, File deleted!');
        this.changetoCOD(orders)

      } else if (result.isDismissed) {
        console.log('Clicked No, File is safe!');
      }
    })
  }

  changetoCOD(orders) {
    this.show = true;
  this.fullScreen = true;
    console.log("ksgjk", orders)
    this.auth.changeCOD(this.orderid).subscribe(data => {
      this.show = false;
  this.fullScreen = false;
      this.getOrder();
    })
  }

  addressEdit(x)
  {
console.log("Address: ",x)
  }

  toImage() {
    var node = document.getElementById('orderList');

    toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        document.body.appendChild(img);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  print(orderid, date) {
    var data = document.getElementById('orderList');
    html2canvas(data).then(canvas => {
      console.log(canvas, data)
      //  document.body.appendChild(canvas)
      var imgWidth = (canvas.width > canvas.height) ? 248 : 350;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;

      var heightLeft = imgHeight;

      if (canvas.width > canvas.height) {
        imgWidth = 248
        imgHeight = canvas.height * imgWidth / canvas.width;
      } else {
        imgHeight = 200
        imgWidth = canvas.width * imgHeight / canvas.height;
      }

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      //save file with specific name
      pdf.save("#" + orderid + "_" + moment(date).format('lll') + ".pdf");
    });
  }

   onClickInsideContainer(x){
    this.show = x;
    this.fullScreen = false;
    this.template = ``
    // setTimeout(() => {
    //     this.show = false
    // }, 2000);
  }


  getStoreDetais(x)
  {
    this.auth.getStoreDetails(x).subscribe(data => {
     
      this.store=data[0]
      console.log("Store Detail: ",this.store)
    })
  }


  payHistory(x,i)
  {
console.log("Pay_history:",x)
console.log("i:",i)
this.auth.paymentDetails(x).subscribe((data: any) => {
  console.log("paymentDetails 1: ",data)
  this.paydetail=data.contentJson.payment_request
  console.log("paymentDetails 2: ",this.paydetail)
})
  }

  retryPay(order)
  {
console.log("RetryPay: ",order)
  }

  download_order()
  {
    window.print();
  }

  repayOnline0000(x)
  {
    console.log("amount: ", x.BillAmount)
    console.log("orderid: ", x.Id)
    console.log("customerid: ", x.CustomerId)
    console.log("mobilenumber: ", x.CustomerDetail[0].PhoneNo)

    var key = [x.BillAmount, x.Id, x.CustomerId, x.CustomerDetail[0].PhoneNo].join("|")
    key = btoa(key)

    this.auth.getrepayonline(key).subscribe((data: any) => {
      console.log("Again PAy Url", data);
        // var re_pay = data.paymentUrl
        window.open(data.paymentUrl);
    })




    // this.http
    //   .get<any>(
    //     "https://biz1ecom.azurewebsites.net/api/Ecommerce/rePayment?key=" + key
    //   )
    //   .subscribe((data) => {
    //     console.log("Again PAy Url", data);
    //     var re_pay = data.paymentUrl
    //     window.open(data.paymentUrl);
    //   });
  }

  repayOnline2(x)
  {
    console.log("amount zz: ", x.BillAmount)
    console.log("ph: ", x.CustomerDetail[0].PhoneNo)
    console.log("email: ", x.CustomerDetail[0].Email)
    console.log("name: ", x.CurrentAddresses[0].Name)
    console.log("oid: ", x.Id)
    console.log("cid: ", x.CustomerId)

    var key = [x.BillAmount, x.CustomerDetail[0].PhoneNo, x.CustomerDetail[0].Email, x.CurrentAddresses[0].Name,x.Id,x.CustomerId].join("|")
    key = btoa(key)

      this.auth.getrepayonlineI(key).subscribe((data: any) => {
      console.log("Again PAy Url", data);
      var paymentresponse = JSON.parse(data["paymentresponse"]);
        // var re_pay = data.paymentUrl
        window.open(paymentresponse.payment_request.longurl);
        // paymentresponse.payment_request.longurl
    })
  }

}
