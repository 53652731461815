<!-- <app-headera *ngIf="header_type=='a'"></app-headera>
<app-headerb *ngIf="header_type=='b'"></app-headerb>
<app-headerc *ngIf="header_type=='c'"></app-headerc> -->

<!-- <p><br> My_current_location
    (lat:-{{lat}})_
    (lan-:{{lng}})</p> -->



<!-- <h3>Device Informations</h3>
<table class="table">
  <thead>
    <tr>
      <th scope="col">Property</th>
      <th scope="col">Value</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Device Type</td>
      <td>{{deviceInfo.deviceType}}</td>
    </tr>
   
    <tbody>
</table> -->


<!-- <app-headerd *ngIf="header_type=='d'"></app-headerd> -->
<!-- <div>hi this is gopi</div> -->

<!-- <app-top-message></app-top-message> -->
<app-headerd></app-headerd>

<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6972226194484846"
     crossorigin="anonymous"></script>
<!-- test 002 vert -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-6972226194484846"
     data-ad-slot="7002893946"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>

<router-outlet>  
  <app-supportchat></app-supportchat>
</router-outlet>


<!-- <app-footerd></app-footerd> -->
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6972226194484846"
     crossorigin="anonymous"></script>
<!-- test 001 rect -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-6972226194484846"
     data-ad-slot="3829935690"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>
<app-footerc></app-footerc>



<!-- <app-mapview></app-mapview> -->




<style scoped='' type='text/css'>
  .fixed-leftSd,
  .fixed-rightSd {
    position: fixed;
    top: 60px;
    width: 160px;
    height: 600px;
    z-index: 9999;
    transform: translateZ(0)
  }

  .fixed-leftSd {
    left: 0
  }

  .fixed-rightSd {
    right: 0
  }

  .close-fixedSd {
    position: absolute;
    width: 160px;
    height: 20px;
    line-height: 15px;
    font-size: 11px;
    font-weight: 400;
    top: -15px;
    left: 0;
    text-align: center;
    background: #e0e0e0;
    color: #666;
    padding: 5px 0;
    cursor: pointer
  }

  @media screen and (max-width:850px) {

    .fixed-leftSd,
    .fixed-rightSd {
      display: none;
      visibility: hidden;
    }
  }
</style>

<!-- <div class="fixed-leftSd" style="margin-top: 30px;">

  <div aria-label="Close Ads" class="close-fixedSd" role="button" tabindex="0"
    style="background-color: black; color: #fff;" onclick="this.parentElement.style.display=&quot;none&quot;">
    CLOSE ADS
  </div>

  <div>
   
        <a  href="https://bit.ly/36Ze8WZ" target="blank"><img
          src="https://i.ibb.co/Wp8FXMk/Sarina-ads1.jpg"
          alt="sarina the boutique"></a>
  </div>
</div>


<div class="fixed-rightSd" style="margin-top: 30px;">


  <div style="background-color: black; color: #fff;" aria-label="Close Ads" class="close-fixedSd" role="button"
    tabindex="0" onclick="this.parentElement.style.display=&quot;none&quot;">
    CLOSE ADS
  </div>

  <div>
  
        <a  href="https://bit.ly/36Ze8WZ" target="blank">
          <img src="https://i.ibb.co/Lnj0MVL/Sarina-ads2.jpg"
            alt="sarina the boutique"></a>
  </div> 

</div>-->






<!-- mpbile view -->


<!-- {
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 12px 0 0; */
    position: absolute;
    right: -5px;
    top: -38px;
    /* background-color: #fefefe; */
    box-shadow: 0 -6px 18px 0 rgb(9 32 76 / 8%);
} -->


<!-- <style>
  .Arpian-ads {
    display: none;
  }

  @media only screen and (max-width: 851px) {
    .Arpian-ads {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      min-height: 70px;
      max-height: 90px;
      padding: 5px 0;
      box-shadow: none;
      -webkit-transition: all .1s ease-in;
      transition: all .1s ease-in;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      z-index: 2000;
    }

    .Arpian-ads-close {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      color: white;
      right: -5px;
      top: -38px;
      box-shadow: 0 -6px 18px 0 rgb(9 32 76 / 8%);
    }

    .Arpian-ads .Arpian-ads-close svg {
      width: 22px;
      height: 22px;
      fill: white;
    }

    .Arpian-ads .Arpian-ads-content {
      overflow: hidden;
      display: block;
      position: relative;
      text-align: center;
      height: 90px;
      width: 100%;
      background: transparent;
    }
  }
</style>


<div class='Arpian-ads' id='Arpian-ads'>
  <div class='Arpian-ads-content'>

    <a href="javascript:void(0);" style="position: relative;" (click)="ADSclick()">
      <div class='Arpian-ads-close'
        onclick='document.getElementById(&quot;Arpian-ads&quot;).style.display=&quot;none&quot;'>
        <svg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' style="fill: white;">
          <path
            d='M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z' />
        </svg>
      </div>
      <a href="https://bit.ly/36Ze8WZ" target="blank" (click)="ADSclick()">
        <img src="https://i.ibb.co/jwqMqrR/Urban-Clothing-Co-Ebay-Billboard.png" alt="sarina the boutique"
          style="height: 100%;">
      </a>
    </a>

  </div>
</div> -->

