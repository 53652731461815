import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { AuthService } from '../auth.service'
import Swal from 'sweetalert2';
import { KmlLayerManager, LatLngLiteral, MapsAPILoader, MouseEvent } from '@agm/core';


import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import { Router } from '@angular/router';
import { __values } from 'tslib';

declare function closeModal(): any


@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.css']
})
export class MyaccountComponent implements OnInit {
  isMobile: boolean = false
  toggle;
  mytoogle;
  verifytoggle;
  private geoCoder;
  address_text: string;
  ordtab = 0;
  orders = [];
  corders = [];
  ccorders = [];
  orderid = null;
  orderlist = [];
  formaddress = { name: "", address: "", city: "", state: "", phone: "", pincode: "" }
  currentaddressid;
  customerid = 0;
  cartitems;
  myccount;
  addressmessage = false;
  myAcco='';

  public lat;
  public lng;


  zoom: number;
  bcolor;


  // map
  @ViewChild('search')
  public searchElementRef: ElementRef;

  marker: any
  map: any;
  latitude = 12.9190519;
  longitude = 80.2300343;

  centerLatitude = this.latitude;
  centerLongitude = this.longitude;

  initialZoom = 20;



  stud = [{
    name: "Chennai",
    rnNo: 1
  },
  {
    name: "coimbatore",
    rnNo: 2
  },
  {
    name: "Madurai",
    rnNo: 3
  },
  {
    name: "Bangalore",
    rnNo: 4
  }
  ];

  selected: any;
  baseurl: string;






  constructor(private auth: AuthService, private router: Router, private mapsAPILoader: MapsAPILoader,private ngZone: NgZone,) {
   // this.setCurrentLocation();;
    //this.getfbstores();
   
   

    //karp 12.919418350781912, 80.23007336001956
    // this.logout();
    this.cartitems = JSON.parse(localStorage.getItem("cart_items"));
    if (this.cartitems)
      this.myccount = this.cartitems.length
    console.log("myccount 1", this.myccount)
    // console.log("C_length",this.cartitems.length)
    //localStorage.setItem("toogle", "1")
    console.log("my toogle Value ", JSON.parse(localStorage.getItem("toogle")))
    this.toggle = JSON.parse(localStorage.getItem("toogle"));
    this.addresses = JSON.parse(localStorage.getItem("addresses"));
    console.log("my all address", this.addresses)

    this.currentaddressid = JSON.parse(localStorage.getItem("currentaddressid"));
    this.customerid = +localStorage.getItem("customer_id");
    this.orders = JSON.parse(localStorage.getItem("orders"));
    localStorage.setItem("toogle", "1")
   // this.getorder();
    
    this.cities = this.countries.filter(x => x.id == 1)[0].cities;

    //  console.log("test_address",this.addresses[3].Contact.split('_')[2])

    // this.setState({ Lat: res[0].City.split('_')[0] })
    // this.setState({ Lon: res[0].City.split('_')[1] })
    // this.setState({ latlng: { latitude: parseFloat(res[0].City.split('_')[0]), 
    // longitude: parseFloat(res[0].City.split('_')[1]) } })
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      // document.write("mobile device");
      this.isMobile = true
    } else {
      // false for not mobile device
      // document.write("not mobile device");
    }

    
  }

tok=""
  ngOnInit() {
    //load Places Autocomplete
    
    this.customerid = +localStorage.getItem("customer_id");
    this.cartitems = JSON.parse(localStorage.getItem("cart_items"));

    localStorage.setItem("nearbySid", '22');
    localStorage.setItem("nearbystore_km", '0');
    this.tok=localStorage.getItem("tok")
    this.getOrdertok();
    this.getorder();

    this.myAcco=localStorage.getItem("customer_emailid");
    
    

   
    // this.mapsAPILoader.load().then(() => {
    //   // this.setCurrentLocation();
    //   this.geoCoder = new google.maps.Geocoder;

    //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       //get the place result
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();

    //       //verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       //set latitude, longitude and zoom
    //       this.latitude = place.geometry.location.lat();
    //       this.longitude = place.geometry.location.lng();
    //       this.zoom = 12;
    //      // this.getAddress(this.latitude, this.longitude);
    //     });
    //   });
    //   this.mapConfiguration();
    //   //this.getDistance(this.latitude, this.longitude)
    // });
    
  }

  applyZIndex(){
    var pacContainer = document.getElementsByClassName("pac-container")
    console.log(pacContainer)
    pacContainer.item(0)["style"].zIndex = 10000000000000;
  }

  valueChange(event) {
    console.log("Its Work")
    console.log("selected value", event.target.value,
      'value of selected', this.selected);
    //this.selected = event.target.value;
  }

  selectedCountry: any;

  cities = {};

  countries = [{
    id: 1, name: 'Tamilnadu', cities: ['Chennai', 'Madurai', 'Coimbatore']
  },
  {
    id: 2, name: 'Karnataka', cities: ['Bengaluru']
  }
  ];

  setcity(city) {
    console.log("My City", city)
  }

  onChange(deviceValue) {
    this.cities = this.countries.filter(x => x.id == deviceValue)[0].cities;
  }



  @ViewChild('pdfTable') pdfTable: ElementRef;
  @ViewChild('pdfTable2') pdfTable2: ElementRef;

  public downloadAsPDF() {
    const doc = new jsPDF();
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();
  }

  public downloadAsPDF2() {
    const doc = new jsPDF();
    const pdfTable2 = this.pdfTable2.nativeElement;
    var html = htmlToPdfmake(pdfTable2.innerHTML);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();
  }

  // getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position: Position) => {
  //       if (position) {
  //         console.log("Latitude: " + position.coords.latitude +
  //           "Longitude: " + position.coords.longitude);
  //         this.lat = position.coords.latitude;
  //         this.lng = position.coords.longitude;
  //       }
  //     },
  //       (error: PositionError) => console.log(error));
  //   } else {
  //     console.log("Geolocation is not supported by this browser.")
  //   }
  // }

  // private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.latitude = position.coords.latitude;
  //       this.longitude = position.coords.longitude;
  //       console.log("Current Lat Location:", this.latitude)
  //       console.log("Current Lng Location:", this.longitude)
  //       this.zoom = 15;
  //     });

  //   }
  // }
  
  _ccorder
  viewccorder(order) {
    this._ccorder = order
    console.log("CC_Order:", this._ccorder)
  }



  // getproducts() {
  //   this.auth.getproducts(3).subscribe(data => {
  //     console.log(data);
  //     this.products = data["products"]
  //     this.options = data["options"]
  //     this.categories = data["categories"].filter(x => x.ParentCategoryId != null);
  //     this.categoryId = this.categories[0].Id;
  //     this.predefinedquantities = data["predefinedquantities"]
  //   })
  // }

  getorder() {
    console.log("my custId",this.customerid);
    console.log("my token",this.tok);
    this.auth.getorder(this.customerid).subscribe(data => {
      console.log("All Orders tok rep", data);
      this.orders = data["orders"].filter(x => x.osi != -1 && x.osi != 5)
      // this.orders = data["orders"].filter(x => x.OrderStatusId == 0);
      this.corders = data["orders"].filter(x => x.osi == 5);
      this.ccorders = data["orders"].filter(x => x.osi == -1);

      console.log("Pending Orders", this.orders)
      console.log("Completed Orders", this.corders)
      console.log("Cancelled Orders", this.ccorders)
    })
  }

  getOrdertok()
  {
    console.log("my custId",this.customerid);
console.log("my token",this.tok);
// this.auth.getorderTok(this.customerid,this.tok).subscribe(data => {
//   console.log("All Orders tok 1", data);
// })

this.auth.getorder(this.customerid).subscribe(data => {
    console.log("All Orders tok 2", data);
  })
  }

  // get_new()
  // {
  //   this.auth.getproducts(3).subscribe(data=> {
  //     console.log(data);
  //     this.products=data["products"];
  //     this.options=data['options'];
  //     this.categories=data['categories'].filter(x=>x.ParentCategoryId!=null)
  //     this.categoryId=this.categories[0].Id;
  //     this.predefinedquantities=data['predefinedquantities']
  //   })
  // }



  mypdf() {
    alert("Working")
  }

  completeorder(Id) {
    console.log('CorderId', Id)
  }

  // generatePdf(){
  //   const documentDefinition = { content: 'This is an sample PDF printed with pdfMake' };
  //   pdfmake.createPdf(documentDefinition).open();
  //  }

  getorderdetail() {
    // this.auth.getorder(this.orders.Id).subscribe(data => {
    //   console.log("Orders List",data);
    //   this.orderlist=data["orders"];
    // })
  }

  porder() {
    this.ordtab = 0
    console.log("P_order :", this.ordtab)
  }

  corder() {
    this.ordtab = 1
    console.log("C_order :", this.ordtab)
    var fiorder;
    //  this.orders = data["orders"];
    fiorder = this.orders.filter(x => x.OrderStatusId == 1)
    console.log("Orders", fiorder);
  }


  ccorder() {
    this.ordtab = 3
    console.log("C_order :", this.ordtab)
  }

  addresses = [];
 
  hide() {
    document.getElementsByClassName('btn-group bootstrap-select form-control 1')[0]["style"].display = 'none'
  }

  saveaddress2()
  {
    var address = {
      Name: this.formaddress.name,
      Address:this.address_text+ '. Ph.' + this.formaddress.phone,
      Contact: this.latitude + '_' + this.longitude + '_1',
      CustomerId: +localStorage.getItem("customer_id"),
      CompanyId: 3,
      Latitude: this.latitude, 
      Longitude: this.longitude,
     // NearByStoreId:this.minstoreid
    }

    console.log("Saved Address: ",address);
    this.function_distance(this.latitude,this.longitude)
    //console.log("Saved Address: ",this.final_km);
  }

saveaddress()
{
  var address = {
    Name: this.formaddress.name,
    Address:this.address_text+'. Pin: '+this.formaddress.pincode+ '. Ph no.' + this.formaddress.phone,
    Contact: this.latitude + '_' + this.longitude + '_1',
    CustomerId: +localStorage.getItem("customer_id"),
    CompanyId: 3,
    Latitude: this.latitude, 
    Longitude: this.longitude,
    NearByStoreId:this.minstoreid
  }

  console.log("CID:",address.CustomerId)
console.log("Address:",JSON.stringify(address))
console.log("token:",this.tok)

  if (this.address_text != "" && this.formaddress.name != "" && this.formaddress.phone != "" && this.address_text.length>5 && this.formaddress.pincode.length>=5) {
    this.addressmessage = false;
   
     this.auth.saveaddress({ Details: JSON.stringify(address) }).subscribe(data => {
    // this.auth.saveaddresstok({ Details: JSON.stringify(address) },'bearer '+this.tok,address.CustomerId).subscribe(data => {
      console.log("saved_address 1 tok", data)
      var mydata=data;
      localStorage.setItem("addresses", JSON.stringify(data));
      this.addresses = JSON.parse(localStorage.getItem("addresses"));

      this.addresses.forEach(element => {
        if (element.iscurrentaddress) {
          localStorage.setItem("currentaddressid", element.Id.toString())
          localStorage.setItem("addresses", JSON.stringify(data));
          localStorage.setItem("nearbystoreId", element.NearByStoreId);
          //this.applyaddress(element.iscurrentaddress);
          console.log("Last Value: 1",mydata)
          console.log("Last Value: 2",mydata[(<any>mydata).length-1])
          console.log("element.iscurrentaddress",element.iscurrentaddress)
         this.applyaddress(mydata[(<any>mydata).length-1]);
        }
        
      }); 
      this.currentaddressid = +localStorage.getItem("currentaddressid");
    })
    this.verifytoggle = !this.verifytoggle
  }
  else {
    this.addressmessage = true;
  }
}


  saveaddress_BGST() {

    // this.getLocation();

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((position: Position) => {
    //     if (position) {
    //       console.log("Latitude: " + position.coords.latitude +
    //         "Longitude: " + position.coords.longitude);
    //       this.lat = position.coords.latitude;
    //       this.lng = position.coords.longitude;

    //       var address = {
    //         Name: this.formaddress.name,
    //         Address: this.formaddress.address+', '+this.formaddress.phone+'-'+this.formaddress.pincode+".",
    //         // Contact: this.formaddress.phone,
    //           Contact: position.coords.latitude+'_'+position.coords.longitude,
    //         CustomerId: +localStorage.getItem("customer_id"),
    //         CompanyId: 3
    //       }

    //       if(this.formaddress.address!="" && this.formaddress.pincode!="" )
    //       {
    //         this.addressmessage=false;
    //         this.auth.saveaddress({ Details: JSON.stringify(address) }).subscribe(data => {
    //           console.log(data)
    //           localStorage.setItem("addresses", JSON.stringify(data));
    //           this.addresses = JSON.parse(localStorage.getItem("addresses"));

    //           this.addresses.forEach(element => {
    //             if (element.iscurrentaddress) {
    //               localStorage.setItem("currentaddressid", element.Id.toString())
    //             }
    //           });
    //           this.currentaddressid = +localStorage.getItem("currentaddressid");

    //         })
    //        // this.verifytoggle = !this.verifytoggle
    //       }
    //       else
    //       {
    //         this.addressmessage=true;
    //         //alert("Address fields should not be Empty!")
    //       }
    //     }
    //   },
    //     (error: PositionError) => console.log(error));
    // } else {
    //   console.log("Geolocation is not supported by this browser.")
    // }
    this.function_distance(this.latitude,this.longitude)

    if(this.minkm>=6)
    {
      this.minstoreid=22
    }

    var address = {
      Name: this.formaddress.name,
      Address:this.address_text+'. Pin: '+this.formaddress.pincode+ '. Ph no.' + this.formaddress.phone,
      Contact: this.latitude + '_' + this.longitude + '_1',
      CustomerId: +localStorage.getItem("customer_id"),
      CompanyId: 3,
      Latitude: this.latitude, 
      Longitude: this.longitude,
      NearByStoreId:this.minstoreid
    }

    

    console.log("Saved Address: ",address);
   console.log("Saved Address: ",this.final_km);

//     if(this.final_km<=7)
//     {
// console.log("lesser than 7")
//     }
//     else
//     {
//       console.log("greater than 7")
//     }

    localStorage.setItem("nearbySid", this.minstoreid);
    localStorage.setItem("nearbystore_km", this.final_km);
   

   

    if (this.address_text != "" && this.formaddress.name != "" && this.formaddress.phone != "" && this.address_text.length>5 && this.formaddress.pincode.length>=5) {
      this.addressmessage = false;
      this.auth.saveaddress({ Details: JSON.stringify(address) }).subscribe(data => {

        console.log("saved_address 1", data)

        localStorage.setItem("addresses", JSON.stringify(data));

        // JSON.parse(localStorage.getItem("addresses")).forEach(x=>
        //   {
        //     if(x.hidden==false)
        //   {
        //     this.addresses = JSON.parse(localStorage.getItem("addresses"));
        //   }
        //   })
        //   console.log("Working Addresss: ",this.addresses)
       
        this.addresses = JSON.parse(localStorage.getItem("addresses"));

        this.addresses.forEach(element => {
          if (element.iscurrentaddress) {
            localStorage.setItem("currentaddressid", element.Id.toString())
            localStorage.setItem("addresses", JSON.stringify(data));
            localStorage.setItem("nearbystoreId", element.NearByStoreId);
            this.applyaddress(element.iscurrentaddress);
          }
        });
        this.currentaddressid = +localStorage.getItem("currentaddressid");


      })
      this.verifytoggle = !this.verifytoggle
    }
    else {
      this.addressmessage = true;
     
    }


  }


  removeaddress(address) {
    console.log("Remove address Id", address.Id)
    // var myaddress : any=address
    // myaddress.forEach(element => {
    //   if(element.Id==address.Id)
    //   {
    //     element.Contact="Working"
    //   }
    // })
    var alladdress = JSON.parse(localStorage.getItem("addresses"));
    console.log("All_address_1", alladdress)
    alladdress.forEach(element => {
      if (element.Id == address.Id) {
        console.log("ele", element)
        element.Contact = "Working Fine";
      }
    });
    console.log("All_address_2", alladdress)
    // localStorage.setItem("addresses", alladdress)

  }

  removeaddress2(address)
  {
    console.log("Remove address Id", address.Id)
    this.auth.editCustAddress(address.Id).subscribe(data => 
      {
      console.log("data:",data);
  })
}



  applyaddress(address) {
    console.log(address)
    this.auth.setcurrentaddress(address.CustomerAddressId, this.customerid).subscribe(data => {
      var addresses: any = data;

      addresses.forEach(element => {
        if (element.iscurrentaddress) {
          localStorage.setItem("currentaddressid", element.CustomerAddressId.toString())
          localStorage.setItem("addresses", JSON.stringify(data));
          localStorage.setItem("nearbystoreId", element.NearByStoreId);
          localStorage.setItem("currentaddress", JSON.stringify(element))
        }
      });

      this.currentaddressid = JSON.parse(localStorage.getItem("currentaddress"));
      localStorage.setItem("addresses", JSON.stringify(addresses))
      this.addresses = JSON.parse(localStorage.getItem("addresses"));
      var ccount;
      if (this.cartitems == undefined || this.cartitems == "") {
        console.log("my Cart Value :", 0)
        ccount = 0
      }
      else {
        console.log("my Cart Value :", this.cartitems.length)
        ccount = this.cartitems.length;
        if (ccount > 0) {
          document.location.href = document.location.origin + '/checkout'
        }
        else {
          console.log("my Cartcount o :", ccount)
        }
      }


    })
  }

  logout() {


    Swal.fire({
      title: 'Log out?',
      text: 'You may check out any time you like, but you can never leave',
      // icon:'info',
      showCancelButton: true,
      confirmButtonText: 'Go back',
      cancelButtonText: 'Cancel',
      confirmButtonColor: 'red',
    }).then((result) => {
      if (result.isConfirmed) {

        console.log('Clicked Yes, File deleted!');
        localStorage.clear();
        document.location.href = document.location.origin + "/"

      } else if (result.isDismissed) {
        console.log('Clicked No, File is safe!');
      }
    })

  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    console.log("Lat:", this.latitude)
    console.log("Lng:", this.longitude)
    //this.getAddress(this.latitude, this.longitude);
  }


  Transactiondetail() {
    console.log("Transactiondetail")
  }

  getlog_loc($event: MouseEvent) {
    console.log("My mouse event:", MouseEvent)
    console.log("My mouse event:", event)
  }

  tryagainpayment(oId, BillAmt) {
    console.log("Order_id", oId);
    console.log("Bill Amount", BillAmt);

    // var mybillamt:number =BillAmt;

    this.auth.tryagainpayment(BillAmt).subscribe(data => {
      console.log("tryagainpayment", data)
      var paymentresponse = JSON.parse(data["paymentresponse"]);
      console.log("paymentresponse", paymentresponse);
      console.log("paymentresponsestatus", paymentresponse.payment_request.status);

      var allpamentdetail = {
        Oid: oId,
        status: paymentresponse.payment_request.status,
        Billamt: BillAmt,
        Comp_Responce: paymentresponse,
      };
      localStorage.setItem("my_payment_resendPayment_response", JSON.stringify(allpamentdetail));

      document.location.href = paymentresponse.payment_request.longurl;

      if (paymentresponse.payment_request.status == "Credit") {
        // if (paymentresponse.payment_request.status == "Pending") {
        this.auth.getorderpaymentconfirm(oId).subscribe(data => {
          console.log("order_update_data", data);
        })
      }
      document.location.href = window.location.origin + '/myaccount'
    })
  }

  customerOrders(order) {
    console.log("Order ID :", order.Id)
  //  this.router.navigateByUrl('orderlist/' + order.Id);

    this.router.navigate([]).then(result => {  window.open('/orderlist/' + order.OdrsId, '_blank'); });

    // this.router.navigate([]).then(result => { window.open(this.baseurl + '/#/itemdetails', '_blank'); });
    // this.router.navigateByUrl({ window.open(this.baseurl + '/#/itemdetails', '_blank'); });

  }

  cancelOrder(order) {
    Swal.fire({
      title: 'Order Cancel',
      text: 'Do you want to cancel this Order',
      // icon:'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: 'red',
    }).then((result) => {

      if (result.isConfirmed) {

        console.log('Clicked Yes, File deleted!');
        this.customerSideCancelOrders(order)

      } else if (result.isDismissed) {
        console.log('Clicked No, File is safe!');
      }
    })
  }


  customerSideCancelOrders(order) {
    console.log("customerSideCancelOrders :", order)
    this.auth.ordercancelCust(order.Id).subscribe(data => {
      // console.log("All Orders", data);
      this.getorder();
    })

  }


  getRevieworder(i) {

    Swal.fire({
      title: 'Please Mention your Order Review!',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off'
      },

      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,

      preConfirm: (login) => {
        if (!login) {
          Swal.showValidationMessage(
            // '<i class="fa fa-info-circle"></i> Your name is required'
            'Your Review is required!'
          )
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      // return false
      if (result.isConfirmed) {
        console.log("d2:", result)
        this.AddCustomerReview(result.value, i)
      }
    })
  }

  AddCustomerReview(value, i) {
    console.log("C_Review:", value)
    console.log("OrderId:", i.Id)
    console.log("CustomerId:", i.CustomerId)
    this.auth.saveCustomerReview(i.CustomerId, i.Id, value).subscribe(data => {
      console.log("data", data)
    })
  }

  getRevieworder2(i)
  {
    console.log("i",i)
   console.log("StoreId",i.DeliveryStoreId)
  //  this.auth.getReviewUrl(i.DeliveryStoreId).subscribe(data => {
  //   console.log("data", data)
  //   window.open(data[0].Review_Url, "_blank");
  // })
    window.open("https://fbcakes.com/feedback/"+i.Id, "_blank");
  }

  ADSclick()
  {
    console.log("Working Fine")
    var cData="Id: #"+this.orders[0].CustomerId+", Name: "+this.orders[0].CurrentAddresses[0].Name.toUpperCase()+", Mobile: "+this.orders[0].CustomerDetail[0].PhoneNo.slice(2)+", Address: "+this.orders[0].CurrentAddresses[0].Address;
    console.log("cDate: "+cData)

    this.auth.sarinsAds(cData).subscribe(data => {
      console.log("data", data)
    })
  }



  // map

  mapConfiguration() {
    let image = "https://res.cloudinary.com/swiggy/image/upload/v1493751154/Other_Pin_urgkbb.png"
    this.map = new google.maps.Map(document.getElementById('gmap'), {
      scrollwheel: false,
      scaleControl: false,
      draggable: false,
      zoom: 10,
      center: new google.maps.LatLng(51.75339, -0.210114),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      gestureHandling: 'greedy',
      mapTypeControl: false
    });
    this.marker = new google.maps.Marker({
      icon: image,
      zIndex: 10
    });

  }
  public centerChanged(coords: LatLngLiteral) {
    console.log("centerchanged")
    this.centerLatitude = coords.lat;
    this.centerLongitude = coords.lng;
    // this.getDistance(coords.lat, coords.lng);
    //   this.latitude = coords.lat;
    // this.longitude = coords.lng;
    // this.getAddress(this.latitude, this.longitude);
  }

  public mapReady(map) {
    map.addListener("dragend", () => {
      console.log("dragend")
      console.log("A:", this.centerLatitude, this.centerLongitude)
      this.latitude = this.centerLatitude;
      this.longitude = this.centerLongitude;
     // this.getAddress(this.latitude, this.longitude);
     // this.getDistance(this.latitude, this.longitude);
     //this.function_distance(this.latitude, this.longitude)
    });
  }




  // markerDragEnd($event: MouseEvent) {
  //   console.log($event);
  //   this.latitude = $event.coords.lat;
  //   this.longitude = $event.coords.lng;
  //   this.getAddress(this.latitude, this.longitude);
  // }

  getAddress_old(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log("getAddress results", results);
      console.log("getAddress status", status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address_text = results[1].formatted_address;
          console.log(this.address_text)
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  fbstores:any=[];
  getfbstores()
  {
    this.auth.getFBstores().subscribe(data => {
      console.log("my FB stores");
      console.log("FB stores", data);
       localStorage.setItem("fbstores", JSON.stringify(data));
       this.fbstores=data

       console.log("FB stores 2626", this.fbstores);
      
    })
    
    //console.log("FB stores new", JSON.parse(localStorage.getItem("fbstores")));
  }



  dist_rep: any = [];
  LowDistStore: any = [];
  final_store: any=[];
  final_km;
  final_duration;
  minstoreid:any=22;
  minkm=0;

  // const origin =
    //   [{ lat: 12.925334415932213, lng: 80.23078237620904, area: 'kpkm', id: 1 },
    //   { lat: 12.902750472343127, lng: 80.22823061478074, area: 'sholi', id: 2 },
    //   { lat: 12.89827323679782, lng: 80.24741986072152, area: 'ecr', id: 3 }];

  // public getDistance(x, y) {
  //   console.log("getDistance works")
  //   const destination = { lat: x, lng: y };
  //   const matrix = new google.maps.DistanceMatrixService();
  //   this.dist_rep = []
  //   this.fbstores.map(x => {
  //     return {...x, lat: +x.lat, lng: +x.lng}
  //   }).forEach((pq, i) => {

  //     return new Promise((resolve, reject) => {
  //       matrix.getDistanceMatrix({
  //         origins: [pq],
  //         destinations: [destination],
  //         travelMode: google.maps.TravelMode.DRIVING,
  //         "unitSystem": google.maps.UnitSystem.METRIC,
  //         "avoidHighways": false,
  //         "avoidTolls": false
  //       }, function (response, status) {
  //         if (status === 'OK') {
  //           let res: any = response;
  //            res.store_details = pq;
  //           resolve(res);
  //           console.log("Km:", response.rows[0].elements[0].distance.text + " ___ " + "time:",
  //             response.rows[0].elements[0].duration.text + " | ", response.destinationAddresses[0]);
  //         } else {
  //           reject(response);
  //           console.log("my error ", response)
  //         }
  //       });
  //     }
  //     ).then(val => {
  //       console.log(val)

  //       this.dist_rep = [...this.dist_rep, val]
  //       console.log("val", this.dist_rep)
  //       let minkm;
  //       let minstoreid:any=22;
  //       let minstore;
  //       let minduration;
  //       this.dist_rep.forEach(el => {
  //         if (minkm === undefined || parseInt(el.rows[0].elements[0].distance.text) < minkm) {
  //           minkm = parseInt(el.rows[0].elements[0].distance.text);
  //           minduration=el.rows[0].elements[0].duration.text;
  //           minstoreid = el.store_details.StoreId;
  //           minstore=el.store_details;
  //           this.LowDistStore=el.store_details;
            
  //         }
  //       });
  //       this.final_store=minstore;
  //       this.final_km=minkm
  //       this.final_duration=minduration
  //       console.log(".............................................................................................................lowest km", 
  //       minstoreid, minkm);
  //       console.log(".............................................................................................................minstore", 
  //       this.final_store);

  //       this.minstoreid=minstoreid;
  //     }).catch(err => {
  //       console.log(err)
  //     })
  //   })
  // }

  // Get Current Location Coordinates
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
      });
    }
  }
  

  let_var()
  {
    var data='fbcakes'
    console.log("output: 0", data)
    if(true)
    {
      var data='fbcakes and sweets'
      console.log("output 1: ", data)
    }
    
    console.log("output: 2", data)

    console.log("-------")

    let mydata='fbcakes'
    console.log("output 0: ", mydata)
    if(true)
    {
      let mydata='fbcakes and sweets'
      console.log("output 1: ", mydata)
    }
    console.log("output 1: ", mydata)
    
  }



addaddress()
{
  this.mapsAPILoader.load().then(() => {
    this.setCurrentLocation();
    this.geoCoder = new google.maps.Geocoder;
    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        //set latitude, longitude and zoom
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.zoom = 12;
      });
    });
    this.mapConfiguration();
   
  });
}

// 12.913877719165534, 80.2290633233113
// 12.845900018659297, 80.22641488644533

// mydistance3(x,y)
// {
// var lat1= x;
// var lon1=y;
// var distStores;
// console.log("Works distance", this.fbstores);
// this.fbstores.map(x => {
//   return {...x, Latitude: +x.Latitude, Longitude: +x.Longitude}
//      }).forEach((pq, i) => {
//   lat1 = lat1 * Math.PI / 180;
//   lon1 =  lon1 * Math.PI / 180;
//   let dlon = pq.Longitude - lon1;
//   let dlat = pq.Latitude - lat1;
//   let a = Math.pow(Math.sin(dlat / 2), 2)
// + Math.cos(lat1) * Math.cos(pq.Latitude)
// * Math.pow(Math.sin(dlon / 2),2);
// let c = 2 * Math.asin(Math.sqrt(a));
// let r = 3956;
// distStores={"km":c*r,"Name":pq.Name,"Id":pq.Id}
// this.myalldata.push(distStores);
// //return(c * r);   
// })
// console.log("this.myalldata  :",this.myalldata)
// this.myalldata.sort((a,b)=>a.km-b.km)
// console.log("this.myalldata sorted :",this.myalldata)
// console.log("Shortest Distance Store :",this.myalldata[0])
// }


// function_distance2() {

//   var lat1= 12.919042985205758
//   var lon1=80.2300164921752

//   var lat2= 12.730375535734261
//   var lon2=80.18896363681156

//   var unit="K"

// 	if ((lat1 == lat2) && (lon1 == lon2)) {
// 		return 0;
// 	}
// 	else {
// 		var radlat1 = Math.PI * lat1/180;
// 		var radlat2 = Math.PI * lat2/180;
// 		var theta = lon1-lon2;
// 		var radtheta = Math.PI * theta/180;
// 		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
// 		if (dist > 1) {
// 			dist = 1;
// 		}
// 		dist = Math.acos(dist);
// 		dist = dist * 180/Math.PI;
// 		dist = dist * 60 * 1.1515;
// 		if (unit=="K") { dist = dist * 1.609344 }
// 		if (unit=="N") { dist = dist * 0.8684 }
//     console.log("Dist :",dist);
// 		return dist;
// 	}
// }

myalldata;

function_distance(lat1,lon1)
{
  // var lat1= 12.919042985205758
  //  var lon1=80.2300164921752
this.myalldata=[];
  console.log("lat1:",lat1);
  console.log("lon1:",lon1);

  var unit="K"
  var distStores;

  console.log("This.FBstores: ",this.fbstores)

  this.fbstores.map(x => {
    return {...x, Latitude: +x.Latitude, Longitude: +x.Longitude}
       }).forEach((pq, i) => {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * pq.Latitude/180;
		var theta = lon1-pq.Longitude;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
    // console.log("Dist :",dist);
    distStores={"km":dist,"Store_Name":pq.Name,"sid":pq.Id}
    this.myalldata.push(distStores);
		return dist;
	})
  // console.log("this.myalldata  :",this.myalldata)
this.myalldata.sort((a,b)=>a.km-b.km)
// console.log("this.myalldata sorted :",this.myalldata)

console.log("Shortest Distance Store :",this.myalldata[0])
this.minstoreid=this.myalldata[0].sid;
this.minkm=this.myalldata[0].km
localStorage.setItem("nearbySid", this.minstoreid)
localStorage.setItem("minkm", this.myalldata[0].km)

}

}

