import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-contuctus',
  templateUrl: './contuctus.component.html',
  styleUrls: ['./contuctus.component.css']
})
export class ContuctusComponent implements OnInit {

  name = "";
  email = "";
  mobile = "";
  message = "";

  report = "";
  rcolor = 0;
  whatssente=""

  constructor(private auth: AuthService, private router: Router,) { }

  ngOnInit(): void {
  }

  submit() {
    if (this.name != "" && this.mobile != "" && this.message != "") {
      console.log("huiyhji",this.name, this.email, this.mobile, this.message)
      this.auth.saveCustomerEnquiry("(Contact Form) "+this.name, this.email, this.mobile, this.message).subscribe(data => {
        this.rcolor = 1;
        console.log("Data:", data)
        this.report = "Enquiry Submit Successful! You will get a reply within a few hours."
        this.message="";
      })


    }
    else {
      this.rcolor = 0;
      this.report = "Input Fields are Empty!"
    }
  }

  whatsup()
  {
    if (this.name != "" && this.mobile != "" && this.message != "")
    {
      this.rcolor = 1; this.message=""; this.report="";
      this.whatssente="Hi, I am "+this.name+" and my mobile no. "+this.mobile+". My inquiry is: "+this.message
      window.open("https://wa.me/919003432888?text="+this.whatssente, '_blank');
    
    }
    else {
      this.rcolor = 0;
      this.report = "Input Fields are Empty!"
    }
    }

}
