import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, state, animate, style, AnimationEvent } from '@angular/animations';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-supportchat',
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          height: '420px',
          opacity: 1,
          backgroundColor: 'white',
        })
      ),
      state(
        'closed',
        style({
          height: '40px',
          opacity: 0.7,
          backgroundColor: 'white',
        })
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
      transition('* => closed', [animate('0.5s')]),
      transition('* => open', [animate('0.5s')]),
      transition('open <=> closed', [animate('0.5s')]),
      transition('* => open', [animate('0.5s', style({ opacity: '*' }))]),
      transition('* => *', [animate('0.5s')]),
    ]),
  ],
  templateUrl: './supportchat.component.html',
  styleUrls: ['./supportchat.component.css']
})
export class SupportchatComponent {
    isOpen = false;
  isloggedin: boolean
  constructor(private auth: AuthService, private router: Router,) {
    this.Rmsg = 0;
    if (localStorage.getItem("customer_id") == undefined) {
      this.x = false;
    }
    else {
      this.x = true;
    }
    this.auth.isloggedin.subscribe(d => {
      console.log(d)
      this.isloggedin = d
    })
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  message = ''
  Rmsg = 0
  Mobno = ''
  x: Boolean = false;

  submit2(x, y) {
    console.log(x);
    if (x == '') {
      this.Rmsg = 1
    }
    else {
      if (localStorage.getItem("customer_id") == undefined) {
        this.Rmsg = 3
      }
      else {
        console.log("Mess", x)
        var mob = '91' + localStorage.getItem("customer_emailid")
        console.log("Mobile", mob)

        this.auth.saveCustomerEnquiry("Chat", "Chat", mob, x).subscribe(data => {
          console.log("Data:", data)
          this.Rmsg = 2
          this.message = ''
        })
      }
    }

  }

  submit3(x, y) {
    console.log(x, y);
    if (x == '' || y == '' || y.length != 10) {
      this.Rmsg = 1
    }

    else {
      console.log("Mess", x);
      console.log("ph", y)
      var mob = y
      console.log("Mobile", mob)

      this.auth.saveCustomerEnquiry("Chat", "Chat", mob, x).subscribe(data => {
        console.log("Data:", data)
        this.Rmsg = 2
        this.message = ''
      })
    }

  }





  submit(x, y) {
    if (this.x == true) {
      this.submit2(x, y)
    }

    else if (this.x == false) {
      this.submit3(x, y)
    }
    else {

    }

  }

  submit5(x, y) {
    console.log(x,'/',y);
    window.open("https://wa.me/+919003432888?text="+x, '_blank');
  }

  call()
  {
    window.open('tel:+919003432888');
  }

  // isloggedin() {
  //   if (localStorage.getItem("customer_id") == undefined) {
  //     return false;

  //   } else {
  //     return true;
  //   }
  // }

  @Input() logging = false;
  onAnimationEvent(event: AnimationEvent) {
    if (!this.logging) {
      return;
    }
    // openClose is trigger name in this example
    console.warn(`Animation Trigger: ${event.triggerName}`);

    // phaseName is start or done
    console.warn(`Phase: ${event.phaseName}`);

    // in our example, totalTime is 1000 or 1 second
    console.warn(`Total time: ${event.totalTime}`);

    // in our example, fromState is either open or closed
    console.warn(`From: ${event.fromState}`);

    // in our example, toState either open or closed
    console.warn(`To: ${event.toState}`);

    // the HTML element itself, the button in this case
    console.warn(`Element: ${event.element}`);
  }
}