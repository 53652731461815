import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footerd',
  templateUrl: './footerd.component.html',
  styleUrls: ['./footerd.component.css']
})
export class FooterdComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
