<footer class="site-footer footer-full">
    <div class="p-tb60 bg-dark">
        <div class="container">
            <div class="row">
                <div class="col-md-6 text-md-left text-center footer-logo logo-white">
                    <img src="../../assets/images/fbcakes.png" alt="" width="150">
                    <p class="m-b0 m-t10 text-white" style="margin-top: 10px;">CONTACT US: +91 90034 32888
                        </p>
                    <!-- <p class="m-b0 m-t10 text-white">Copyright &copy; 2022 FBcakes</p> -->
                </div>
                <div class="col-md-6 text-md-right text-center">
                    <div class="widget-link "> 
                        <ul>
                            <li><a href="">Home</a></li> 
                            <li><a href="#/aboutus">About us</a></li> 
                            <li><a href="#/contuctus">Contact Us</a></li> 
                            <li><a href="#/privacypolicy">Privacy Policy</a></li> 
                            <li><a href="#/termsandconditions">Terms and Conditions</a></li> 
                            
                            <!-- <li><a href="ourmenu">Our Menu</a></li>  -->
                            <!-- <li><a href="ourservices">Our Services</a></li> 
                            <li><a href="contacta">Contact Us</a></li>  -->
                        </ul>
                    </div>
                    <!-- <ul class="list-inline m-b0 m-t10">
                        <li><a href="javascript:void(0);" class="btn circle hover"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="javascript:void(0);" class="btn circle hover"><i class="fa fa-google-plus"></i></a></li>
                        <li><a href="javascript:void(0);" class="btn circle hover"><i class="fa fa-linkedin"></i></a></li>
                        <li><a href="javascript:void(0);" class="btn circle hover"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="javascript:void(0);" class="btn circle hover"><i class="fa fa-twitter"></i></a></li>
                    </ul> -->

                    <p class="m-b0 m-t10 text-white" >Copyright &copy; 2022 FBcakes</p>
                   
                </div>
               
            </div>
           
        </div>

        
    </div>
</footer>
