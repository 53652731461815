import { BrowserModule,Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PathLocationStrategy, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ItemListComponent } from './item-list/item-list.component';
import { CartComponent } from './cart/cart.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { HeaderaComponent } from './headera/headera.component';
import { HeaderbComponent } from './headerb/headerb.component';
import { HeadercComponent } from './headerc/headerc.component';
import { HeaderdComponent } from './headerd/headerd.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { FooteraComponent } from './footera/footera.component';
import { FooterbComponent } from './footerb/footerb.component';
import { FootercComponent } from './footerc/footerc.component';
import { FooterdComponent } from './footerd/footerd.component';
import { PolicyComponent } from './policy/policy.component';
import { AgmCoreModule } from '@agm/core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ProductPipe } from './pipes/product.pipe';
import { MapviewComponent } from './mapview/mapview.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrderlistComponent } from './orderlist/orderlist.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContuctusComponent } from './contuctus/contuctus.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { GalleryComponent } from './gallery/gallery.component';
import { StorelistComponent } from './storelist/storelist.component';
import { FaqComponent } from './faq/faq.component';
import { AdminComponent } from './admin/admin.component';
import { CarrerComponent } from './carrer/carrer.component';
import { StoreComponent } from './store/store.component';
import { OfferComponent } from './offer/offer.component';
import { Offer2Component } from './offer2/offer2.component';
import { BlogComponent } from './blog/blog.component';
import { LowPriceCakesComponent } from './low-price-cakes/low-price-cakes.component';
import { FirstbirthdaycakeComponent } from './firstbirthdaycake/firstbirthdaycake.component';
import { LabelComponent } from './label/label.component';
import { NgxLoaderModule } from '@tusharghoshbd/ngx-loader';

import { FeedbackComponent } from './feedback/feedback.component';
import { SupportchatComponent } from './supportchat/supportchat.component';
import { TopMessageComponent } from './top-message/top-message.component';
import { TransactionComponent } from './transaction/transaction.component';
import { ReturnpolicyComponent } from './returnpolicy/returnpolicy.component';
import { RefundpolicyComponent } from './refundpolicy/refundpolicy.component';
import { DeliverypolicyComponent } from './deliverypolicy/deliverypolicy.component';
import { OdrsComponent } from './odrs/odrs.component';






// import {
//   MatButtonModule,
//   MatDialogModule,
//   MatFormFieldModule,
//   MatInputModule,
//   MatSelectModule,
//   MatToolbarModule
// } from '@angular/material';





@NgModule({
  declarations: [
    AppComponent,
    ItemListComponent,
    CartComponent,
    ItemDetailsComponent,
    HeaderaComponent,
    HeaderbComponent,
    HeadercComponent,
    HeaderdComponent,
    CheckoutComponent,
    MyaccountComponent,
    FooteraComponent,
    FooterbComponent,
    FootercComponent,
    FooterdComponent,
    PolicyComponent,
    ProductPipe,
    MapviewComponent,
    OrderlistComponent,
    AboutusComponent,
    ContuctusComponent,
    PrivacypolicyComponent,
    TermsconditionsComponent,
    GalleryComponent,
    StorelistComponent,
    FaqComponent,
    AdminComponent,
    CarrerComponent,
    StoreComponent,
    OfferComponent,
    Offer2Component,
    BlogComponent,
    LowPriceCakesComponent,
    FirstbirthdaycakeComponent,
    LabelComponent,
    FeedbackComponent,
    SupportchatComponent,
    TopMessageComponent,
    TransactionComponent,
    ReturnpolicyComponent,
    RefundpolicyComponent,
    DeliverypolicyComponent,
    OdrsComponent,
  ],
  imports: [
    BrowserModule,
    AutocompleteLibModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    NgxLoaderModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDqPJB1DDvFkUPKCT5WRRPcepF3ZAJPtlo',
      libraries: ['places']
    })
  ],
  // providers: [ { provide: LocationStrategy, 
  // useClass: PathLocationStrategy } ],
  // bootstrap: [AppComponent],

  // apiKey: 'AIzaSyAQdxruVP1K2nXXHfiySbB48bvzJNikBn0',

  providers: [
    // {
    //   provide: LocationStrategy, 
    //   // useClass: HashLocationStrategy
    //   useClass:PathLocationStrategy
    // }
    Title
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
