<!-- <!DOCTYPE html>
<html lang="en">
<head>
	<meta charset="utf-8">
	<meta http-equiv="X-UA-Compatible" content="IE=edge">
	<meta name="keywords" content="" />
	<meta name="author" content="" />
	<meta name="robots" content="" />
	<meta name="description" content="RestroKing - Cakery & Bakery HTML5 Template"/>
	<meta property="og:title" content="RestroKing - Cakery & Bakery HTML5 Template"/>
	<meta property="og:description" content="RestroKing - Cakery & Bakery HTML5 Template"/>
	<meta property="og:image" content="http://restroking.dexignzone.com/xhtml/social-image.png" />
	<meta name="format-detection" content="telephone=no"> -->

<!-- FAVICONS ICON -->
<!-- <link rel="icon" href="../../assets/images/favicon.ico" type="image/x-icon" />
	<link rel="shortcut icon" type="image/x-icon" href="../../assets/images/favicon.png" /> -->

<!-- PAGE TITLE HERE -->
<!-- <title>RestroKing - Cakery & Bakery HTML5 Template</title> -->

<!-- MOBILE SPECIFIC -->
<!-- <meta name="viewport" content="width=device-width, initial-scale=1"> -->

<!--[if lt IE 9]>
	<script src="js/html5shiv.min.js"></script>
	<script src="js/respond.min.js"></script>
	<![endif]-->

<!-- STYLESHEETS -->
<!-- <link rel="stylesheet" type="text/css" href="css/plugins.css">
	<link rel="stylesheet" type="text/css" href="css/style.css">
	<link rel="stylesheet" type="text/css" href="css/templete.css">
	<link class="skin" rel="stylesheet" type="text/css" href="css/skin/skin-1.css">
	<link class="skin" rel="stylesheet" type="text/css" href="plugins/rangeslider/rangeslider.css"> -->

<!-- </head>
<body id="bg" class="leftsidebar"> -->
<div class="page-wraper">
    <div hidden id="loading-area">
        <div class="ball-pulse-rise">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <!-- Content -->
    <div class="page-content bg-white">
        <!-- inner page banner -->
        <!-- <div class="dlab-bnr-inr overlay-black-middle bg-pt" style="height: fit-content;">
                  
                    <img src="../../assets/images/banner1.jpg" alt="banner" height="auto" width="100%"> -->

        <!-- style="background-image:url(../../assets/images/mywall.jpg);" -->

        <!--PATTAS- https://c.stocksy.com/a/yzx100/z9/468966.jpg -->
        <div 
        class="dlab-bnr-inr bg-pt" id="main-img"
        >
        <!-- style="background-image:url(https://i.postimg.cc/fyr8HbCx/Proof-D.jpg);" -->
            <!-- <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(https://i.pinimg.com/originals/42/e0/51/42e0519e48585c8bf90d50e9e7f5bd8d.jpg, https://i.ibb.co/F66JFH4/New-wall-001.jpg);"> -->
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <!-- <h1 class="text-white">Shop Grid 3 Side Bar</h1> -->
                    <!-- Breadcrumb row -->
                    <!-- <div class="breadcrumb-row">
                        <ul class="list-inline">
                            <li style="font-size: 30px; 
                            padding-top: 10px; padding-bottom: 10px;
                            font-weight: bold;">Buy 1 kg Get 1/2 kg Free !</li>
                        </ul>
                    </div> -->
                    <!-- Breadcrumb row END -->
                </div>
            </div>
        </div>



        <!-- inner page banner END -->
        <!-- contact area -->
        <div class="section-full content-inner">
            <!-- Product -->
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-lg-4 col-md-5 m-b30">
                        <!-- <aside class="side-bar shop-categories sticky-top" > -->
                        <aside class="side-bar shop-categories ">
                            <div class="dlab-accordion advanced-search toggle" id="accordion1">
                                <!-- SEARCH -->
                                <!-- <div class="md-form mt-0" style="margin-bottom: 10px;">
                                            <input class="form-control mr-sm-2" type="text" placeholder="Search">
                                          </div> -->
                                <div class="panel" *ngIf="!isMobile">
                                    <div class="acod-head">
                                        <h5 class="acod-title">
                                            <a data-toggle="collapse" href="#categories" class="collapsed">
                                                Product categories
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="categories" class="acod-body collapse show ">
                                        <div class="acod-content">
                                            <div class="widget_services ">
                                                <ul>
                                                    <li [class.active]="categoryId == category.id"
                                                        *ngFor="let category of cat"
                                                        (click)="getSpgProds(category.id)">
                                                        <a [class.active]="categoryId == category.id"
                                                            href="javascript:void(0);">{{category.Name}}</a>
                                                    </li>
                                                    <!-- <li><a href="shopcart">Veggie Supreme</a></li>
                                                            <li><a href="shopcart">The Funghi</a></li>
                                                            <li><a href="shopcart">Duck Prosciutto</a></li>
                                                            <li><a href="shopcart">Grilled Sausage</a></li>
                                                            <li><a href="shopcart">Burger</a></li>
                                                            <li><a href="shopcart">Brisket Onion</a></li> -->
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel" *ngIf="isMobile">
                                    <div class="acod-head">
                                        <h5 class="acod-title">
                                            <a data-toggle="collapse" href="#categories" class="collapsed">
                                                Product categories
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="categories" class="acod-body collapse show" data-toggle="collapse"
                                        href="#categories">
                                        <div class="acod-content">
                                            <div class="widget_services ">
                                                <ul>
                                                    <li [class.active]="categoryId == cat.id"
                                                        *ngFor="let cat of cat"
                                                        (click)="getSpgProds(cat.id)">
                                                        <a [class.active]="categoryId == cat.id"
                                                            href="javascript:void(0);">{{cat.Name}}</a>
                                                    </li>

                                                    <!-- <li [class.active]="categoryId == category.Id"
                                                    *ngFor="let category of categories"
                                                    (click)="getSpgProds(category.Id)">
                                                    <a [class.active]="categoryId == category.Id"
                                                        href="javascript:void(0);">{{category.Description}}</a>
                                                </li> -->

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="panel">
                                            <div class="acod-head">
                                                <h5 class="acod-title">
                                                    <a data-toggle="collapse" href="#price-range">
                                                        Price Range
                                                    </a> 
                                                </h5>
                                            </div>
                                            <div id="price-range" class="acod-body collapse show">
                                                <div class="acod-content">
                                                    <div class="price-slide-2 range-slider">
                                                        <div class="price">
                                                            <input type="text" id="amount-2" class="amount" readonly="" value="$400 - $4000" />
                                                            <div id="slider-range-2"></div>
                                                        </div>
                                                     </div>
                                                </div>
                                            </div>
                                        </div> -->
                                <!-- <div class="panel">
                                            <div class="acod-head">
                                                <h5 class="acod-title"> 
                                                    <a data-toggle="collapse"  href="#size">
                                                        Size
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="size" class="acod-body collapse show">
                                                <div class="acod-content">
                                                    <h6>Product Size</h6>
                                                    <div class="btn-group product-item-size" data-toggle="buttons">
                                                        <label class="btn">
                                                            <input name="options" id="option7" type="radio"> Large
                                                        </label>
                                                        <label class="btn active">
                                                            <input name="options " id="option8" type="radio"> Medium
                                                        </label>
                                                        <label class="btn">
                                                            <input name="options" id="option9" type="radio"> Small
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="panel">
                                            <div class="acod-head">
                                                <h5 class="acod-title"> 
                                                    <a data-toggle="collapse"  href="#toppings">
                                                        Add toppings :
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="toppings" class="acod-body collapse show">
                                                <div class="acod-content">
                                                    <div class="btn-group product-item-size" data-toggle="buttons">
                                                        <label class="btn">
                                                            <input name="options" id="veggies" type="radio"> Veggies
                                                        </label>
                                                        <label class="btn active">
                                                            <input name="options " id="medium" type="radio"> Medium
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                <!-- <div class="panel">
                                            <div class="acod-head">
                                                <h5 class="acod-title"> 
                                                    <a data-toggle="collapse"  href="#quantity">
                                                        Quantity
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="quantity" class="acod-body collapse show">
                                                <div class="acod-content">
                                                    <div class="btn-group product-item-size" data-toggle="buttons">
                                                        <label class="btn">
                                                            <input name="options" id="qty1" type="radio"> 01
                                                        </label>
                                                        <label class="btn active">
                                                            <input name="options " id="qty2" type="radio"> 02
                                                        </label>
                                                        <label class="btn">
                                                            <input name="options" id="qty3" type="radio"> 03
                                                        </label>
                                                        <label class="btn">
                                                            <input name="options" id="qty4" type="radio"> 04
                                                        </label>
                                                        <label class="btn">
                                                            <input name="options " id="qty5" type="radio"> 05
                                                        </label>
                                                        <label class="btn">
                                                            <input name="options" id="qty6" type="radio"> 06
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                <!-- <div class="panel">
                                            <div class="acod-head">
                                                <h5 class="acod-title"> 
                                                    <a data-toggle="collapse" href="#vendor" class="collapsed">
                                                        Vendor
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="vendor" class="acod-body collapse">
                                                <div class="acod-content">
                                                    <div class="product-brand">
                                                        <div class="search-content">
                                                            <input id="seating1" type="checkbox">
                                                            <label for="seating1"  class="search-content-area">Burger</label>
                                                        </div>
                                                        <div class="search-content">
                                                            <input id="seating2" type="checkbox">
                                                            <label for="seating2"  class="search-content-area">Pizza</label>
                                                        </div>
                                                        <div class="search-content">
                                                            <input id="seating3" type="checkbox">
                                                            <label for="seating3"  class="search-content-area">Fries</label>
                                                        </div>
                                                        <div class="search-content">
                                                            <input id="seating4" type="checkbox">
                                                            <label for="seating4"  class="search-content-area">Cola</label>
                                                        </div>
                                                        <div class="search-content">
                                                            <input id="seating5" type="checkbox">
                                                            <label for="seating5"  class="search-content-area">Hotdog</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->

                               <!-- <div class="panel">
                                    <div class="acod-head">
                                        <h5 class="acod-title">
                                            <a data-toggle="collapse" href="#tags" class="collapsed">
                                                Popular Tags
                                            </a>
                                        </h5>
                                    </div>
                                    
                                    <div id="tags" class="acod-body collapse">
                                        <div class="acod-content">
                                            <div class="widget_tag_cloud row">
                                                <div class="tagcloud" *ngFor="let t of tags">
                                                    <a href="javascript:void(0);" 
                                                    style="margin-right: 5px;"
                                                    (click)="myTags(t.id)"
                                                    >{{t.name}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>  -->


                                <div class="panel" >
                                    <div class="acod-head">
                                        <h5 class="acod-title">
                                            
                                            <a data-toggle="collapse" href="#tags" class="collapsed">
                                                <i class="fa fa-search"></i> Search 
                                            </a>
                                        </h5>


                                    </div>
                                    <br>
                                    <div id="tags" class="acod-body collapse" [class.show]="showsearch"
                                        style="height: 500px;">
                                        <!-- <div class="md-form mt-0"  >
                                                    <input class="form-control mr-sm-2" type="text" placeholder="Search Products" 
                                                >
                                                  </div> -->

                                        <div class="ng-autocomplete" style="width: 100%;">
                                            <ng-autocomplete [data]="products" [searchKeyword]="keyword"
                                                placeholder="Enter the Product Name" (selected)='selectEvent($event)'
                                                (inputChanged)='onChangeSearch($event)'
                                                (inputFocused)='onFocused($event)' historyIdentifier="products"
                                                [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.Name"></a>
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>




                                    </div>

                                </div>
                            </div>
                        </aside>
                    </div>

                    <div class="col-xl-9 col-lg-8 col-md-7">
                        <!-- 
                                <div class="md-form mt-0" style="margin-bottom: 10px; background-color: azure; align-content: center;" >
                                    <input class="form-control mr-sm-2" type="text" placeholder="Search" 
                                    style="width: 250px;">
                                  </div> -->

                        <div class="ball-pulse-rise text-center" *ngIf="!(products.length > 0)">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>

                        </div>

                        <!-- <marquee>We can not home delivery in Cake from 31st dec 2022 to 1st Jan 2021. 
                            Customer please understand to pickup orders in our nearby stores.</marquee> -->

                        <div class="row">
                            
                            <!-- <div *ngFor="let product of getproductbycategory() | product : saleProductId : productId | paginate: { itemsPerPage: 18, currentPage: p }"
                                class="col-xl-4 col-lg-6 col-md-12 col-sm-6 m-b30"> -->
                                
                                <div *ngFor="let product of getproductbycategory() | product : saleProductId : productId | paginate: { itemsPerPage: 18, currentPage: p }"
                                class="col-6 col-xl-4 col-lg-6 col-md-12 col-sm-6 m-b50">
                                       
                                

                                <div class="item-box shop-item style2" [class.slide-up]="productid == product.Id"
                                    (click)="productid = product.Id">

                                    

                                    <div class="item-img" href="javascript:void(0);" (click)="selectproduct(product)" >
                                        
                                        <div *ngFor="let pdq of getpredefinedquantities(product.Id)">
                                            <div *ngIf="pdq.FreeQuantity > 0">
                                               
                                                <!-- <span *ngIf="pdq.FreeQuantity==0.5" 
                                                class="center-align wave-effect product-tag green"
                                                >{{pdq.FreeQuantity==0.5 ? "1/2" : pdq.FreeQuantity}} KG FREE!</span>

                                                <span *ngIf="pdq.ProductId==1" 
                                                class="center-align wave-effect product-tag yellow"
                                                >{{pdq.FreeQuantity==0.5 ? "1/2" : pdq.FreeQuantity}} KG FREE!</span> -->

                                                <!-- old Free
                                                <span 
                                                *ngIf="pdq.ProductId!=12571 && pdq.ProductId!=12572 &&
                                                pdq.ProductId!=12578 && pdq.ProductId!=12579 && pdq.ProductId!=12580 &&
                                                pdq.ProductId!=12581 && pdq.ProductId!=12582 && pdq.ProductId!=12583 &&
                                                pdq.ProductId!=12575 && pdq.ProductId!=12577 && pdq.ProductId!=12574 && pdq.ProductId!=12576" 
                                                class="center-align wave-effect product-tag green"
                                                >1/2 KG FREE!</span> 

                                               

                                                <span *ngIf="pdq.ProductId==12571 || pdq.ProductId==12572 || 
                                                pdq.ProductId==12578 || pdq.ProductId==12579 || pdq.ProductId==12580 ||
                                                pdq.ProductId==12581 || pdq.ProductId==12582 || pdq.ProductId==12583 ||
                                                pdq.ProductId==12575 || pdq.ProductId==12577 || pdq.ProductId==12574 || pdq.ProductId==12576" 
                                                class="center-align wave-effect product-tag yellow"
                                                >1 KG FREE!</span>  -->


                                                <span *ngIf="product.SaleProductId!=5764" class="center-align wave-effect product-tag green">
                                                    1/2 KG FREE!
                                                </span>
                                                <span *ngIf="product.SaleProductId==5764" class="center-align wave-effect product-tag yellow">
                                                    1 KG FREE!
                                                </span>
                                                
                                            </div>
                                        
                                           
                                       
                                    </div>

                                        <!-- <div class="center-align wave-effect product-tag green">1/2 Kg Free!</div> -->

                                        <!-- <div class="center-align2 wave-effect product-tag2 green2">Free Delivery</div> -->
                                        <img *ngIf="!product.ImgUrl" src="../../assets/images/product/item1.jpg" >
                                        <img *ngIf="product.ImgUrl" src="{{product.ImgUrl}}"
                                            alt="{{product.Name}} Cake | {{product.Description}} | FBcakes">
                                    </div>
                                    <div class="item-info text-center">
                                        <h4 class="item-title font-12"><a>{{product.Name}}</a></h4>

                                        <!-- <div *ngFor="let pdq of getpredefinedquantities(product.Id)" >
                                                    <span>{{pdq.QuantityText}}={{pdq.Quantity}}</span>
                                                </div> -->

                                        <div class="star-rating text-yellow">
                                            <div data-rating="3">
                                                <i class="fa fa-star" data-alt="1" title="regular"></i>
                                                <i class="fa fa-star" data-alt="2" title="regular"></i>
                                                <i class="fa fa-star" data-alt="3" title="regular"></i>
                                                <i class="fa fa-star" data-alt="4" title="regular"></i>
                                                <i class="fa fa-star-o" data-alt="5" title="regular"></i>
                                            </div>
                                        </div>

                                        <div *ngFor="let pdq of getpredefinedquantities(product.Id); let i = index;"
                                            class="price text-primary">

                                            <span *ngIf="product.SaleProductId!=5762 && product.SaleProductId!=5767">{{pdq.QuantityText}}-₹{{pdq.Price.toFixed(0)}}</span>

                                            <span *ngIf="i==0 && (product.SaleProductId==5762)">
                                                {{pdq.QuantityText}}-₹{{(pdq.Price+1110).toFixed(0)}}</span>
                                        </div>

                                        <div class="price text-primary">
                                            <span *ngIf="product.Price!=0" >₹{{product.Price}}</span>
                                        </div>

                                        <!-- <div *ngFor="let pdq of getpredefinedquantities(product.Id)"
                                        class="price text-primary">

                                        <span *ngIf="pdq.FreeQuantity > 0">{{pdq.FreeQuantity==0.5 ? "1/2" : pdq.FreeQuantity}}</span>
                                    </div> -->

                                        <div>
                                            <h5 *ngIf="getoption(product.Id) && getoption(product.Id).length == 0"
                                                class="price text-primary">

                                                <span *ngIf="product.Price!=0">₹ {{product.Price}}</span>
                                            </h5>
                                        </div>
                                        <!-- <h5 *ngFor="let option of getoption(product.Id)" class="price text-primary">{{option.Name}} - <span>₹ {{product.Price + option.Price}}</span></h5>
                                                -->
                                        <!-- Add to Cart Div -->
                                        <!-- <div class="cartbtn">
                                            <a href="javascript:void(0);" (click)="selectproduct(product)"
                                                class="btn btnhover radius-xl">
                                                <i class="ti-shopping-cart"></i> Add my Cart</a>
                                        </div> -->

                                        <div class="cartbtn">
                                            <a href="javascript:void(0);" (click)="selectproduct(product)"
                                                class="btn btn-danger btnhover radius-xl">

                                                <i class="ti-shopping-cart"></i> Add my Cart</a>

                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div *ngIf="getproductbycategory().length > 0"
                                style="margin: auto; width: 100%; padding-right: 10%">
                                <pagination-controls (pageChange)="p = $event" class="float-right">
                                </pagination-controls>
                            </div>

                            <div class="panel" *ngIf="isMobile">
       
                                <div class="acod-content">
                                    <div class="widget_services ">
                    
                                <span 
                                [class.active]="categoryId == cat.id"
                                *ngFor="let cat of cat"
                                (click)="getSpgProdsmob(cat.id)" >
                                   
                                    <a style="font-size: 16px; line-height: 20pt; text-align: justify; " 
                                    [class.active]="categoryId == cat.id"
                                    href="javascript:void(0);"
                                        >{{cat.Name}}, </a> 
                                        
                                </span>
                    
                            </div>
                        </div>
                    
                            </div>

                            <br><br><br>
                          
                            <div >
                                <h2 style="margin-left: 5px;"> TESTIMONIALS</h2>
                                <!-- <div class="row col-xl-12">
                                    
                                    <div *ngFor="let item of store" class="col-xl-4 px-2 " style="margin-bottom: 15px;"
                                    (mouseover)="item.mouseOvered=true" (mouseout)="item.mouseOvered=false">
                                    <div [class.shadow-lg]="item.mouseOvered"
                                        style="background-color: whitesmoke;height: 100%;padding: 10px; border-radius: 10px;">
                                        <div>
                                            <div class="star-rating text-yellow">
                                                <div data-rating="3">
                                                    <i class="fa fa-star" data-alt="1" title="regular"></i>
                                                    <i class="fa fa-star" data-alt="2" title="regular"></i>
                                                    <i class="fa fa-star" data-alt="3" title="regular"></i>
                                                    <i class="fa fa-star" data-alt="4" title="regular"></i>
                                                    <i class="fa fa-star-o" data-alt="5" title="regular"></i>
                                                </div>
                                            </div>

                                            <div style="margin-top: 20px;">
                                                <span style="font-size: 16px;">
                                                    <span style="font-size: 22px;">"</span>
                                                    {{item.cmd}}
                                                    <span style="font-size: 22px;">"</span>
                                                </span>
                                            </div>

                                            <div style="margin-top: 10px;">
                                                <span style="color: rgb(241, 41, 41); margin-left: 10px; font-size: 18px;">- {{item.name}}</span>
                                            </div>
                                        </div>
                                    </div>
        
        
        
                                </div>
                                </div> -->

                                <div class="row col-xl-12">
                                    
                                    <div *ngFor="let item of store" class="col-xl-4 px-2 ">
                                        <div class="book" style="margin-bottom: 20px;">
                                            <img style="padding: 5px;" src={{item.img}} alt="Girl in a jacket" width="220px" height="300px">
                                            
                                            <div class="cover">
                                                <div style="padding: 8px;">
                                                    <div class="star-rating text-yellow">
                                                        <div data-rating="3">
                                                            <i class="fa fa-star" data-alt="1" title="regular"></i>
                                                            <i class="fa fa-star" data-alt="2" title="regular"></i>
                                                            <i class="fa fa-star" data-alt="3" title="regular"></i>
                                                            <i class="fa fa-star" data-alt="4" title="regular"></i>
                                                            <i class="fa fa-star-o" data-alt="5" title="regular"></i>
                                                        </div>
                                                    </div>
        
                                                    <div style="margin-top: 20px;">
                                                        <span style="font-size: 16px;">
                                                            <span style="font-size: 22px;">"</span>
                                                            {{item.cmd}}
                                                            <span style="font-size: 22px;">"</span>
                                                        </span>
                                                    </div>
        
                                                    <div style="margin-top: 10px;">
                                                        <span style="color: rgb(241, 41, 41); margin-left: 10px; font-size: 18px;">- {{item.name}}</span>
                                                    </div>
                                                </div>
                                             </div>
                                            </div>
    
                                    </div>

                                    </div>

                                

                                

                                

                                
                            </div>
                            
<br><hr>
                            


                            <div style="margin: 5px;"

                            >
                                <h2>Top Searches</h2>

                                <span *ngFor="let e of event2" >
                                    <a style="font-size: 18px; line-height: 30pt; text-align: justify;"
                                        href={{e.link}}>{{e.name}}</a> <span style="font-size: 20px; color: #c0c0c0;"> |
                                    </span>

                                </span>
                            </div>


                            <!-- <div class="col-xl-4 col-lg-6 col-md-12 col-sm-6 m-b30">
                                        <div class="item-box shop-item style2">
                                            <div class="item-img">
                                                <img src="../../assets/images/product/item2.jpg" alt="">
                                            </div>
                                            <div class="item-info text-center">
                                                <h4 class="item-title"><a href="itemdetails">White Forest</a></h4>
                                                <h5 class="price text-primary"><del>45</del><span>$40</span></h5>
                                                <div class="cart-btn"><a href="itemdetails" class="btn btnhover radius-xl"><i class="ti-shopping-cart"></i> Add To Cart</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-6 m-b30">
                                        <div class="item-box shop-item style2">
                                            <div class="item-img">
                                                <img src="../../assets/images/product/item3.jpg" alt="">
                                            </div>
                                            <div class="item-info text-center">
                                                <h4 class="item-title"><a href="itemdetails">Milky Blast</a></h4>
                                                <h5 class="price text-primary"><del>45</del><span>$40</span></h5>
                                                <div class="cart-btn"><a href="itemdetails" class="btn btnhover radius-xl"><i class="ti-shopping-cart"></i> Add To Cart</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-6 m-b30">
                                        <div class="item-box shop-item style2">
                                            <div class="item-img">
                                                <img src="../../assets/images/product/item4.jpg" alt="">
                                            </div>
                                            <div class="item-info text-center">
                                                <h4 class="item-title"><a href="itemdetails">Black Forest</a></h4>
                                                <h5 class="price text-primary"><del>45</del><span>$40</span></h5>
                                                <div class="cart-btn"><a href="itemdetails" class="btn btnhover radius-xl"><i class="ti-shopping-cart"></i> Add To Cart</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-6 m-b30">
                                        <div class="item-box shop-item style2">
                                            <div class="item-img">
                                                <img src="../../assets/images/product/item5.jpg" alt="">
                                            </div>
                                            <div class="item-info text-center">
                                                <h4 class="item-title"><a href="itemdetails">Pineapple Cake</a></h4>
                                                <h5 class="price text-primary"><del>45</del><span>$40</span></h5>
                                                <div class="cart-btn"><a href="itemdetails" class="btn btnhover radius-xl"><i class="ti-shopping-cart"></i> Add To Cart</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-6 m-b30">
                                        <div class="item-box shop-item style2">
                                            <div class="item-img">
                                                <img src="../../assets/images/product/item6.jpg" alt="">
                                            </div>
                                            <div class="item-info text-center">
                                                <h4 class="item-title"><a href="itemdetails">Choco Truffle</a></h4>
                                                <h5 class="price text-primary"><del>45</del><span>$40</span></h5>
                                                <div class="cart-btn"><a href="itemdetails" class="btn btnhover radius-xl"><i class="ti-shopping-cart"></i> Add To Cart</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-6 m-b30">
                                        <div class="item-box shop-item style2">
                                            <div class="item-img">
                                                <img src="../../assets/images/product/item7.jpg" alt="">
                                            </div>
                                            <div class="item-info text-center">
                                                <h4 class="item-title"><a href="itemdetails">Kurkure</a></h4>
                                                <h5 class="price text-primary"><del>45</del><span>$40</span></h5>
                                                <div class="cart-btn"><a href="itemdetails" class="btn btnhover radius-xl"><i class="ti-shopping-cart"></i> Add To Cart</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-6 m-b30">
                                        <div class="item-box shop-item style2">
                                            <div class="item-img">
                                                <img src="../../assets/images/product/item8.jpg" alt="">
                                            </div>
                                            <div class="item-info text-center">
                                                <h4 class="item-title"><a href="itemdetails">Burger</a></h4>
                                                <h5 class="price text-primary"><del>45</del><span>$40</span></h5>
                                                <div class="cart-btn"><a href="itemdetails" class="btn btnhover radius-xl"><i class="ti-shopping-cart"></i> Add To Cart</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-6 m-b30">
                                        <div class="item-box shop-item style2">
                                            <div class="item-img">
                                                <img src="../../assets/images/product/item1.jpg" alt="">
                                            </div>
                                            <div class="item-info text-center">
                                                <h4 class="item-title"><a href="itemdetails">Cup Cake</a></h4>
                                                <h5 class="price text-primary"><del>45</del><span>$40</span></h5>
                                                <div class="cart-btn"><a href="itemdetails" class="btn btnhover radius-xl"><i class="ti-shopping-cart"></i> Add To Cart</a></div>
                                            </div>
                                        </div>
                                    </div> -->
                        </div>




                    </div>


                </div>
            </div>
            <!-- Product END -->
        </div>

    </div>
    <!-- Content END-->
    <!-- Footer -->
    <!-- Footer END -->
  
    <!-- <button class="scroltop fa fa-chevron-up"></button> -->
  
    <!-- <button class="scroltop fa fa-chevron-up"></button> -->


    <!-- <div class="panel" *ngIf="isMobile">
       
            <div class="acod-content">
                <div class="widget_services ">

            <span 
            [class.active]="categoryId == cat.id"
            *ngFor="let cat of cat"
            (click)="getSpgProds(cat.id)" >
               
                <a style="line-height: 20pt; text-align: justify; " [class.active]="categoryId == cat.id"
                href="javascript:void(0);"
                    >{{cat.Name}}, </a> 
                    
            </span>

        </div>
    </div>

        </div> -->
   
    
    
</div>

<!-- JAVASCRIPT FILES ========================================= -->
<!-- <script src="js/jquery.min.js"></script>JQUERY.MIN JS
        <script src="plugins/bootstrap/js/popper.min.js"></script>BOOTSTRAP.MIN JS
        <script src="plugins/bootstrap/js/bootstrap.min.js"></script>BOOTSTRAP.MIN JS
        <script src="plugins/bootstrap-select/bootstrap-select.min.js"></script>FORM JS
        <script src="plugins/lightgallery/js/lightgallery-all.min.js"></script>LIGHTGALLERY
        <script src="plugins/rangeslider/rangeslider.js" ></script>Rangeslider
        <script src="js/custom.js"></script>CUSTOM FUCTIONS 
        <script src="plugins/rangeslider/rangeslider.js" ></script>Rangeslider
        </body>
        </html> -->