
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { LatLng, LatLngLiteral, MapsAPILoader, MouseEvent } from '@agm/core';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

// declare let google: any
@Component({
  selector: 'app-mapview',
  templateUrl: './mapview.component.html',
  styleUrls: ['./mapview.component.css']
})

export class MapviewComponent implements OnInit {

  title: string = 'AGM project';
  // latitude: number;
  // longitude: number;
  zoom: number;
  address_text: string;
  private geoCoder;
  marker: any
  map: any;
  addresses = [];
  currentaddressid;
  customerid = 0;
  cartitems;

  cid: number = 0;
  droute = [];



  @ViewChild('search')
  public searchElementRef: ElementRef;


  latitude = 12.9190519;
  longitude = 80.2300343;

  centerLatitude = this.latitude;
  centerLongitude = this.longitude;

  initialZoom = 20;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private auth: AuthService, private router: Router,
  ) {
    this.setCurrentLocation();;
  }

  ngOnInit() {
    //load Places Autocomplete
    this.cid = Number(localStorage.getItem("customer_id"));
    this.customerid = +localStorage.getItem("customer_id");
    this.cartitems = JSON.parse(localStorage.getItem("cart_items"));
    this.sa_rep = ""
    console.log("cid", this.cid)

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude);
        });
      });
      this.mapConfiguration();
      console.log("calling getdistance")
      this.getDistance(this.latitude, this.longitude)
    });



  }

  mapConfiguration() {
    let image = "https://res.cloudinary.com/swiggy/image/upload/v1493751154/Other_Pin_urgkbb.png"
    this.map = new google.maps.Map(document.getElementById('gmap'), {
      scrollwheel: false,
      scaleControl: false,
      draggable: false,
      zoom: 10,
      center: new google.maps.LatLng(51.75339, -0.210114),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      gestureHandling: 'greedy',
      mapTypeControl: false
    });
    this.marker = new google.maps.Marker({
      icon: image,
      zIndex: 10
    });

  }
  public centerChanged(coords: LatLngLiteral) {
    console.log("centerchanged")
    this.centerLatitude = coords.lat;
    this.centerLongitude = coords.lng;
    this.getDistance(coords.lat, coords.lng);
    //   this.latitude = coords.lat;
    // this.longitude = coords.lng;
    // this.getAddress(this.latitude, this.longitude);
  }

  public mapReady(map) {
    map.addListener("dragend", () => {
      console.log("dragend")
      console.log("A:", this.centerLatitude, this.centerLongitude)
      this.latitude = this.centerLatitude;
      this.longitude = this.centerLongitude;
      this.getAddress(this.latitude, this.longitude);
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }


  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
    this.getDistance(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log("getAddress results", results);
      console.log("getAddress status", status);
      //this.getDistance()
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address_text = results[0].formatted_address;
          console.log(this.address_text)
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  c_name;
  c_ph;
  c_address;
  c_lat;
  c_lon;
  c_id;

  sa_rep = "";

  saveaddress() {
    var address = {
      Name: this.c_name,
      Address: this.address_text + '. Alternate Ph-no.' + this.c_ph,
      Contact: this.latitude + '_' + this.longitude + '_1',
      Latitude: this.latitude,
      Longitude: this.longitude,
      CustomerId: localStorage.getItem("customer_id"),
      CompanyId: 3
    }

    console.log("address: ", address);

    console.log("this.c_id: ", this.c_id);
    console.log("address.CustomerId: ", address.CustomerId);

    if (address.CustomerId != null) {
      if (this.c_name != undefined && this.c_ph != undefined && this.address_text != '') {
        console.log("ook");
        this.sa_rep = ""

        this.auth.saveaddress({ Details: JSON.stringify(address) }).subscribe(data => {
          console.log("saved_address aath", data)


          this.addresses = JSON.parse(localStorage.getItem("addresses"));

          this.addresses.forEach(element => {
            if (element.iscurrentaddress) {
              localStorage.setItem("currentaddressid", element.Id.toString())
              this.applyaddress(element.iscurrentaddress);
            }
          });
          this.currentaddressid = +localStorage.getItem("currentaddressid");
          localStorage.setItem("addresses", JSON.stringify(data));
        })
        // this.verifytoggle = !this.verifytoggle


        localStorage.setItem("toogle", "2")
        document.location.href = window.location.origin + '/myaccount'
      }
      else {
        console.log("no data");
        this.sa_rep = "Plz full fill your Address"



      }
    }
    else {
      console.log("login plz");
      this.sa_rep = "Plz Login your Account!"
    }

  }



  applyaddress(address) {
    console.log(address)
    this.auth.setcurrentaddress(address.Id, this.customerid).subscribe(data => {
      var addresses: any = data;

      addresses.forEach(element => {
        if (element.iscurrentaddress) {
          localStorage.setItem("currentaddressid", element.Id.toString())
        }
      });

      this.currentaddressid = JSON.parse(localStorage.getItem("currentaddress"));
      localStorage.setItem("addresses", JSON.stringify(addresses))
      this.addresses = JSON.parse(localStorage.getItem("addresses"));
      var ccount;
      if (this.cartitems == undefined || this.cartitems == "") {
        console.log("my Cart Value :", 0)
        ccount = 0
      }
      else {
        console.log("my Cart Value :", this.cartitems.length)
        ccount = this.cartitems.length;
        if (ccount > 0) {
          document.location.href = document.location.origin + '/checkout'
        }
        else {
          console.log("my Cartcount o :", ccount)
        }
      }


    })
  }





  dist_rep: any = [];


  public getDistance(x, y) {
    console.log("getDistance works")

    const origin =
      [{ lat: 12.925334415932213, lng: 80.23078237620904, area: 'kpkm', id: 1 },
      { lat: 12.902750472343127, lng: 80.22823061478074, area: 'sholi', id: 2 },
      { lat: 12.89827323679782, lng: 80.24741986072152, area: 'ecr', id: 3 }];
    const destination = { lat: x, lng: y };
    const matrix = new google.maps.DistanceMatrixService();

    this.dist_rep = []

    origin.forEach((pq, i) => {
      return new Promise((resolve, reject) => {
        matrix.getDistanceMatrix({
          origins: [origin[i]],
          destinations: [destination],
          travelMode: google.maps.TravelMode.DRIVING,
          "unitSystem": google.maps.UnitSystem.METRIC,
          "avoidHighways": false,
          "avoidTolls": false
        }, function (response, status) {
          if (status === 'OK') {
            let res: any = response;
            res.store_details = origin[i];
            resolve(res);
            console.log("Km:", response.rows[0].elements[0].distance.text + " ___ " + "time:",
              response.rows[0].elements[0].duration.text + " | ", response.destinationAddresses[0]);
          } else {
            reject(response);
            console.log("my error ", response)
          }
        });
      }
      ).then(val => {
        console.log(val)

        this.dist_rep = [...this.dist_rep, val]
        console.log("val", this.dist_rep)
        let minkm;
        let minstoreid;
        this.dist_rep.forEach(el => {
          if (minkm === undefined || parseInt(el.rows[0].elements[0].distance.text) < minkm) {
            minkm = parseInt(el.rows[0].elements[0].distance.text);
            minstoreid = el.store_details.id
          }
        });
        console.log("lowest km", minstoreid, minkm);
        localStorage.setItem("n_sid", minstoreid);

      }).catch(err => {
        console.log(err)
      })
    })
  }

}

