import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footerb',
  templateUrl: './footerb.component.html',
  styleUrls: ['./footerb.component.css']
})
export class FooterbComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
