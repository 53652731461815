<!-- <!DOCTYPE html>
<html lang="en">
<head>
	<meta charset="utf-8">
	<meta http-equiv="X-UA-Compatible" content="IE=edge">
	<meta name="keywords" content="" />
	<meta name="author" content="" />
	<meta name="robots" content="" />
	<meta name="description" content="RestroKing - Cakery & Bakery HTML5 Template"/>
	<meta property="og:title" content="RestroKing - Cakery & Bakery HTML5 Template"/>
	<meta property="og:description" content="RestroKing - Cakery & Bakery HTML5 Template"/>
	<meta property="og:image" content="http://restroking.dexignzone.com/xhtml/social-image.png" />
	<meta name="format-detection" content="telephone=no"> -->

<!-- FAVICONS ICON -->
<!-- <link rel="icon" href="../../assets/images/favicon.ico" type="image/x-icon" />
	<link rel="shortcut icon" type="image/x-icon" href="../../assets/images/favicon.png" /> -->

<!-- PAGE TITLE HERE -->
<!-- <title>RestroKing - Cakery & Bakery HTML5 Template</title> -->

<!-- MOBILE SPECIFIC -->
<!-- <meta name="viewport" content="width=device-width, initial-scale=1"> -->

<!--[if lt IE 9]>
	<script src="js/html5shiv.min.js"></script>
	<script src="js/respond.min.js"></script>
	<![endif]-->

<!-- STYLESHEETS -->
<!-- <link rel="stylesheet" type="text/css" href="css/plugins.css">
	<link rel="stylesheet" type="text/css" href="css/style.css">
	<link rel="stylesheet" type="text/css" href="css/templete.css">
	<link class="skin" rel="stylesheet" type="text/css" href="css/skin/skin-1.css">
	
</head>
<body id="bg" class="leftsidebar"> -->
<div class="page-wraper">
    <div hidden id="loading-area">
        <div class="ball-pulse-rise">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <!-- Content -->
    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
         id="main-img"

            >

            <!-- style="background-image:url(../../assets/images/cartbanner.jpg); -->
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <!-- <h1 class="text-white">Cart</h1> -->
                    <!-- Breadcrumb row -->
                    <!-- <div class="breadcrumb-row">
                        <ul class="list-inline">
                            <li><a href="">Home</a></li>
                            <li>Shop Cart</li>
                        </ul>
                    </div> -->
                    <!-- Breadcrumb row END -->
                </div>
            </div>
        </div>
        <!-- inner page banner END -->
        <!-- contact area -->
        <div *ngIf="!cartitems || cartitems.length == 0" class="section-full content-inner">
            <div class="container text-center">
                <h1 class="text-red">Your Cart is Empty!</h1>
            </div>
        </div>
        
        <div *ngIf="cartitems.length > 0" class="section-full content-inner">
            <!-- Product -->
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div >
                            <table class="table check-tbl">
                                <thead>
                                    <tr>
                                        <!-- <th>Product</th> -->
                                        <th>Product Name</th>
                                        <!-- <th>Unit Price</th> -->
                                        <!-- <th>Quantity</th> -->
                                        <th>Price</th>
                                        <!-- <th>Msg</th> -->
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let product of cartitems; let i = index " class="alert">
                                        <!-- <td class="product-item-img" style="width: 25px;">
                                            <img *ngIf="product.ImgUrl" src="{{product.ImgUrl}}" alt="">
                                            <img *ngIf="!product.ImgUrl"
                                                src="https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png"
                                                alt="">
                                           
                                        </td> -->

                                        <td class="product-item-name" style="width: 70%;">
                                            <!-- <img src="{{product.ImgUrl}}" alt=""> -->
                                            <strong> {{product.Name}}</strong> <br />

                                            <div style="margin-left: 10px;" class="left">

                                                <div>
                                                    <small>* 
                                                        <b>QTY:</b>
                                                        {{product.Quantity}} Kg
                                                        <!-- ₹{{(product.quantityrate).toFixed(2)}} -->
                                                    </small>

                                                    <!-- BGST <small>* 
                                                        <b>QTY:</b> 
                                                        {{product.Quantity}} Kg ({{product.pqty}} Kg + {{product.freeQty}} Kg Free): 
                                                        ₹{{(product.quantityrate).toFixed(2)}}
                                                    </small> -->
                                                    
                                                    <!-- <img src="https://cdn.iconscout.com/icon/free/png-256/edit-1767972-1502308.png"
                                                        alt="Edit Cake Message!" data-toggle="modal"
                                                        data-target="#editoptionsModal"
                                                        (click)="editOptions(product.Id,i,product.Quantity,product.SelectKG_Id)"
                                                        style="width: 18px; height: 18px;"> -->
                                                </div>

                                                <div >
                                                    <small *ngFor="let variant of product.variants">
                                                        * <b>FLAVOUR: </b> {{variant.Name}} {{variant.Price>0 ?
                                                        '₹'+(variant.Price*product.pqty).toFixed(2): '' }}
                                                   

                                                    <!-- <img src="https://cdn.iconscout.com/icon/free/png-256/edit-1767972-1502308.png"
                                                        alt="Edit Cake Message!" data-toggle="modal"
                                                        data-target="#editoptionvarientModal"
                                                        (click)="editOptions_varient(product.Id,i,product.SelectVarient_Id)"
                                                        style="width: 18px; height: 18px;"> -->

                                                    </small>
                                                </div>

                                                <div *ngIf="product.addons!=undefined">
                                                    <small *ngFor="let addon of product.addons">
                                                        * <b>ADDONS: </b>Eggless
                                                        ₹{{(addon.Price*product.pqty).toFixed(2)}}
                                                    

                                                    <!-- <img src="https://cdn.iconscout.com/icon/free/png-256/edit-1767972-1502308.png"
                                                        alt="Edit Cake Message!" data-toggle="modal"
                                                        data-target="#editoptionaddonsModal"
                                                        (click)="editOptions_addons1(product,i)"
                                                        style="width: 18px; height: 18px;"> -->

                                                    </small>

                                                </div>

                                                <div>
                                                    <small >
                                                        * <b>CAKE MSG: </b> {{product.Message}}
                                                    </small>
                                                    <img (click)="msgedit(product.Message,i)"
                                                        src="https://cdn.iconscout.com/icon/free/png-256/edit-1767972-1502308.png"
                                                        alt="Edit Cake Message!" style="width: 18px; height: 18px;">
                                                </div>

                                                <div>
                                                    <small *ngIf="product.eggless!=0">
                                                        * <b>Eggless Added: </b> ₹{{product.eggless}}
                                                    </small>

                                                </div>

                                                <div>
                                                    <small style="font-style: italic;">
                                                        Preparing Time: {{product.PrepTime}} hours</small>
                                                </div>

                                                <!-- <div>
                                                    <small *ngFor="let variant of product.variants">
                                                        * <b>CAKE MSG: </b> {{product.Message}}
                                                    </small>
                                                    <img (click)="msgedit(product.Message,i)"
                                                        src="https://cdn.iconscout.com/icon/free/png-256/edit-1767972-1502308.png"
                                                        alt="Edit Cake Message!" style="width: 18px; height: 18px;">
                                                </div> -->


                                            </div>

                                            <!-- <div style="margin-left: 10px;">

                                            <span style="font-size: small;">
                                                QTY: {{product.quantity!=''? product.Quantity+" Kg." :
                                                    product.Quantity+" No."}}
                                            </span>

                                            <br />

                                            <div>
                                                <span style="font-size: small;" *ngIf="product.Message!='' && product.Message!=null">
                                                    {{"CAKE MSG: "+product.message}}
                                                </span>
    
                                                <img 
                                                (click)="msgedit(product.Message,i)"
                                                src="https://cdn.iconscout.com/icon/free/png-256/edit-1767972-1502308.png" 
                                                alt="Edit Cake Message!" 
                                                style="width: 18px; height: 18px;">
                                               </div>
                                            
                                         

                                            <span *ngFor="let variant of product.variants" >
                                                FLAVOUR: {{variant.Name}} Rs.{{variant.Price*product.pqty}}
                                            </span> 

                                            <br />
                                            
                                            <div *ngIf="product.addons.length">
                                                ADDONS:

                                                <span *ngFor="let addon of product.addons">
                                                    + {{addon.Name}} Rs.{{addon.Price*product.pqty}}<br>
                                                </span>
                                            </div>

                                          


                                          

                                          

                                        </div> -->

                                            <!-- <span style="font-size: small;" *ngIf="product.message!=''">
                                                <i>   {{"Qty: "+product.quantity}}</i> 
                                               </span> -->
                                        </td>
                                        <!-- <td>
                                           <input type="number"min="1" value="1"[(ngModel)]="Quantity">
                                            
                                        </td> -->

                                        <!-- <td class="product-item-price">$28.00</td> -->
                                        <!-- <td class="product-item-quantity">
                                                    <div class="quantity btn-quantity max-w50">
                                                        <input id="demo_vertical2" type="text" value="1" name="demo_vertical2" >
                                                    </div>
                                                </td> -->

                                        <!-- <td class="product-item-totle">₹ {{product.TotalPrice}}</td> -->
                                        <!-- <td class="product-item-totle">₹ {{product.prate.toFixed(2)}}</td> -->


                                        <!-- bgst -->
                                        <!-- <td class="product-item-totle">₹
                                           {{(product.quantityrate+product.optionrate1+product.optionrate2).toFixed(0)}}
                                        </td> -->

                                        <!-- agst -->
                                       
                                        <!-- <td class="product-item-totle">₹
                                            {{(product.quantityrate+product.eggless).toFixed(2)}}
                                        </td> -->
                                        <td class="product-item-totle">₹
                                            {{(product.TotalAmount).toFixed(2)}}
                                        </td>



                                        <!-- <td class="product-item-totle">₹
                                            {{(product.Price).toFixed(0)}}
                                        </td> -->




                                        <!-- <td class="product-item-totle">{{product.Msg}}</td> -->


                                        <!-- <td class="product-item-close">
                                            <a href="javascript:void(0);" 
                                            data-dismiss="alert" 
                                            (click)="removeTask(i)"
                                                aria-label="close" class="ti-close"></a>

                                        </td> -->

                                        <td class="product-item-close">
                                            <a href="javascript:void(0);" (click)="removeTask(i)" aria-label="close"
                                                class="ti-close"></a>
                                        </td>

                                        <!-- <td >
                                                <a href="javascript:void(0);" class="comment-reply-link" 
                                                data-toggle="modal"
                                                data-target="#alertModal" (click)="removeTask(i)"> Cancel </a> |

                                                <a href="javascript:void(0);" class="comment-reply-link" 
                                                data-toggle="modal"
                                                data-target="#editoptionaddonsModal"
                                                (click)="editOptions_addons1(product)"
                                               
                                                
                                                > Edit </a> 
                                        </td> -->


                                    </tr>


                                    <!--BGST <div>
                                        <a href="javascript:void(0);" (click)="getaccess()" data-toggle="modal"
                                            data-target="#editaccessoriesModal" class="comment-reply-link">+Add
                                            Accessories</a>

                                    </div> -->





                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- <div class="dlab-divider bg-gray-dark text-gray-dark icon-center"><i
                    class="fa fa-circle bg-white text-gray-dark"></i></div> -->
                    

                <!-- Accessories Table -->

                <div class="row">
                    <div class="col-lg-6 col-md-6 m-b30">
                        <form class="shop-form">
                            <!-- <h3>Accessories</h3> -->

                            <!-- <a href="javascript:void(0);" (click)="getaccess()" data-toggle="modal" style="margin-bottom: 10px;"
            data-target="#editaccessoriesModal" class="comment-reply-link">+Add Accessories</a> -->

                            <div class="row" *ngIf="accessories.length">
                                <div class="col-lg-12">
                                    <div class="table-responsive">
                                        <table class="table check-tbl">
                                            <thead>
                                                <tr>
                                                    <th>Accessories Name</th>
                                                    <th style="text-align: center;">Qty</th>
                                                    <th>Price</th>
                                                    <th>Close</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let acc of accessories; let i = index">
                                                    <td>{{acc.Name}}</td>
                                                    <td style="width: 30%;">
                                                        <div style="text-align: center;">
                                                            <button href="javascript:void(0);"
                                                                [disabled]="acc.Quantity<=1"
                                                                style="width: 23px; height: 23px; border-radius: 7px;justify-content: center;"
                                                                (click)="acc.Quantity = acc.Quantity-1;saveAcc(acc, i)">-</button>
                                                            <span style="margin: 5px;">{{acc.Quantity}}</span>
                                                            <button href="javascript:void(0);"
                                                                style="width: 23px; height: 23px; border-radius: 7px; justify-content: center;"
                                                                (click)="acc.Quantity = acc.Quantity+1;saveAcc(acc, i)">+</button>
                                                        </div>

                                                    </td>
                                                    <td>₹ {{(acc.Price * acc.Quantity).toFixed(0)}}</td>
                                                    

                                                    <td class="product-item-close">
                                                        <a href="javascript:void(0);" (click)="removeAccess(i)"
                                                            aria-label="close" class="ti-close"></a>
                                                    </td>

                                                </tr>

                                                <!-- <tr>
                                                            <td>Snow Spray</td>
                                                            <td  style="text-align: center;">
                                                                <div>
                                                                    <button  href="javascript:void(0);"  (click)='minusAccQty()'
                                                                    style="width: 23px; height: 23px; border-radius: 7px;justify-content: center;">-</button>
                                                                    <span style="margin: 5px;">{{this.AccQty}}</span>
                                                                    <button  href="javascript:void(0);" (click)='addAccQty()'
                                                                    style="width: 23px; height: 23px; border-radius: 7px; justify-content: center;" >+</button>
                                                                </div>
                                                            </td>
                                                            <td>₹ {{acc.Price}}</td>

                                                            <td class="product-item-close">
                                                                <a href="javascript:void(0);" (click)="removeTask(i)" aria-label="close"
                                                                    class="ti-close"></a>
                                                            </td>
                                                        </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                  

                    <div class="col-lg-6 col-md-6">
                        

                        <!-- <h3 style="font-size: 25px;"><b>Total</b></h3> -->
                       
                        <table class="table-bordered check-tbl">
                            <tbody>
                                <!-- <tr>
                                            <td>Order Subtotal</td>
                                            <td>$125.96</td>
                                        </tr> -->
                                <tr>
                                    <td>Shipping Charge</td>
                                    <td>₹ 0.00</td>
                                </tr>
                                <tr>
                                    <td>SubTotal</td>
                                    <td class="product-price">₹ {{(getTotal()).toFixed(2)}}</td>
                                </tr>
                                <!-- <tr>
                                    <td>Tax</td>
                                    <td class="product-price">₹ {{tax}}</td>
                                </tr> -->
                                <tr>
                                    <td>Tax 18%</td>
                                    <td class="product-price">₹ {{settaxes().toFixed(2)}}</td>
                                </tr>
                                <!-- <tr>
                                    <td><strong>Total</strong></td>
                                    <td><strong>₹ {{getTotal() + tax}}</strong></td>
                                </tr> -->
                                <tr>
                                    <td><strong style="font-size: 20px;">Total</strong></td>
                                    <td><strong style="font-size: 20px;">₹ {{(getTotal() + settaxes()).toFixed(2)}}</strong></td>
                                </tr>


                            </tbody>
                        </table>
                        <div class="form-group">
                            <button *ngIf="!isloggedin()" href="javascript:void(0);" class="btn btnhover btn-danger"
                                data-toggle="modal" data-target="#exampleModal">Proceed to
                                Checkout</button>
                            <button *ngIf="isloggedin()" class="btn btnhover btn-danger" (click)="ordercheckout()" type="button">
                                Proceed to
                                Checkout</button>
                        </div>

                        <!-- <button type="button" mdbBtn color="primary" class="waves-light"
    mdbPopover="And here some amazing content. It's very engaging. Right?"
    placement="right"
    mdbPopoverHeader="Popover on right" mdbWavesEffect>
    Popover on right
</button> -->
                    </div>
                </div>
            </div>
            <!-- Product END -->
        </div>

    </div>
    <!-- Content END-->
    <!-- Footer -->


    <!-- Footer END -->
    <button class="scroltop fa fa-chevron-up"></button>
</div>


<div class="modal fade subscribe-box" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form *ngIf="verifytoggle" class="dzSubscribe modal-content w-100 mb-10">
            <div class="modal-header">

                <h5 class="modal-title" id="exampleModalLabel">Enter Emailaddress to Continue</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>

                </button>
            </div>

            <div class="modal-body">
                <div class="form-group">

                    <input class="form-control" name="dzNumber" #email id="email" required="required"
                        placeholder="Enter Email" type="email">
                    <div class="dzSubscribeMsg"></div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btnhover shadow" (click)="customerlogin(email.value)">Submit</button>
            </div>
        </form>

        <form *ngIf="!verifytoggle" class="dzSubscribe modal-content otp-container w-100 h-10" method="post">
            <div class="modal-header">

                <h5 class="modal-title" id="exampleModalLabel">Enter OTP</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mb-10">
                <label style="padding-right: 10px; padding-top: 15px; font-size: 25px;">G-</label>
                <input id="codeBox1" #codeBox1 type="text" maxlength="1" onkeyup="onKeyUpEvent(1, event)"
                    onfocus="onFocusEvent(1)">
                <input id="codeBox2" #codeBox2 type="text" maxlength="1" onkeyup="onKeyUpEvent(2, event)"
                    onfocus="onFocusEvent(2)">
                <input id="codeBox3" #codeBox3 type="text" maxlength="1" onkeyup="onKeyUpEvent(3, event)"
                    onfocus="onFocusEvent(3)">
                <input id="codeBox4" #codeBox4 type="text" maxlength="1" onkeyup="onKeyUpEvent(4, event)"
                    onfocus="onFocusEvent(4)">
            </div>
            <div class="modal-footer">

                <button type="submit"
                    (click)="otpverification(codeBox1.value+codeBox2.value+codeBox3.value+codeBox4.value)"
                    ng-model="otpverification(codeBox1.value+codeBox2.value+codeBox3.value+codeBox4.value)"
                    ng-init="myVar = true" class="btn btnhover shadow" data-dismiss="modal">Verify</button>
            </div>
            <a class="mt-20" href="javascript:void(0);" *ngIf="!isloggedin()" data-toggle="modal"
                data-target="#exampleModal" style="margin-top: 20px;">Change Email Id</a>
        </form>
    </div>
</div>


<!-- ALERT3 Qty -->

<div class="modal fade subscribe-box" id="editoptionsModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">

        <div class="dzSubscribe modal-content w-100 mb-10">

            <!-- Product -->

            <div class="dlab-post-title">
                <h4 class="post-title">{{alqtyname}}</h4>

                <div class="dlab-divider bg-gray tb15">
                    <i class="icon-dot c-square"></i>
                </div>
            </div>

            <div class="relative">
                <h2 class="m-tb10 text-red">₹ {{kgprice}} </h2>
                <div class="shop-item-rating">
                    <span class="rating-bx">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i>
                        <i class="fa fa-star-o"></i>
                    </span>

                </div>
            </div>
            <br />


            <!-- QTY -->
            <div>
                <h6>*Select Weight</h6>
                <div class="btn-group product-item-size " data-toggle="buttons" style="flex-wrap: wrap;">
                    <label *ngFor="let country of predefinedquantity" class="btn" name="city"
                        [class.active]="country.selected == true" (click)="setqty(country)">
                        <input type="radio" value="{{country.Id}}" [checked]="country.selected">
                        {{country.QuantityText}}
                    </label>
                </div>
            </div>

            <!-- Options -->

            <!-- <div class="row">
                <div  *ngIf="variantgroups.length > 0">
                   <div *ngFor="let group of variantgroups">
                       <h6>*{{group.OptionGroup.Name}}</h6>
                       <div class="btn-group product-item-size" data-toggle="buttons"
                           style="flex-wrap: wrap;">
                           <label *ngFor="let option of getoptionsbyid(group.OptionGroupId)"
                               (click)="setvariant(group.OptionGroupId, option.Id)" class="btn"
                               [class.active]="option.selected == true">
                               <input name="options" id="option7" type="radio"
                                   [(ngModel)]="option.selected"> {{option.Name}}
                                  
                               <a *ngIf="option.Price!=0"> + ₹ {{(this.qty2*option.Price).toFixed(0)}}</a>
                           </label>

                       </div>
                   </div>
               </div>

               <div *ngFor="let group of addongroups" class="m-b30 col-md-12 col-sm-12">
                <h6>{{group.OptionGroup.Name}} (Optional) </h6>
                <div class="btn-group  btn-group-toggle product-item-size add-onns"
                    data-toggle="buttons">
                    <label *ngFor="let option of getoptionsbyid(group.OptionGroupId)"
                        class="btn " (click)="option.selected = !option.selected">
                        <input type="checkbox">Make Eggless
                        <a *ngIf="option.Price!=0">+ ₹ {{(this.qty2*option.Price).toFixed(0)}}</a>
                    </label>
                </div>
            </div>

            </div> -->



            <!-- <div class="form-group" style="align-self: flex-end; margin-top: 20px;">
                <button class="btn btnhover" (click)="EditOptions()" type="button" data-dismiss="modal">
                    SAVE</button>
            </div> -->

            <div class="form-group" style="align-self: flex-end; margin-top: 20px;">
                <button class="btn btnhover" (click)="EditOptions()" type="button" data-dismiss="modal">
                    SAVE</button>
            </div>


        </div>

    </div>
</div>

<!-- ALERT4 Options -->

<div class="modal fade subscribe-box" id="editoptionvarientModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">

        <div class="dzSubscribe modal-content w-100 mb-10">

            <!-- Product -->

            <div class="dlab-post-title">
                <h4 class="post-title">{{alqtyname}}</h4>

                <div class="dlab-divider bg-gray tb15">
                    <i class="icon-dot c-square"></i>
                </div>
            </div>

            <div class="relative">
                <h2 class="m-tb10 text-red">₹ {{this.var_price}}</h2>
                <div class="shop-item-rating">
                    <span class="rating-bx">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i>
                        <i class="fa fa-star-o"></i>
                    </span>

                </div>
            </div>
            <br />


            <!-- Options -->

            <div class="row">
                <div *ngIf="variantgroups.length > 0">
                    <div *ngFor="let group of variantgroups">
                        <h6>*Select {{group.OptionGroup.Name}}</h6>
                        <div class="btn-group product-item-size" data-toggle="buttons" style="flex-wrap: wrap;">
                            <label *ngFor="let option of getoptionsbyid(group.OptionGroupId)"
                                (click)="setvariant(group.OptionGroupId, option.Id,option.Price)" class="btn"
                                [class.active]="option.selected == true">
                                <input name="options" id="option7" type="radio" [(ngModel)]="option.selected">
                                {{option.Name}}

                                <!-- <a *ngIf="option.Price!=0"> + ₹ {{(this.qty2*option.Price).toFixed(0)}}</a> -->
                            </label>

                        </div>
                    </div>
                </div>

            </div>





            <div class="form-group" style="align-self: flex-end; margin-top: 20px;">
                <button class="btn btnhover" (click)="EditVarient()" type="button" data-dismiss="modal">
                    SAVE</button>
            </div>


        </div>

    </div>
</div>

<!-- ALERT5 Options -->

<div class="modal fade subscribe-box" id="editoptionaddonsModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">

        <div class="dzSubscribe modal-content w-100 mb-10">

            <!-- Product -->

            <div class="dlab-post-title">
                <h4 class="post-title">{{alqtyname}}</h4>

                <div class="dlab-divider bg-gray tb15">
                    <i class="icon-dot c-square"></i>
                </div>
            </div>

            <div class="relative">
                <h2 class="m-tb10 text-red">₹ {{this.Addon_price}}</h2>
                <div class="shop-item-rating">
                    <span class="rating-bx">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i>
                        <i class="fa fa-star-o"></i>
                    </span>

                </div>
            </div>
            <br />

            <!-- Options -->




            <div *ngFor="let group of addongroups" class="m-b30 col-md-12 col-sm-12">
                <h6>{{group.OptionGroup.Name}} (Optional) </h6>
                <div class="btn-group  btn-group-toggle product-item-size add-onns" data-toggle="buttons">

                    <label *ngFor="let option of getoptionsbyid(group.OptionGroupId)" class="btn "
                        [class.active]="option.selected" (click)="option.selected = !option.selected">
                        <!-- <input type="checkbox">{{option.Name}} {{option.selected}} -->
                        <input type="checkbox" name="options" id="option7">Make Eggless
                    </label>
                </div>
            </div>








            <div class="form-group" style="align-self: flex-end; margin-top: 20px;">
                <button class="btn btnhover" (click)="EditOption()" type="button" data-dismiss="modal">
                    SAVE</button>
            </div>


        </div>

    </div>
</div>



<!-- ALERT5 Accessories -->

<div class="modal fade subscribe-box" id="editaccessoriesModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">

    <div class="modal-dialog" role="document">
        <!-- <button type="button" 
        class="close" data-dismiss="modal" aria-label="Close" style="background-color: red;">
            <span aria-hidden="true">&times;</span>

          

        </button> -->

        <div class="dzSubscribe modal-content w-100 mb-10">

            <h1> My Accessories</h1>

            <!-- <button style="position: absolute; top:20px; right:20px" type="button" 
            data-dismiss="modal" aria-label="Close" class="close">
            <span aria-hidden="true">&times;</span>
        </button> -->

            <a href="javascript:void(0);" aria-label="close" class="product-item-close" data-dismiss="modal" class="ti-close"
                style="position:absolute; top:20px; right:20px" type="button"></a>
  <!-- <td class="product-item-close">
                <a href="javascript:void(0);" (click)="removeTask(i)" aria-label="close"
                    class="ti-close"></a>
            </td> -->

            <!-- Options -->

            <div class="btn-group product-item-size " 
            data-toggle="buttons" 
            style="flex-wrap: wrap;">
                <!-- <label 
            *ngFor="let item of Accessories"
            class="btn" (click)="addAccessories(item)" 
            [class.active]="item.select_value"
            >
                <span *ngIf="item.select_value==true">on</span>
                <input type="checkbox" [(ngModel)]="item.select_value"
                >
                {{item.Name
                }} {{item.select_value}}
            </label> -->

                <button 
                *ngFor="let item of Accessories" style="margin: 5px; padding: 5px; 
                background-color: #fff; border-radius: 10px;"
                (click)="addAccessories(item)">
                        <!-- <span *ngIf="item.select_value==true">on</span> -->
                        <img  *ngIf="item.select_value==true"
                        src="https://icon-library.com/images/check-icon-png/check-icon-png-8.jpg"
                       style="width: 18px; height: 18px;">
                        <input type="checkbox" [(ngModel)]="item.select_value">
                        {{item.Name}}</button>
            </div>
            <!-- <div class="m-b30 col-md-12 col-sm-12">
                <div class="btn-group  btn-group-toggle product-item-size add-onns" data-toggle="buttons"
                    style="flex-wrap: wrap;">

                    <label *ngFor="let item of Accessories" class="btn">
                        <input type="checkbox" name="options" id="option7">{{item.Name}}
                    </label>
                </div>
            </div> -->

            <!-- <div class="form-group" style="align-self: flex-end; margin-top: 20px;">
                <button class="btn btnhover" (click)="saveAccesories()" type="button" data-dismiss="modal">
                    SAVE</button>
            </div> -->
        </div>
    </div>
</div>

