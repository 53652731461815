<!-- <!DOCTYPE html>
<html lang="en">
<head>
	<meta charset="utf-8">
	<meta http-equiv="X-UA-Compatible" content="IE=edge">
	<meta name="keywords" content="" />
	<meta name="author" content="" />
	<meta name="robots" content="" />
	<meta name="description" content="RestroKing - Cakery & Bakery HTML5 Template"/>
	<meta property="og:title" content="RestroKing - Cakery & Bakery HTML5 Template"/>
	<meta property="og:description" content="RestroKing - Cakery & Bakery HTML5 Template"/>
	<meta property="og:image" content="http://restroking.dexignzone.com/xhtml/social-image.png" />
	<meta name="format-detection" content="telephone=no"> -->

<!-- FAVICONS ICON -->
<!-- <link rel="icon" href="../../assets/images/favicon.ico" type="image/x-icon" />
	<link rel="shortcut icon" type="image/x-icon" href="../../assets/images/favicon.png" /> -->

<!-- PAGE TITLE HERE -->
<!-- <title>RestroKing - Cakery & Bakery HTML5 Template</title> -->

<!-- MOBILE SPECIFIC -->
<!-- <meta name="viewport" content="width=device-width, initial-scale=1"> -->

<!--[if lt IE 9]>
	<script src="js/html5shiv.min.js"></script>
	<script src="js/respond.min.js"></script>
	<![endif]-->

<!-- STYLESHEETS -->
<!-- <link rel="stylesheet" type="text/css" href="css/plugins.css">
	<link rel="stylesheet" type="text/css" href="css/style.css">
	<link rel="stylesheet" type="text/css" href="css/templete.css">
	<link class="skin" rel="stylesheet" type="text/css" href="css/skin/skin-1.css"> -->

<!-- </head>
<body id="bg" class="leftsidebar"> -->

<div class="page-wraper">
    <div hidden id="loading-area">
        <div class="ball-pulse-rise">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt" id="main-img">

            <!-- style="background-image:url(../../assets/images/cartbanner.jpg);" -->
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <!-- <h1 class="text-white">Checkout</h1> -->
                    <!-- Breadcrumb row -->
                    <!-- <div class="breadcrumb-row">
                            <ul class="list-inline">
                                <li><a href="">Home</a></li>
                                <li>Checkout</li>
                            </ul>
                        </div> -->
                    <!-- Breadcrumb row END -->
                </div>
            </div>
        </div>
        <!-- inner page banner END -->
        <!-- contact area -->
        <div class="section-full content-inner">
            <!-- Product -->
            <div class="container">
                <div class="shop-form">
                    <div class="row">

                        <div class="col-lg-6 col-md-12">
                            <h3><b>Billing & Shipping Address</b></h3>
                            <div>
                                <!-- <a href="javascript:void(0);" class="btn btnhover text-center " data-toggle="modal"
                                        data-target="#AddressModel"  >Add Address</a> -->

                                <!-- <a href="javascript:void(0);" class="btn btnhover text-center " data-toggle="modal"
                                    (click)="changeaddress()">{{addresses?.length > 0?'Change Address':"Add
                                    Address"}}</a> -->

                                <a href="javascript:void(0);" class="btn btnhover text-center btn-danger"
                                    data-toggle="modal" (click)="changeaddress()">{{currentaddressid!=0?'Change
                                    Address':"Add Delivery Address"}}</a>
                            </div>
                            <br>

                            <!-- <span>Add Delivery Address</span> -->

                            <i *ngIf="CIDCheckout==null" class="text-danger">Add Delivery Address!</i>

                            <!-- <div  class="clearfix" >
                                <ol class="comment-list" >
                                <div class="comment-body">
                                    <div class="comment-author vcard">
                                        <cite class="fn">{{currentaddress.Name}}</cite>
                                    </div>
                                    {{currentaddress.Address}}
                                    </div>
                                </ol>
                            </div> -->


                            <!-- <div class="clearfix" *ngIf="addresses?.length > 0" 
                                >
                               
                                <ol class="comment-list" >
                                    <li class="comment" >
                                        <div class="comment-body">
                                            <div class="comment-author vcard">
                                                <cite class="fn">{{currentaddress.Name}}</cite>
                                            </div>
                                            <p>{{currentaddress.Address}}</p>
                                           
                                        </div>
                                    </li>
                                </ol>
                            </div> -->

                            <div class="clearfix" *ngIf="currentaddressid!=0">

                                <ol class="comment-list">
                                    <li class="comment">
                                        <div class="comment-body">
                                            <div class="comment-author vcard">
                                                <cite class="fn">{{currentaddress.Name}}</cite>
                                            </div>
                                            <p>{{currentaddress.Address}}</p>

                                        </div>
                                    </li>
                                </ol>
                            </div>



                            <!-- <h3>Date Section 1</h3>
    
                                <input class="form-control m-input pl-6" type="date" 
                                [min]="todayDate"formControlName="dateValidity"> -->

                            <!-- <h3>Select Delivery Date & Time</h3>


                            <div class="dlab-divider bg-gray-dark text-gray-dark icon-center"><i
                                class="fa fa-circle bg-white text-gray-dark"></i></div>
                        <div class="row">
                            <div class="col-lg-6">
                                <h3>Date</h3>
                                <div class="container">
                                    <input type="date" (change)="validateTime()" min="{{minDate}}" [(ngModel)]="date">
                                    <br/>
                                    <p *ngIf="this.date!='' && this.date!=null">If you choose today. please choose delivery time after 2 hours from right time
                                    </p>
                                    </div>
                              
                            </div>
                            <div class="col-lg-6">
                               
                                    <h3>Time</h3>
                                    <div class="container">
                                        <input type="time" (change)="validateTime()" [(ngModel)]="time" min="10:00"
                                        max="22:00">
                                        <br/>
                                       
                                        <i *ngIf="date&&!timeValid" class="text-danger">Sorry!!! We can't deliver cake order
                                            between 10.00 P.M to 10:00 A.M</i>
                                        </div>
                                    
        
                            </div>
                        </div> -->




                            <!-- <div class="container" >
                              
                                <div class="container">
                                    <input type="date" (change)="validateTime()" min="{{minDate}}" [(ngModel)]="date">
                                 
                                </div>
                                <br />
                                <div class="container" *ngIf="this.date!='' && this.date!=null">
                                    <p>If you choose today. please choose delivery time after 2 hours from right time
                                    </p>
                                    <input type="time" (change)="validateTime()" [(ngModel)]="time" min="10:00"
                                        max="22:00">
                                  
                                    <br>
                                    <i *ngIf="date&&!timeValid" class="text-danger">Sorry!!! We can't deliver cake order
                                        between 10.00 P.M to 10:00 A.M</i>
                                </div>
                               
                            </div> -->



                            <!-- <pre>Selected time: {{time | json}}</pre> -->



                            <!-- <div class="form-group">
                                            <select>
                                                <option value="">Åland Islands</option>
                                                <option value="">Afghanistan</option>
                                                <option value="">Albania</option>
                                                <option value="">Algeria</option>
                                                <option value="">Andorra</option>
                                                <option value="">Angola</option>
                                                <option value="">Anguilla</option>
                                                <option value="">Antarctica</option>
                                                <option value="">Antigua and Barbuda</option>
                                                <option value="">Argentina</option>
                                                <option value="">Armenia</option>
                                                <option value="">Aruba</option>
                                                <option value="">Australia</option>
                                            </select>	
                                        </div> -->
                            <!-- <div class="row">
                                    <div class="form-group col-md-6">
                                        <input type="text" class="form-control" placeholder="First Name">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <input type="text" class="form-control" placeholder="Last Name">
                                    </div>
                                </div> -->
                            <!-- <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Company Name">
                                        </div> -->
                            <!-- <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Address">
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <input type="text" class="form-control" placeholder="Apartment, suite, unit etc.">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <input type="text" class="form-control" placeholder="Town / City">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <input type="text" class="form-control" placeholder="State / County">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <input type="text" class="form-control" placeholder="Postcode / Zip">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <input type="email" class="form-control" placeholder="Email">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <input type="text" class="form-control" placeholder="Phone">
                                    </div>
                                </div> -->
                            <!-- <h4 class="cp" data-toggle="collapse" data-target="#create-an-account">Create an account
                                    <i class="fa fa-angle-down"></i>
                                </h4>
                                <div id="create-an-account" class="collapse">
                                    <p>Create an account by entering the information below. If you are a returning customer
                                        please login at the top of the page.</p>
                                    <div class="form-group">
                                        <input type="password" class="form-control" placeholder="Password">
                                    </div>
                                </div> -->
                        </div>
                        <!-- <div class="col-lg-6 col-md-12 m-b30 m-md-b0">
                                <h3 class="cp" data-toggle="collapse" data-target="#different-address">Ship to a different
                                    address <i class="fa fa-angle-down"></i></h3>
                                <div id="different-address" class="collapse">
                                    <p>If you have shopped with us before, please enter your details in the boxes below. If
                                        you are a new customer please proceed to the Billing & Shipping section.</p>
                                    <div class="form-group">
                                        <select>
                                            <option value="">Åland Islands</option>
                                            <option value="">Afghanistan</option>
                                            <option value="">Albania</option>
                                            <option value="">Algeria</option>
                                            <option value="">Andorra</option>
                                            <option value="">Angola</option>
                                            <option value="">Anguilla</option>
                                            <option value="">Antarctica</option>
                                            <option value="">Antigua and Barbuda</option>
                                            <option value="">Argentina</option>
                                            <option value="">Armenia</option>
                                            <option value="">Aruba</option>
                                            <option value="">Australia</option>
                                        </select>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <input type="text" class="form-control" placeholder="First Name">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <input type="text" class="form-control" placeholder="Last Name">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Company Name">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Address">
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <input type="text" class="form-control"
                                                placeholder="Apartment, suite, unit etc.">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <input type="text" class="form-control" placeholder="Town / City">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <input type="text" class="form-control" placeholder="State / County">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <input type="text" class="form-control" placeholder="Postcode / Zip">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <input type="email" class="form-control" placeholder="Email">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <input type="text" class="form-control" placeholder="Phone">
                                        </div>
                                    </div>
                                    <p>Create an account by entering the information below. If you are a returning customer
                                        please login at the top of the page.</p>
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control"
                                        placeholder="Notes about your order, e.g. special notes for delivery"></textarea>
                                </div>
                            </div> -->


                        <!-- <div class="col-lg-6 col-md-12" >
                                <h3><b>MAp</b></h3>
                                 <div style="width: 100%; height: 100%;">
                        
                        <agm-map 
                        [latitude]="latitude" 
                        [longitude]="longitude" 
                        [zoom]="zoom">
                            <agm-marker 
                            [latitude]="latitude" 
                            [longitude]="longitude" 
                            [markerDraggable]="true"
                               
                                >
                            </agm-marker>
                        </agm-map>
                    </div>
                                </div> -->

                    </div>
                </div>


                <!-- <h3>Select Delivery Date & Time</h3> -->


                <div class="dlab-divider bg-gray-dark text-gray-dark icon-center"><i
                        class="fa fa-circle bg-white text-gray-dark"></i></div>

                <!-- Normal start-->
                <div class="row" style="padding: 5px;">
                    <div class="col-lg-6" style="margin-bottom: 10px;">
                        <h3><b>Delivery Date</b></h3>
                        <!-- <div class="container">
                            <p>If you choose today. please choose delivery time after <b>{{this.mypretime}} hours</b> from right time</p>
                            <input type="date" (change)="validateTime()" min="{{minDate}}" value="{{minDate}}"
                                [(ngModel)]="date" class="form-control" style="width: 75%;">
                            <img *ngIf="this.date!=''"
                                src="https://www.pngitem.com/pimgs/m/180-1807208_check-mark-computer-icons-ok-clip-art-right.png"
                                style="width: 25px; height: 25px; margin-left: 10px;" alt="">
                            <br />

                        </div> -->

                        <div class="row">
                            <p>If you choose today. please choose delivery time after <b>{{this.mypretime}} hours</b>
                                from right time</p>
                            <input type="date" (change)="validateTime()" min="{{minDate}}" value="{{minDate}}"
                                [(ngModel)]="date" class="form-control" style="width: 75%; font-weight: bold;">
                            <img *ngIf="this.date!=''"
                                src="https://www.pngitem.com/pimgs/m/180-1807208_check-mark-computer-icons-ok-clip-art-right.png"
                                style="width: 25px; height: 25px; margin-left: 10px; margin-top: 5px; " alt="">
                            <br />

                        </div>

                    </div>


                    <div class="col-lg-6">

                        <h3><b>Delivery Time</b></h3>
                        <!-- <div class="container">
                            <p>We can deliver cake order
                                between <b>10.00 A.M to 10:00 P.M</b></p>
                            <input type="time" (change)="validateTime()" [(ngModel)]="time" value="time" min="10:00"
                                max="22:00" required>

                            <img *ngIf="date && timeValid"
                                src="https://www.pngitem.com/pimgs/m/180-1807208_check-mark-computer-icons-ok-clip-art-right.png"
                                style="width: 25px; height: 25px; margin-left: 10px;" alt="">
                            <br /><br />

                            <i *ngIf="date&&!timeValid" class="text-danger">Sorry!!! Its not a Valid Time</i>


                        </div> -->

                        <div class="row">
                            <p>We can deliver cake order
                                between <b>10.00 A.M to 10:00 P.M</b></p>
                            <input type="time" (change)="validateTime()" [(ngModel)]="time" value="time" min="10:00"
                                class="form-control" style="width: 75%; font-weight: bold;" max="22:00" required>

                            <img *ngIf="date && timeValid"
                                src="https://www.pngitem.com/pimgs/m/180-1807208_check-mark-computer-icons-ok-clip-art-right.png"
                                style="width: 25px; height: 25px; margin-left: 10px; margin-top: 5px;" alt="">
                            <br /><br />

                            <!-- <i *ngIf="date&&!timeValid" class="text-danger">Sorry!!! Its not a Valid Time</i> -->
                            <i *ngIf="date&&!timeValid" class="text-danger">Sorry!!! Kindly choose time between shop
                                working hours and Select <b>{{this.mypretime}} hours</b> from right time..., </i>


                        </div>


                    </div>
                </div>
                <!-- Normal end-->


                <!-- Special start-->
                <!-- <div class="row">
                    <div class="col-lg-6" style="margin-bottom: 10px;">
                        <h3><b>Pickup Date</b></h3>
                        <div class="container">
                            <p>If you choose today. please choose Pickup time after 2 hours from right time</p>
                            <input type="date" (change)="validateTime2()" 
                            min="{{minDate}}" max="2023-01-01"
                            
                            value="{{minDate}}"
                                [(ngModel)]="date">
                            <img *ngIf="this.date!=''"
                                src="https://www.pngitem.com/pimgs/m/180-1807208_check-mark-computer-icons-ok-clip-art-right.png"
                                style="width: 25px; height: 25px; margin-left: 10px;" alt="">
                            <br />

                        </div>

                    </div>


                    <div class="col-lg-6">

                        <h3><b>Pickup Time</b></h3>
                        <div class="container">
                            <p>You can Pickup cake order
                                between 10.00 A.M to 11:30 P.M</p>
                            <input type="time" (change)="validateTime()" [(ngModel)]="time" value="time" min="10:00"
                                max="22:00" required>

                            <img *ngIf="date && timeValid"
                                src="https://www.pngitem.com/pimgs/m/180-1807208_check-mark-computer-icons-ok-clip-art-right.png"
                                style="width: 25px; height: 25px; margin-left: 10px;" alt="">
                            <br /><br />

                            <i *ngIf="date&&!timeValid" class="text-danger">Sorry!!! Its not a Valid Time</i>


                        </div>


                    </div>
                </div> -->
                <!-- Special end-->

                <div class="dlab-divider bg-gray-dark text-gray-dark icon-center"><i
                        class="fa fa-circle bg-white text-gray-dark"></i></div>
                <div class="row" *ngIf="currentaddressid!=0">
                    <div class="col-lg-6">
                        <h3><b>Your Order</b></h3>
                        <table class="table-bordered check-tbl">
                            <thead>
                                <tr>
                                    <th>IMAGE</th>
                                    <th>PRODUCT NAME</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let product of cartitems">
                                    <td><img src="{{product.ImgUrl}}" alt=""></td>
                                    <td><strong>{{product.Name}} </strong>

                                        <div style="margin-left: 10px;">
                                            <div>
                                                <small>* <b>QTY: </b>{{product.Quantity}} Kg
                                                    <!-- ₹{{(product.quantityrate).toFixed(2)}} -->
                                                </small>
                                            </div>
                                            <div>
                                                <!-- <small *ngFor="let variant of product.variants">
                                                    * <b>FLAVOUR: cvcv</b> {{variant.Name}} {{variant.Price>0 ?
                                                    '₹'+(variant.Price*product.pqty).toFixed(2): '' }}
                                                   
                                                </small> -->

                                                <span *ngFor="let variant of product.variants">{{variant.Name}}</span>
                                            </div>

                                            <div *ngIf="product.addons.length">
                                                <small *ngFor="let addon of product.addons">
                                                    <!-- <b>ADDONS: </b>{{addon.Name}} Rs.{{addon.Price*product.pqty}} -->
                                                    * <b>ADDONS: </b>Eggless
                                                    ₹{{(addon.Price*product.pqty).toFixed(2)}}
                                                </small>
                                            </div>

                                            <!-- <div *ngIf="product.Message!='' && product.Message!=null">
                                                <small *ngFor="let variant of product.variants">
                                                    * <b>CAKE MSG: </b> {{product.Message}}
                                                </small>
                                            </div> -->

                                            <div *ngIf="product.Message!='' && product.Message!=null">

                                                * MSG: {{product.Message}}

                                            </div>

                                            <div *ngIf="product.eggless!=0">

                                                * Eggless Added: ₹{{product.eggless}}

                                            </div>

                                            <div>
                                                <span style="font-style: italic;">Preparing Time: {{product.PrepTime}}
                                                    hours</span>
                                            </div>
                                        </div>
                                    </td>
                                    <!-- <td class="product-price">₹ {{product.TotalAmount*product.Quantity}}</td> -->
                                    <td class="product-price">₹ {{product.prate.toFixed(2)}}</td>
                                    <!-- {{calcTotalPrice()}} -->
                                </tr>
                                <!-- <tr>
                                                <td><img src="../../assets/images/product/item2.jpg" alt=""></td>
                                                <td>Pizza</td>
                                                <td class="product-price">$36.00</td>
                                            </tr>
                                            <tr>
                                                <td><img src="../../assets/images/product/item3.jpg" alt=""></td>
                                                <td>Burger</td>
                                                <td class="product-price">$28.00</td>
                                            </tr>
                                            <tr>
                                                <td><img src="../../assets/images/product/item4.jpg" alt=""></td>
                                                <td>Chow Mein</td>
                                                <td class="product-price">$36.00</td>
                                            </tr>
                                            <tr>
                                                <td><img src="../../assets/images/product/item5.jpg" alt=""></td>
                                                <td>Pizza</td>
                                                <td class="product-price">$28.00</td>
                                            </tr> -->
                            </tbody>

                            <!-- Accesories -->
                            <tbody>
                                <tr *ngFor="let acces of accessories">
                                    <td><img src="https://tinyurl.com/39py47hw" alt=""></td>
                                    <td>
                                        <strong>{{acces.Name}}</strong>
                                        <!-- <br>Qty. {{acces.Quantity}} -->


                                        <div style="margin-left: 10px; ">
                                            <small>* <b>QTY: </b>{{acces.Quantity}} No.
                                                ₹{{(acces.Price).toFixed(2)}}
                                            </small>
                                        </div>

                                    </td>
                                    <!-- <td class="product-price">₹ {{product.TotalPrice*product.Quantity}}</td> -->
                                    <td class="product-price" style="text-align: left;">₹
                                        {{(acces.Price*acces.Quantity).toFixed(2)}}</td>
                                    <!-- {{calcTotalPrice()}} -->
                                </tr>
                                <!-- <tr>
                                                <td><img src="../../assets/images/product/item2.jpg" alt=""></td>
                                                <td>Pizza</td>
                                                <td class="product-price">$36.00</td>
                                            </tr>
                                            <tr>
                                                <td><img src="../../assets/images/product/item3.jpg" alt=""></td>
                                                <td>Burger</td>
                                                <td class="product-price">$28.00</td>
                                            </tr>
                                            <tr>
                                                <td><img src="../../assets/images/product/item4.jpg" alt=""></td>
                                                <td>Chow Mein</td>
                                                <td class="product-price">$36.00</td>
                                            </tr>
                                            <tr>
                                                <td><img src="../../assets/images/product/item5.jpg" alt=""></td>
                                                <td>Pizza</td>
                                                <td class="product-price">$28.00</td>
                                            </tr> -->
                            </tbody>
                        </table>
                    </div>
                    <div class="col-lg-6">

                        <div>
                            <h3><b>Order Message (Optional)</b></h3>
                            <textarea rows="2" name="text" class="form-control" style="width: 100%; margin-top: 5px;"
                                [(ngModel)]="Omsg" placeholder="Eg: Kindly provide extra plates and spoon"></textarea>
                        </div>

                        <br>
                        <!-- COOPEN -->

                        <div>
                            <div>
                                <h3><b>Coupon Code (Optional)</b></h3>
                                <div class="row">
                                    <input type="text" placeholder="Enter Coupon Code" value="TESTCODE"
                                        [(ngModel)]="pCode"
                                        style="width: 50%; margin-right: 5px; border-radius: 5px;  margin-left: 20px;">

                                    <button class="btn btnhover btn-danger" type="button"
                                        (click)="getCode(pCode)">Apply</button>


                                </div>

                                <div *ngIf="this.coopenAlert==1"><br>
                                    <span style="color: green; margin-top: 10px; font-style: italic;">Coupon Code was
                                        Appled! 😃
                                    </span>
                                    <div>
                                        <span style="color: green; font-style: italic;">₹
                                            {{this.coopenAmount.toFixed(2)}} was reduced you Order Amount.
                                        </span>

                                    </div>

                                </div>

                                <div *ngIf="this.coopenAlert==2"><br>
                                    <span class="text-danger" style="font-style: italic;">Coupon Code was not Applied!
                                        😔</span>
                                    <div>
                                        <span class="text-danger" style="font-style: italic;">Its Valid on (
                                            {{this.order_Pcode[0].StartDate | date:'MMM d, y'}} to
                                            {{this.order_Pcode[0].EndDate | date:'MMM d, y'}} )</span>
                                    </div>
                                </div>
                                <div *ngIf="this.coopenAlert==3"><br>
                                    <span class="text-danger" style="font-style: italic;">Order Amount not Enough for
                                        your Coupon Code!🥺</span>
                                    <div>
                                        <span class="text-danger" style="font-style: italic;">Coupon Code up to Min INR
                                            {{this.order_Pcode[0].MiniOrderValue.toFixed(2)}}</span>
                                    </div>
                                </div>
                                <div *ngIf="this.coopenAlert==4"><br>
                                    <span class="text-danger" style="margin-top: 10px; font-style: italic;">Invalid
                                        Coupon Code! 😡</span>
                                </div>
                            </div>
                        </div>






                        <br>
                        <form class="shop-form">
                            <h3><b>Order Total</b></h3>

                            <table class="table-bordered check-tbl" *ngIf="coopenAlert!=1">
                                <tbody>
                                    <!-- <tr>
                                                    <td>Order Subtotal</td>
                                                    <td class="product-price">$125.96</td>
                                                </tr> -->
                                    <tr>
                                        <td>Shipping & Packing Charge</td>
                                        <td style="color: green;">₹ 0.00</td>
                                    </tr>


                                    <!-- <tr>
                                        <td>SubTotal</td>
                                        <td 
                                        class="product-price-total"
                                        style="text-decoration: line-through;"
                                        >₹ {{getTotal().toFixed(2)}}</td>
                                    </tr>
                                    <tr>
                                        <td>Coopen Amount</td>
                                        <td 
                                        class="product-price-total"
                                        style="color: red;"
                                        >₹ -50</td>
                                    </tr> -->

                                    <tr>
                                        <td>SubTotal</td>
                                        <td class="product-price-total">₹ {{getTotal().toFixed(2)}}</td>
                                    </tr>

                                    <tr>
                                        <td>Tax (18%)</td>
                                        <!-- <td class="product-price">₹ {{tax1+tax2+tax3}}</td> -->
                                        <td class="product-price">₹ {{settaxes2().toFixed(2)}}</td>
                                    </tr>

                                    <!-- <tr>
                                        <td>FB coins Amount</td>
                                     
                                     
                                        <a href="javascript:void(0);"
                                         class="btnhover text-center" 
                                       >Apply FBcoins</a>
                                    </tr> -->

                                    <tr>
                                        <td><strong style="font-size: 20px;">Total</strong></td>
                                        <!-- <td class="product-price-total"><strong style="font-size: 20px;">₹
                                                {{(getTotal() + settaxes2()).toFixed(2)}}</strong></td> -->
                                        <td class="product-price-total"><strong style="font-size: 20px;">₹
                                                {{(final_amount()).toFixed(2)}}</strong></td>
                                    </tr>

                                    <!-- <tr  >
                                        <td colspan="3" style="padding: 10px;">
                                            Rs.{{(((getTotal() + settaxes2()).toFixed(2)*5)/100).toFixed(0)}} bonus Amounts save in FB Coins. you can use in the Next Purchase.

                                        </td>
                                    </tr> -->


                                </tbody>
                            </table>


                            <table class="table-bordered check-tbl" *ngIf="coopenAlert==1">
                                <tbody>
                                    <!-- <tr>
                                                    <td>Order Subtotal</td>
                                                    <td class="product-price">$125.96</td>
                                                </tr> -->
                                    <tr>
                                        <td>Shipping & Packing Charge</td>
                                        <td style="color: green;">₹ 0.00</td>
                                    </tr>


                                    <!-- <tr>
                                        <td>SubTotal</td>
                                        <td 
                                        class="product-price-total"
                                       
                                        >₹ {{getTotal().toFixed(2)}}</td>
                                    </tr> -->


                                    <tr>
                                        <td>SubTotal</td>
                                        <td class="product-price-total">₹ {{getTotal().toFixed(2)}}</td>
                                    </tr>

                                    <tr>
                                        <td>Tax (5%)</td>
                                        <!-- <td class="product-price">₹ {{tax1+tax2+tax3}}</td> -->
                                        <td class="product-price">₹ {{settaxes2().toFixed(2)}}</td>
                                    </tr>

                                    <!-- <tr>
                                        <td>FB coins Amount</td>
                                     
                                     
                                        <a href="javascript:void(0);"
                                         class="btnhover text-center" 
                                       >Apply FBcoins</a>
                                    </tr> -->

                                    <tr>
                                        <td><strong
                                                style="font-size: 20px; text-decoration: line-through;">Total</strong>
                                        </td>
                                        <td class="product-price-total">
                                            <strong style="font-size: 20px; text-decoration: line-through;">₹
                                                {{(getTotal() + settaxes2()).toFixed(2)}}</strong>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Coopen Amount ({{this.order_Pcode[0].DiscountType}}%)</td>
                                        <td class="product-price-total" style="color: red;">₹
                                            -{{(((getTotal() +
                                            settaxes2())*this.order_Pcode[0].DiscountType)/100).toFixed(2)}}</td>
                                    </tr>

                                    <tr>
                                        <td><strong style="font-size: 20px;">Total</strong></td>
                                        <td class="product-price-total"><strong style="font-size: 20px;">₹
                                                {{(final_amount()).toFixed(2)}}</strong></td>
                                    </tr>

                                    <!-- <tr  >
                                        <td colspan="3" style="padding: 10px;">
                                            Rs.{{(((getTotal() + settaxes2()).toFixed(2)*5)/100).toFixed(0)}} bonus Amounts save in FB Coins. you can use in the Next Purchase.

                                        </td>
                                    </tr> -->
                                </tbody>
                            </table>


                            <h3><b>Payment Options</b></h3>
                            <div class="row">



                                <div class="form-check">
                                    <input type="radio" class="form-check-input with-gap" id="radioGap1"
                                        name="groupOfRadioGap" [checked]="PaymentTypeId==1" [value]="1"
                                        [(ngModel)]="PaymentTypeId" (click)="ptyRes()">
                                    <label class="form-check-label" for="radioGap1">Online Payment</label>
                                </div>

                                <!-- <div class="form-check" (click)="ptyRes()">
                                    <input type="radio" class="form-check-input with-gap" 
                                        name="groupOfRadioGap"  >
                                    <label class="form-check-label" for="radioGap1">Online Payment</label>
                                </div> -->

                                <div class="form-check pull-right">
                                    <input type="radio" class="form-check-input with-gap" id="radioGap2"
                                        name="groupOfRadioGap" [checked]="PaymentTypeId==2"  [(ngModel)]="PaymentTypeId" 
                                        [value]="2">
                                    <label class="form-check-label" for="radioGap2">Cash On Delivery</label>
                                </div>


                            </div>
                            <!-- <span>{{PaymentTypeId}}</span> -->
                            <br>
                            <!-- <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Name on Card">
                                        </div>
                                        <div class="form-group">
                                            <select>
                                                <option value="">Credit Card Type</option>
                                                <option value="">Another option</option>
                                                <option value="">A option</option>
                                                <option value="">Potato</option>
                                            </select>	
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Credit Card Number">
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Card Verification Number">
                                        </div> -->
                            <!-- <div class="form-group">
                                <button class="btn button-lg btnhover btn-block btn-danger" type="button" [disabled]="clicked" 
                                    (click)="placeorder();">Place Order Now </button> -->

                            <div class="form-group">
                                <button class="btn button-lg btnhover btn-block btn-danger" type="button"
                                    [disabled]="clicked" (click)="checkcart();">Place Order Now </button>

                                <!-- <button class="btn button-lg btnhover btn-block btn-danger" type="button" [disabled]="clicked" 
                                    (click)="placeorder5();">Place Order Now </button> -->

                                <!-- <br>

                                    <button class="btn button-lg btnhover btn-block btn-danger" type="button"
                                    [disabled]="clicked" (click)="actionMethod(); clicked = true;">Place Order Now </button> -->
                            </div>
                            <span *ngIf="this.poalert!=false" class="text-danger">Please Choose Valid Delivery
                                Time!</span>

                            <span class="text-danger">{{mycheckcart}}</span>
                        </form>

                    </div>
                </div>
            </div>
            <!-- Product END -->
        </div>

    </div>
    <!-- Content END-->
    <!-- Footer -->
    <!-- Footer END -->
    <button class="scroltop fa fa-chevron-up"></button>
</div>
<div class="modal fade subscribe-box" id="AddressModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" aria-label="close">
    <div class="modal-dialog" role="document">
        <form class="dzSubscribe modal-content w-100 mb-10">
            <div class="clearfix">
                <!-- comment list END -->
                <ol class="comment-list">
                    <li class="comment" *ngFor="let address of addresses">
                        <div class="comment-body">
                            <div class="comment-author vcard">
                                <cite class="fn">{{address.Name}}</cite>
                            </div>
                            <p>{{address.Address}}</p>
                            <p>{{address.Contact}}</p>
                        </div>
                    </li>
                </ol>
            </div>


            <div class="modal-header">
                <!-- <img src="../../assets/images/bell.png" alt="" /> -->
                <h5 class="modal-title" id="exampleModalLabel">Add Address</h5>
                <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore.</p> -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="m-b30">
                    <!-- <h3>Address</h3> -->
                    <!-- <div class="form-group">
                                    <select>
                                        <option value="">Åland Islands</option>
                                        <option value="">Afghanistan</option>
                                        <option value="">Albania</option>
                                        <option value="">Algeria</option>
                                        <option value="">Andorra</option>
                                        <option value="">Angola</option>
                                        <option value="">Anguilla</option>
                                        <option value="">Antarctica</option>
                                        <option value="">Antigua and Barbuda</option>
                                        <option value="">Argentina</option>
                                        <option value="">Armenia</option>
                                        <option value="">Aruba</option>
                                        <option value="">Australia</option>
                                    </select>	
                                </div> -->
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Name">
                    </div>
                    <!-- <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Company Name">
                                </div> -->
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Address">
                    </div>
                    <div class="row">
                        <!-- <div class="form-group col-md-6">
                                <input type="text" class="form-control" placeholder="Apartment, suite, unit etc.">
                            </div> -->
                        <div class="form-group col-md-6">
                            <input type="text" class="form-control" placeholder="Town / City">
                        </div>
                        <div class="form-group col-md-6">
                            <input type="text" class="form-control" placeholder="State / County">
                        </div>

                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <input type="text" class="form-control" placeholder="Phone">
                        </div>
                        <div class="form-group col-md-6">
                            <input type="text" class="form-control" placeholder="Postcode / Zip">
                        </div>
                    </div>
                    <!-- <div class="row"> -->
                    <!-- <div class="form-group ">
                            <input type="email" class="form-control" placeholder="Email">
                        </div> -->

                    <!-- </div> -->
                </div>

            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btnhover shadow">Submit</button>
            </div>




        </form>


    </div>

    <!-- JAVASCRIPT FILES ========================================= -->
    <!-- <script src="js/jquery.min.js"></script>JQUERY.MIN JS -->
    <!-- <script src="plugins/bootstrap/js/popper.min.js"></script>BOOTSTRAP.MIN JS -->
    <!-- <script src="plugins/bootstrap/js/bootstrap.min.js"></script>BOOTSTRAP.MIN JS -->
    <!-- <script src="plugins/bootstrap-touchspin/jquery.bootstrap-touchspin.js"></script>FORM JS -->
    <!-- <script src="plugins/bootstrap-select/bootstrap-select.min.js"></script>FORM JS -->
    <!-- <script src="plugins/lightgallery/js/lightgallery-all.min.js"></script>LIGHTGALLERY -->
    <!-- <script src="js/custom.js"></script>CUSTOM FUCTIONS  -->
    <!-- <script src="plugins/rangeslider/rangeslider.js" ></script>Rangeslider -->
    <!-- <script src="plugins/rangeslider/rangeslider.js" ></script>Rangeslider -->

    <!-- </body>
            </html> -->


    <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>