<div class="page-wraper">

    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">Stores</h1>
                </div>
            </div>
        </div>

        <div class="section-full content-inner">
            <div class="container">
                <div class="shop-form">


                    <h1>Our FB Cakes Store List</h1>

                    <div class="row col-xl-12">

                        <div *ngFor="let item of store" class="col-xl-3 px-2 " style="margin-bottom: 15px;"
                            (mouseover)="item.mouseOvered=true" (mouseout)="item.mouseOvered=false">
                            <div [class.shadow-lg]="item.mouseOvered"
                                style="background-color: whitesmoke;height: 100%;padding: 10px; border-radius: 10px;">
                                <div>
                                    <div style="margin-bottom: 5px;">
                                        <span style="font-size: 20px;">FBCakes-{{item.Title}}</span> </div>
                                    <div>
                                        <span style="font-size: 18px;">{{item.Address}}</span>
                                    </div>
                                    <div>
                                        <span style="font-size: 18px;">{{item.City}}-{{item.Pincode}} </span>
                                    </div>
                                    <div style="margin-top: 5px;">
                                        <span style="font-size: 18px;">Mob. +91 {{item.Mobile}}</span>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>


                </div>




            </div>