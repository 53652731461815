<div class="page-wraper">

    <div class="page-content bg-white">
        <!-- inner page banner -->
        <!-- <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">Job oppenings FBcakes - Chennai</h1>
                </div>
            </div>
        </div> -->

        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(https://lh3.googleusercontent.com/p/AF1QipPE3PVYsY7ZV_EvYfEKZOzCBJDSB85EScRP35Q=s1360-w1360-h1020);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">IT Jobs openings FBcakes - Chennai</h1>
                </div>
            </div>
        </div>

        <div class="section-full content-inner">
            <div class="container">
                <div class="shop-form">

                    <div>
                        <!-- <div class="box">
                            <div class="text-center d-flex flex-column items-lef max-w-sm">
                                <div class="text-center pb-3">
                                    <img 
                                    src="https://i.postimg.cc/QMnq25Fm/paru3.png" 
                                    alt="Parthiban FBcakes | Parthiban HR | Parthiban HR FBcakes | Parthiban Career Team FBcakes | HR FBcakes | FBcakes HR"
                                    >
                                </div>
                                <span class="text-center" style="font-size: 20px; font-weight: bold;">Parthiban (HR)</span>
                                <span style="font-size: 18px; margin-top: 5px;">+91 90037 96333</span>
                                
                                
                            </div>
                        </div>

                        <hr> -->

                        <div style="font-size: 15px;">

                            <h2>Career Oppurtunities at FBcakes IT (FBcakes Software Delopment Team) <br>Freshers are 
                                most welcome...</h2>

                            <span style="font-size: large;"><b>📢 Immediate Requirement – Freshers: Software Developer Trainee | Karapakkam, OMR Road</b></span><br><br>
                            <b>Job Description:</b><br>
                                • Graduated in Computer Science or IT domain. <br>
                                • Should be strong in programming and coding skills. Or Willing to Learn<br>
                                   • Knowledge in HTML, Javascript, C++, Java will be an added advantage. <br>
                                        • Willingness to learn Full Stack Software Delopment. <br>
                                            • Immediate joiners are preferred.<br><br>

                                            No .of open positions: 15 nos (male/female)<br>
                                            Location: Karapakkam, OMR Road, Chennai.<br>
                                            
Job Type: Full time (9pm to 6pm)<br>
Experience: 0 to 1 year (Freshers are 
most welcome...)<br>
Salary: (12K to 20K per month)<br>
<br>

<b>
Interested candidates send your resume whatsapp message<br>
Vijay: <a href="#" (click)="sendwts()">+91 7604983238</a></b>

                            
                            
                        </div>

                        <br><br>


                        <!-- <h2>Career Oppurtunities at FBcakes (Sales / Accounts / IT (Software and Hardware)) <br>Freshers are 
                            most welcome...</h2> -->

                        <div class="row col-xl-12">

                            <h4 style="text-align: center;">Let's Spread Smiles Together 😊. We're looking for heroes
                                who are enthusiastic to work their parts and help us deliver smiles all around the
                                world.</h4>
                        </div>

                        <div class="row col-xl-12">
                            <div class="col-xl-6">

                                <input class="form-control" name="Con_Name" placeholder="Full Name" type="name"
                                    [(ngModel)]="name">
                                <br>

                                <input class="form-control" name="Con_Name" placeholder="Father Name" type="name"
                                    [(ngModel)]="Fname">
                                <br>

                                <!-- <input class="form-control" name="Con_Name" placeholder="Age / Gender" type="name"
                                    [(ngModel)]="age"> -->
                                    <input class="form-control" name="Con_Name" placeholder="DOB (05-01-1991)" type="name"
                                    [(ngModel)]="age">
                                <br>

                                <div class="row"><span style="margin-left: 17px;">Gender: </span> 

                                    <div class="form-check"> 
                                        <input type="radio" class="form-check-input with-gap" 
                                       
                                        id="radioGap11"
                                            name="groupOfRadioGap2" 
                                            [checked]="gendertype==1" 
                                            [value]="1"
                                            [(ngModel)]="gendertype">
    
                                        <label class="form-check-label" for="radioGap11">Male</label>
                                    </div>
    
                                    <div class="form-check pull-right">
                                        <input type="radio" class="form-check-input with-gap" 
                                       
                                        id="radioGap22"
                                            name="groupOfRadioGap2" 
                                            [checked]="gendertype==2" 
                                            [(ngModel)]="gendertype"
                                            [value]="2">
    
                                        <label class="form-check-label" for="radioGap22">Female</label>
                                    </div>
    
                                   
    
    
                                </div>
                                <br>

                                <input class="form-control" name="Con_Name" placeholder="Phone no."
                                    [(ngModel)]="phoneno">
                                <br>

                                <input class="form-control" name="Con_Name" placeholder="Email" [(ngModel)]="email">
                                <br>


                                <textarea rows="4" name="text" class="form-control"
                                    style="width: 100%; margin-top: 5px;" [(ngModel)]="address"
                                    placeholder="Present Address"></textarea>
                                    <br>

                            </div>

                           

                            <div class="col-xl-6">

                                
                                <textarea rows="3" name="text" class="form-control"
                                    style="width: 100%; margin-top: 5px;" placeholder="Education Details"
                                    [(ngModel)]="e_details"></textarea>
                                <br>


                                <textarea rows="3" name="text" class="form-control"
                                    style="width: 100%; margin-top: 5px;" placeholder="Working Experience (Optional)"
                                    [(ngModel)]="w_details"></textarea>
                                <br>

                                <textarea rows="3" name="text" class="form-control"
                                    style="width: 100%; margin-top: 5px;" placeholder="Skills / Additional Details (Optional)"
                                    [(ngModel)]="a_details"></textarea>
                                <br>

                                <div class="row">

                                    <!-- <div class="form-check">
                                        <input type="radio" class="form-check-input with-gap" 
                                       
                                        id="radioGap1"
                                            name="groupOfRadioGap" 
                                            [checked]="jobtype==1" 
                                            [value]="1"
                                            [(ngModel)]="jobtype">
    
                                        <label class="form-check-label" for="radioGap1">Sales</label>
                                    </div> -->
    
                                    <!-- <div class="form-check pull-right">
                                        <input type="radio" class="form-check-input with-gap" 
                                       
                                        id="radioGap2"
                                            name="groupOfRadioGap" 
                                            [checked]="jobtype==2" 
                                            [(ngModel)]="jobtype"
                                            [value]="2">
    
                                        <label class="form-check-label" for="radioGap2">Accounts</label>
                                    </div> -->
    
                                    <div class="form-check pull-right">
                                        <input type="radio" class="form-check-input with-gap" 
                                        
                                        id="radioGap3"
                                            name="groupOfRadioGap" 
                                            [checked]="jobtype==3" 
                                            [(ngModel)]="jobtype"
                                            [value]="3">
    
                                        <label class="form-check-label" for="radioGap3">IT (Software & Hardware Support)</label>
                                    </div>
    
    
                                </div>
                                <br>

                                <!-- <div class="row justify-content-center" class="col-xl-6"> -->
                                    <div  style="width: 100%;"> 
                                        <div class="form-group files">
                                            <label class="my-auto">Upload Your File </label> 
                                            <input id="file" type="file" class="form-control" (change)="upload($event.target.files)"/>
                                            <!-- <input type='file' (change)="upload($event.target.files)"
                                            style="background-color: white;"> -->
                                        </div></div>
                                <!-- </div> -->

                            </div>

                           


                        </div>

                        <div><span *ngIf="this.alert==1" style="color: red;"> Plz. Full fill the Details!</span></div>
                        <div class="row col-xl-12" style="align-content: center;">


                            <div>
                                <br>
                                <label class="btn btn-danger" (click)="submit()">Apply Job</label>
                            </div>
                        </div>

                        <span style="color: green;"> {{report}}</span>

                    </div>

                    <!-- <hr> -->

                    <!-- <div style="margin-top: 20px;">
                    <span style="font-size: 20px; font-weight: bold;">Parthiban HR</span><br>
                    <span style="font-size: 18px;">+91 90037 96333</span>
                </div> -->




                </div>

                <div>
                    <p>tags:</p>
                    <a href="#">Fresher IT jobs in Karapakkam, </a>
                    <a href="#">IT Jobs Fresher, </a>
                    <a href="#">IT Jobs Fresher Karapakkam Chennai, </a>
                    <a href="#">Fresher jobs in Chennai, </a>
                    <a href="#">Freshers Software Developer Trainee Karapakkam ,</a>
                    <a href="#">Java jobs in Chennai, </a>
                    <a href="#">IT Jobs OMR, </a><a href="#">C# jobs in Chennai, </a><a href="#">IT Jobs OMR, </a>
                    <a href="#">Full Stack Developer Job Fresher OMR Chennai, </a>
                    <a href="#">Programming IT Jobs OMR Chennai</a>
                </div>

                

            </div>

           



            <!-- <div class="container card-0 justify-content-center ">
                <div class="card-body px-sm-4 px-0">
                    <div class="row justify-content-center mb-5">
                        <div class="col-md-10 col"><h3 class="font-weight-bold ml-md-0 mx-auto text-center text-sm-left"> Request a Quote </h3><p class="mt-md-4  ml-md-0 ml-2 text-center text-sm-left"> Entrust with heigh professionalism we are offering pixel perfect web and mobile application development third party integration and solution to our.</p></div>
                    </div>
                    <div class="row justify-content-center round">
                        <div class="col-lg-10 col-md-12 ">
                            <div class="card shadow-lg card-1">
                                <div class="card-body inner-card">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-5 col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="first-name">First Name</label>
                                                <input type="text" class="form-control" id="first-name" placeholder="Type your Name"> </div>
                                            <div class="form-group"> <label for="Mobile-Number">Mobile Number</label> <input type="text" class="form-control" id="Mobile-Number" placeholder=""> </div>
                                            <div class="form-group"> <label for="inputEmail4">Project Type</label> <select class="form-control"><option>Web Design</option><option>Blockchain</option> <option>ML</option></select> </div>
                                            <div class="form-group"> <label for="time">Maximum time for the project</label> <input type="text" class="form-control" id="time" placeholder="">  </div>
                                            <div class="form-group"><label for="skill">Required Skills</label> <input type="text" class="form-control" id="skill" placeholder=""> </div>
                                        </div>
                                        <div class="col-lg-5 col-md-6 col-sm-12">
                                            <div class="form-group"> <label for="last-name">Last Name</label> <input type="text" class="form-control" id="last-name" placeholder=""> </div>
                                            <div class="form-group"> <label for="phone">Email</label>  <input type="email" class="form-control" id="email" placeholder=""> </div>
                                            <div class="form-group"> <label for="Evaluate Budget">Evaluate Budget</label> <input type="text" class="form-control" id="Evaluate Budget" placeholder=""> </div>
                                            <div class="form-group"> <label for="Company-Name">Company Name</label> <input type="text" class="form-control" id="Company-Name" placeholder=""> </div>
                                            <div class="form-group"> <label for="inputEmail4">Country</label> <select class="form-control"> <option>India</option><option>China</option> <option>UK</option> </select></div>
                                        </div>
                                    </div>
            
                                    <div class="row justify-content-center">
                                        <div class="col-md-12 col-lg-10 col-12"> <div class="form-group files"><label class="my-auto">Upload Your File </label> <input id="file" type="file" class="form-control" /></div></div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-md-12 col-lg-10 col-12"><div class="form-group"> <label for="exampleFormControlTextarea2">Message</label> <textarea class="form-control rounded-0" id="exampleFormControlTextarea2" rows="5"></textarea></div>
                                            <div class="row justify-content-end mb-5">
                                                <div class="col-lg-4 col-auto "><button type="button" class="btn btn-primary btn-block"><small class="font-weight-bold">Request a Quote</small></button> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->