<!-- <p>orderlist works!</p>
<div class="section-full content-inner">
    <table class="table table-striped table-bordered">
        <thead>
            <tr>
               <th  class="center">#Id</th>
               <th  class="center">Total</th>
             
               <th  class="center">Delivery Time</th>
               <th  class="center">Status</th>
            </tr>
            </thead>

    </table>
</div> -->






<div class="page-wraper">
    <div hidden id="loading-area">
        <div class="ball-pulse-rise">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">Order Details</h1>
                </div>
            </div>
        </div>


        <div class="section-full content-inner">


            <!-- <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template">
            </ngx-loader> -->

            <div class="container" id="orderList" *ngIf="orders.length==0">
                <h1>Loading...😎</h1>
                <br><br><br><br><br><br><br><br>
                <!-- <span style="font-size: 20px;">Plz Check Login (time out....😎)</span> -->

                
                <div class="loader">
                    <div class="cubes">
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                      <div class="cube">
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                          <div class="side"></div>
                      </div>
                    </div>
                  </div>
                  <br><br><br><br><br><br><br><br>
    
            </div>

            <div class="container" id="orderList" *ngIf="orders.length>0">

                <div>
                    <div class="container px-0">
                        <div class="">
                            <p id="sub-heading" style="font-size: 28px;">Order Track ({{orders[0].OdrsId}})</p>
                            <br>
                            <div class="row">
                                <div class="col">
                                    <p style="text-align: left; font-size: 22px; ">Order
                                        {{orders[0].osi==0 ? "Placed":
                                        orders[0].osi==-5 ? "Accepted":
                                        orders[0].osi==1 ? "Preparing":
                                        orders[0].osi==2 ? "Preparing":
                                        orders[0].osi==3 ? "Prepared":
                                        orders[0].osi==4 ? "Dispatched":
                                        orders[0].osi==5 ? "Delivered":
                                        orders[0].osi==-1 ? "Cancelled":

                                        "---" }} </p>
                                </div>
                                <!-- <div class="col col-2 px-0">
                                    

                                    <span id="right" class="float-right" style="font-size: 22px;">{{per}}%</span>
                                </div> -->
                            </div>

                            <div class="progress">
                                <div class="progress-bar">
                                    <img src="https://iili.io/Hgs6hWx.png">

                                </div>
                                <!-- https://iili.io/Hgs6hWx.png -->
                               
                            </div>

                            <div>
                                <p style="text-align: center; font-size: 22px; ">Order
                                    {{orders[0].OrderStatusId==0 ? "Placed":
                                    orders[0].OrderStatusId==-5 ? "Accepted":
                                    orders[0].OrderStatusId==1 ? "Preparing":
                                    orders[0].OrderStatusId==2 ? "Preparing":
                                    orders[0].OrderStatusId==3 ? "Prepared":
                                    orders[0].OrderStatusId==4 ? "Dispatched":
                                    orders[0].OrderStatusId==5 ? "Delivered":
                                    orders[0].OrderStatusId==-1 ? "Cancelled":

                                    "Detail" }} </p>
                            </div>

                            <div class="container px-0">
                                <div class="row">
                                    <div class="col-sm" style="text-align: left; width: 50%;">
                                        <h5>FROM</h5>
                                        <b style="font-size: 20px;">FBcakes</b>
                                           
                                            <span
                                                *ngIf="orders[0].si!=22 && orders[0].osi!=0"
                                                style="font-size: 20px;">-{{store.Name}}</span><br>
                                           
                                        <span
                                        *ngIf="orders[0].si!=22 && orders[0].osi!=0">
                                            {{store.Address}}<br>
                                            {{store.City}}-{{store.PostalCode}}<br>
                                            Ph: {{store.ContactNo}}


                                        </span>

                                        <!-- <span>
                                            {{orders[0].StoreDetails[0] ? orders[0].StoreDetails[0].Name : ""}}
                                        </span> -->

                                        <!-- <div *ngIf="orders[0].StoreId!=22 && orders[0].StoreId!=172 && orders[0].StoreId!=165 && orders[0].StoreId!=166 && orders[0].StoreId!=168">
                                    <a href="tel:+91-900-343-2888">WhatsApp your delivery shop</a>
                                </div> -->



                                    </div>

                                    <div class="col-sm" style="text-align: right; width: 50%;">
                                        <!-- <img (click)="addressEdit(orders[0].CurrentAddresses[0])"
                                        src="https://cdn.iconscout.com/icon/free/png-256/edit-1767972-1502308.png"
                                        alt="Edit Customer Address" 
                                        > -->

                                        <!-- <li><a a href="https://wa.me/9003432888" target="blank">
                                            <i class="fa fa-edit"></i>
                                        </a>
                                    </li> -->
                                        <!-- <a href="javascript:void(0)" (click)="addressEdit(orders[0].CurrentAddresses[0])">  
                                        <i class="fa fa-edit"></i>
                                    </a> -->
                                    <h5>TO</h5>
                                        <b style="font-size: 20px;"> Mr/ms.{{orders[0].CurrentAddresses[0].Name}}
                                        </b><br>
                                        <span >{{orders[0].CurrentAddresses[0].Address}}.<br>
                                            Ph.
                                            {{orders[0].CustomerDetail[0].PhoneNo.slice(2)}}</span>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <hr>


                        <div class="container" style="margin-bottom: 10px;">
                            <div class="row">
                                <div class="col-sm" style="text-align: left; width: 50%;">

                                    <div class="row">
                                        <div>
                                            <img src="https://www.kindpng.com/picc/m/154-1547142_transparent-calendar-icons-png-date-and-time-icon.png"
                                                style="width: 35px; height: 35px; margin-right: 5px;">
                                        </div>
                                        <div>
                                            <div><b>Ordered Date </b></div>
                                            <div>{{orders[0].oddt | date:'MMM d,y, h:mm a'}}</div>
                                        </div>
                                    </div>



                                    <!-- Order Id: #{{orders[0].Id}} -->
                                    <!-- <span><b>Ordered Date: </b></span> <span>{{orders[0].BillDateTime
                                        | date:
                                        'MMM d,
                                        y, h:mm
                                        a'}}</span> -->
                                </div>

                                <div class="col-sm" style="text-align: right; width: 50%;">
                                    <!-- <b>Ordered Date & Time: </b> {{orders[0].BillDateTime
                                    | date:
                                    'MMM d,
                                    y, h:mm
                                    a'}}

                                    <br> -->
                                    <!-- <div><b>Delivery Date </b></div>
                                    <div>{{orders[0].OrderedDateTime | date:'MMM d,y, h:mm a'}}</div> -->

                                    <div class="float-right row">
                                        
                                        <div>
                                            <div><b>Delivery Date </b></div>
                                            <div>{{orders[0].didt | date:'MMM d,y, h:mm a'}}</div>
                                        </div>
                                        <div>
                                            <img src="https://www.kindpng.com/picc/m/154-1547142_transparent-calendar-icons-png-date-and-time-icon.png"
                                                style="width: 35px; height: 35px; margin-left: 5px;">
                                        </div>
                                    </div>

                                    <!-- <b>Delivery Date: </b>{{orders[0].OrderedDateTime
                                    | date:
                                    'MMM d,
                                    y, h:mm
                                    a'}} -->


                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- <p id="sub-heading">Order Track</p> -->
                </div>





                <div class="shop-form">
                    <!-- <div class="row">

                        

                      

                        <div class="row col-xl-12" style="margin-bottom: 10px;">

                            <div class="col-xl-6" style="margin-bottom: 10px;">
                                Order Id: #{{orders[0].Id}} <br>

                                <b>Delivery Address: </b><span>
                                    <b> Mr/ms.{{orders[0].CurrentAddresses[0].Name}}</b>
                                </span>

                                <div style="margin-left: 10px;">
                                    
                                    <div>
                                        <span>{{orders[0].CurrentAddresses[0].Address}}. </span>
                                    </div>
                                    <div>
                                        Ph. +{{orders[0].CustomerDetail[0].PhoneNo}}
                                    </div>

                                </div>
                            </div>


                            <div class="col-xl-6">

                                <b>Ordered Date & Time</b>
                                <div style="margin-left: 10px;">
                                    {{orders[0].BillDateTime
                                    | date:
                                    'MMM d,
                                    y, h:mm
                                    a'}}
                                </div>


                                <b>Delivery Date & Time</b>
                                <div style="margin-left: 10px;">
                                    {{orders[0].OrderedDateTime
                                    | date:
                                    'MMM d,
                                    y, h:mm
                                    a'}}
                                </div>
                            </div>
                        </div>
                    </div> -->


                    <table style="border: 1px solid;">

                        <!-- <thead style="background-color: gainsboro;">
                            <tr style="margin-top: 10px;" >
                                <th  style="font-size: 16px; margin-top: 10px;">Product Name</th>
                                <th class="center" style="font-size: 16px;">Qty/Kg</th>
                                <th class="center" style="font-size: 16px;">Total</th>
                            </tr>
                        </thead> -->
                        <tbody>
                            <tr style="font-weight: bold; background-color: gainsboro;">
                                <td>S.No</td>
                                <td>Image</td>
                                <td>Product Name</td>
                                <td>Qty/Kg</td>
                                <td>Amount</td>
                            </tr>

                            <tr *ngFor="let product of orders[0].OrderItems let i = index;">
                                <td>{{i+1}}.</td>

                                
                                    <td>
                                        <img style="width: 100px; height: 100px;" src="{{product.ImgUrl}}" alt="">
                                    </td>
                                

                                <td style="text-align: left;">{{product.Name.toUpperCase()}} {{product.oj ? '( '+product.oj+" )" : ""}}
                                    <br />
                                    <a *ngFor="let option of product.Options">
                                        ({{option.Name}})
                                    </a>
                                    <div *ngIf="product.n!=0"><a>{{product.n}}</a></div>
                                    <a *ngIf="product.CategoryId!=48" style="font-style: italic;">
                                        Cake Msg: "{{product.msg}}"</a>
                                </td>
                                <!-- <td>{{product.qy}}
                                    {{
                                    (product.CategoryId!=48)?
                                    ("Kg"):
                                    ("No")
                                    }}
                                </td> -->

<!-- after gst -->
                                <td *ngIf="product.pi!=7854">{{product.qy}} Kg</td>
                                <td *ngIf="product.pi==7854">{{product.qy}} No</td>
            
                                

                                <!-- <td>₹
                                    {{
                                    (product.CategoryId!=48)?
                                    (product.TotalAmount.toFixed(2)):
                                    (((product.Price)*(product.Quantity)).toFixed(2))
                                    }}</td> -->

                                    <td>₹
                                        {{
                                        
                                        (product.ta.toFixed(2))
                                        
                                        }}</td>
                            </tr>
                        </tbody>
                        <tr> 
                            <td></td>
                        <td colspan="4" *ngIf="orders[0].n.length>0" style="text-align: left;">
                        Order Note: {{orders[0].n}}
                    </td></tr>
                        <tbody>

                           
                           
                            <!-- <tr style="background-color: gainsboro;">
                                <td></td>
                                <td style="text-align: right;"><span>Sub Total:
                                        ₹{{(orders[0].BillAmount+orders[0].DiscAmount-(orders[0].Tax1+orders[0].Tax2)).toFixed(2)}}</span>

                                   
                                    <span>Tax 5%: ₹{{(orders[0].Tax1+orders[0].Tax2).toFixed(2)}}</span>
                                </td>
                                <td colspan="2" *ngIf="orders[0].DiscountRuleId!=13 || orders[0].DiscountRuleId==14">
                                    <h2>Total: ₹{{(orders[0].BillAmount+orders[0].DiscAmount).toFixed(2)}}</h2>

                                </td>
                                <td colspan="2" *ngIf="orders[0].DiscountRuleId==13 || orders[0].DiscountRuleId==14">
                                    <div>
                                        <span style="text-decoration: line-through;">Total:
                                            ₹{{(orders[0].BillAmount+orders[0].DiscAmount).toFixed(2)}}</span><br>
                                        <span style="color: red;">Coopen Amount (10%):
                                            ₹{{(orders[0].DiscAmount).toFixed(2)}}</span><br>
                                        <h2>Total: ₹{{(orders[0].BillAmount).toFixed(2)}}</h2>
                                    </div>
                                </td>
                            </tr> -->


                            <tr style="background-color: gainsboro;">
                                <td></td>
                                


                                
                                <td colspan="3"  style="text-align: right;">
                                    <span style="font-size: 12px;">Sub Total: ₹{{getTotal().toFixed(2)}}</span><br>
                                    <span style="font-size: 12px;">Tax Amount (18%): ₹{{(getTotal()*18/100).toFixed(2)}}</span>

                                </td>
                                <td></td>
                                
                                
                            </tr>



                             <tr style="background-color: gainsboro;" style="text-align: right;">
                                <td>
                                    
                                </td>


                                
                                <!-- <td colspan="3" *ngIf="orders[0].DiscountRuleId!=13 || orders[0].DiscountRuleId==14">
                                    <h2>Total <span style="font-size: 12px;">(Tax Inclusive)</span> - ₹{{(orders[0].ba).toFixed(2)}}</h2>

                                </td> -->

                                <td colspan="3" *ngIf="orders[0].dri!=5001">
                                    <h2>Total <span style="font-size: 12px;">(Tax Inclusive)</span>:  ₹{{(orders[0].ba).toFixed(2)}}</h2>

                                </td>

                                <td colspan="3" *ngIf="orders[0].dri==5001">
                                    <span>Total <span style="font-size: 12px;">(Tax Inclusive)</span>: <s>₹{{ ((getTotal())+(getTotal()*18/100)).toFixed(2) }}</s></span><br>
                                    <span style="color: rgb(94, 31, 31);">Discount -₹{{(orders[0].da).toFixed(2)}}</span><br>
                                    <h2>Total <span style="font-size: 12px;">(Tax Inclusive)</span>: ₹{{(orders[0].ba).toFixed(2)}}</h2>
                                </td>


                                <td></td>
                                
                            </tr>

                            






                            <tr style="border-top: #cccccc 1px solid;">
                                <td></td>
                                <td>
                                    <span><b>Enquiry / Complaint Order: </b></span>
                                    <a href="tel:+91-900-343-2888">9003432888</a>
                                </td>
                                <td colspan="2"><b>PayType: </b>
                                    <span>{{orders[0].Transactions[0].PaymentTypeId==1 ? "Cash on Delivery":
                                        orders[0].Transactions[0].PaymentTypeId==4 ? "Online " :
                                        orders[0].Transactions[0].PaymentTypeId==8 ? "Online " :
                                        orders[0].Transactions[0].PaymentTypeId==6 ? "COD " :
                                        "Payment Error!!!"
                                        }}</span>

                                    <!-- <a href="javascript:void(0)" (click)="payHistory(orders[0].Transactions[0].Notes)"
                                        *ngIf="orders[0].Transactions[0].PaymentTypeId==4 || orders[0].Transactions[0].PaymentTypeId==8">
                                        {{orders[0].Transactions[0].PaymentStatusId==1 ?
                                        "(Success)":
                                        orders[0].Transactions[0].PaymentStatusId==0 ? "(Pending) " : orders[0].Transactions[0].PaymentStatusId==-1 ? "(Declined) " :
                                        "(Failed) "}}</a> -->

                                    <!-- <a href="javascript:void(0)"
                                        *ngIf="orders[0].Transactions[0].PaymentTypeId==4 || orders[0].Transactions[0].PaymentTypeId==8">
                                        <span *ngIf="orders[0].Transactions[0].PaymentStatusId==-1"
                                            style="color: red;">(Declined)</span>
                                        <span *ngIf="orders[0].Transactions[0].PaymentStatusId==0"
                                            style="color: blue;">(Pending)</span>
                                        <span *ngIf="orders[0].Transactions[0].PaymentStatusId==1"
                                            style="color: green;">(Success)</span>
                                    </a> -->

                                    <a 
                                    *ngIf="orders[0].Transactions[0].PaymentTypeId==8">
                                    <span *ngIf="orders[0].Transactions[0].PaymentStatusId==-1"
                                        >Declined <span><a href="javascript:void(0)" style="color: blue;" 
                                            (click)="repayOnline(orders[0])">(Try Again)</a></span>
                                    </span>
                                    <span *ngIf="orders[0].Transactions[0].PaymentStatusId==0"
                                        >Pending 
                                        <span><a href="javascript:void(0)" style="color: blue;" 
                                            (click)="repayOnline(orders[0])">(Try Again)</a></span>
                                    </span>
                                    <span *ngIf="orders[0].Transactions[0].PaymentStatusId==1" (click)="payHistory(orders[0].Transactions[0].Notes)"
                                        >Success</span>
                                </a>


                                <a 
                                    *ngIf="orders[0].Transactions[0].PaymentTypeId==4">
                                    <span *ngIf="orders[0].Transactions[0].PaymentStatusId==-1"
                                        >Declined <span><a href="javascript:void(0)" style="color: blue;" 
                                            (click)="repayOnline2(orders[0])">(Try Again)</a></span>
                                    </span>
                                    <span *ngIf="orders[0].Transactions[0].PaymentStatusId==0"
                                        >Pending 
                                        <span><a href="javascript:void(0)" style="color: blue;" 
                                            (click)="repayOnline2(orders[0])">(Try Again)</a></span>
                                    </span>
                                    <span *ngIf="orders[0].Transactions[0].PaymentStatusId==1" (click)="payHistory(orders[0].Transactions[0].Notes)"
                                        >Success</span>
                                </a>



                                    <!-- <span *ngIf="orders[0].Transactions[0].PaymentTypeId==4">
                                                <a href="javascript:void(0)" *ngIf="orders[0].Transactions[0].PaymentStatusId != 1" (click)="changeCOD(orders[0])">(Req. to change COD)</a>
                                            </span> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- <div>
                        <label class="btn btn-danger" style="margin-top: 20px;" (click)="download_order()">Download Copy
                        </label>
                    </div>
                    <hr> -->

                    <div>
                        <table style="border: 1px solid;">
                            <tbody>
                                <tr style="font-weight: bold; background-color: gainsboro;">
                                    <td>Tranc Id</td>
                                    <td>Amount</td>
                                    <td>PaymentType</td>
                                    <td>PaymentStatus</td>
                                </tr>
    
                                <tr *ngFor="let data of orders[0].Transactions let i = index;">
                                    <!-- <td>{{i+1}}.</td> -->
                                    <td>{{data.TransactionId}}
                                      <span *ngIf="data.PaymentTypeId==4 || data.PaymentTypeId==8">_{{data.Notes}}</span>  
                                    </td>
                                    <td>₹{{data.Amount}}</td>
                                    <td>{{data.PaymentTypeId==1 ? 'COD' : data.PaymentTypeId==6 ? 'COD' : data.PaymentTypeId==4 ? 'Online' : data.PaymentTypeId==8 ? 'Online' : 'Others'}}</td>
                                   
                                    <td>
                                        <div *ngIf="data.PaymentTypeId==1 || data.PaymentTypeId==6"> 
                                            <span *ngIf="orders[0].OrderStatusId!=5">Cash not Recived</span>
                                            <span *ngIf="orders[0].OrderStatusId==5">Cash Received</span>
                                        </div>
                                       
                                        <!-- PaymentTypeId Insto -->
                                        <div *ngIf="data.PaymentTypeId==4">
                                            <!-- <span *ngIf="data.TransTypeId==1">
                                                {{data.PaymentStatusId==0 ? 'Pending' : data.PaymentStatusId==1 ? 'Success' : data.PaymentStatusId==-1 ? 'Declined' : 'Others' }}
                                     
                                            </span> -->

                                            <span *ngIf="data.TransTypeId==1">
                                                <span *ngIf="data.PaymentStatusId==0">Pending
                                                    <a href="javascript:void(0)" style="color: blue;" (click)="payHistory(orders[0].Transactions[i].Notes)" >(Pay Info)
                                                    </a>
                                                </span>

                                                <span *ngIf="data.PaymentStatusId==1">Success
                                                    <!-- <a href="javascript:void(0)" style="color: blue;" (click)="payHistory(orders[0].Transactions[i].Notes)" >(Pay Info)
                                                    </a> -->
                                                </span>

                                                <span *ngIf="data.PaymentStatusId==-1">Declined
                                                    <a href="javascript:void(0)" style="color: blue;" (click)="payHistory(orders[0].Transactions[i].Notes,i)" >(Pay Info)
                                                    </a>
                                                </span>
                                            </span>

                                        
                                                
                                                <span *ngIf="data.TransTypeId==2">
                                                    Refund</span>

                                        </div>


                                        <!-- PaymentTypeId phonepay -->
                                        <div *ngIf="data.PaymentTypeId==8">
                                            <span *ngIf="data.TransTypeId==1">
                                                {{data.PaymentStatusId==0 ? 'Pending' : data.PaymentStatusId==1 ? 'Success' : data.PaymentStatusId==-1 ? 'Declined' : 'Others' }}
                                     
                                            </span>

                                            <!-- <span *ngIf="data.TransTypeId==1">
                                                <span *ngIf="data.PaymentStatusId==0">Pending
                                                    <a href="javascript:void(0)" style="color: blue;" (click)="payHistory(orders[0].Transactions[0].Notes)" >(Pay Info)
                                                    </a>
                                                </span>

                                                <span *ngIf="data.PaymentStatusId==1">Success
                                                    <a href="javascript:void(0)" style="color: blue;" (click)="payHistory(orders[0].Transactions[0].Notes)" >(Pay Info)
                                                    </a>
                                                </span>

                                                <span *ngIf="data.PaymentStatusId==-1">Declined
                                                    <a href="javascript:void(0)" style="color: blue;" (click)="payHistory(orders[0].Transactions[0].Notes)" >(Pay Info)
                                                    </a>
                                                </span>
                                            </span> -->

                                        
                                                
                                                <span *ngIf="data.TransTypeId==2">
                                                    Refund</span>

                                        </div>
                                        
                                    </td>

                        </table>
                    </div>

                    
                    <div>
                        <label class="btn btn-danger" style="margin-top: 20px;" (click)="download_order()">Download Copy
                        </label>
                    </div>
                    <hr>

                    <div *ngIf="paydetail.length!=0 && orders[0].Transactions[0].PaymentTypeId==4">
                        <h3>Transaction Details</h3>


                        <div class="container">
                            <div class="row">
                                <div class="col-sm" style="text-align: left; width: 50%;">
                                    {{paydetail.purpose}}
                                </div>

                                <div class="col-sm" style="text-align: right; width: 50%;">
                                    Req.Id: {{paydetail.id}}
                                </div>
                            </div>
                        </div>

                        <br>

                        <!-- <table>
                            <thead>
                                <tr>
                                    <th class="center">Created_at</th>
                                    <th class="center">Payment Id</th>
                                    <th class="center">Name</th>
                                    <th class="center">Billing_instrument</th>
                                    <th class="center">Amount</th>
                                    <th class="center">status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let trans of paydetail.payments">
                                    <td style="text-align: left;">{{trans.created_at}}</td>
                                    <td style="text-align: left;">{{trans.payment_id}}</td>
                                    <td style="text-align: left;">{{trans.buyer_name}}</td>
                                    <td style="text-align: left;">{{trans.billing_instrument}}</td>
                                    <td style="text-align: left;">{{trans.amount}}</td>
                                    <td style="text-align: left;">{{trans.status}}</td>
                                </tr>
                            </tbody>
                        </table> -->


                        <table *ngFor="let trans of paydetail.payments">

                            <tbody *ngIf="trans" style="border: 1px solid black;">
                                <tr>
                                    <td style="text-align: left;">Created_at</td>
                                    <td style="text-align: left;">{{trans.created_at | date:
                                        'MMM d,
                                        y, h:mm
                                        a'}}</td>
                                </tr>
                                <tr>
                                    <td style="text-align: left;">Payment Id</td>
                                    <td style="text-align: left;">{{trans.payment_id}}</td>
                                </tr>
                                <tr>
                                    <td style="text-align: left;">Name</td>
                                    <td style="text-align: left;">{{trans.buyer_name}}</td>
                                </tr>
                                <tr>
                                    <td style="text-align: left;">Billing_instrument</td>
                                    <td style="text-align: left;">{{trans.billing_instrument}}</td>
                                </tr>
                                <tr>
                                    <td style="text-align: left;">Amount</td>
                                    <td style="text-align: left;">{{trans.amount}}</td>
                                </tr>
                                <tr>
                                    <td style="text-align: left;">status</td>
                                    <td style="text-align: left;">{{trans.status}}</td>
                                </tr>

                                <tr *ngIf="trans.failure">
                                    <td style="text-align: left;">Failure</td>
                                    <td style="text-align: left;">{{trans.failure.reason}} - {{trans.failure.message}}
                                    </td>
                                </tr>
                            </tbody>


                        </table>

                        <table *ngIf="paydetail.payments.length==0 && orders[0].Transactions[0].PaymentTypeId==4">



                            <tbody style="border: 1px solid black;">
                                <tr>
                                    <td style="text-align: left;">Payment</td>
                                    <td style="text-align: left;">Transaction Incomplete
                                        <!-- <a href="javascript:void(0)" (click)="retryPay(orders[0])">(Retry Payment)</a> -->
                                    </td>

                                </tr>

                                <tr>
                                    <td style="text-align: left;">Payment Type</td>
                                    <td style="text-align: left;">Change to
                                        <a href="javascript:void(0)" (click)="changeCOD(orders[0])">(Cash on
                                            Delivery)</a>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>



                </div>
            </div>
        </div>