import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footerc',
  templateUrl: './footerc.component.html',
  styleUrls: ['./footerc.component.css']
})
export class FootercComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(2, currentScroll - (currentScroll / 8));
      }
    })();
  }

  whatsup()
  {
    window.open("https://wa.me/919003432888", '_blank');
  }

  map()
  {
    window.open("https://tinyurl.com/ywup3twe", '_blank');
  }

  phone()
  {
    window.open(`tel:${9003432888}`, '_self');
  }
  // <a href="tel:+91-900-343-2888">+91 9003432888</a>
  
}

