import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-headerc',
  templateUrl: './headerc.component.html',
  styleUrls: ['./headerc.component.css']
})
export class HeadercComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  setheadertype(type) {
    localStorage.setItem("header_type", type)
  }


}
