import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'
import { Router } from '@angular/router'
import { ItemDetailsComponent } from '../item-details/item-details.component';
import { DeviceDetectorService } from "ngx-device-detector";



@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.css'],
  host: {
    "(window:resize)": "onWindowResize($event)"
  }
})
export class ItemListComponent implements OnInit {

demopro=
{
  "categories": [
      {
          "Id": 1,
          "Description": "Bread",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 2,
          "Description": "Banana",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 3,
          "Description": "Sweets",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 4,
          "Description": "Savorious",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 5,
          "Description": "Chocolate",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 6,
          "Description": "Accessories",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 7,
          "Description": "Dry Cake",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 8,
          "Description": "Donuts",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 9,
          "Description": "Tres Leches",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 10,
          "Description": "Milkshake",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 11,
          "Description": "Cookies",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 12,
          "Description": "Browine Blast",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 13,
          "Description": "Browine",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 14,
          "Description": "Fresh Cream Cakes",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      },
      {
          "Id": 15,
          "Description": "Jar",
          "FreeQtyPercentage": null,
          "MinimumQty": null,
          "IsUPCategory": false,
          "IsSynced": false,
          "isactive": true,
          "SortOrder": null,
          "ParentCategoryId": null,
          "ParentCategory": null,
          "CompanyId": 0,
          "Company": null,
          "CreatedDate": "0001-01-01T00:00:00",
          "ModifiedDate": "0001-01-01T00:00:00"
      }
  ],
  "products": [
      {
          "Id": 487,
          "Name": "VANILLA 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 340.0,
          "TakeawayPrice": 340.0,
          "DeliveryPrice": 340.0,
          "UPPrice": 340.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 488,
          "Name": "BLACK FOREST 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 390.0,
          "TakeawayPrice": 390.0,
          "DeliveryPrice": 390.0,
          "UPPrice": 390.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 489,
          "Name": "CHOCOLATE 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 390.0,
          "TakeawayPrice": 390.0,
          "DeliveryPrice": 390.0,
          "UPPrice": 390.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 490,
          "Name": "MANGO 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 390.0,
          "TakeawayPrice": 390.0,
          "DeliveryPrice": 390.0,
          "UPPrice": 390.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
     
      {
          "Id": 492,
          "Name": "PINEAPPLE 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 430.0,
          "TakeawayPrice": 430.0,
          "DeliveryPrice": 430.0,
          "UPPrice": 430.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 493,
          "Name": "BLACK CURRANT 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 470.0,
          "TakeawayPrice": 470.0,
          "DeliveryPrice": 470.0,
          "UPPrice": 470.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 494,
          "Name": "STRAWBERRY 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 470.0,
          "TakeawayPrice": 470.0,
          "DeliveryPrice": 470.0,
          "UPPrice": 470.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 495,
          "Name": "BUTTER SCOTCH 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 490.0,
          "TakeawayPrice": 490.0,
          "DeliveryPrice": 490.0,
          "UPPrice": 490.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
     
      {
          "Id": 497,
          "Name": "WHITE FOREST 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 490.0,
          "TakeawayPrice": 490.0,
          "DeliveryPrice": 490.0,
          "UPPrice": 490.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 498,
          "Name": "BLUEBERRY 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 530.0,
          "TakeawayPrice": 530.0,
          "DeliveryPrice": 530.0,
          "UPPrice": 530.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 499,
          "Name": "CHOCO SCOTCH 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 530.0,
          "TakeawayPrice": 530.0,
          "DeliveryPrice": 530.0,
          "UPPrice": 530.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 500,
          "Name": "CHOCO STRAWBERRY 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 530.0,
          "TakeawayPrice": 530.0,
          "DeliveryPrice": 530.0,
          "UPPrice": 530.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 501,
          "Name": "CHOCO TRUFFLE 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 530.0,
          "TakeawayPrice": 530.0,
          "DeliveryPrice": 530.0,
          "UPPrice": 530.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 502,
          "Name": "CHOCO VANILLA 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 530.0,
          "TakeawayPrice": 530.0,
          "DeliveryPrice": 530.0,
          "UPPrice": 530.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 503,
          "Name": "FERRO SCOTCH 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 530.0,
          "TakeawayPrice": 530.0,
          "DeliveryPrice": 530.0,
          "UPPrice": 530.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 504,
          "Name": "FERRO TRUFFLE 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 530.0,
          "TakeawayPrice": 530.0,
          "DeliveryPrice": 530.0,
          "UPPrice": 530.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 511,
          "Name": "RED VELVET 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 540.0,
          "TakeawayPrice": 540.0,
          "DeliveryPrice": 540.0,
          "UPPrice": 540.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 512,
          "Name": "BLACK FOREST BLAST 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 550.0,
          "TakeawayPrice": 550.0,
          "DeliveryPrice": 550.0,
          "UPPrice": 550.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 513,
          "Name": "WHITE FOREST BLAST 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 550.0,
          "TakeawayPrice": 550.0,
          "DeliveryPrice": 550.0,
          "UPPrice": 550.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      
     
     
      
      {
          "Id": 518,
          "Name": "BLACK CURRANT BLAST 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 590.0,
          "TakeawayPrice": 590.0,
          "DeliveryPrice": 590.0,
          "UPPrice": 590.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      
      
    
    
      {
          "Id": 526,
          "Name": "BLUEBERRY BLAST 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 640.0,
          "TakeawayPrice": 640.0,
          "DeliveryPrice": 640.0,
          "UPPrice": 640.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 527,
          "Name": "CHOCO TRUFFLE BLAST 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 640.0,
          "TakeawayPrice": 640.0,
          "DeliveryPrice": 640.0,
          "UPPrice": 640.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 528,
          "Name": "FERRO BLAST 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 640.0,
          "TakeawayPrice": 640.0,
          "DeliveryPrice": 640.0,
          "UPPrice": 640.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 529,
          "Name": "MILKY BLAST 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 640.0,
          "TakeawayPrice": 640.0,
          "DeliveryPrice": 640.0,
          "UPPrice": 640.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 530,
          "Name": "PINEAPPLE BLAST 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 640.0,
          "TakeawayPrice": 640.0,
          "DeliveryPrice": 640.0,
          "UPPrice": 640.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      },
      {
          "Id": 531,
          "Name": "RASAMALAI BLAST 0.5 Kg",
          "Description": null,
          "UnitId": 2,
          "Unit": null,
          "CategoryId": 14,
          "Category": {
              "Id": 14,
              "Description": "Fresh Cream Cakes",
              "FreeQtyPercentage": null,
              "MinimumQty": null,
              "IsUPCategory": false,
              "IsSynced": false,
              "isactive": true,
              "SortOrder": null,
              "ParentCategoryId": null,
              "ParentCategory": null,
              "CompanyId": 0,
              "Company": null,
              "CreatedDate": "0001-01-01T00:00:00",
              "ModifiedDate": "0001-01-01T00:00:00"
          },
          "Price": 640.0,
          "TakeawayPrice": 640.0,
          "DeliveryPrice": 640.0,
          "UPPrice": 640.0,
          "ImgUrl": null,
          "ProductCode": null,
          "BarCode": null,
          "isactive": true,
          "isonline": true,
          "SortOrder": null,
          "GroupSortOrder": null,
          "Recomended": true,
          "minquantity": null,
          "minblock": null,
          "TaxGroupId": 8,
          "TaxGroup": null,
          "KOTGroupId": null,
          "KOTGroup": null,
          "Image": null,
          "ProductTypeId": 1,
          "ProductType": null,
          "CreatedDate": "2020-04-03T10:36:23.1375371",
          "ModifiedDate": "2020-04-03T10:36:23.1375371",
          "CompanyId": 0,
          "Company": null,
          "IsSaleProdGroup": null,
          "IsQtyPredefined": null,
          "ProductName": null
      }
      
  ],
  "options": [],
  "predefinedquantities": []
}

  public products = [];
  public myproducts = [];
  categories = [];
  p: number = 1
  // SPGdata=[];
  parentcategories = [];
  categoryId = 0;
  options = [];
  baseurl = window.location.origin;
  drawer: any;
  deviceService: any;
  showsearch: boolean = false
  constructor(private auth: AuthService, private router: Router, deviceService: DeviceDetectorService,) {
    this.auth.showsearch.subscribe(ss => {
      this.showsearch = ss
    })
  }
  isMobile: boolean = false;
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  mobileWidth: number = 560;
  predefinedquantities = []
  saleProductId: number = 0
  productId: number = 0
  event2 = [
    {
      name: 'cakes and prices',
      link: 'https://fbcakes.com/offer/cakes-and-prices'
    },

    {
      name: 'cakes near me',
      link: 'https://fbcakes.com/offer/cakes-near-me'
    },
    {
      name: 'cakes for birthday',
      link: 'https://fbcakes.com/offer/cakes-for-birthday'
    },
    {
      name: 'cakes for girls',
      link: 'https://fbcakes.com/offer/cakes-for-girls'
    },
    {
      name: 'cakes for boys',
      link: 'https://fbcakes.com/offer/cakes-for-boys'
    },
    {
      name: 'cakes online',
      link: 'https://fbcakes.com/offer/cakes-online'
    },




    {
      name: 'cakes for kids',
      link: 'https://fbcakes.com/offer/cakes-for-kids'
    },
    {
      name: 'cake design',
      link: 'https://fbcakes.com/offer/cake-design'
    },
    {
      name: 'cakes for anniversary',
      link: 'https://fbcakes.com/offer/cakes-for-anniversary'
    },
    {
      name: 'cakes for children birthday',
      link: 'https://fbcakes.com/offer/cakes-for-children-birthday'
    },
    {
      name: 'cakes for girls birthday',
      link: 'https://fbcakes.com/offer/cakes-for-girls-birthday'
    },



    {
      name: 'cakes at low price',
      link: 'https://fbcakes.com/offer/cakes-at-low-price'
    },

    {
      name: 'cakes available near me',
      link: 'https://fbcakes.com'
    },
    {
      name: 'cakes below 300',
      link: 'https://fbcakes.com/offer/cakes-below-300'
    },
    {
      name: 'cakes below 500',
      link: 'https://fbcakes.com/offer/cakes-below-500'
    },



    {
      name: 'birthday cakes for girls',
      link: 'https://fbcakes.com/offer/birthday-cakes-for-girls'
    },
    {
      name: 'birthday cakes for boys',
      link: 'https://fbcakes.com/offer/birthday-cakes-for-boys'
    },
    {
      name: 'baby shower cakes for girls',
      link: 'https://fbcakes.com'
    },
    {
      name: 'birthday cakes for men',
      link: 'https://fbcakes.com'
    },
    {
      name: 'baby shower cakes for boys',
      link: 'https://fbcakes.com'
    },
    {
      name: 'birthday cakes for women',
      link: 'https://fbcakes.com/offer/birthday-cakes-for-women'
    },
  ]

  tags = [
    {id:1,
      name: "Blast Cakes",
    },
    {id:2,
      name: "Couples",
    }
  ]

  store = [
    {
      "Id": 1,
      "cmd": "Ordered first time and Cake is awesome and was delivered nicely. Thanks a lot FB. Am going to recommend this to all 💖",
      "name": "Archana` Mukherjee",
      "img": "https://content3.jdmagicbox.com/comp/chennai/n8/044pxx44.xx44.200922125720.c6n8/catalogue/fb-cakes-poonamallee-chennai-cake-shops-0j33pv4s4f.jpg"
    },
    {
      "Id": 2,
      "cmd": "It was good...Just one thing tracking was not updated",
      "name": "Jayasubramaniam",
      "img": "https://content.jdmagicbox.com/comp/madurai/q6/0452px452.x452.220626204824.k6q6/catalogue/fb-cakes-goripalayam-madurai-cake-shops-qhbc7r14xb.jpg"
    },
    {
      "Id": 3,
      "cmd": "The cake was delicious, but the delivery system is too good as the cake came 1/2 hr before the allotted time 😅. (I didn't have a place to keep the cake)",
      "name": "Shaurya Dev Pathak",
      "img": "https://img.restaurantguru.com/r9fe-FB-CAKES-alcohol.jpg"
    },

    {
      "Id": 4,
      "cmd": "So Delicious.. Thank You So Much",
      "name": "Anitha Raj",
      "img": "https://iili.io/SbE971.jpg"
    },

    {
      "Id": 5,
      "cmd": "Nice Service...On Tym Correct Delivery 😍😍😍",
      "name": "Aysha Parveen",
      "img": "https://b.zmtcdn.com/data/reviews_photos/817/9bf8d8aa2091246546173a720b712817_1641205460.jpg"
    },
    {
      "Id": 6,
      "cmd": "There Is No Word To Say... More Soft, Extraordinary And Tasty....",
      "name": "K Rahini",
      "img": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZBk9lr-vXs38SHD63hcFOMmzL17AYhZTo_7HWKkxXt7UbYqsTa6ooCfdYNWFjX9az-b8&usqp=CAU"   }
    
   
  ]


  ngOnInit(): void {
    this.myamount();
    this.isMobile = this.width < this.mobileWidth;
    this.getproducts();
    // this.getLocation()
    console.log("demopro :",this.demopro)
   
  
  }


myTags(id)
{
console.log("My ID: ",id)
}

  keyword = 'Name';

  public searchproduct;
  prodFilterId: number = 0
  selectEvent(item) {
    console.log("Selected Product", item)
    this.prodFilterId = item.Id
    this.p = 1;
    // this.productId = item.Id
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something
  }

  public countrie = [
    {
      id: 1,
      Name: 'Albania',
    },
    {
      id: 2,
      Name: 'Belgium',
    }]

    public cat = [
      {
        id: 4529,
        Name: 'Instant Cakes',
      },
      {
        id: 5762,
        Name: 'Love Cakes',
      },
      {
        id: 5764,
        Name: 'Blast Cakes (Buy 1 kg get 1 kg Free!)',
      },
      {
        id: 5767,
        Name: 'Model Cakes',
      },
    ]

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    this.isMobile = this.width < this.mobileWidth;
  }

  SPGdata
  getproducts() {
    this.auth.getproducts(1000).subscribe(data => {
      console.log("My log",data);
      this.products = data["products"]
      this.searchproduct = data["products"]
      this.options = data["options"]
      // this.categories = data["categories"].filter(x => x.ParentCategoryId != null);
      // this.categories = data["categories"].filter(x => x.ParentCategoryId != null && x.Id>=845 && x.Id<=848);
      this.categories = data["products"].filter(x => x.CompanyId == 3 && x.IsSaleProdGroup == true && x.isonline == true
        //&& x.IsSaleProdGroup==true && x.isonline==true &&  x.ParentCategoryId != null
      ).sort((a, b) => a.SortOrder - b.SortOrder);
      console.log("My new Categories:", this.categories)
     
      //this.categoryId = this.categories[0].Id;
      this.categoryId = 4529;
     
      console.log("Top Category Id", this.categoryId)
      this.predefinedquantities = data["predefinedquantities"]
      console.log("predefinedquantities:", this.predefinedquantities)

      this.auth.getSPG_Products(this.categoryId).subscribe(data => {
        this.SPGdata = data['SPG_list']
        this.products = data["SPG_list"].sort((a, b) => a.SortOrder - b.SortOrder);
      
        this.productId = 0

        this.saleProductId = 0
        console.log("All Products", this.products)
        console.log("All Products2", this.SPGdata)
      })
    })
  }


  getSpgProds(spgid) {
    console.log("Cat Id",spgid)
    this.p = 1;
    this.prodFilterId = 0
    this.categoryId = spgid
    this.auth.getSPG_Products(this.categoryId).subscribe(data => {
      console.log("SPG_id", spgid)
      console.log("SPG_list1", data['SPG_list'])
      // this.SPGdata = data['SPG_list']
      // this.products = data["SPG_list"]
      this.SPGdata = data['SPG_list'].sort((a, b) => a.SortOrder - b.SortOrder);
      this.products = data["SPG_list"].sort((a, b) => a.SortOrder - b.SortOrder);
      this.productId = 0
      this.saleProductId = 0
      console.log("SPGdata", this.SPGdata)
      console.log("products", this.products)
    })
  }

  getSpgProdsmob(spgid) {
    console.log("Cat Id",spgid)
    this.p = 1;
    this.prodFilterId = 0
    this.categoryId = spgid
    this.auth.getSPG_Products(this.categoryId).subscribe(data => {
      console.log("SPG_id", spgid)
      console.log("SPG_list1", data['SPG_list'])
      // this.SPGdata = data['SPG_list']
      // this.products = data["SPG_list"]
      this.SPGdata = data['SPG_list'].sort((a, b) => a.SortOrder - b.SortOrder);
      this.products = data["SPG_list"].sort((a, b) => a.SortOrder - b.SortOrder);
      this.productId = 0
      this.saleProductId = 0
      console.log("SPGdata", this.SPGdata)
      console.log("products", this.products)
      this.scrollToTop();
    })
  }

  // getspgLowtoHigh()
  // {
  //   console.log("Low to High",this.SPGdata);
  // }


  getproductbycategory() {
    // this.SPGdata.forEach(option => {
    //   console.log("StockProductId:", option.StockProductId)
    //   this.myproducts = this.products.filter(x => x.Id == option.StockProductId);
    // })
    // console.log("Myproducts", this.myproducts)
    //  return this.products.filter(x => x.Id == this.categoryId);
  //  console.log("R Pro: ",this.products.filter(x => x.Id == this.prodFilterId || this.prodFilterId == 0 && x.ImgUrl!=null))
    return this.products.filter(x => x.Id == this.prodFilterId || this.prodFilterId == 0 && x.ImgUrl!=null);
    
  }


  getoption(productId) {
    // return this.options.filter(x => x.ProductId == productId)
  }



  getpredefinedquantities(productId) {
  //   if (this.predefinedquantities)
  //  // console.log("___getpredefinedquantities",this.predefinedquantities)
  //     return this.predefinedquantities.filter((x, i) => x.ProductId == productId).filter((x, i) => i < 2);
  //   else return []

  console.log("Cate Id: ",productId)
    if (this.predefinedquantities)
    {
      if(this.categoryId==5762 || this.categoryId==5767)
    {
      // return this.predefinedquantities.filter((x, i) => x.ProductId == productId).filter((x, i) => i>0 && i<3);
      return this.predefinedquantities.filter((x, i) => x.ProductId == productId).filter((x, i) => i < 2);
    }
    else{
      return this.predefinedquantities.filter((x, i) => x.ProductId == productId).filter((x, i) => i < 2);
    }

    }else return []
    
  }



  //Hi, sry i have no experience in MVC based project. i just worked in .Net core web api (BAcked only)

  // getpredefinedquantities(productId)
  // {
  //   return this.predefinedquantities.filter(x => x.ProductId == productId);
  // }

  setcategoryid(id) {
    console.log("Category Id", id)
    this.categoryId = id;
    this.scrollToTop();
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(2, currentScroll - (currentScroll / 8));
      }
    })();
  }

  ChatWeb() {
    (function myChatWeb() {
      console.log("Its Works")
    })();
  }

  selectproduct(product) {
    product.quantity = 1;
    localStorage.setItem("selected_item", JSON.stringify(product));
    localStorage.setItem("selected_item_qtys", JSON.stringify(this.predefinedquantities.filter(x => x.ProductId == product.Id)));
    //  this.router.navigate(['/itemdetails']);
    // document.location.href = this.baseurl + '/#/itemdetails'
    //  this.router.navigate([]).then(result => {  window.open(document.location.href = this.baseurl + '/#/itemdetails', '_blank'); });
    const pname = product.Name.replace(/\s/g, "-");
    // console.log("pname",pname.replace(/\s/g, "-"))
    this.router.navigate([]).then(result => {
      window.open(this.baseurl + '/cakes/' + pname.toLowerCase() + '-cake-' + product.Id, '_blank');
      // this.router.navigate([]).then(result => { window.open(this.baseurl + '/itemdetails/'+product.Name, '_blank'); 
      localStorage.setItem("Pid", product.Id);
      console.log("Pid", product.Id)
      //this.router.navigate([]).then(result => { window.open(this.baseurl + '/itemdetails/'+/product.Name+'-'+product.Id, '_blank'); 
      // this.router.navigate([]).then(result => {  window.open('/orderlist/' + order.Id, '_blank'); });
    });

  }

  selectproduct5(product) {
    console.log("Pid", product.Id)
  }


  public lat;
  public lng;
  deviceInfo = null;

  // getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position: Position) => {
  //       if (position) {
  //         console.log("Latitude: " + position.coords.latitude +
  //           "Longitude: " + position.coords.longitude);
  //         this.lat = position.coords.latitude;
  //         this.lng = position.coords.longitude;

  //         console.log(this.lat);
  //         console.log(this.lng);

  //         var data={
  //           lat:this.lat,
  //           lng:this.lng
  //         };



  //        localStorage.setItem("LOC", JSON.stringify(data));
  //       }
  //     },
  //       (error: PositionError) => console.log(error));
  //   } else {
  //     console.log("Geolocation is not supported by this browser.")
  //   }
  // }

  

  myamount()
  {
var base=960
base=base+20;
console.log("0.5 Kg :",(base*0.5)+30)
console.log("1.5 Kg :",(base*1))
console.log("  3 Kg :",(base*2))
console.log("4.5 Kg :",(base*3))
console.log("  6 Kg :",(base*4))
  }

}