<div class="page-wraper">
   
    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                   
                    <h1 class="text-white">Cancellation and Refund Policy</h1>
                </div>
            </div>
        </div>

        <div class="section-full content-inner">
            <div class="container">
                <div class="shop-form">
                   

                    <h2><strong>Cancellation and Refund Policy</strong></h2> 

                    

                    

                    <p>Welcome to FBCakes!</p>

                    <p>www.fbcakes.com believes in helping its customers as far as possible and has therefore a liberal cancellation policy. Under this policy:</p>



                    <p>Cancellations will be considered only if the request is made before 8 AM on the date of delivery. </p>



                        <p>A 100% Refund will be provided if the cancellation request is made 1 to 2 days prior to the date of delivery otherwise the transactional charges of 10% will be deducted from your amount.</p>



                            <p>Cancellation of an order is not possible if the order is ready to move delivery. In any other cases, 50% * amount will be refunded.</p>

<p>If your Refund has been initiated. The (Credit card, Debit card, and Net banking) amount should get credited in 5 business days, and Upi Payments within 48 hours.</p>

                                <p>If you have any questions/comments about our Cancellation And Refund Policy, </p>

                                    <p>please email us at fbcakes.info@gmail.com</p>

                                        <p>Ph: 90034 32888</p>
                    
                   

                </div>

              
            </div>
           



        </div>