<div class="page-wraper">

    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 style="font-size: 26px;" class="text-white">Low Price Cakes Online | Free Delivery</h1>
                </div>
            </div>
        </div>

        <div class="section-full content-inner">
            <div class="container">
                <div class="row">
                    <div *ngFor="let product of myproduct"
                        class="col-xl-4 col-lg-6 col-md-12 col-sm-6 m-b30">

                        <div class="item-box shop-item style2" 
                            >
                            <div class="item-img" href="javascript:void(0);" (click)="selectproduct(product)">
                                <img *ngIf="!product.img" src="../../assets/images/product/item1.jpg" alt="">
                                <img *ngIf="product.img" src="{{product.img}}" 
                                alt="{{product.alt}}">
                            </div>
                            <div class="item-info text-center">
                                <h4 class="item-title font-12"><a>{{product.name}}</a></h4>


                                <div class="star-rating text-yellow">
                                    <div data-rating="3">
                                        <i class="fa fa-star" data-alt="1" title="regular"></i>
                                        <i class="fa fa-star" data-alt="2" title="regular"></i>
                                        <i class="fa fa-star" data-alt="3" title="regular"></i>
                                        <i class="fa fa-star" data-alt="4" title="regular"></i>
                                        <i class="fa fa-star-o" data-alt="5" title="regular"></i>
                                    </div>
                                </div>

                                <div>
                                        <span style="font-size: 24px; margin-top: 10px;">₹ {{product.price}}</span>
                            
                                </div>

                                <div class="cartbtn">
                                    <a href="javascript:void(0);" 
                                    (click)="selectproduct(product)" 
                                    class="btn btn-danger btnhover radius-xl">

                                    <i class="ti-shopping-cart"></i> View</a>

                                </div>
                            </div>
                        </div>

                    </div>
                
                     </div>

                     <div class="text-center" style="margin:20px;">
                        <button 
                        (click)="window()"
                        style="font-size: 25px; padding: 10px; font-weight: bold; border-radius: 20px;"
                        type="button" 
                        class="btn btn-danger">View More Cakes</button>
                    </div>
                    <br>
                    <hr>

                    
                    <br>
                    <div>

                        <h2>Cake Delivery in 5 Biggest Cities in South India With Free Shipping.</h2>


                            <table>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Cakes By Type</th>
                                    <th>Shipping Method</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Black Forest Cake</td>
                                    <td>Earliest 3 Hours Delivery</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Eggless Cake</td>
                                    <td>Normal Delivery</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Photo Cake</td>
                                    <td>Midnight Delivery</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Heart-shaped Cake</td>
                                    <td>Same Day Delivery</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Customized Cake</td>
                                    <td>Fixed Time Delivery</td>
                                </tr>

                                <tr>
                                    <td>5</td>
                                    <td>Delivery Cities</td>
                                    <td>Chennai, Bangalore, Coimbatore, Madurai, Thiruvallur</td>
                                </tr>

                            </table>

                    </div>
<br>
                    <div>
                        <h2>Our Flavours</h2>
                        <p>Black Forest Cake, Mango Flavor Cake, Pineapple Flavor Cake, Strawberry Flavor Cake, 
                            Black Current Cake, White Forest Cake, ButterScotch Cake, Choco Truffle Cake, 
                            Blueberry Cake, White Truffle Cake, Red Velvet Cake, Milky Blast Cake, 
                            Ferro Blast Cake, ButterScotch Blast Flavor Cake, Blueberry Blast Flavor Cake, Red Blast Flavor Cake, Bento Cake</p>

                    </div>

                    <br>

                    <div>
                        <h2>Upcoming Occasions</h2>
                    
                        <p *ngFor="let e of event">
                           
                            <a href={{e.link}}>{{e.name}}</a>

                        </p>
            </div>
        </div>




       