import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deliverypolicy',
  templateUrl: './deliverypolicy.component.html',
  styleUrls: ['./deliverypolicy.component.css']
})
export class DeliverypolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
