<div class="page-wraper">

    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">Feedback</h1>
                </div>
            </div>
        </div>

        <div class="section-full content-inner">
            <div class="container">
                <div class="shop-form">


                    <h1>Feedback / Complaint</h1>

                    <div class="row col-xl-12" *ngIf="order">
                        <div class="col-xl-6">

                            <span style="font-size:18px;">Mr/mrs. {{order.CurrentAddresses[0].Name}} | Ph.
                                {{order.CustomerDetail[0].PhoneNo.slice(2)}}</span>
                          
                                <br><br>
                          
                            <span>Order Id: {{order.Id}}</span>
                          
                            <!-- <div *ngFor="let item of order.OrderItems" style="margin: 10px;">
                                <div>
                                    * {{item.Name.toUpperCase()}}
                                    {{item.CategoryId!=48 ? "CAKE" : ""}}
                                    - {{item.Quantity}} {{item.CategoryId!=48 ? "Kg" : "No"}}
                                </div>
                            </div> -->


                            <table>

                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th><strong>Item Image</strong> </th>
                                    <th><strong>Item Name</strong> </th>
                                    <th><strong>Qty</strong> </th>
                                    <!-- <th><strong>Msg</strong> </th> -->
                                    <th><strong>Price</strong> </th>
                                  </tr>
                                </thead>
                          
                          
                                <tbody *ngFor="let ord of order.OrderItems; let i = index">
                          
                                  <tr>
                                    <td>{{i+1}}</td>
                                    <td>
                                      <img src={{ord.ImgUrl}} height="60px" width="60px" />
                                    </td>
                                    <td>{{ord.Name}} <span *ngIf="ord.note!='0'">(Make Eggless)</span></td>
                                    <td>{{ord.qty}} KG</td>
                                    <!-- <td>{{ord.msg}}</td> -->
                                    <td>₹ {{(ord.total).toFixed(0)}}</td>
                                  </tr>
                          
                                </tbody>

                                </table>
                                

                            <span>Bill Amount: ₹{{order.billamount}}</span>
                         
                            <br>
                            <br>
                          
                            <textarea rows="4" name="text" class="form-control"
                                style="width: 100%; margin-top: 5px; font-size: 17px;" [(ngModel)]="message"
                                placeholder="Feedback / Complaint"></textarea>
                                <br>

                                <div class="form-group files">
                                    <label class="my-auto">Attach your Feelings </label> 
                                    <input id="file" type="file" class="form-control" (change)="upload($event.target.files)"/>
                                    
                                </div>


                            <!-- <div class="row">
                                    <div class="form-check">
                                        <input 
                                        
                                        class="form-check-label" 
                                        type="radio" 
                                        class="form-check-input with-gap" 
                                        id="radioRev1"
                                        name="groupOfRadioGap" 
                                        [checked]="PaymentTypeId==1" 
                                        [value]="1"
                                        [(ngModel)]="PaymentTypeId">
                                        <label style="color: green;"
                                        class="form-check-label" 
                                        for="radioRev1">BEST</label>
                                    </div>
    
                                    <div class="form-check pull-right">
                                        <input 
                                        type="radio" 
                                        class="form-check-input with-gap" 
                                        id="radioRev2"
                                        name="groupOfRadioGap" 
                                        [checked]="PaymentTypeId==2" 
                                        [(ngModel)]="PaymentTypeId"
                                        [value]="0">
                                        <label style="color: orange;"
                                        class="form-check-label" 
                                        for="radioRev2">GOOD</label>
                                    </div>

                                    <div class="form-check pull-right">
                                        <input 
                                        type="radio" 
                                        class="form-check-input with-gap" 
                                        id="radioRev3"
                                        name="groupOfRadioGap" 
                                        [checked]="PaymentTypeId==2" 
                                        [(ngModel)]="PaymentTypeId"
                                        [value]="-1">
                                        <label style="color: red;"
                                        class="form-check-label" 
                                        for="radioRev3">BAD</label>
                                    </div>
                                </div> -->

                                <br>
                                <div class="row">

                                    <div class="form-check">
                                        <input type="radio" class="form-check-input with-gap" 
                                       
                                        id="radioGap1"
                                            name="groupOfRadioGap" 
                                            [checked]="star==1" 
                                            [value]="1"
                                            [(ngModel)]="star">
    
                                        <label class="form-check-label" for="radioGap1">Very Poor</label>
                                    </div>
    
                                    <div class="form-check pull-right">
                                        <input type="radio" class="form-check-input with-gap" 
                                       
                                        id="radioGap2"
                                            name="groupOfRadioGap" 
                                            [checked]="star==2" 
                                            [(ngModel)]="star"
                                            [value]="2">
    
                                        <label class="form-check-label" for="radioGap2">Poor</label>
                                    </div>
    
                                    <div class="form-check pull-right">
                                        <input type="radio" class="form-check-input with-gap" 
                                        
                                        id="radioGap3"
                                            name="groupOfRadioGap" 
                                            [checked]="star==3" 
                                            [(ngModel)]="star"
                                            [value]="3">
    
                                        <label class="form-check-label" for="radioGap3">Average</label>
                                    </div>

                                    <div class="form-check pull-right">
                                        <input type="radio" class="form-check-input with-gap" 
                                        
                                        id="radioGap4"
                                            name="groupOfRadioGap" 
                                            [checked]="star==4" 
                                            [(ngModel)]="star"
                                            [value]="4">
    
                                        <label class="form-check-label" for="radioGap4">Good</label>
                                    </div>

                                    <div class="form-check pull-right">
                                        <input type="radio" class="form-check-input with-gap" 
                                        
                                        id="radioGap5"
                                            name="groupOfRadioGap" 
                                            [checked]="star==5" 
                                            [(ngModel)]="star"
                                            [value]="5">
    
                                        <label class="form-check-label" for="radioGap5">Excellent</label>
                                    </div>
    
    
                                </div>

                            <label class="btn btn-danger" style="margin-top: 20px;" (click)="submit(order.OdrsId)">Submit
                            </label>
                        </div>
                    </div>
<div style="margin-top: 10px;">
    <!-- <span style="font-weight: bold; font-size: 16px;">{{msg}}</span> -->
    <span [ngStyle]="{'color': rcolor==0 ? 'red':'green' }" style="font-weight: bold; font-size: 16px;">{{msg}}</span>
</div>
                    
                </div>


                <div style="margin-top: 20px;" >
                    <span style="font-size:18px;">Your Submitted Feedbacks</span>

                    <table style="margin-top: 10px;">
                        <tbody>
                            <tr *ngFor="let item of feedback"
                            >
                                <td>{{item.CreatedDate | date:
                                    'MMM d,
                                    y, h:mm
                                    a'}}</td>
                                <td style="text-align: left;">{{item.Message}}</td>
                                <td style="text-align: left;">
                                <div>Rating: {{item.Rating==1 ? "Very Poor" : item.Rating==2 ? "Poor" : item.Rating==3 ? "Average" : item.Rating==4 ? "Good" : item.Rating==5 ? "Excellent" : "Nil"}}</div>
                                </td>
                                <td style="text-align: left;">
                                    <div *ngIf="item.Email!=''">
                                        <a  (click)="Attachimg(item)"
                                        >
                                        <i class="fa-solid fa-link"></i></a>
                                    </div>
                                    
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            