<!-- app.component.html -->


<div class="page-wraper">

  <div class="page-content bg-white">
    <!-- inner page banner -->
    <div class="dlab-bnr-inr overlay-black-middle bg-pt"
      style="background-image:url(../../assets/images/cartbanner.jpg);">
      <div class="container">
        <div class="dlab-bnr-inr-entry">
          <h1 class="text-white">MAP VIEW</h1>
        </div>
      </div>
    </div>

    <div class="section-full content-inner">
      <div class="container">
        <div class="shop-form">



          <!-- code here -->
          <!-- app.component.html -->
          <div class="container">

            <!-- <h1>Angular Google Maps with Places Search Example</h1> -->

            <div class="form-group">
              <label>Search Address</label>
              <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
                placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off"
                type="text" #search>
            </div>

            <!-- <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" style="width: 100%; height: 800px;">
              <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="false"
                (dragEnd)="markerDragEnd($event)"></agm-marker>
            </agm-map> -->
         
            <div class="map">
              <agm-map
              #agmMap
              [latitude]="latitude"
              [longitude]="longitude"
              [zoom]="initialZoom"
              [usePanning]="true"
              (centerChange)="centerChanged($event)"
              (mapReady)="mapReady($event)"
              
              
              ></agm-map>
              <div class="map-center-overlay">
                <img src="https://res.cloudinary.com/swiggy/image/upload/v1493751154/Other_Pin_urgkbb.png" width="40px" height="auto">
              </div>
            </div>
            <br>

            <table>
              <tr>
              
              
                <input type="text" class="form-control"  style="margin-bottom: 10px;"
                
                placeholder="Full Name" 
                [(ngModel)]="c_name"
                type="text">
              
              
                
              
              
                <input type="text" class="form-control" style="margin-bottom: 10px;"
                [(ngModel)]="c_ph"
                placeholder="Alternate mobile no." 
                type="text">
              
              

              </tr>

              <tr>
                <textarea rows="3"  style="height: 15%;" class="form-control"
                [(ngModel)]="address_text"
                placeholder="Address with Landmark">
              
              </textarea>
              </tr>
              

              </table>  

              <div >
                <button type="submit" class="btn btnhover shadow btn-danger" 
                    (click)="saveaddress()">Save
                    Address</button>
            </div>

<br>
<span *ngIf="sa_rep!=''">{{sa_rep}}</span>

            <h3>Address: {{address_text}}</h3>
            <span>Latitude: {{latitude}}</span> | 
            <span>Longitude: {{longitude}}</span>
          </div>




        </div>
      </div>
    </div>
  </div>
</div>