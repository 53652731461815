import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { DeviceDetectorService } from "ngx-device-detector";
import { HttpClient  } from '@angular/common/http';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {

  public lat = 0;
  public lng = 0;
  report = '';
  ipAddress = '';

  constructor(private auth: AuthService,private deviceService: DeviceDetectorService,private http:HttpClient) { }

 

  ngOnInit(): void {
    // this.getLocation();
    // this.getPosition().then(success => {
    //   console.log(success)
    // }).catch(err => {
    //   console.log(err)
    // });
    
    // this.getIPAddress();

    // var location=this.lat+"_"+this.lng;
    // var D_type=this.deviceService.getDeviceInfo().deviceType

    // var Detail="Location: "+location+" | Device Type: "+D_type+" | IPAddress: "+this.ipAddress;
    // console.log("Detail: ",Detail)

  }

 

  submit() {
    this.getLocation()
    if (this.lat != 0) {
      this.report = '';
      this.getLocation()
      window.open("http://fbcakes.com/", '_blank');
    }
    else {
      this.report = "Your Store Not Selected!"
      console.log(this.report)
    }

  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;

          console.log("Lat Id: " + this.lat);
          console.log("Long Id: " + this.lng);
          // this.auth.loc(this.lat,this.lng).subscribe(data => {
          //   console.log("report", data)

          // })

        }
      },
        (error: PositionError) => console.log(error));
    } else {
      console.log("Geolocation is not supported by this browser.")
    }
  }

  loc() {
    // this.auth.loc(this.lat,this.lng).subscribe(data => {
    //   console.log("report", data)

    // })
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        console.log("resolve", resolve)
      },
        err => {
          reject(err);
          console.log("reject", reject)
        });
    });
  }





 

  getIPAddress()
  {
    this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
      console.log("ipAddress:",this.ipAddress);
    });
  }
}
