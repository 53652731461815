import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-carrer',
  templateUrl: './carrer.component.html',
  styleUrls: ['./carrer.component.css']
})
export class CarrerComponent implements OnInit {

  name = "";
  Fname = "";
  age = "";
  phoneno = "";
  email = "";
  address = "";
  e_details = "";
  w_details = "";
  a_details = "";
  alert=0;
  report=""

  jobtype=3
  gendertype=1

  constructor(private auth: AuthService, private router: Router,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.jobtype=3
  }

  submitsd()
  {
    console.log("Job Type: ",this.jobtype)
  }

  submit()
  {
    if(this.name!="" && this.age!="" && this.phoneno!="" && this.address!="" && this.e_details!="" && this.email!="")
    {
     // && this.age!="" && this.phoneno!="" && this.address!="" && this.e_details!="" && this.email!=""
      // var note="";
      // if(this.jobtype==1)
      // {
      //   note="Sales";
      // }
      // else if(this.jobtype==2)
      // {
      //   note="Accounts";
      // }
      // else if(this.jobtype==3)
      // {
      //   note="IT";
      // }

      // this.alert=0;
      // this.auth.saveCareer(this.name, this.age, this.phoneno, this.email,this.address,this.e_details,this.w_details,this.a_details+', '+this.jobtype,this.image).subscribe(data => {
      
      //   console.log("Data:", data)
      //   this.report = "Your Profile Successfully Submitted! You will get a reply with an Email or Phone Call."
      // })

      var gender="no gender"
      if(this.gendertype==1)
      {
        gender="Male";
      }
      else if(this.gendertype==2)
      {
        gender="Female";
      }

      var data={
          Name: this.name+' _ '+this.Fname,
          Age: this.age+' _'+' Gender: '+gender,
          PhoneNo: this.phoneno,
          Email: this.email,
          Address: this.address,
          EducationDetails: this.e_details,
          WorkDetails: this.w_details,
          AdditionalDetails: this.a_details+', '+this.jobtype
      }
      console.log("Data:", data)

      this.auth.saveCareer2(data,this.image).subscribe(data => {
        console.log("Data:", data)
        this.report = "Your Profile Successfully Submitted! You will get a reply with an Email or Phone Call."
      })

    }
    else{
      this.alert=1;
      this.report=""
    }
  }

  image: any = null;
  blobimageurl: SafeUrl;
  
  upload(files) {
    this.image = files[0];
    var reader = new FileReader();
    this.blobimageurl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.image).replace('unsafe:', ''));
    console.log("File: ", files)
    // this.url = files;

    reader.readAsDataURL(files[0]); // read file as data url
    reader.onload = (event) => { // called once readAsDataURL is completed
      // this.url = event.target.result;
      //this.imgURL=this.url;
      console.log("The File: ",this.image)
    }

  }

  sendwts()
  {
    window.open("https://wa.me/+917604983238?text=");
  }

}
