<div class="page-wraper">
   
    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">About Us</h1>
                </div>
            </div>
        </div>

        <div class="section-full content-inner">
            <div class="container">
                <div class="shop-form" style="background-color: beige; padding: 20px;  border-top-left-radius: 20px;  border-bottom-right-radius: 20px;">
                   

                   <h1 style="font-size: 25px;">About Us</h1>

                   <p style="color: black;">
                    FBCakes is a web-based store that conveys cakes and Sweets in excess of 50 areas in Chennai.
                    We make the best quality cakes and convey them on schedule.
                    We have our own conveyance group with solid innovative and tasks experience which empowers us to convey. <br><br>
                    
                    We present to you an exceptional and simple approach to requesting your number one cake through on the web and home conveyance.
                    Our confectionary group prepares the new cakes and makes the best quality garnish in all cakes. <br><br>
                    
                    Our creation group begins the creation a 9.00 AM,
                    assuming the client put in a request before 9 AM will be handled right away and makes followed through on schedule.
                    We have different conveyance openings and Same Day Delivery is likewise accessible. We do conveyance on All days at 10.00 AM to 11.00 PM
                    <br><br>
                    
                    Request on the web and get the cake close to home. You can converse with us from 9.00 AM to 8.00 PM on the entire days at +91 90034 32888.
                   </p>



                </div>

              
            </div>
           



        </div>