<header class="site-header header mo-left header-basic header-transparent">
    <!-- main header -->
    <!-- <div>hijhkjhkljhkjh</div> -->
    <div class="sticky-header main-bar-wraper navbar-expand-lg">
        <div class="main-bar clearfix ">
            <div class="container clearfix">
                <!-- website logo -->
                <div class="logo-header mostion logo-white">
                    <a href=""><img src="../../assets/images/fbcakes.png" alt=""></a>
                </div>
                <!-- nav toggle button -->

                <!-- <button 
                class="navbar-toggler collapsed navicon justify-content-end" 
                type="button"
                data-toggle="collapse" 
                data-target="#navbarNavDropdown" 
                aria-controls="navbarNavDropdown"
                aria-expanded="false" 
                aria-label="Toggle navigation"
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </button> -->

                <!-- extra nav -->
                <div class="extra-nav">
                    <div class="extra-cell">

                        <ul class="extra-info">

                            <!-- Search -->

                            <!-- <li *ngIf="isMobile">
                                  <a (click)="SearchClick(1)"> 
                                    <span>
                                        <i class="ti-search text-white font-20"
                                        
                                        > </i>
                                    </span>
                                </a>
                            </li> -->

                            <li>
                                <!-- test -->
                                <a routerLink="cart" style="margin-right:20px;" *ngIf="!isMobile">
                                    <span><i class="ti-shopping-cart text-black  font-20"></i></span>
                                    <span *ngIf="getcartitemquantity() > 0"
                                        class="badge">{{getcartitemquantity()}}</span>

                                </a>

                                <a routerLink="Home" *ngIf="isMobile" style="padding:5px; margin-right:5px">
                                    <span><i class="ti-home text-white stroke text-white font-20"></i></span>
                                </a>

                                <a routerLink="cart" 
                                *ngIf="isMobile" 
                                style="padding:5px; margin-right:10px">
                                    <span><i class="ti-shopping-cart text-white stroke text-white font-20"></i></span>
                                    <span *ngIf="getcartitemquantity() > 0" class="badge"
                                        style="padding:5px;">{{getcartitemquantity()}}</span>
                                </a>


                            </li>

                            <!-- <li>
                                <a href="javascript:void(0);">Shop <i class="fa fa-chevron-down"></i></a>
                                <ul class="sub-menu">
                                    
                                    <li><a routerLink="cart"  
                                        data-toggle="collapse" 
                                        data-target="#navbarNavDropdown" 
                                        aria-controls="navbarNavDropdown"
                                        aria-expanded="false" 
                                        aria-label="Toggle navigation"
                                        >
                                        My Cart
                                    </a></li> 
                                    <li *ngIf="getcartitemquantity() > 0" ><a routerLink="checkout">
                                        Checkout
                                    </a>
                                </li> -->


                            <li>
                                <!-- <div  *ngIf="!isMobile">
                                <a *ngIf="!isloggedin()" href="javascript:void(0);" class="btn white"
                                    data-toggle="modal" data-target="#exampleModal">Join</a>

                                <a *ngIf="isloggedin()" routerLink="myaccount" class="btn white">My Account</a>
                            </div> -->

                                <div>
                                    <a *ngIf="!isloggedin()" href="javascript:void(0);" class="btn white"
                                        data-toggle="modal" data-target="#exampleModal">Join</a>

                                    <a *ngIf="isloggedin()" routerLink="myaccount" class="btn white">My Account</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Quik search -->
                <!-- <div class="dlab-quik-search ">
                    <form action="#">
                        <input name="search" value="" type="text" class="form-control" placeholder="Type to search">
                        <span id="quik-search-remove"><i class="ti-close"></i></span>
                    </form>
                </div> -->

                <!-- main nav -->
                <div class="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
                    <div class="logo-header">
                        <a href=""><img src="../../assets/images/fbcakes.png" alt=""></a>
                    </div>
                    <ul class="nav navbar-nav">
                        <li>
                            <a routerLink="" class="text-black" data-toggle="collapse" aria-expanded="false"
                                data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-label="Toggle navigation">Home
                            </a>

                        </li>

                        <li>
                            <a href="javascript:void(0);" class="text-black">Shop
                                <i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu">

                                <li><a routerLink="cart" data-toggle="collapse" data-target="#navbarNavDropdown"
                                        aria-controls="navbarNavDropdown" aria-expanded="false"
                                        aria-label="Toggle navigation">
                                        My Cart
                                    </a></li>
                                <li *ngIf="getcartitemquantity() > 0"><a routerLink="checkout">
                                        Checkout
                                    </a></li>


                            </ul>

                        </li>
                        <li *ngIf="isMobile">
                            <a *ngIf="!isloggedin()" href="javascript:void(0);" data-toggle="modal"
                                data-target="#exampleModal">Join</a>

                            <a *ngIf="isloggedin()" routerLink="myaccount">My Account</a>
                        </li>


                    </ul>

                </div>
            </div>


        </div>
        <!-- <div style="align-items: center; padding: 20px; width:100%; justify-content: center;" >
            <span style="color: antiquewhite; font-size: 35px; font-weight:bold;">ONLINE ORDERING NOT AVAILABLE</span><br>
            <span style="color: antiquewhite;">PLEASE CALL 9003432888 FOR ORDERS AND ENQUIRIES</span>
        </div> -->

        <!-- <div style="width: 100%; align-content: center; margin-top: 20px; padding-left: 10px; padding-right: 10px; ">
            <p style="color: #ffffff; font-size: 35px; font-weight:bold; text-align: center; line-height: 30px; stroke: black; stroke-width: 1%;">ONLINE ORDERING NOT AVAILABLE</p>
            <p style="color: darkorange; text-align: center; line-height: 26px; font-size: 24px;">PLEASE CALL <b style="color: #ffffff; font-style: italic;">( +91 90034 32888 )</b> FOR ORDERS AND ENQUIRIES</p>
     

        </div> -->



    </div>
    <!-- <div>
        <h5 style="text-shadow: 9px 5px 9px black;; 
        font-weight: bold; 
        color: yellow;
        text-align: center;
        font-size: 40px;
        height: 100px;
        margin-top: 30px;
        "
        >Buy 1 KG get 1/2 KG Free!</h5>
        </div> -->

    <!-- main header END -->
</header>


<!-- Loginwork -->

<div class="modal fade subscribe-box" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form *ngIf="verifytoggle" class="dzSubscribe modal-content w-100 mb-10">
            <div class="modal-header">

                <!-- <h3 class="modal-title" id="exampleModalLabel">Enter Mobile No.</h3> -->
                <h3 class="modal-title" id="exampleModalLabel">JOIN</h3>
                <br>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>

                </button>
            </div>

            <div class="modal-body">
                <span>Enter 10-Digit Mobile No.</span>
                <div class="form-group">

                    <input class="form-control" name="dzNumber" #PhoneNo id="PhoneNo" required="required"
                        placeholder="Mobile Number*" [(ngModel)]="inputtext" type="number">


                    <i class="text-danger" style="margin-bottom: 15px;">{{logintoast}}</i>
                    <br />

                    <span>Email-Id (Optional)</span>
                    <input class="form-control" name="dzNumber" #email id="email" placeholder="Email-Id" type="email"
                        [(ngModel)]="inputtext2">

                    <div class="dzSubscribeMsg"></div>

                </div>
                <!-- <span *ngIf="inputtext != '' && !isvalidemail()">Invalid Email-Id</span> -->
                <!-- <span *ngIf="inputtext != ''">Invalid Mobile No</span> -->
            </div>

            <!-- <div class="modal-footer" [disabled]="!isvalidemail()">
                <button type="submit" class="btn btnhover shadow buttonload" [disabled]="isLoading" (click)="click()"
                (click)="customerlogin(email.value)"> <i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'check': !isLoading}"></i>{{button}}</button>
            </div> -->

            <div class="modal-footer">
                <button type="submit" class="btn btnhover shadow buttonload btn-danger"
                    (click)="customerlogin(inputtext,inputtext2);">
                    <!-- [disabled]="clicked" 
                (click)="placeorder(); clicked = true;"   -->

                    <i class="fa"
                        [ngClass]="{'fa-spin fa-spinner': isLoading, 'check': !isLoading}"></i>{{button}}</button>


            </div>




            <!-- <span >{{logintoast}}</span>  -->



        </form>

        <form *ngIf="!verifytoggle" class="dzSubscribe modal-content otp-container w-100 h-10" method="post">
            <div class="modal-header">

                <!-- <p style="color: red;">Temp SMS Service Stoped !!!</p> 
                <p style="color: black;"><b>{{misc_otp}}</b> is your OTP <b>(One Time Password)</b> for logging in the FBcakes webSite."</p> -->

                <h3 class="modal-title" id="exampleModalLabel">Enter OTP</h3>
                <br>

                <!-- <span >{{logintoast}}</span>  -->

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body mb-10">
                <label style="padding-right: 5px; padding-top: 15px; font-size: 25px;">G-</label>
                <input id="codeBox1" #codeBox1 type="text" maxlength="1" onkeyup="onKeyUpEvent(1, event)" type="number"
                    onfocus="onFocusEvent(1)">
                <input id="codeBox2" #codeBox2 type="text" maxlength="1" onkeyup="onKeyUpEvent(2, event)" type="number"
                    onfocus="onFocusEvent(2)">
                <input id="codeBox3" #codeBox3 type="text" maxlength="1" onkeyup="onKeyUpEvent(3, event)" type="number"
                    onfocus="onFocusEvent(3)">
                <input id="codeBox4" #codeBox4 type="text" maxlength="1" onkeyup="onKeyUpEvent(4, event)" type="number"
                    onfocus="onFocusEvent(4)">
            </div>

            <span style="margin: 5px;">{{logintoast}}</span>

            <!-- <div class="modal-footer">

                <button type="submit" 
                (click)="otpverification(codeBox1.value+codeBox2.value+codeBox3.value+codeBox4.value)" 
                ng-model="otpverification(codeBox1.value+codeBox2.value+codeBox3.value+codeBox4.value)" 
                ng-init="myVar = true"
                data-dismiss="modal"
                class="btn btnhover shadow" 
                
                >Verify</button>

            </div> -->

            <div class="modal-footer">

                <button type="submit"
                    (click)="otpverification(codeBox1.value+codeBox2.value+codeBox3.value+codeBox4.value)"
                    ng-model="otpverification(codeBox1.value+codeBox2.value+codeBox3.value+codeBox4.value)"
                    ng-init="myVar = true" class="btn btnhover shadow btn-danger">Verify OTP</button>

            </div>
            <!-- data-dismiss="modal" -->

            <!-- Resend OTP -->
            <!-- *ngIf="!isloggedin()"  -->

            <div class="row">
                <a class="mt-10" href="javascript:void(0);" (click)="mobnochange()" style="margin-top: 10px;">Change
                    Mobile No</a>

                <span style="margin: 10px;"> | </span>

                <a class="mt-10" href="javascript:void(0);" (click)="resendotp()" style="margin-top: 10px;">Resend
                    OTP</a>
            </div>


            <span style="margin: 5px;">{{logintoast2}}</span>
            <!-- <button type="submit" 
                (click)="resendotp()"
               
                class="btn btnhover shadow" 
                
                >Resend</button> -->


        </form>
    </div>
</div>

<script>
    // function closeModal(){
    //     $("#exampleModal").modal('hide')
    // }
</script>