import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ItemListComponent } from './item-list/item-list.component';
import { CartComponent } from './cart/cart.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { PolicyComponent } from './policy/policy.component';
import { OrderlistComponent } from './orderlist/orderlist.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContuctusComponent } from './contuctus/contuctus.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { GalleryComponent } from './gallery/gallery.component';
import { FaqComponent } from './faq/faq.component';
import { CarrerComponent } from './carrer/carrer.component';
import { StoreComponent } from './store/store.component';
import { MapviewComponent } from './mapview/mapview.component';
import { OfferComponent } from './offer/offer.component';
import { Offer2Component } from './offer2/offer2.component';
import { BlogComponent } from './blog/blog.component';
import { LabelComponent } from './label/label.component';
import { LowPriceCakesComponent } from './low-price-cakes/low-price-cakes.component';
import { FirstbirthdaycakeComponent } from './firstbirthdaycake/firstbirthdaycake.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { TransactionComponent } from './transaction/transaction.component';
import { RefundpolicyComponent } from './refundpolicy/refundpolicy.component';
import { ReturnpolicyComponent } from './returnpolicy/returnpolicy.component';
import { DeliverypolicyComponent } from './deliverypolicy/deliverypolicy.component';
import { OdrsComponent } from './odrs/odrs.component';



const routes: Routes = [
  { path: "", component: ItemListComponent },
  // { path: "cart", component: MycartComponent },
  { path: "cart", component: CartComponent },
  { path: "cakes/:id", component: ItemDetailsComponent },
  { path: "checkout", component: CheckoutComponent },
  { path: "myaccount", component: MyaccountComponent },
  { path: "mypolicy", component: PolicyComponent },
  { path: "aboutus", component: AboutusComponent },
  { path: "contuctus", component: ContuctusComponent },
  { path: "privacypolicy", component: PrivacypolicyComponent },
  { path: "termsandconditions", component: TermsconditionsComponent },
  { path: "gallery", component: GalleryComponent },
  { path: "orderlist/:id", component: OrderlistComponent },
  { path: "faq", component: FaqComponent },
  { path: "carrer", component: CarrerComponent },
  { path: "store", component: StoreComponent },
  { path: "map", component: MapviewComponent },
  { path: "blog", component: BlogComponent },
  { path: "low-price-cakes-online", component: LowPriceCakesComponent },
  { path: "first-birthday-cake", component: FirstbirthdaycakeComponent },
  { path: "offer/:label", component: LabelComponent },
  { path: "valentine-cake", component: OfferComponent },
  { path: "proposal-cake", component: Offer2Component },
  { path: "feedback/:id", component: FeedbackComponent },
  { path: "mapview", component: MapviewComponent },
  { path: "transaction", component: TransactionComponent },
  { path: "Cancellationandrefundpolicy", component: RefundpolicyComponent },
  { path: "returnpolicy", component: ReturnpolicyComponent },
  { path: "deliverypolicy", component: DeliverypolicyComponent },
  { path: "odrs/:id", component: OdrsComponent },
  // { path: "cakes", redirectTo: "" },
  { path: "**", redirectTo: "" },
]

// https://www.fbcakes.com/cakes/1-2-and-1-kg

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
