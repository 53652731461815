import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-odrs',
  templateUrl: './odrs.component.html',
  styleUrls: ['./odrs.component.css']
})
export class OdrsComponent implements OnInit {

  orderid: number = 0;
  orders = [];

  constructor(private route: ActivatedRoute, private auth: AuthService)
  { 
  
  }


  ngOnInit(): void {
    console.log("OrderID work")
    this.orderid = +this.route.snapshot.paramMap.get('id');
    console.log("OrderID", this.orderid)
    this.getOrder();
  }

  getOrder() {
    this.auth.getorder_temp(this.orderid).subscribe(data => {
      console.log("Orders", data["orders"]);
      this.orders = data["orders"]
    })
  }
  
  getTotal() {
    var totalprice = 0;
    this.orders[0].OrderItems.forEach(element => {
      totalprice += Number(element.ta);
    });
   
    return (totalprice);
  }
  orderAccept(id)
  {
this.auth.tempOrderaccep(id).subscribe(data => {
      console.log("Orders", data["status"]);
     if(data["status"]==200)
     {
      this.getOrder();
     }
      
    })
  }

}
