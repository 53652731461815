<div class="page-wraper">
    <div hidden id="loading-area">
        <div class="ball-pulse-rise">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-middle bg-pt"
            style="background-image:url(../../assets/images/cartbanner.jpg);">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">Temp Order Details</h1>
                </div>
            </div>
        </div>

        <div class="section-full content-inner">
            <div class="container">
                <h2>FBcakes Online Delivery Orders</h2>
                <span>Customer Name: </span> <span><b>{{orders[0].CurrentAddresses[0].Name}}</b> </span><br>
                <span>Phone No: </span> <span> <b>{{orders[0].CustomerDetail[0].PhoneNo}}</b></span><br>
                <span>Delivery Address: </span><br> 
                <div style="width:50%">
                    <span ><b>{{orders[0].CurrentAddresses[0].Address}}</b></span>
                </div><br>

                <span>Ordered DateTime Date and Time: </span> <span><b>{{orders[0].didt | date:'MMM d,y, h:mm a'}}</b></span><br>
                <span>Delivery Date and Time: </span> <span><b>{{orders[0].dddt | date:'MMM d,y, h:mm a'}}</b></span><br><br>

                <span>Order Id: </span><span><b>{{orders[0].OdrsId}}</b></span><br>
                <!-- <span>Order Items:</span><br> -->
                <!-- <div *ngFor="let product of orders[0].OrderItems" >
                    <div>
                        <img src="{{product.ImgUrl}}" alt="{{product.Name}}" width="50px" height="50px">
                        <span><b>* {{product.Name}} | QTY: {{product.qy}} Kg | ₹{{product.ta}} | {{product.msg}}</b></span><br>
                    </div>
                    
                </div>
                <span><b>notes: {{orders[0].n}}</b></span> -->

                <table class="table-bordered check-tbl" style="width: 50%;">
                    <thead>
                        <tr>
                            <th>IMAGE</th>
                            <th>PRODUCT NAME</th>
                            <th>QTY</th>
                           
                            <th>PRICE</th>
                            <!-- <th>MSG</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of orders[0].OrderItems">
                            <td><img src="{{product.ImgUrl}}" alt=""></td>
                            <td>
                               <span>{{product.Name}}</span> <br>
                               <span style="font-size: 10px;">msg: {{product.msg}}</span>
                                <div *ngIf="product.n!=0">
                                    <span style="font-size: 10px;">
                                        {{product.n}}
                                    </span>
                                </div>
                                <!-- <div>
                                    <span style="font-size: 10px;">
                                        {{product.msg}}
                                    </span>
                                </div> -->
                            </td>
                            <td>{{product.qy}} Kg</td>
                            <td>₹{{product.ta}}</td>
                            <!-- <td>{{product.msg}}</td> -->
                            </tr>
                            </tbody>
                           
                            </table>
                            <span>Order note: [{{orders[0].n}}]</span>

                            <br><br>
                            
                            


                <!-- <br><br> -->
                <span>Sub Total: </span>
                <span><b>₹{{getTotal().toFixed(2)}}</b></span><br>
                <span>Tax 18%: </span>
                <span><b>₹{{(getTotal()*18/100).toFixed(2)}}</b></span><br>
                <span>Total Amount: </span><span><b>₹{{orders[0].ba.toFixed(2)}}</b></span><br><br>
                <span>Payment Type: </span> <span><b>{{orders[0].Transactions[0].PaymentTypeId==1 ? "Cash On Delivery" : orders[0].Transactions[0].PaymentTypeId==8 ? "Online" : "Others" }}</b></span><br>
                

                <!-- <div *ngIf="orders[0].Transactions[0].PaymentTypeId==1">
                    <span>Payment Status: </span> 
                    <span><b>Amount not Recived!</b></span>
                </div> -->

                <div *ngIf="orders[0].Transactions[0].PaymentTypeId==8">
                    <span>Payment Status: </span>  <input type="text" style="border:none; width: 50%;"/>
                    <span><b>{{orders[0].Transactions[0].PaymentStatusId==0 ? "" : orders[0].Transactions[0].PaymentStatusId==1 ? " " : " "}}</b></span>
                </div>
<br>

                <span>Delivery Branch Name: </span><span><input type="text" style="border:none; width: 50%;"/> </span><br>
                <span>Contact No: </span> <span><input type="text" style="border:none; width: 50%;"></span>
                

                <br><br><span><b>Your order has been confirmed! Thank You😊</b></span>

                <br>
                <br>
                <div>
                    <!-- <div class="cartbtn">
                        <a href="javascript:void(0);" 
                        (click)="orderAccept(orders[0].OdrsId)"
                            class="btn btn-danger btnhover radius-xl">

                            <i class="ti-shopping-cart"></i> Order Accept</a>

                    </div> -->

                    <div *ngIf="orders[0].osi==0">
                        <label 
                            class="btn btn-danger" 
                            style="margin-top: 20px;" 
                            (click)="orderAccept(orders[0].OdrsId)"
                            >Accept Order SMS
                        </label><br><br>
                        <!-- <span>Status: </span>  -->
                        <span *ngIf="orders[0].osi==0">Order Pending</span>
                        <span *ngIf="orders[0].osi==-5">Order Accepted</span>
                        <!-- <span>{{orders[0].osi==0 ? 'Pending' ? orders[0].osi==-5 : 'Accepted' : orders[0].osi==5 ? 'Delivered' : 'Others'}}</span> -->


                    </div>
                </div>
                
               
                
                
        </div> </div> </div>


       