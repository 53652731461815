import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, SafeUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  message:string=""
  mobile:""
  name:""

  msg:any=""
 

  order: any;
  feedback: any;
  rcolor=0;

  star=0;



  constructor(private auth: AuthService, 
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title, private sanitizer: DomSanitizer,
    private metaS: Meta)
    {
      
    }

  ngOnInit(): void {
    var key = ["", ""].join("|")
    key = btoa(key)
    this.star=0;

     var oId = this.route.snapshot.paramMap.get('id');

   // var oid="Mzc2Njc0Mw==";

    // console.log(atob(oid)); 
    // var D_oid: number = atob(oid);

      // this.auth.getOrdersFBbyId(parseInt(atob(oid))).subscribe((data: any) => {
      //       this.order=data.orders[0];
      //       console.log("my_order Feedback 1", this.order);
      // })

      // this.auth.viewFeedback(parseInt(atob(oid))).subscribe((data: any) => {
      //   this.feedback=data
      // })

        this.auth.getOrdersFBbyId(oId).subscribe((data: any) => {
            this.order=data.orders[0];
            console.log("my_order Feedback 1", this.order);
      })

      this.auth.viewFeedback(oId).subscribe((data: any) => {
        this.feedback=data
        console.log("Feedbacks: ",this.feedback)
      })

      
  }

  submitdfdsf()
  {
console.log("Star",this.star)
  }
  submit(id)
  {
    console.log("Id: ",id);
    console.log("OrderId: ",this.order);
  

    var data={
      OrderId:this.order.OdrsId,
      Name:"(Review - "+id+") "+this.order.CurrentAddresses[0].Name,
      Mobile:this.order.CustomerDetail[0].PhoneNo,
      Message:this.message,
      Rating:this.star,
    }
    console.log("data: ",data);
    console.log("file: ",this.image);


        if(this.message!="")
    {
      this.msg=""
      console.log("msg:",this.msg)
      this.auth.saveFeedback2(data,this.image).subscribe((data: any) => {
        console.log("Oupput", data);
        this.rcolor=1
        this.msg=data
        this.auth.viewFeedback(id).subscribe((data: any) => {
          this.feedback=data
        })
      })
      this.message=""
    }
    else{
      this.rcolor=0;
this.msg="Plz fill your Feedback!"
    }



//     if(this.message!="")
//     {
//       this.msg=""
//       console.log("msg:",this.msg)
//       this.auth.saveFeedback("(Review - "+id+") "+this.order.CurrentAddresses[0].Name,this.order.CustomerDetail[0].PhoneNo,this.message,id,this.order.CustomerId,this.star).subscribe((data: any) => {
       
//         console.log("Oupput", data);
//         this.rcolor=1
//         this.msg=data
//         this.auth.viewFeedback(id).subscribe((data: any) => {
//           this.feedback=data
//         })
//       })
//       this.message=""
//     }
//     else{
//       this.rcolor=0;
// this.msg="Plz fill your Feedback!"
//     }

  }

  image: any = null;
  blobimageurl: SafeUrl;
  
  upload(files) {
    this.image = files[0];
    var reader = new FileReader();
    this.blobimageurl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.image).replace('unsafe:', ''));
    console.log("File: ", files)
    // this.url = files;

    reader.readAsDataURL(files[0]); // read file as data url
    reader.onload = (event) => { // called once readAsDataURL is completed
      // this.url = event.target.result;
      //this.imgURL=this.url;
      console.log("The File: ",this.image)
    }
  }

  Attachimg(item)
  {
console.log("Image: ",item.Email)
window.open(item.Email, '_blank');
  }

}
