import { Component, OnInit,VERSION } from '@angular/core';
import { AuthService } from '../auth.service'
import * as moment from 'moment'
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { MapsAPILoader } from '@agm/core';

import { DeviceDetectorService } from "ngx-device-detector";
import { IfStmt } from '@angular/compiler';


//import {MatDatepickerModule} from '@angular/material/datepicker';




@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  poalert: boolean = false;
  timeValid: boolean = false
  time: string;
  // time;
  cartitems = [];
  accessories = [];
  taxes: any = [];
  tax1 = 0
  tax2 = 0
  tax3 = 0
  // PaymentTypeId: number = 1;
  PaymentTypeId: number = 1;
  current_address;
  Omsg = "";
  addresses;
  currentorderid;
  currentorders;
  currentaddress;
  currentaddressid;
  verifytoggle: boolean = true;
  minDate: any = "";
  date;
  Acctotal: number;
  CIDCheckout: any = 0;
  latitude: number;
  longitude: number;
  zoom: number;
  public lat;
  public lng;

  pCode=null;

  name = "Angular " + VERSION.major;
  deviceInfo = null;
  isDesktopDevice: boolean;
  isTablet: boolean;
  isMobile: boolean;

  coopenAmount=0;
  coopenId=null;
  coopenAlert=0
  Cust_DET:any=[]

  mypretime=2;

  //todayDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  formaddress = { name: "", address: "", city: "", state: "", phone: "", pincode: "" }

  nearbystoreId:any=22;
  shortestKm=0;

  constructor(private auth: AuthService, private deviceService: DeviceDetectorService) {
   
    this.nearbystoreId=22;
    this.shortestKm=0;

    this.epicFunction();
    var cid=localStorage.getItem("customer_id")

    if(localStorage.getItem("nearbySid"))
    {
      this.nearbystoreId=+localStorage.getItem("nearbySid")
      this.shortestKm=+localStorage.getItem("minkm")
      console.log("true");
      console.log("this. nearbystoreId :",this.nearbystoreId);
      console.log("this. shortestKm :",this.shortestKm);
    }
    else{
      this.nearbystoreId=22;
      this.shortestKm=0;
      console.log("false");
      console.log("this. nearbystoreId :",this.nearbystoreId);
      console.log("this. shortestKm :",this.shortestKm);
    }
   // console.log("Customer Id:",cid)
    this.auth.cust_det(cid).subscribe(data => {
     // console.log("data :", data)
      this.Cust_DET=data
      console.log("this.getCustomer() const:",this.Cust_DET)
      console.log("Postal code cons",data[0].PostalCode)
    })
  }

  //   constructor(private datePipe: DatePipe, private auth: AuthService){

  // }

  Device=null;
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();

   
    if(this.isMobile==true)
    {
      this.Device="Mobile"
    }
    else if(this.isTablet==true)
    {
      this.Device="Tablet"
    }
    else if(this.isDesktopDevice==true)
    {
      this.Device="Desktop"
    }

    this.Device=this.Device+' | '+this.deviceInfo.deviceType
    console.log("DEV:",this.Device)
  }



  ngOnInit(): void {

    console.log("current_address: ",this.current_address)
   
    this.mycheckcart="";

    // this.simple();
    this.coopenAlert=0;
    this.coopenAmount=0;
    this.coopenId=null
    console.log("Todat Date", this.date)


    console.log("Working")
    if (localStorage.getItem("cart_items") == undefined) {
      localStorage.setItem("cart_items", '[]')
    }
    this.cartitems = JSON.parse(localStorage.getItem("cart_items"));
    console.log("MYYY cartitems",this.cartitems)
    this.accessories = JSON.parse(localStorage.getItem("accessories"));
    this.current_address = JSON.parse(localStorage.getItem("currentaddress"))
    this.currentaddressid = +localStorage.getItem("currentaddressid");
    console.log("CAID:", this.currentaddressid)
    this.addresses = JSON.parse(localStorage.getItem("addresses"));
    this.currentaddress = this.addresses.filter(x => x.CustomerAddressId == this.currentaddressid)[0]
    
    //this.currentaddress = this.addresses.filter(x => x.Id == this.currentaddressid);
    this.getDate();
    //this.getCustomer();

    var cid=localStorage.getItem("customer_id")
   // console.log("Customer Id:",cid)
    this.auth.cust_det(cid).subscribe(data => {
     // console.log("data :", data)
      this.Cust_DET=data
      console.log("this.getCustomer() :",this.Cust_DET)
      console.log("Postal code",data[0].PostalCode)
    })
    

  

    this.date = this.minDate
    // if (this.date == this.minDate) {
    //   this.time = "14:20"
    //   var mtoday = new Date();
    //   var mtime = (mtoday.getHours() + 2) + ":" + mtoday.getMinutes();
    //   this.time = mtime
    //   console.log("Mt extra time:", mtime)
    // }
    // else
    // {
    //   this.setNow();
    // }
    this.setNow();

    this.setprice();
    this.getTaxes();
    this.scrollToTop();
    this.acctotal();

    console.log("address", this.addresses)
    console.log("Current Address", this.currentaddressid)
    console.log("Cart Items", this.cartitems)
    console.log("accessories", this.accessories)
    localStorage.setItem("toogle", "1")

    var pretime=[];
    this.cartitems.forEach((x,i) => {
      pretime[i]=x.PrepTime;
    })
    if(Math.max(...pretime)==0)
    {
      this.mypretime=2;
    }
    else
    {
      this.mypretime=Math.max(...pretime);
    }
    
  

    console.log("pretime",this.mypretime)
  }

  acctotal() {
    this.Acctotal = 0;

    if (this.accessories != null) {
      this.accessories.forEach(x => {
        this.Acctotal += (x.Price * x.Quantity)
      })
      console.log("Acctotal", this.Acctotal)

    }

    console.log("this.accessories", this.accessories)

  }

  getTaxes() {
    this.auth.getTaxes(3).subscribe(data => {
      this.taxes = data;
      this.settaxes();
    })
  }

  clicked = false;

  actionMethod() {
    console.log("actionMethod was called!");
  }

  // getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position: Position) => {
  //       if (position) {
  //         console.log("Latitude: " + position.coords.latitude +
  //           "Longitude: " + position.coords.longitude);
  //         this.lat = position.coords.latitude;
  //         this.lng = position.coords.longitude;
  //       }
  //     },
  //       (error: PositionError) => console.log(error));
  //   } else {
  //     console.log("Geolocation is not supported by this browser.")
  //     // alert("Geolocation is not supported by this browser.");
  //   }
  // }

  settaxes() {
    this.tax1 = 0
    this.tax2 = 0
    this.tax3 = 0
    this.cartitems.forEach(item => {
      var taxgroup = this.taxes.filter(x => x.Id == item.TaxGroupId)[0]
      item.TotalPrice = item.Price;
      item.variants.forEach(variant => {
        item.TotalPrice += variant.Price
      });
      item.addons.forEach(addon => {
        item.TotalPrice += addon.Price
      });
      this.tax1 = this.tax1 * item.Quantity + item.TotalPrice * ((taxgroup.Tax1) / 100)
      this.tax2 = this.tax2 * item.Quantity + item.TotalPrice * ((taxgroup.Tax2) / 100)
      this.tax3 = this.tax3 + item.TotalPrice * ((taxgroup.Tax3) / 100)
      // this.tax1 = this.tax1 + item.TotalPrice * ((taxgroup.Tax1) / 100)
      // this.tax2 = this.tax2  + item.TotalPrice * ((taxgroup.Tax2) / 100)
      // this.tax3 = this.tax3  + item.TotalPrice * ((taxgroup.Tax3) / 100)
    });
    this.tax1 = +this.tax1.toFixed(0)
    this.tax2 = +this.tax2.toFixed(0)
    this.tax3 = +this.tax3.toFixed(0)
  }
  setprice() {
    this.cartitems.forEach(item => {
      item.TotalPrice = item.Price;
      item.variants.forEach(variant => {
        item.TotalPrice += variant.Price
      });
      item.addons.forEach(addon => {
        item.TotalPrice += addon.Price
      });
    });
  }
  // getTotal() {
  //   var totalprice = 0;
  //   this.cartitems.forEach(element => {
  //     totalprice += element.TotalPrice* element.Quantity;
  //   });
  //   return totalprice;
  // }

  getTotal() {
    var totalprice = 0;
    this.cartitems.forEach(element => {
      totalprice += Number(element.prate);
    });
    this.tax1 = totalprice * 2.5 / 100;
    this.tax2 = totalprice * 2.5 / 100;
 
   
    return (totalprice + this.Acctotal);
  }

  // 18tax
  settaxes2() {
    var totalprice = 0;
    var tax = 0;
    this.cartitems.forEach(element => {
      totalprice += Number(element.prate);
    });
    tax = (totalprice + this.Acctotal) * 18 / 100
    return Number(tax.toFixed(0))
  }

  final_amount()
  {
    if(this.coopenAlert==1)
    {
      return this.getTotal()+this.settaxes2()-this.coopenAmount
    }
    else
    {
      return this.getTotal()+this.settaxes2()
    }
  }




  placeorder2() {
    var order_payload = {
      items: this.cartitems,
      tax1: this.tax1,
      tax2: this.tax2,
      tax3: this.tax3,
      sub_total: this.getTotal(),
      // total: this.getTotal() + this.tax1 + this.tax2 + this.tax3, 
      total: this.final_amount(),
      companyid: 3,
      PaymentTypeId: this.PaymentTypeId,
      customerid: +localStorage.getItem("customer_id"),
      customeraddressid: +localStorage.getItem('currentaddressid'),
      Note: this.Omsg,
      Source:this.Device,
      DiscAmount:this.coopenAmount,
      DiscountRuleId:this.coopenId,
      StoreId:+this.nearbystoreId,
      nearestkm:+this.shortestKm
    };

    this.auth.placeorder({ payload: JSON.stringify(order_payload) }).subscribe(data => {
      // console.log(data, JSON.parse(data["paymentresponse"]));
      var paymentresponse = JSON.parse(data["paymentresponse"]);
      console.log(paymentresponse)
      console.log(paymentresponse.payment_request.longurl)
      document.location.href = paymentresponse.payment_request.longurl;
    })
  }

  allcartitems;
  placeorder_00000() {
    console.log("Its work")
    console.log("Cart Items", this.cartitems);
    console.log("Acc Items", this.accessories);

    this.allcartitems = [...this.cartitems, ...this.accessories]

    //var allcart=this.cartitems+this.accessories;

    console.log("new Items", this.allcartitems);
  }

  mycheckcart=""
  checkcart_oldnewyear_2022()
  {
    console.log("checkcart")
    this.mycheckcart="Sorry, Temporary online orders have not been taken! 😞"
  }

  checkcart() {
    console.log("Working! ", this.cartitems)

    if (this.cartitems && this.cartitems.length > 0) {
      console.log("CART THERE!");
      // this.placeorder();
     // this.placeorder_gmap();
    //  enc
    if(this.PaymentTypeId != 5)
    {

      // console.log("del date: ",this.date.slice(-2))
      // if(this.date.slice(-2)=='01' || this.date.slice(-2)=='31')
      // {
      //   alert("31 st DEC and 1 st JAN (we can't Delivery online Orders). Choose another date...")
      // }
      // else{
      //   this.placeorder_gmap_enc();
      // }
      this.placeorder_gmap_enc();

     // alert("ok done")
    }
    else{
      alert("sry, Online Pay not working...")
    }
    }
    else {
      console.log("CART NOT THERE! ");
      alert("Order Items Removed. Plz order it again...")
    }
  }

  placeorder55() {
    console.log("Phone: ", localStorage.getItem('customer_emailid'));
    console.log("Email: ", localStorage.getItem('customer_PhoneNo'));
    console.log("Name: ", this.currentaddress.Name)
  }

  placeorder500()
  {
    console.log("this.accessories", this.accessories)
  }


  placeorder() {

    console.log("this.accessories", this.accessories)

    if (this.accessories != null) {

      this.allcartitems = [...this.cartitems, ...this.accessories]
      console.log("item & Acc :", this.allcartitems)
    }
    else {

      this.allcartitems = [...this.cartitems]
      console.log("item :", this.allcartitems)
    }
    // this.allcartitems=[...this.cartitems,...this.accessories]
    console.log("All_order:", this.allcartitems)
    this.CIDCheckout = localStorage.getItem('currentaddressid')

    console.log("CID Checkout", localStorage.getItem('currentaddressid'))



    var order_payload = {
      items: this.allcartitems,
      tax1: this.tax1,
      tax2: this.tax2,
      tax3: this.tax3,
      sub_total: this.getTotal(),
      // total: this.getTotal() + this.tax1 + this.tax2 + this.tax3,
      total: this.final_amount(),
      companyid: 3,
      PaymentTypeId: this.PaymentTypeId,
      customerid: +localStorage.getItem("customer_id"),
      customeraddressid: +localStorage.getItem('currentaddressid'),
      OrderedDateTime: moment(this.date + "" + this.time).format('YYYY-MM-DD hh:mm a'),
      Note: this.Omsg,
      Phone: localStorage.getItem('customer_emailid'),
      Email: localStorage.getItem('customer_PhoneNo'),
      Name: this.currentaddress.Name,
      Source:this.Device,
      DiscAmount:this.coopenAmount,
      DiscountRuleId:this.coopenId,
      StoreId:+this.nearbystoreId,
      nearestkm:+this.shortestKm
    };


    if (this.timeValid == true && this.date != undefined) {
      this.clicked = true;
      console.log("timeValid 1 :", this.timeValid)
      console.log("date :", this.date)

      var coid, mojo;
      this.poalert = false;
      if (this.PaymentTypeId == 1) {
        console.log("Online Payment")


        // this.sendordermessage();

        order_payload = {
          items: this.allcartitems,
          tax1: this.tax1,
          tax2: this.tax2,
          tax3: this.tax3,
          sub_total: this.getTotal(),
          // total: this.getTotal() + this.tax1 + this.tax2 + this.tax3,
          total: this.final_amount(),
          companyid: 3,
          PaymentTypeId: this.PaymentTypeId,
          customerid: +localStorage.getItem("customer_id"),
          customeraddressid: +localStorage.getItem('currentaddressid'),
          // OrderedDateTime: moment(this.date + "" + this.time).format('YYYY-MM-DD hh:mm a'),
          OrderedDateTime: moment(this.date + " " + this.time).format('YYYY-MM-DD hh:mm a'),
          Note: this.Omsg,
          Phone: localStorage.getItem('customer_emailid'),
          Email: localStorage.getItem('customer_PhoneNo'),
          Name: this.currentaddress.Name,
          Source:this.Device,
          DiscAmount:this.coopenAmount,
          DiscountRuleId:this.coopenId,
          StoreId:+this.nearbystoreId,
      nearestkm:+this.shortestKm

        };

        this.auth.placeorder({ payload: JSON.stringify(order_payload) }).subscribe(data => {
          if(data.hasOwnProperty("error")) {
            console.log(data)
            alert("Your login Id has been blocked by FBcakes. Plz, contact customer care. Ph. 90034 32888");
            return
          }
          coid = data["orderid"]
          console.log("Place Order Works")
          var paymentresponse = JSON.parse(data["paymentresponse"]);
          // var allpamentdetail = {
          //   Oid: data["orderid"],
          //   status: paymentresponse.payment_request.status,
          //   Comp_Responce: paymentresponse,
          // };
          var locst = ["selected_item", "selected_item_qtys", "cart_items", "accessories"]
          locst.forEach(ll => {
            localStorage.removeItem(ll);
          })
          document.location.href = paymentresponse.payment_request.longurl;
          console.log("Place Order Works2: ",data)

        })
      }






      else {
        console.log("Cash on Delivery")
        order_payload = {
          items: this.allcartitems,
          tax1: this.tax1,
          tax2: this.tax2,
          tax3: this.tax3,
          sub_total: this.getTotal(),
          // total: this.getTotal() + this.tax1 + this.tax2 + this.tax3,
          total: this.final_amount(),
          companyid: 3,
          PaymentTypeId: this.PaymentTypeId,
          customerid: +localStorage.getItem("customer_id"),
          customeraddressid: +localStorage.getItem('currentaddressid'),
          OrderedDateTime: moment(this.date + " " + this.time).format('YYYY-MM-DD hh:mm a'),
          Note: this.Omsg,
          Phone: localStorage.getItem('customer_emailid'),
          Email: localStorage.getItem('customer_PhoneNo'),
          Name: this.currentaddress.Name,
          Source:this.Device,
          DiscAmount:this.coopenAmount,
          DiscountRuleId:this.coopenId,
          StoreId:+this.nearbystoreId,
      nearestkm:+this.shortestKm
        };

        this.auth.placeorder2({ payload: JSON.stringify(order_payload) }).subscribe(data => {
          
          if(data.hasOwnProperty("error")) {
            console.log(data)
            alert("Your login Id has been blocked by FBcakes. Plz, contact customer care. Ph. 90034 32888");
            return
          }

          console.log(data);

          this.currentorderid = data;
          // Swal.fire('Order Confirmation!', "Your order Id #" + this.currentorderid.orderid + 
          // " has been recived and is now being processed...", 'success')

          // Swal.fire('Order Confirmation!', "Your order Id #" + this.currentorderid.orderid + 
          // " has been recived and is now being processed...", 'success').then((result) => {
          //   /* Read more about isConfirmed, isDenied below */
          //   if (result.value) {
          //     console.log("WOrks")
          //   } else if (result.isDenied) {
          //     console.log("Not WOrks")
          //   }
          // })

          Swal.fire({
            title: 'Order Placed Success!', 
            text: "Your order Id #" + this.currentorderid.orderid + 
            " has been placed and is now being processed...",
            icon: 'success',
            confirmButtonText: 'Track Order',
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((result) => {
            if (result.isConfirmed) {
             // console.log("WOrks")
              document.location.href = window.location.origin + '/myaccount'
            } else if (result.isDenied) {
              console.log("Not WOrks")
            }
          })

          // Swal.fire({
          //   title: 'Order Placed Success!', 
          //   text: "Your order Id #" + this.currentorderid.orderid + 
          //   " has been recived and is now being processed...",
          //   icon: 'success',
          //   showCancelButton: false,
          //   confirmButtonColor: '#3085d6',
          //   // cancelButtonColor: '#d33',
          //   confirmButtonText: 'Track Order',
          //   showConfirmButton: false,
          //   allowOutsideClick: false,
          //   allowEscapeKey: false
            
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     console.log("WOrks")
          //   }
          // })


          if (this.currentorderid.orderid != null) {

            // alert("Your order Id #" + this.currentorderid.orderid + " has been recived and is now being processed...")

            // Swal.fire('Order Confirmation!', "Your order Id #" + this.currentorderid.orderid + " has been recived and is now being processed...", 'success')

            // this.sendordermessage();
            var locst = ["selected_item", "selected_item_qtys", "cart_items", "accessories"]
            locst.forEach(ll => {
              localStorage.removeItem(ll)
            })

          //  setInterval(document.location.href = window.location.origin + '/myaccount', 50000);



            
           // document.location.href = window.location.origin + '/myaccount'


            // var locst = ["selected_item", "selected_item_qtys", "cart_items","accessories"]
            // locst.forEach(ll => {
            //   localStorage.removeItem(ll);
            // })
            // document.location.href = paymentresponse.payment_request.longurl;
          }
          else {
            alert("Order Saved Error! Contact your service provider")
          }
        })
      }
    }
    else {
      console.log("timeValid 2 :", this.timeValid)
      console.log("date :", this.date)
      this.poalert = true;
      this.clicked = false;
    }
  }

  

  sendordermessage() {
    var cord_name = "";
    this.currentorders = JSON.parse(localStorage.getItem("cart_items"))
    var msgtotal = this.getTotal() + this.tax1 + this.tax2 + this.tax3

    this.currentorders.forEach(orderitems => {
      cord_name += orderitems.Name + ","
    });

    var email_id = localStorage.getItem("customer_PhoneNo")
    var mob_no = localStorage.getItem("customer_emailid")

    console.log("Email :", email_id)
    console.log("Mobile :", mob_no)

    console.log("working");
    console.log("function_call :", email_id, "91" + mob_no, msgtotal, this.currentorderid.orderid, cord_name, this.PaymentTypeId)


    this.auth.sendordermsg(email_id, "91" + mob_no, msgtotal.toFixed(2), this.currentorderid.orderid, cord_name, this.PaymentTypeId).subscribe(data => {
      console.log("Message send:", data);
    })



  }

  makepayment() {
    var obj
    // this.auth.makepayment().subscribe(data => {
    //   document.location.href = data["payment_request"]["longUrl"]
    // })
  }
  changeaddress() {
    localStorage.setItem("toogle", "2")
    document.location.href = window.location.origin + '/myaccount'

  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(2, currentScroll - (currentScroll / 8));
      }
    })();
  }

  getDate() {
    var date = new Date();
    var toDate: any = date.getDate();
    if (toDate < 10) {
      toDate = '0' + toDate;
    }
    var month: any = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    var year = date.getFullYear();
    this.minDate = year + "-" + month + "-" + toDate;
    console.log("Today date")
    console.log("Mindate1", this.minDate)
  }

  setNow() {
  


console.log("today date: sfdaf",this.date)

var pretime=[];
    this.cartitems.forEach((x,i) => {
      pretime[i]=x.PrepTime;
    })

    console.log("pretime fdfcsxdc",Math.max(...pretime))
 var tt=Math.max(...pretime)
  //  this.date.SetDate(this.date+1)
   
    let now = new Date();
    let hours = ("0" + now.getHours()).slice(-2);
    let minutes = ("0" + now.getMinutes()).slice(-2);
    console.log("MY TIME HOYRS: ",hours)
    console.log("MY TIME mins: ",minutes)
   // hours=(9).toString();
    if(Number(hours)>=10 && Number(hours) <=19)
    {
hours=(Number(hours)+2).toString();
    }
    else if(Number(hours)<=9)
    {
      hours=(10).toString();
      minutes=(50).toString();
    }
    else
    {
      hours=(10).toString();
      minutes=(30).toString()
      var date5 = new Date();
      date5.setDate(date5.getDate() + 1);
      console.log("tomoro date: ",moment(date5).format('YYYY-MM-DD'))
      this.date=moment(date5).format('YYYY-MM-DD');
    }
   
    let str = hours + ':' + minutes;
    console.log("str TIME: ",str)
    this.time = str;
    console.log("MY TIME: ",this.time)


    this.validateTime()
  }
 
  // timeValid: boolean = true
  validateTime() {
    console.log("Valid time: ",this.time)
    // var pretime=this.cartitems[0].PrepTime
    var pretime=[];
    this.cartitems.forEach((x,i) => {
      pretime[i]=x.PrepTime;
    })

    console.log("pretime",Math.max(...pretime))


    var minDate = null
    if (moment().format('YYYY-MM-DD') == moment(this.date).format('YYYY-MM-DD')) {
      // minDate = new Date(moment().add(2, 'hours').format('YYYY-MM-DD HH:MM')).getTime()
      if(Math.max(...pretime)==0)
      {
        minDate = new Date(moment().add(2, 'hours').format('YYYY-MM-DD HH:MM')).getTime()
      }
      else
      {
        minDate = new Date(moment().add(Math.max(...pretime), 'hours').format('YYYY-MM-DD HH:MM')).getTime()
      }
    } else {
      minDate = new Date(moment(this.date).format('YYYY-MM-DD') + ' 10:00').getTime()
    }
    var maxDate = new Date(moment(this.date).format('YYYY-MM-DD') + ' 22:30').getTime()
    var currDate = new Date(moment(this.date).format('YYYY-MM-DD') + ' ' + this.time).getTime()

    console.log("Mindate2", minDate)
    console.log(maxDate, minDate, currDate)

    if (currDate >= minDate && currDate <= maxDate) {
      console.log('Valid Date')
      this.timeValid = true
    } else {
      console.log('In-Valid Date')
      this.timeValid = false
    }
  }

  validateTime222() {
    console.log(this.time)
    var minDate = null
    if (moment().format('YYYY-MM-DD') == moment(this.date).format('YYYY-MM-DD')) {
      minDate = new Date(moment().add(2, 'hours').format('YYYY-MM-DD HH:MM')).getTime()
    } 
    // else {
    //   minDate = new Date(moment(this.date).format('YYYY-MM-DD') + ' 10:00').getTime()
    // }
    // var maxDate = new Date(moment(this.date).format('YYYY-MM-DD') + ' 22:00').getTime()
    var currDate = new Date(moment(this.date).format('YYYY-MM-DD') + ' ' + this.time).getTime()

    this.timeValid = true

    // console.log("Mindate2", minDate)
    // console.log(maxDate, minDate, currDate)

    if (currDate >= minDate) {
      console.log('Valid Date')
      this.timeValid = true
    } else {
      console.log('In-Valid Date')
      this.timeValid = false
    }
  }


  // validateTime()
  // {
  //   var cdate= new Date ("")
  // }


  simple() {

    var Cust_Details = "Cust_Id: " + localStorage.getItem("customer_id") + ", Cust_Mobile: " + localStorage.getItem("customer_emailid");
    var CartItem = JSON.parse(localStorage.getItem("cart_items"))
    var loc=window.location.href;
    var pro = '';
    var data = '';


    CartItem.forEach(x => {
      pro += x.Name + ' - ' + x.quantity + ' - ₹' + x.prate + ' - msg: ' + x.Message + ' | ';
    }
    )

   // console.log("Window.location.href ", window.location.href)

    data = Cust_Details + ' | Prod: ' + pro+' location: '+loc +" |";
    console.log("Pro", data.slice(0, -2));

    // this.auth.simpleCart(data.slice(0, -2)).subscribe(data => {
    //   console.log("Simple :", data)
    // })
  }


  order_Pcode
  getCode(pCode)
  {
    console.log("pCOde",pCode)
    this.auth.orderPcode(pCode).subscribe(data => {
      this.order_Pcode=data
      console.log("order_Pcode",this.order_Pcode)

      var date = new Date();

      // console.log("EXP Date",this.order_Pcode[0].EndDate)
      // console.log("Today Date",date)
     // var Date=new Date(moment(date).format('YYYY-MM-DD'))

     if(this.order_Pcode.length)
     {
      var CDate=new Date(moment(this.date).format('YYYY-MM-DD'))
     
      var SDate=new Date(moment(this.order_Pcode[0].StartDate).format('YYYY-MM-DD'))
      var EDate=new Date(moment(this.order_Pcode[0].EndDate).format('YYYY-MM-DD'))
      
      console.log("Date",date)
      console.log("cDate",CDate)
     
      console.log("SDate",SDate)
      console.log("EDate",EDate)

      


      if(this.order_Pcode[0].MiniOrderValue<=this.getTotal())
      {
        console.log("Amount High");

        if(CDate>=SDate && CDate<=EDate || date>=SDate && date<=EDate)
        {

          console.log("Its Valid Date");
          // this.coopenAmount=this.order_Pcode[0].DiscountValue;
          this.coopenAmount=(((this.getTotal()+this.settaxes2())*this.order_Pcode[0].DiscountType)/100)
          console.log(" this.coopenAmount :",this.coopenAmount)
          this.coopenId=this.order_Pcode[0].Id;
          console.log("coopenAmount",this.coopenAmount);
          this.coopenAlert=1;
        }
        else
        {
          console.log("offer not start or Experied");
          this.coopenAlert=2;
        }
      }
      else
      {
        console.log("Amount Low");
        this.coopenAlert=3;
      }
     }

     else{
console.log("ERROR")
this.coopenAlert=4;
     }
 
    })

   
   
  }

  getCustomer()
  {
    var cid=localStorage.getItem("customer_id")
    console.log("Customer Id:",cid)

    this.auth.cust_det(cid).subscribe(data => {
      console.log("data :", data)
      this.Cust_DET=data
    })
    
  }

// .......................................

  placeorder_gmap() {

    console.log("this.accessories", this.accessories)

    if (this.accessories != null) {

      this.allcartitems = [...this.cartitems, ...this.accessories]
      console.log("item & Acc :", this.allcartitems)
    }
    else {

      this.allcartitems = [...this.cartitems]
      console.log("item :", this.allcartitems)
    }

    console.log("All_order:", this.allcartitems)
    this.CIDCheckout = localStorage.getItem('currentaddressid')

    console.log("CID Checkout", localStorage.getItem('currentaddressid'))

// var nearstoreId=localStorage.getItem('nearbystore_id')
// var nearestkm=localStorage.getItem('nearbystore_km')

    var order_payload = {
      items: this.allcartitems,
      tax1: this.tax1,
      tax2: this.tax2,
      tax3: this.tax3,
      sub_total: this.getTotal(),
      total: this.final_amount(),
      companyid: 3,
      PaymentTypeId: this.PaymentTypeId,
      customerid: +localStorage.getItem("customer_id"),
      customeraddressid: +localStorage.getItem('currentaddressid'),
      OrderedDateTime: moment(this.date + "" + this.time).format('YYYY-MM-DD hh:mm a'),
      Note: this.Omsg,
      Phone: localStorage.getItem('customer_emailid'),
      Email: localStorage.getItem('customer_PhoneNo'),
      Name: this.currentaddress.Name,
      Source:this.Device,
      DiscAmount:this.coopenAmount,
      DiscountRuleId:this.coopenId,
      StoreId:+this.nearbystoreId,
      nearestkm:+this.shortestKm
    };


    if (this.timeValid == true && this.date != undefined) {
      this.clicked = true;
      console.log("timeValid 1 :", this.timeValid)
      console.log("date :", this.date)

      var coid, mojo;
      this.poalert = false;
   
   
   
      if (this.PaymentTypeId == 1) {
        console.log("Online Payment")

        order_payload = {
          items: this.allcartitems,
          tax1: this.tax1,
          tax2: this.tax2,
          tax3: this.tax3,
          sub_total: this.getTotal(),
          total: this.final_amount(),
          companyid: 3,
          PaymentTypeId: this.PaymentTypeId,
          customerid: +localStorage.getItem("customer_id"),
          customeraddressid: +localStorage.getItem('currentaddressid'),
          OrderedDateTime: moment(this.date + " " + this.time).format('YYYY-MM-DD hh:mm a'),
          Note: this.Omsg,
          Phone: localStorage.getItem('customer_emailid'),
          Email: localStorage.getItem('customer_PhoneNo'),
          Name: this.currentaddress.Name,
          Source:this.Device,
          DiscAmount:this.coopenAmount,
          DiscountRuleId:this.coopenId,
          StoreId:+this.nearbystoreId,
      nearestkm:+this.shortestKm

        };

        this.auth.placeorder({ payload: JSON.stringify(order_payload) }).subscribe(data => {
          if(data.hasOwnProperty("error")) {
            console.log(data)
            alert("Your login Id has been blocked by FBcakes. Plz, contact customer care. Ph. 90034 32888");
            return
          }
          coid = data["orderid"]
          console.log("Place Order Works")
          console.log("Data",data)

          // instamojo
          var paymentresponse = JSON.parse(data["paymentresponse"]);


          // PhonePay
         // var paymentresponse: any = data
          //var paymentresponse = client.Execute(request);
          // console.log("paymentresponse",paymentresponse)
         //  console.log("paymentresponse Phonepay",paymentresponse.paymentUrl)
        
        //  old ls

          // var locst = ["selected_item", "selected_item_qtys", "cart_items", "accessories"]
          // locst.forEach(ll => {
          //   localStorage.removeItem(ll);
          // })

          if (coid != null) {
            var locst = ["selected_item", "selected_item_qtys", "cart_items", "accessories"]
            locst.forEach(ll => {
              localStorage.removeItem(ll)
            })
          }
          else {
            alert("Order Saved Error! Contact your service provider")
          }

          // console.log("paymentresponse.payment_request.longurl",paymentresponse.payment_request.longurl)
          // console.log("paymentresponse",paymentresponse.payment_request)

          // instamojo
          document.location.href = paymentresponse.payment_request.longurl;
         

          // PhonePay
         // document.location.href = paymentresponse.paymentUrl
          
          
          console.log("Place Order Works2: ",data)
        })
      }

      else {
        console.log("Cash on Delivery")
        order_payload = {
          items: this.allcartitems,
          tax1: this.tax1,
          tax2: this.tax2,
          tax3: this.tax3,
          sub_total: this.getTotal(),
          total: this.final_amount(),
          companyid: 3,
          PaymentTypeId: this.PaymentTypeId,
          customerid: +localStorage.getItem("customer_id"),
          customeraddressid: +localStorage.getItem('currentaddressid'),
          OrderedDateTime: moment(this.date + " " + this.time).format('YYYY-MM-DD hh:mm a'),
          Note: this.Omsg,
          Phone: localStorage.getItem('customer_emailid'),
          Email: localStorage.getItem('customer_PhoneNo'),
          Name: this.currentaddress.Name,
          Source:this.Device,
          DiscAmount:this.coopenAmount,
          DiscountRuleId:this.coopenId,
          StoreId:+this.nearbystoreId,
          nearestkm:+this.shortestKm
        };

        this.auth.placeorder2({ payload: JSON.stringify(order_payload) }).subscribe(data => {
          
          if(data.hasOwnProperty("error")) {
            console.log(data)
            alert("Your login Id has been blocked by FBcakes. Plz, contact customer care. Ph. 90034 32888");
            return
          }

          console.log(data);
          this.currentorderid = data;
          Swal.fire({
            title: 'Order Placed Success!', 
            text: "Your order Id #" + this.currentorderid.orderid + 
            " has been placed and is now being processed...",
            icon: 'success',
            confirmButtonText: 'Track Order',
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((result) => {
            if (result.isConfirmed) {
              document.location.href = window.location.origin + '/orderlist/'+this.currentorderid.orderid
            } else if (result.isDenied) {
              console.log("Not WOrks")
            }
          })

          if (this.currentorderid.orderid != null) {
            var locst = ["selected_item", "selected_item_qtys", "cart_items", "accessories"]
            locst.forEach(ll => {
              localStorage.removeItem(ll)
            })
          }
          else {
            alert("Order Saved Error! Contact your service provider")
          }
        })


      }
    }
    else {
      console.log("timeValid 2 :", this.timeValid)
      console.log("date :", this.date)
      this.poalert = true;
      this.clicked = false;
    }
  }

  testapply()
  {
    var locst = ["selected_item", "selected_item_qtys", "cart_items", "accessories"]
    locst.forEach(ll => {
      localStorage.removeItem(ll);
    })
  }

  placeorder_gmap_enc_test()
  {
    console.log("data: ","Ph:"+localStorage.getItem('customer_emailid')+", Name: "+this.currentaddress.Name+", Address: "+this.currentaddress.Address+", [Order: "+this.cartitems[0].Name+"|"+this.cartitems[0].Message+"|₹"+this.cartitems[0].Price+"] Del Time: "+moment(this.date + "" + this.time).format('YYYY-MM-DD hh:mm a')+", Notes: "+this.Omsg
    )
    console.log("date:", moment(this.date + "" + this.time).format('YYYY-MM-DD hh:mm a'))
    console.log("date:", this.date + "," + this.time)

    // var order_payload = {
    //   items: this.allcartitems,
    //   // tax1: this.tax1,
    //   // tax2: this.tax2,
    //   // tax3: this.tax3,
    //   sub_total: this.getTotal(),
    //   // total: this.final_amount(),
    //   companyid: 3,
    //   PaymentTypeId: this.PaymentTypeId,
    //   customerid: +localStorage.getItem("customer_id"),
    //   customeraddressid: +localStorage.getItem('currentaddressid'),
    //   OrderedDateTime: moment(this.date + "" + this.time).format('YYYY-MM-DD hh:mm a'),
    //   Note: this.Omsg,
    //   Phone: localStorage.getItem('customer_emailid'),
    //   Email: localStorage.getItem('customer_PhoneNo'),
    //   Name: this.currentaddress.Name,
    //   Source:this.Device,
    //   // DiscAmount:this.coopenAmount,
    //   DiscountRuleId:this.coopenId,
    //   StoreId:+this.nearbystoreId,
    //   nearestkm:+this.shortestKm,
    //   //service:key,
    // //  cast:"Ph:"+localStorage.getItem('customer_emailid')+", Name: "+this.currentaddress.Name+", Address: "+this.currentaddress.Address+", [Order: "+this.cartitems[0].Name+"|"+this.cartitems[0].Message+"|₹"+this.cartitems[0].Price+"] Del Time: "+moment(this.date + "" + this.time).format('YYYY-MM-DD hh:mm a')+", Notes: "+this.Omsg
    // };

    //console.log(order_payload)
  }

  placeorder_gmap_enc2()
  {
    var order_payload = {
      items: this.allcartitems}
      console.log("order_payload: ",order_payload)
  }
  placeorder_gmap_encfghfgh() {
    var key = [this.final_amount(), this.tax1, this.tax2, this.coopenAmount].join("|")
    key = btoa(key)

    console.log("this.accessories", this.accessories)

    if (this.accessories != null) {

      this.allcartitems = [...this.cartitems, ...this.accessories]
      console.log("item & Acc :", this.allcartitems)
    }
    else {

      this.allcartitems = [...this.cartitems]
      console.log("item :", this.allcartitems)
    }

    console.log("All_order:", this.allcartitems)
    this.CIDCheckout = localStorage.getItem('currentaddressid')

    console.log("CID Checkout", localStorage.getItem('currentaddressid'))

    var order_payload = {
      items: this.allcartitems,
      // tax1: this.tax1,
      // tax2: this.tax2,
      // tax3: this.tax3,
      sub_total: this.getTotal(),
      // total: this.final_amount(),
      companyid: 3,
      PaymentTypeId: this.PaymentTypeId,
      customerid: +localStorage.getItem("customer_id"),
      customeraddressid: +localStorage.getItem('currentaddressid'),
      OrderedDateTime: moment(this.date + "" + this.time).format('YYYY-MM-DD hh:mm a'),
      Note: this.Omsg,
      Phone: localStorage.getItem('customer_emailid'),
      Email: localStorage.getItem('customer_PhoneNo'),
      Name: this.currentaddress.Name,
      Source:this.Device,
      DiscAmount:this.coopenAmount,
      DiscountRuleId:this.coopenId,
      StoreId:+this.nearbystoreId,
      nearestkm:+this.shortestKm,
      service:key,
      cast:"Ph:"+localStorage.getItem('customer_emailid')+", Name: "+this.currentaddress.Name+", Address: "+this.currentaddress.Address+", [Order: "+this.cartitems[0].Name+"|"+this.cartitems[0].quantity+"|"+this.cartitems[0].Message+"|₹"+this.cartitems[0].prate+"] Del Time: "+this.date + ", " + this.time+", Notes: "+this.Omsg+" [Order Amount total: ₹"+this.final_amount().toFixed(2)
    };

    console.log("Cast",order_payload)
  }

  placeorder_gmap_enc() {

    // console.log("BillAmount: ", this.final_amount())
    // console.log("tax1: ", this.tax1)
    // console.log("tax2: ", this.tax2)
    // console.log("discAmt: ", this.coopenAmount)



    var key = [this.final_amount(), this.tax1, this.tax2, this.coopenAmount].join("|")
    key = btoa(key)

    console.log("this.accessories", this.accessories)

    if (this.accessories != null) {

      this.allcartitems = [...this.cartitems, ...this.accessories]
      console.log("item & Acc :", this.allcartitems)
    }
    else {

      this.allcartitems = [...this.cartitems]
      console.log("item :", this.allcartitems)
    }

    console.log("All_order:", this.allcartitems)
    this.CIDCheckout = localStorage.getItem('currentaddressid')

    console.log("CID Checkout", localStorage.getItem('currentaddressid'))

// var nearstoreId=localStorage.getItem('nearbystore_id')
// var nearestkm=localStorage.getItem('nearbystore_km')

    var order_payload = {
      items: this.allcartitems,
      // tax1: this.tax1,
      // tax2: this.tax2,
      // tax3: this.tax3,
      sub_total: this.getTotal(),
      // total: this.final_amount(),
      companyid: 3,
      PaymentTypeId: this.PaymentTypeId,
      customerid: +localStorage.getItem("customer_id"),
      customeraddressid: +localStorage.getItem('currentaddressid'),
      OrderedDateTime: moment(this.date + "" + this.time).format('YYYY-MM-DD hh:mm a'),
      Note: this.Omsg,
      Phone: localStorage.getItem('customer_emailid'),
      Email: localStorage.getItem('customer_PhoneNo'),
      Name: this.currentaddress.Name,
      Source:this.Device,
      DiscAmount:this.coopenAmount,
      DiscountRuleId:this.coopenId,
      StoreId:+this.nearbystoreId,
      nearestkm:+this.shortestKm,
      service:key,
      cast:"Ph:"+localStorage.getItem('customer_emailid')+", Name: "+this.currentaddress.Name+", Address: "+this.currentaddress.Address+", [Order: "+this.cartitems[0].Name+"|"+this.cartitems[0].quantity+"|"+this.cartitems[0].Message+"|₹"+this.cartitems[0].prate+"] Del Time: "+this.date + ", " + this.time+", Notes: "+this.Omsg+" [Order Amount total: ₹"+this.final_amount().toFixed(2)
    };

    console.log("Cast",order_payload)


    if (this.timeValid == true && this.date != undefined) {
      this.clicked = true;
      console.log("timeValid 1 :", this.timeValid)
      console.log("date :", this.date)

      var coid, mojo;
      this.poalert = false;
   
   
   
      if (this.PaymentTypeId == 1) {
        console.log("Online Payment")

        order_payload = {
          items: this.allcartitems,
          // tax1: this.tax1,
          // tax2: this.tax2,
          // tax3: this.tax3,
          sub_total: this.getTotal(),
          // total: this.final_amount(),
          companyid: 3,
          PaymentTypeId: this.PaymentTypeId,
          customerid: +localStorage.getItem("customer_id"),
          customeraddressid: +localStorage.getItem('currentaddressid'),
          OrderedDateTime: moment(this.date + " " + this.time).format('YYYY-MM-DD hh:mm a'),
          Note: this.Omsg,
          Phone: localStorage.getItem('customer_emailid'),
          Email: localStorage.getItem('customer_PhoneNo'),
          Name: this.currentaddress.Name,
          Source:this.Device,
          DiscAmount:this.coopenAmount,
          DiscountRuleId:this.coopenId,
          StoreId:+this.nearbystoreId,
      nearestkm:+this.shortestKm,
      service:key,
      cast:"Ph:"+localStorage.getItem('customer_emailid')+", Name: "+this.currentaddress.Name+", Address: "+this.currentaddress.Address+", [Order: "+this.cartitems[0].Name+"|"+this.cartitems[0].quantity+"|"+this.cartitems[0].Message+"|₹"+this.cartitems[0].prate+"] Del Time: "+this.date + ", " + this.time+", Notes: "+this.Omsg+" [Order Amount total: ₹"+this.final_amount().toFixed(2)
  

        };

        this.auth.placeorder({ payload: JSON.stringify(order_payload) }).subscribe(data => {
          if(data.hasOwnProperty("error")) {
            console.log(data)
            // alert("Your login Id has been blocked by FBcakes. Plz, contact customer care. Ph. 90034 32888");
            alert("Something Error!!! Plz, make Cash on Delivery :(");
            return
          }
          coid = data["orderid"]
          console.log("Place Order Works")
          console.log("Data",data)

          // instamojo
         // var paymentresponse = JSON.parse(data["paymentresponse"]);
        //  console.log("paymentresponse.payment_request.longurl",data["paymentresponse"]["payment_request"].longurl)


          // PhonePay
       //  var paymentresponse: any = data
         // var paymentresponse = client.Execute(request);
        //  console.log("paymentresponse",paymentresponse)
//console.log("paymentresponse Phonepay",paymentresponse.paymentUrl)
        
        //  old ls

          // var locst = ["selected_item", "selected_item_qtys", "cart_items", "accessories"]
          // locst.forEach(ll => {
          //   localStorage.removeItem(ll);
          // })

          if (coid != null) {
            var locst = ["selected_item", "selected_item_qtys", "cart_items", "accessories"]
            locst.forEach(ll => {
              localStorage.removeItem(ll)
            })
            localStorage.setItem("customerid", coid);
          }
          else {
           // alert("Order Saved Error! Contact your service provider")
            alert("Something Error!!! Plz, make Cash on Delivery :(");
          }

          // console.log("paymentresponse.payment_request.longurl",paymentresponse.payment_request.longurl)
          // console.log("paymentresponse",paymentresponse.payment_request)

        //  instamojo
         document.location.href = data["paymentresponse"]["payment_request"].longurl;
         

          // PhonePay
         // document.location.href = paymentresponse.paymentUrl
          
          
          console.log("Place Order Works2: ",data)
        })
      }

     

      else {
        console.log("Cash on Delivery")
        order_payload = {
          items: this.allcartitems,
          // tax1: this.tax1,
          // tax2: this.tax2,
          // tax3: this.tax3,
          sub_total: this.getTotal(),
          // total: this.final_amount(),
          companyid: 3,
          PaymentTypeId: this.PaymentTypeId,
          customerid: +localStorage.getItem("customer_id"),
          customeraddressid: +localStorage.getItem('currentaddressid'),
          OrderedDateTime: moment(this.date + " " + this.time).format('YYYY-MM-DD hh:mm a'),
          Note: this.Omsg,
          Phone: localStorage.getItem('customer_emailid'),
          Email: localStorage.getItem('customer_PhoneNo'),
          Name: this.currentaddress.Name,
          Source:this.Device,
          DiscAmount:this.coopenAmount,
          DiscountRuleId:this.coopenId,
          StoreId:+this.nearbystoreId,
          nearestkm:+this.shortestKm,
          service:key,
          cast:"Ph:"+localStorage.getItem('customer_emailid')+", Name: "+this.currentaddress.Name+", Address: "+this.currentaddress.Address+", [Order: "+this.cartitems[0].Name+"|"+this.cartitems[0].quantity+"|"+this.cartitems[0].Message+"|₹"+this.cartitems[0].prate+"] Del Time: "+this.date + ", " + this.time+", Notes: "+this.Omsg+" [Order Amount total: ₹"+this.final_amount().toFixed(2)
        };
        console.log("Cash on Delivery order_payload: ",order_payload)

        // this.auth.getrepayonlineI(key).subscribe((data: any) => {

        this.auth.placeorder2({ payload: JSON.stringify(order_payload) }).subscribe(data => {
          
          if(data.hasOwnProperty("error")) {
            console.log(data)
            alert("Your login Id has been blocked by FBcakes. Plz, contact customer care. Ph. 90034 32888");
            return
          }

          console.log(data);
          this.currentorderid = data;
          Swal.fire({
            title: 'Order Placed Success!', 
            text: "Your order Id #" + this.currentorderid.orderid + 
            " has been placed and is now being processed...",
            icon: 'success',
            confirmButtonText: 'Track Order',
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((result) => {
            if (result.isConfirmed) {
              document.location.href = window.location.origin + '/orderlist/'+this.currentorderid.orderid
            } else if (result.isDenied) {
              console.log("Not WOrks")
            }
          })

          if (this.currentorderid.orderid != null) {
            var locst = ["selected_item", "selected_item_qtys", "cart_items", "accessories"]
            locst.forEach(ll => {
              localStorage.removeItem(ll)
            })
          }
          else {
            alert("Order Saved Error! Contact your service provider")
          }
        })


      }
    }
    else {
      console.log("timeValid 2 :", this.timeValid)
      console.log("date :", this.date)
      this.poalert = true;
      this.clicked = false;
    }
  }

  ptyRes()
  {
    this.PaymentTypeId=2
    alert("Temporary FBcakes Online Pay Stopped...")
    
  }

}
